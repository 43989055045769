import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

import './../../../scss/protocol-summary.scss';

class ProtocolSummary extends Component {
    render() {

        const protocolSummary = this.props.protocolSummary;
        const { protocolNo, targetNoOfOnStudyParticipants, targetNoOfParticipantsToBeScreened } = protocolSummary;

        return (
            <div className="protocol-summary__container">
                <ul className="protocol-summary">
                    <li>
                        <div className="small-heading">Protocol number</div>
                        <div className="protocol-summary__item">{protocolNo}</div>
                    </li>
                    <li>
                        <div className="small-heading">Target no. of on-study participants</div>
                        <div className="protocol-summary__item">{targetNoOfOnStudyParticipants}</div>
                    </li>
                    <li>
                        <div className="small-heading">Target no. of participants to be screened</div>
                        <div className="protocol-summary__item">{targetNoOfParticipantsToBeScreened}</div>
                    </li>
                </ul>
            </div>
        )
    }
}

export default ProtocolSummary;

ProtocolSummary.propTypes = {
    protocolSummary: PropTypes.shape({
        protocolNo: PropTypes.string,
        targetNoOfOnStudyParticipants: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        targetNoOfParticipantsToBeScreened: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
    }),
};