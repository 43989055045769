import React, { Component } from 'react';
import SearchResultList from './SearchResultList'
import SearchHeader from './SearchHeader'
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { SEARCH_PARTICIPANT_BY_LAB_NUMBER_API} from './../../../src/utils/constants';

import './../../scss/search.scss';
class SearchContainer extends Component {

    state = {
        searchResults: null,
        isLoading: false
    }
    getMockData = () => {
        let temp = []
        temp.push({ data: '123'})
        temp.push({ data: '123'})
        temp.push({ data: '123'})
        temp.push({ data: '123'})
        temp.push({ data: '123'})

        return temp;
    }
    onSubmit = (value)=>{
        if(value == null || value == ""){
            return;
        }
        this.setState({isLoading: true})
        const url = SEARCH_PARTICIPANT_BY_LAB_NUMBER_API.POST;
        Auth.currentSession().then((response) => {
            const headers = { Authorization: response.idToken.jwtToken};
            axios.post(url, {labNumber: value, action:'s'}, { headers })
                .then((response) => {

                    let result = JSON.parse(response.data.body)
                    console.log(result)
                    if(response.data != null){
                        this.setState({isLoading: false, searchResults: result})
                    }

                })
                .catch((error) => {
                    console.error('error:',error);
                    this.setState({isLoading: false})
                });
        })
    }
    componentDidMount() {
    }
    deleteCollection = (barcode, episode)=>{
        console.log(barcode);

        this.setState({isLoading: true})
        const url = SEARCH_PARTICIPANT_BY_LAB_NUMBER_API.POST;
        Auth.currentSession().then((response) => {
            const headers = { Authorization: response.idToken.jwtToken};
            axios.post(url, {labNumber: barcode, action:'d', episode: episode}, { headers })
                .then((response) => {

                    let result = JSON.parse(response.data.body)
                    console.log(result)
                    if(response.data != null){
                        this.setState({isLoading: false, searchResults: result})
                    }

                })
                .catch((error) => {
                    console.error('error:',error);
                    this.setState({isLoading: false})
                });
        })
    }
    render(){
        return (
            <React.Fragment>
                <div className="page-heading">
                    <div className="container search-container">
                        <SearchHeader
                                onSubmit={this.onSubmit}
                        />
                        {
                            this.state.searchResults != null && <SearchResultList
                            searchResults={this.state.searchResults}
                            deleteCollection={this.deleteCollection}
                            isLoading={this.state.isLoading}
                        />
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default SearchContainer;