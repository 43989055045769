import React, { Component } from 'react';
import SearchResultList from './SearchResultList'
import PropTypes from 'prop-types';
import './../../scss/search.scss';
import moment from 'moment';
const SearchResultItem = (props) => {
    return (
        <div className="row search-result-row">
            <div className = "col-2">{props.resultItem.barcode}</div>
            <div className = "col-2">{props.resultItem.screening_no}</div>
            <div className = "col-2">{props.resultItem.Protocol_code}</div>
            <div className = "col-2">{props.resultItem.episode || 'SCREENING'}</div>
            <div className = "col-2">{moment(props.resultItem.Collection_time).utc().format("DD/MM/YYYY HH:mm") }</div>
            <div className = "col-2">
                            <div 
                                style={{
                                    marginRight: 10,
                                    color: '#753bbd',
                                    cursor: 'pointer'
                                }}
                                onClick={()=>{props.deleteCollection(props.resultItem.barcode, props.resultItem.episode)}}><u>Delete</u></div>
                        
                    </div>
        </div>
    )
    
}
export default SearchResultItem;
SearchResultItem.propTypes = {
    index: PropTypes.number,
    deleteCollection: PropTypes.func
   
};