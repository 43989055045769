import { withFormik } from 'formik';

import * as yup from 'yup';

import { Auth } from 'aws-amplify';

import { passwordRegex, passwordRegexErrorFlag } from './../../../utils/utils';

import SetPasswordForm from './SetPasswordForm';

/**
 * Formik HOC for form validation
 */
const SetPasswordContainer = withFormik({
    mapPropsToValues: () => ({
        newPassword: '',
        confirmNewPassword: ''
    }),
    validationSchema: yup.object().shape({
        newPassword: yup.string().required('Please enter a new password')
            .matches(passwordRegex, passwordRegexErrorFlag),
        confirmNewPassword: yup.string().required('Please confirm your new password')
            .oneOf([yup.ref('newPassword'), null], 'Your passwords do not match'),
    }),
    handleSubmit: (values, { props, setSubmitting, setErrors }) => {
        setSubmitting(true);
        console.log(values);
        console.log(props.user);
        const newPassword = values.newPassword;
        // Call AWS Amplify
        Auth.completeNewPassword(props.user, newPassword)
            // If successful...
            .then((data) => {
                console.log("DATAAA:", data);
                // Form is no longer submitting
                setSubmitting(false);
                // Update password set success state
                props.updateSetPasswordSuccess(true);
            })
            // If unsuccessful...
            .catch((error) => {
                console.log(error);
                // Form is no longer submitting
                setSubmitting(false);
                setErrors({ form: error.message });
            });
    },
    displayName: 'SetPasswordForm', // helps with React DevTools
    validateOnBlur: false,
    validateOnChange: false,
})(SetPasswordForm);

export default SetPasswordContainer;