import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import moment from 'moment';

// import '../scss/lib/react-day-picker/style.css';
import '../../../src/scss/lib/react-day-picker/style.css';
// import '../scss/react-day-picker.scss';

import { formatDate, parseDate } from 'react-day-picker/moment';
import { dateFormat } from '../../utils/constants';

class CustomisedDayPicker extends Component {

    firstDosingDateInput = React.createRef();

    handleDayChange = (day) => {

        
        // let value = moment(day).format(dateFormat);
        
        this.props.updateSelectedDays(day);
    }

    render() {

        // console.log(this.props.disabledDays)
        const inputProps = {
            className: 'form-control form-control--datepicker',
            ref: this.firstDosingDateInput,
        }
        const dayPickerProps={
            disabledDays: this.props.disabledDays
          }
        
        return (
            <DayPickerInput 
                dayPickerProps={dayPickerProps}
                disabledDays={this.props.disabledDays}
                value={this.props.date}
                onDayChange={this.handleDayChange}
                placeholder={dateFormat}
                inputProps={inputProps}
                format={dateFormat}
                formatDate={formatDate}
                parseDate={parseDate}
            />
        )
    }
}

export default CustomisedDayPicker;

CustomisedDayPicker.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    updateSelectedDays: PropTypes.func.isRequired,
    disabledDays: PropTypes.any
};

CustomisedDayPicker.defaultProps = {
    date: new Date()

}