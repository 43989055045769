import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Preloader from './../common/Preloader';
import SearchResultItem from './SearchResultItem'

import './../../scss/analytes-panels.scss';

class SearchResultList extends Component {

    state = {
        
    }

    componentDidMount() {
    }

    render() {
        return (
            <React.Fragment>
                <div className="analyte-panel-list flex-table filter-table container search-result-container">
                    <div className="row list--heading">
                        <div className = "col-2">Lab Number</div>
                        <div className = "col-2">SCREENING NUMBER</div>
                        <div className = "col-2">Protocol Number</div>
                        <div className = "col-2">Episode</div>
                        <div className = "col-4">Collected</div>
                    </div>
                    {
                        this.props.searchResults && this.props.searchResults.length ?
                        this.props.searchResults.map((resultItem, index) => {
                            return (
                                <SearchResultItem
                                    key={index + 'list'}
                                    resultItem = {resultItem}
                                    deleteCollection={this.props.deleteCollection}
                                    index = {index}
                                />
                            )
                        })
                        :
                        <div className="row list--item list--no-results">
                            <div className="item">{this.props.isLoading ? 'Fetching results...' : 'Tube barcode is not found'}</div>
                        </div>
                    }
                    {
                    (this.props.isLoading) ? <Preloader /> : null
                }
                </div>
            </React.Fragment>
        )
    }
}

export default SearchResultList;

SearchResultList.propTypes = {
    searchResults: PropTypes.array,
    isLoading: PropTypes.bool.isRequired,
    deleteCollection: PropTypes.func
   
};
