import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Checkbox from './../common/Checkbox';

import './../../scss/filter.scss';

import { PROTOCOL_STATUS_MAPPING } from './../../utils/protocol-statuses';

class ProtocolFilter extends Component {

    handleFilter = () => {
        return '';
    }

    handleFilterByString = (e) => {
        const inputValue = e.currentTarget.value;

        // Update the search query string
        this.props.updateSearchQuery(inputValue);
    }

    handleFilterByCheckbox = (e) => {
        const target = e.target;
        const value = target.checked;
        const name = target.id;

        // Update the checked state of the status
        this.props.updateStatusFilter(name, value);
    }

    handleSubmit = (e) => {
        e.preventDefault();
    }

    render() {
        const existingStatuses = this.props.existingStatuses;

        return (
            <form className="filter__container" onSubmit={this.handleSubmit}>
                <div className="filters__heading">Filter</div>

                <div className="filters">                
                    <div className="filter search-filter__container">
                        {/* React's onChange has the same behaviour as onInput */}
                        
                        <label htmlFor="search" className="filter-label">Protocol number</label>
                        <input name="search" type="text" className="form-control" placeholder="Search" onChange={this.handleFilterByString} />
                    </div>

                    <div className="filter checkbox-filter__container">
                        <label htmlFor="status" className="filter-label">Status</label>

                        {
                            Object.keys(existingStatuses).map(status => {

                                const label = <span><span className={`status-icon status--${status}`}></span><span>{PROTOCOL_STATUS_MAPPING[status]}</span></span>;
                                
                                return (
                                    <div className="checkbox-filter" key={status}>
                                        <Checkbox
                                            id={status}
                                            name="status"
                                            value={status}
                                            checked={existingStatuses[status]}
                                            label={label}
                                            onChange={this.handleFilterByCheckbox}
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </form>
        );
    }
}

ProtocolFilter.propTypes = {
    updateSearchQuery: PropTypes.func,
    updateStatusFilter: PropTypes.func,
    existingStatuses: PropTypes.object,
};

export default ProtocolFilter;