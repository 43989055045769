import { withFormik } from 'formik';
import * as yup from 'yup';

import { Auth } from 'aws-amplify';

import { passwordRegex, passwordRegexErrorFlag } from './../../../utils/utils';

import ResetPasswordConfirmForm from './ResetPasswordConfirmForm';

/**
 * Formik HOC for form validation
 */
const ResetPasswordConfirmContainer = withFormik({
    mapPropsToValues: () => ({
         confirmationCode: '', 
         newPassword: '', 
         confirmNewPassword: '' 
        }),
    validationSchema: yup.object().shape({
        confirmationCode: yup.string().required('Please enter a confirmation code'),
        newPassword: yup.string().required('Please enter a new password')
                        .matches(passwordRegex, passwordRegexErrorFlag),
        confirmNewPassword: yup.string().required('Please confirm your new password')
                                .oneOf([yup.ref('newPassword'), null], 'Your passwords do not match'),
    }),
    handleSubmit: (values, { props, setSubmitting, setErrors }) => {
        // Get the email from the previously submitted form
        // It was sent as a prop from ResetPasswordManager
        const email = props.email;
        const { confirmationCode, newPassword } = values;
       // console.log(email, confirmationCode, newPassword);
        setSubmitting(true);
        // Call AWS Amplify
        Auth.forgotPasswordSubmit(email, confirmationCode, newPassword)
            .then((data) => {
               // console.log(data);
                // Form is no longer submitting
                setSubmitting(false);

                // Update password reset success state
                props.updateResetPasswordSuccess(true);
            })
            .catch(error => {
               // console.log(error);
                // Form is no longer submitting
                setSubmitting(false);

                if (error.code === 'CodeMismatchException') {
                    setErrors({ confirmationCode: 'Your confirmation code is incorrect' });
                } else {
                    setErrors({ form: error.code });
                }
            });
    },
    displayName: 'ResetPasswordConfirmForm', // helps with React DevTools
    validateOnBlur: false,
    validateOnChange: false,
})(ResetPasswordConfirmForm);

export default ResetPasswordConfirmContainer;