import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import AnalytePanelFilter from './AnalytePanelFilter';
import AnalytePanelList from './AnalytePanelList';

import axios from 'axios';
import { Auth } from 'aws-amplify';
import { PANEL_API , DELETE_PANEL_API} from './../../utils/constants';
import { sortByObjectProperty } from './../../utils/utils';

import './../../scss/analytes-panels.scss';

class AnalytePanelListContainer extends Component {

    state = {
        panels: [],
        filteredPanels: [],
        isLoading: false,
        serverError: '',
        searchQuery: '',
    }

    componentDidMount() {
        this.fetchPanels();
    }

    fetchPanels = () => {
        // Fetching...
        this.setState({ isLoading: true, })

        const url = PANEL_API.GET;

        Auth.currentSession().then((response) => {
            const headers = { Authorization: response.idToken.jwtToken };

            // Fetch the Panels list
            axios.get(url, { headers })
                .then((response) => {
                    //console.log(response);
                    if (response.data) {
                        const result = response.data.result;
                        if (result && result.length) {
                            result.sort(sortByObjectProperty('Panel_code'));
                            const parsedResult = this.parseAnalytes(result);
                            // console.log('panelsss:',parsedResult);
    
                            this.setState({
                                panels: parsedResult,
                                filteredPanels: parsedResult,
                            });
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    this.setState({ isLoading: false, })
                });

        }).catch((error) => {
            console.error(error);
            this.setState({ isLoading: false, })
        });


    }

    parseAnalytes = (result) => {
        // console.log(result);
        return result.map(item => {
            const analytesString = item.Analytes;
            const parsed = JSON.parse(analytesString);
            if(parsed != null){
                const joined = parsed.join(', ');
                item.Analytes = joined;
            }
            return item;
        })
    }

    /**
     * Filter the list of Panels by the search query string
     */
    filterPanelsByString = (searchQuery) => {
        const panels = this.state.panels;
        const string = searchQuery.toLowerCase();
        const result = panels.filter(panel => {
            const panelCode = panel.Panel_code.toLowerCase();
            const analytes = panel.Analytes.toLowerCase();
            if (panelCode.includes(string) || analytes.includes(string)) {
                return panel;
            }
            return;
        });
        //console.log(result);
        // return result;
        this.setState({
            filteredPanels: result,
            searchQuery, // This is required as a flag for the pagination to reset (no need for controlled field)
        })
    };

    deletePanel = (panel) => {
        //console.log('Call delete panel API');
        const Panel_ID = panel.Panel_ID;
        
        const url = DELETE_PANEL_API.GET;

        Auth.currentSession().then((response) => {
        const headers = { Authorization: response.idToken.jwtToken };

        // delete Panel 
        axios.get(`${url}?panelId=${Panel_ID}`, { headers })
        
            .then((response) => {
                //console.log('Delete Panel !!');
                //console.log(response);

                // No longer submitting
                this.setState({
                    serverError: '',
                });

                // Status code 400 is a server error.
                if (response.data.statusCode && response.data.statusCode === 400) {
                    this.setState({
                        serverError: 'There was an error deleting the panel. Please try again.',
                    });
                    return;
                }

                // If successful:
                this.deletePanelFromState(Panel_ID);
            })
            .catch((error) => {
                console.error(error);
            });

        }).catch((error) => {
            console.error(error);
        });

        
    }

    deletePanelFromState = (panelId) => {
        const newPanels = [...this.state.panels];
        const newFilteredPanels = [...this.state.filteredPanels];

        const indexToRemove = newPanels.findIndex(panel => panel.Panel_ID == panelId);
        const indexToRemoveFiltered = newFilteredPanels.findIndex(panel => panel.Panel_ID == panelId);

        //console.log(indexToRemove);

        newPanels.splice(indexToRemove, 1);
        newFilteredPanels.splice(indexToRemoveFiltered, 1);

        this.setState({
            panels: newPanels,
            filteredPanels: newFilteredPanels
        });
    }

    render() {
        const {
            serverError,
        } = this.state;

        return (
            <React.Fragment>
                <div className="page-heading">
                    <div className="container">
                        <div className="row">
                            <div className="col-6">
                                <h1>Analytes and panels</h1>
                            </div>
                            <div className="col-6 text-right">
                                <div className="btn-container">
                                    <Link to={`${this.props.match.path}/mbs-codes`} className="btn btn-secondary btn--manage-mbs-codes">Manage MBS codes</Link>
                                    <Link to={`${this.props.match.path}/panels/add`} className="btn btn-primary btn--add-panel">Add a new panel</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="analyte-panel-management section container">
                    {
                        serverError ?
                            <div className="form-errors__container row">
                                <div className="col-12">
                                    <div className="alert alert-danger" role="alert">
                                        <small>
                                            {serverError}
                                        </small>
                                    </div>
                                </div>
                            </div> : null
                    }
                    <div className="row">
                        <div className="col-lg-3">
                            <AnalytePanelFilter
                                filterPanelsByString={this.filterPanelsByString}
                            />
                        </div>
                        <div className="col-lg-9">
                            <AnalytePanelList
                                panels={this.state.panels}
                                filteredPanels={this.state.filteredPanels}
                                isLoading={this.state.isLoading}
                                deletePanel={this.deletePanel}
                                searchQuery={this.state.searchQuery}
                            />
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export default AnalytePanelListContainer;

AnalytePanelListContainer.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            protocolId: PropTypes.string
        }),
        path: PropTypes.string,
    }),
};
