/**
 * Display Episode value from the Kits page
 * @param {*} kit 
 */
export function formatEpisodeForKit(kit) {
    if (!kit) {
        return;
    }
    if (kit.day_value.toLowerCase() == 'screening' && kit.period_value == null && kit.hour_value == null && kit.minute_value == null) {
        return 'Screening';
    }
    const { day_value, period_value } = kit;
    let string = `${day_value} (${period_value}`;

    const { hour_value, minute_value } = kit;

    if (period_value === 'Dose +') {
        string += (hour_value !== null && hour_value !== '') ? `${hour_value}hr ` : '';
        string += (minute_value !== null && minute_value !== '') ? `${minute_value}min` : '';
    } else {
        if (hour_value !== null || minute_value !== null) {
            if (hour_value !== null) {
                string += (hour_value < 10) ? `0${hour_value}` : `${hour_value}`;
            }
            if (minute_value !== null) {
                string += (minute_value < 10) ? `:0${minute_value}` : `:${minute_value}`;
            }
        }
    }
    string += ')'

    return string;
}