import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SVG from 'react-inlinesvg';

import reorderUp from './../../../../assets/images/reorder-up-positive.svg';
import reorderDown from './../../../../assets/images/reorder-down-positive.svg';
class EpisodesItem extends Component {

    state = {
        inputTimeFocus: false,
    }

    Hour_value = React.createRef();
    Minute_value = React.createRef();

    handleInputChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const index = this.props.index;

        this.props.updateEpisode(index, name, value);
    }

    handleHourInputChange = (e) => {
        // Handle input change as normal
        this.handleInputChange(e);

        // If hour is already filled, focus on the minute input
        const valueLength = e.target.value.length;
        const maxLength = e.target.maxLength;
        if (valueLength >= maxLength) {
            const Minute_value = this.Minute_value.current;
            Minute_value.focus();
        }
    }


    handleInvisibleInputClick = (e) => {
        const target = e.target;
        const Hour_value = this.Hour_value.current;
        const Minute_value = this.Minute_value.current;

        // Focus on the Hour value by default, if the user clicks outside
        if (target !== Hour_value && target !== Minute_value) {
            Hour_value.focus();
        }
    }

    addInvisibleInputFocus = () => {
        this.setState({ inputTimeFocus: true });
    }

    removeInvisibleInputFocus = () => {
        this.setState({ inputTimeFocus: false });
    }

    renderPanelsButton = () => {
        const { values, index } = this.props;
        const Panels = values.Panels;

        // If the episode has 0 Panels, render this button style:
        if (!Panels || Panels === 0) {
            return (
                <button className="btn btn-secondary btn--panels" onClick={() => this.props.handleOpenPanelsInEpisode(values, index)}>
                    Add panels
                </button>
            )
        }

        // Else, render this button style:
        return (
            <button className="btn btn-secondary btn--with-sections btn--panels" onClick={() => this.props.handleOpenPanelsInEpisode(values, index)}>
                <span className="btn__section">{Panels}</span>
                <span className="btn__section">Edit panels</span>
            </button>
        )
    }

    renderPeriodDetails = () => {
        const { values, errors, index } = this.props;
        const { Period_value, Hour_value, Minute_value, hasPeriodDetails } = values;

        if (Period_value === '') {
            return '';
        }

        /* Mandatory - for Dose + */
        if (Period_value === 'Dose +') {
            return (
                <div className="period--time-period">
                    <input
                        type="text"
                        name="Hour_value"
                        className={`form-control ${errors.Hour_value ? 'has-error' : ''}`}
                        maxLength="3"
                        value={Hour_value}
                        onChange={this.handleInputChange}
                    />
                    <span className="label">hr</span>
                    <input
                        type="text"
                        name="Minute_value"
                        className={`form-control ${errors.Minute_value ? 'has-error' : ''}`}
                        maxLength="2"
                        value={Minute_value}
                        onChange={this.handleInputChange}
                    />
                    <span className="label">min</span>
                </div>
            )
        }

        /* Optional - For Single, Predose, Exit, Revisit */
        if (hasPeriodDetails) {
            const { inputTimeFocus } = this.state;
            return (
                <div className="period--single-time">
                    <div 
                        className={`input-time fake-input-mask form-control ${inputTimeFocus ? 'focus' : ''} ${(errors.Hour_value || errors.Minute_value) ? 'has-error' : ''}`} 
                        ref={this.inputTime}
                        onClick={this.handleInvisibleInputClick}
                    >
                        <input
                            type="text"
                            name="Hour_value"
                            maxLength="2"
                            placeholder="HH"
                            className="time__hour invisible-input"
                            value={Hour_value}
                            onChange={this.handleHourInputChange}
                            onFocus={this.addInvisibleInputFocus}
                            onBlur={this.removeInvisibleInputFocus}
                            ref={this.Hour_value}
                        />
                        <span>:</span>
                        <input
                            type="text"
                            name="Minute_value"
                            maxLength="2"
                            placeholder="MM"
                            className="time__minute invisible-input"
                            value={Minute_value}
                            onChange={this.handleInputChange}
                            onFocus={this.addInvisibleInputFocus}
                            onBlur={this.removeInvisibleInputFocus}
                            ref={this.Minute_value}
                        />
                    </div>
                    <span className="label muted">(24hr)</span>
                    <a onClick={() => this.props.togglePeriodDetails(index)} className="link">Remove time</a>
                </div>
            )
        }

        return (
            <div className="period--single-time">
                <a onClick={() => this.props.togglePeriodDetails(index)} className="link">Add time</a>
            </div>
        )
    }

    render() {
        const {
            values,
            errors,
            index,
            episodesLength,
            reorderEpisode,
        } = this.props;

        return (
            <div className="episodes-item flex-table-form-item row list--item">
                <div className="item item__reorder">
                    <div className="reorder-container">
                        {/* Don't show the reorder up button if it's the first Episode */}
                        <a className={ `btn-reorder--up ${index === 0 ? 'hide' : ''}` } onClick={() => reorderEpisode(index, 'up')}>
                            <SVG className="icon icon--reorder" src={reorderUp} />
                        </a>
                    </div>
                    <div className="reorder-container">
                        {/* Don't show the reorder down button if it's the last Episode */}
                        <a className={ `btn-reorder--down ${index === (episodesLength - 1) ? 'hide' : ''}` } onClick={() => reorderEpisode(index, 'down')}>
                            <SVG className="icon icon--reorder" src={reorderDown} />
                        </a>
                    </div>
                </div>
                <div className="item item__day">
                    <input
                        type="text"
                        name="Day_value"
                        maxLength="15"
                        className={`form-control ${errors.Day_value ? 'has-error' : ''}`}
                        value={values.Day_value}
                        onChange={this.handleInputChange}
                    />
                </div>
                <div className="item item__period">

                    <input type="text" list="periods" 
                        name="Period_value" 
                        className={`form-control ${errors.Period_value ? 'has-error' : ''}`}
                        value={values.Period_value}
                        onChange={this.handleInputChange}
                    />
                    <datalist id="periods">
                        <option value="">Select period</option>
                        <option value="Single">Single</option>
                        <option value="Dose +">Dose +</option>
                        <option value="Predose">Pre-dose</option>
                        <option value="Exit">Exit</option>
                        <option value="Revisit">Revisit</option>
                    </datalist>
                    
                </div>
                <div className="item item__period-details">
                    { this.renderPeriodDetails() }
                </div>
                <div className="item item__panels">
                    { this.renderPanelsButton() }
                </div>
                <div className="item item__urgent">
                    <label htmlFor={`Urgent_flag${index}`} className="checkbox__container large">
                        <input
                            type="checkbox"
                            id={`Urgent_flag${index}`}
                            name="Urgent_flag"
                            className="form-check-input"
                            checked={values.Urgent_flag}
                            onChange={this.handleInputChange}
                        />
                        <span className="form-check-checkmark"></span>
                    </label>
                </div>
                <div className="item item__actions">
                    <a onClick={() => this.props.openModal(index)} className="link">Remove</a>
                </div>
            </div>
        )
    }
}

export default EpisodesItem;

EpisodesItem.propTypes = {
    index: PropTypes.number.isRequired,
    updateEpisode: PropTypes.func.isRequired,
    values: PropTypes.shape({
        Cohort_days_id: PropTypes.number,
        Day_period_id: PropTypes.number, // is the unique episode ID
        Day_value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
        Period_value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
        Hour_value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
        Minute_value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
        Urgent_flag: PropTypes.bool.isRequired,
        Panels: PropTypes.number.isRequired,
        hasPeriodDetails: PropTypes.bool.isRequired,
    }).isRequired,
    handleOpenPanelsInEpisode: PropTypes.func.isRequired,
    errors: PropTypes.shape({

    }).isRequired,
    togglePeriodDetails: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    episodesLength: PropTypes.number.isRequired,
    reorderEpisode: PropTypes.func.isRequired,
    Screening_skipped: PropTypes.bool,
};


//deleted from line 209
// <select 
//     name="Period_value" 
//     className={`form-control ${errors.Period_value ? 'has-error' : ''}`}
//     value={values.Period_value}
//     onChange={this.handleInputChange}
// >
//     <option value="">Select period</option>
//     <option value="Single">Single</option>
//     <option value="Dose +">Dose +</option>
//     <option value="Predose">Pre-dose</option>
//     <option value="Exit">Exit</option>
//     <option value="Revisit">Revisit</option>
// </select>