import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import ScreeningKits from './ScreeningKits';
import EpisodeKits from './EpisodeKits';

import Pagination from './../common/Pagination';
import Preloader from './../common/Preloader';

class KitsList extends Component {

    state = {
        pageOfItems: [],
    }

    /**
     * Update state with new page of items
     */
    onChangePage = (pageOfItems) => {
        this.setState({ pageOfItems });
    }

    handleViewKitDetails = (kit_code, kitType) => {
        const { path } = this.props.match;

        let kitTypeSlug = '';
        if (kitType === 'screening') {
            kitTypeSlug = 'screening';
        }
        if (kitType === 'episode') {
            kitTypeSlug = 'episode';
        }
        if (kitType === 'lowstock') {
            kitTypeSlug = 'lowstock';
        } 
        if (kitType === 'expiringkits') {
            kitTypeSlug = 'expiringkits';
        }  

        // Rafey: Error might be here
        // Go to the kit details page
       this.props.history.push(`${path}/${kitTypeSlug}/${kit_code}`);
    }

    renderKitsToDisplay = () => {
        const { kitsToDisplay } = this.props;
        if (kitsToDisplay === 'screening') {
            return (
                <ScreeningKits
                    pageOfItems={this.state.pageOfItems}
                    handleViewKitDetails={this.handleViewKitDetails}
                    />
                )
        } else {//if (kitsToDisplay === 'EPISODE') {
            return (
                <EpisodeKits
                    kitsToDisplay = {this.props.kitsToDisplay}
                    pageOfItems={this.state.pageOfItems}
                    handleViewKitDetails={this.handleViewKitDetails}
                />
            )
        }
    }

    render() {
        const {
            filteredKits,
            isLoading,
            kitsToDisplay
        } = this.props;
        return (
            <React.Fragment>
                <div className="kits-list flex-table filter-table container">
                    <div className="row list--heading">
                        <div className="item item__kit-code">Kit code</div>
                        <div className="item item__protocol">Protocol</div>
                        <div className="item item__cohort">Cohort</div>
                        <div className="item item__episode">Episode</div>
                        {
                            kitsToDisplay == 'lowstock' && <div className="item item__numberused">Number Used</div>
                        }
                        <div className="item item__packed-required">Packed / required</div>
                        {
                            (kitsToDisplay == 'episode' || kitsToDisplay == 'lowstock') && <div className="item item__packingrequired">Packing Required</div>
                        }
                        {
                            kitsToDisplay == 'expiringkits' && <div className="item item__packingrequired">Expiry Date</div>
                        }
                        
                        <div className="item item__actions"></div>
                    </div>
                    {
                        filteredKits && filteredKits.length ?
                            this.renderKitsToDisplay()
                        :
                        <div className="row list--item list--no-results">
                            <div className="item">{isLoading ? 'Fetching kits...' : 'No matching records found'}</div>
                        </div>
                    }
                    
                </div>
                {
                    (isLoading) ? <Preloader /> : null
                }
                {
                    filteredKits && filteredKits.length ?
                    <Pagination
                        items={filteredKits}
                        onChangePage={this.onChangePage}
                        statusCheckboxes={this.props.statusCheckboxes}
                        selectedDayStart={this.props.selectedDayStart}
                        selectedDayEnd={this.props.selectedDayEnd}
                    /> : null
                }

            </React.Fragment>
        )
    }
}

export default withRouter(KitsList);

KitsList.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string,
    }),
    history: PropTypes.shape({
        push: PropTypes.func,
    }),
    kitsToDisplay: PropTypes.string.isRequired,
    filteredKits: PropTypes.array,
    isLoading: PropTypes.bool.isRequired,
    pageOfItems: PropTypes.array,
    statusCheckboxes: PropTypes.object.isRequired, // should be shaped
    selectedDays: PropTypes.array,
    selectedDayStart: PropTypes.any,
    selectedDayEnd: PropTypes.any,
};
