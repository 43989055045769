import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import Modal from 'react-modal';

import SVG from 'react-inlinesvg';
import close from './../../../assets/images/close.svg';

Modal.setAppElement('#root');
class ConfirmEditPanelModal extends Component {

    handleConfirm = () => {
        const { closeEditModal, updatePanelAPI } = this.props;
        closeEditModal();
        updatePanelAPI();
    }

    renderPanelName = () => {
        const { values } = this.props;
        if (values && values.panelName) {
            return values.panelName;
        }
    }

    render() {
        const {
            editModalIsOpen,
            closeEditModal,
        } = this.props;

        return (
            <Modal
                className="modal__bootstrap modal--edit-panel modal-dialog"
                overlayClassName="overlay"
                isOpen={editModalIsOpen}
                onRequestClose={closeEditModal}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title">Confirm changes to panel {this.renderPanelName()}</h3>
                        <button type="button" className="close" onClick={closeEditModal}>
                            <span aria-hidden="true">
                                <SVG className="icon icon--close" src={close} />
                            </span>
                            <span className="sr-only"></span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p>This will save all the changes you’ve made to the panel</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={closeEditModal}>Go back</button>
                        <button type="button" className="btn btn-primary" onClick={() => this.handleConfirm()}>Confirm changes</button>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default ConfirmEditPanelModal;

ConfirmEditPanelModal.propTypes = {
    editModalIsOpen: PropTypes.bool.isRequired,
    closeEditModal: PropTypes.func.isRequired,
    updatePanelAPI: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired, // should be shaped
};