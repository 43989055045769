import React, { Component } from 'react';
import { Route, NavLink } from 'react-router-dom';
import { PropTypes } from 'prop-types';

class NavItem extends Component {

    render() {
        const { children, to, exact, ...props } = this.props;

        return (
            <Route path={to} exact={exact} children={({ match }) => (
                <li className={`nav-item ${match ? 'is-active' : null}`}>
                    <NavLink to={to} className="nav-link" activeClassName="active" {...props}>{children}</NavLink>
                </li>
            )} />
        )
    }
}

export default NavItem;

NavItem.propTypes = {
    to: PropTypes.string.isRequired,
    exact: PropTypes.bool,
    children: PropTypes.node.isRequired,
};
