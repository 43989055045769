import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AnalytePanelFilter extends Component {

    handleFilterByString = (e) => {
        const inputValue = e.currentTarget.value;
        const { filterPanelsByString } = this.props;

        // Update the search query string
        // console.log(inputValue);
        filterPanelsByString(inputValue);
    }

    render() {
        return (
            <div className="filter__container">
                <div className="filters__heading">Filter</div>

                <div className="filters">
                    <div className="filter search-filter__container">
                        <label htmlFor="search" className="filter-label">Search panels or analytes</label>
                        <input name="search" type="text" className="form-control" placeholder="Search" onChange={this.handleFilterByString} />
                    </div>
                </div>
            </div>
        )
    }
}

export default AnalytePanelFilter;

AnalytePanelFilter.propTypes = {
    filterPanelsByString: PropTypes.func.isRequired,
};