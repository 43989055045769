import { withFormik } from 'formik';
import * as yup from 'yup';

import { Auth } from 'aws-amplify';

import ResetPasswordForm from './ResetPasswordForm';

/**
 * Formik HOC for form validation
 */
const ResetPasswordContainer = withFormik({
    mapPropsToValues: () => ({ email: '', password: '' }),
    validationSchema: yup.object().shape({
        email: yup.string()
            .email('Please enter a valid email address')
            .required('Please enter a valid email address'),
    }),
    handleSubmit: (values, { props, setSubmitting, setErrors }) => {
        const { email } = values;
        //console.log(email);

        const emailString = email.toString().toLowerCase();
        

        const isValidEmail = emailString.substring(emailString.lastIndexOf('@')) === (('@clinicallabs.com.au')) || emailString.substring(emailString.lastIndexOf('@')) === (('@accenture.com'));
        if (!isValidEmail) {
            setSubmitting(false);
            return setErrors({ form: 'Please enter a valid email address' });
        }

        // Call AWS Amplify
        Auth.forgotPassword(emailString)
            .then((data) => {
                //console.log(data);
                // Form is no longer submitting
                setSubmitting(false);
                // Update the confirmation code generated state
                // And the email to ResetPasswordManager
                // So it can be passed to ResetPasswordConfirmContainer
                props.updateConfirmationCodeGenerated(true);
                props.updateEmail(emailString);
            })
            .catch((error) => {
                //console.log(error);
                // Form is no longer submitting
                setSubmitting(false);
                // Set an error message for the whole form.
                // Use it as error.form in the child component.
                if (error.code === 'UserNotFoundException') {
                    setErrors({ form: 'User not registered for this portal, contact clinicaltrials@clinicallabs.com.au if you believe you should have access' });
                } else {
                    setErrors({ form: error.message });
                }
            });
    },
    displayName: 'ResetPasswordForm', // helps with React DevTools
    validateOnBlur: false,
    validateOnChange: false,
})(ResetPasswordForm);

export default ResetPasswordContainer;