import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import ProtocolFilter from './ProtocolFilter';
import ProtocolList from './ProtocolList';

import { filterByUnique, sortByObjectProperty } from './../../utils/utils';
import { PROTOCOL_API,
         GET_EXPORT_BY_PROTOCOLID_API, 
         GET_DELETE_PROTOCOL_BY_PROTOCOLID_API,
         UPDATE_PROTOCOL_API,
         GET_QUOTE_BY_PROTOCOLID_API
} from './../../utils/constants';
import axios from 'axios';
import { Auth } from 'aws-amplify';
// import { protocolListJson } from './../../assets/protocol-list';



class ProtocolListContainer extends Component {

  state = {
    protocols: [],
    filteredProtocols: [],
    pageOfItems: [],
    searchQuery: '',
    existingStatuses: {},
    isLoading: false,
    isDeleting: false,
  };

  componentDidMount() {
    this.fetchProtocols();
   
  }

  /**
   * Fetches the list of protocols from the Amazon API
   */
  fetchProtocols = () => {

    this.setState({ isLoading: true });
    
    /* Uncomment to use API */
    const url = PROTOCOL_API.GET;

    // Get the current session
    Auth.currentSession().then((response) => {
      // Grab the jwtToken to use as Authorization header
      const headers = { Authorization: response.idToken.jwtToken };
      let beforeCallTime = new Date().getTime();
      // Fetch the Protocol list
      axios.get(url, { headers })
        .then((response) => {
          let afterCallTime = new Date().getTime();
          let loadTime = afterCallTime - beforeCallTime;

          let array = url.split('/');
          let endpoint = array[array.length-1];
          //Google analytics tracking
          ReactGA.timing({
            category: endpoint,
            variable: 'responseTime',
            value: loadTime, // in milliseconds
            label: null
          });

          if (response.data && response.data.result != null) {
            // Sort list of protocols by Protocol Code
            const result = response.data.result.sort(sortByObjectProperty('Protocol_code'));
            this.setState({
              protocols: result,
              filteredProtocols: result,
              isLoading: false,
            });
            this.getExistingStatuses(result);
          } else {
            this.setState({ isLoading: false });
          }
        })
        .catch((error) => {
          console.error(error);
          this.setState({ isLoading: false });
        });

    }).catch((error) => {
      console.error(error);
      this.setState({ isLoading: false });
    });

      /* Uncomment to use sample JSON */
      // const result = protocolListJson.data.sort(sortByObjectProperty('Protocol_code'));
      // this.setState({
      //   protocols: result,
      //   filteredProtocols: result,
      // });
      // this.getExistingStatuses(result);
  }

  /**
     * Getting the quote for protocol
     */
    getQuote = (protocolId) => {

      this.setState({ isDownloadingQuote: true });

      /* Uncomment to use API */
      const url = GET_QUOTE_BY_PROTOCOLID_API.GET;

      // Get the current session
      Auth.currentSession().then((response) => {
          // Grab the jwtToken to use as Authorization header
          const headers = { Authorization: response.idToken.jwtToken };

          // Fetch the Quote using protocol id
          axios.get(`${url}?protocolId=${protocolId}`, { headers })
              .then((response) => {
                  //const quote=response.data[0].result[0].quote;
                  const URL = response.data[0].result[0].URL;
        
                      //downloading the file in browser
                      window.open(URL);
              })
              .catch((error) => {
                  console.error(error);
              })
              .then(() => {
                  this.setState({ isDownloadingQuote: false });
              });

      }).catch((error) => {
          console.error(error);
          this.setState({ isDownloadingQuote: false });
      });
  }
 
    /**
   * delete protocol using protocol id API
   */
  deleteProtocol = (protocolId) => {

    this.setState({ isDeleting: true });
    
    /* Uncomment to use API */
    const url = GET_DELETE_PROTOCOL_BY_PROTOCOLID_API.GET;

    // Get the current session
    Auth.currentSession().then((response) => {
      // Grab the jwtToken to use as Authorization header
      const headers = { Authorization: response.idToken.jwtToken };

      // delete Protocol 
     // axios.get(url, { headers })
     axios.get(`${url}?protocolId=${protocolId}`, { headers })
     
        .then((response) => {
          if (response.data) {
           this.deleteProtocolFromState(protocolId);
            
          }
        })
        .catch((error) => {
          console.error(error);
          this.setState({ isDeleting: false });
        });

    }).catch((error) => {
      console.error(error);
      this.setState({ isDeleting: false });
    });

     
  }

  
  deleteProtocolFromState = (protocolId) => {    
    const newProtocols = [...this.state.protocols];    
    const newFilteredProtocols = [...this.state.filteredProtocols];  

    const indexToRemove = newProtocols.findIndex(protocol => protocol.Protocol_ID == protocolId);  
    const indexToRemoveFiltered = newFilteredProtocols.findIndex(protocol => protocol.Protocol_ID == protocolId);

    //console.log(indexToRemove);

    newProtocols.splice(indexToRemove, 1);   
    newFilteredProtocols.splice(indexToRemoveFiltered,1)

    this.setState({ 
      protocols: newProtocols,
      filteredProtocols: newFilteredProtocols,
      isDeleting: false
    });
  }

  updateProtocolStatusAPI = (protocol, status) => {
      this.setState({ isChangingStatus: true });
      const updateurl = UPDATE_PROTOCOL_API.POST;

      const data = JSON.stringify({
          body: {
              protocolId: protocol.Protocol_ID.toString(),
              number: protocol.Protocol_code,
              name: protocol.Protocol_name,
              sponsor: protocol.Study_sponser,
              coord: protocol.Nucleus_coordinator,
              copydoctor: protocol.Copy_doctor,
              ultracode: protocol.Ultra_code,
              onstudyparti: protocol.Study_participants.toString(),
              screenparti: protocol.Screening_participants.toString(),
              statusid: status,
              user: this.props.username,
          }
      });

      Auth.currentSession().then((response) => {
          const headers = { Authorization: response.idToken.jwtToken };
          axios.post(updateurl, data, { headers })
              .then((response) => {
                  this.updateProtocolStatusState(protocol, status);
              })
              .catch(function (error) {
                  console.log(error);
              })
              .then(() => {
                  this.setState({ isChangingStatus: false });
              });
      });


  }

  updateProtocolStatusState = (protocolToUpdate, status) => {
    const protocols = [...this.state.protocols];    
    const filteredProtocols = [...this.state.filteredProtocols];  

    const indexToUpdate = protocols.findIndex(protocol => protocol.Protocol_ID == protocolToUpdate.Protocol_ID);  
    const indexToUpdateFiltered = filteredProtocols.findIndex(protocol => protocol.Protocol_ID == protocolToUpdate.Protocol_ID);
    const newProtocols = protocols.map((protocol, i) => {
      if (i === indexToUpdate) {
        protocol = {
          ...protocol, 
          Status_id: status,
         };
      }
      return protocol;
    });

    const newFilteredProtocols = filteredProtocols.map((protocol, i) => {
      if (i === indexToUpdateFiltered) {
        protocol = {
          ...protocol,
          Status_id: status,
        };
      }
      return protocol;
    })

    // Set the state
    this.setState({ 
      protocols: newProtocols,
      filteredProtocols: newFilteredProtocols
    });
  }
  


 /**
   * Check the status of the protocol wants to delete
   */
  checkProtocolStatus = (protocolId,statusId) => {
    if ((statusId==1) || (statusId==2) || (statusId==6))
    {
      return true;
    }
    else
    {
      return false;
    }
  }

 /**
   * Check the status of the protocol wants to export in protocol listing page
   */
  checkStatusExport = (protocolId,statusId) => {
    if ((statusId==1))
    {
      return false;
    }
    else
    {
      return true;
    }
  } 

 /**
     * Export the protocol using protocol id
     */
    exportProtocol = (protocolId) => {

      this.setState({ isExportingProtocol: true });

      /* Uncomment to use API */
      const url = GET_EXPORT_BY_PROTOCOLID_API.GET;

      // Get the current session
      Auth.currentSession().then((response) => {
          // Grab the jwtToken to use as Authorization header
          const headers = { Authorization: response.idToken.jwtToken };

         
          // Export the protocol
          axios.get(`${url}?protocolId=${protocolId}`, { headers })
              .then((response) => {
                  const file = response.data[0].result[0].URL;
                  window.open(file);
              })
              .catch((error) => {
                  console.error(error);
              })
              .then(() => {
                  this.setState({ isExportingProtocol: false });
              });

      }).catch((error) => {
          console.error(error);
          this.setState({ isExportingProtocol: false });
      });
  }


  /**
   * Update state with new page of items
   */
  onChangePage = (pageOfItems) => {
    this.setState({ pageOfItems });
  }

  /**
   * Get the existing statuses from the protocol list, 
   * and set it as an object for filtering.
   */
  getExistingStatuses = (protocolList) => {
    let existingStatuses = protocolList.map(protocol => protocol.Status_id)
      // First get all the statuses from the list
      .filter(filterByUnique)
      // Sort the statuses
      .sort();

    // Then turn it into an object
    existingStatuses = this.arrayToObject(existingStatuses);

    // Set the object as a state in this component
    this.setState({ 
      existingStatuses 
    }, () => { // Then filter the protocols to the default checked states.
      this.filterProtocols();
    });
  }

  /**
   * Update search query string
   */
  updateSearchQuery = (queryString = '') => {
    queryString = queryString.toLowerCase();
    this.setState({ 
      searchQuery: queryString 
    }, () => {
      this.filterProtocols();
    });
  }

  /**
   * Update whether a status is checked or unchecked in the filter
   */
  updateStatusFilter = (name, value) => {
    this.setState({
      existingStatuses: {
        ...this.state.existingStatuses,
        [name]: value
      }
    }, () => {
      this.filterProtocols();
    });
    
  }

  /**
   * Filter the list of Protocols by the search query string
   */
  filterProtocolsByString = (protocolList) => {
    const searchQuery = this.state.searchQuery;
    const result = protocolList.filter(protocol => {
      const protocolCode = protocol.Protocol_code.toLowerCase();
      return protocolCode.includes(searchQuery);
    });
    return result;
  };

  /**
   * Filter the list of Protocols by the status checkboxes
   */
  filterProtocolsByStatus = (protocolList) => {

    const existingStatuses = this.state.existingStatuses;
    
    const checkedStatuses = Object.keys(existingStatuses).filter(key => {
      return existingStatuses[key];
    });

    let result = [];

    // If none of the checkboxes are checked, show the whole list.
    if (checkedStatuses && checkedStatuses.length === 0) {
      return protocolList;
    }

    // Start filtering with all the checked statuses
    protocolList.map(protocol => {
      for (let status of checkedStatuses) {
        if (protocol.Status_id == status) {
          result.push(protocol);
        }
      }
    })

    return result;
  }

  /**
   * Converts an array into an object with value of true
   */
  arrayToObject = (arr) => {
    var object = {};
    for (var i = 0; i < arr.length; ++i) {
      // If the status is Completed or Rejected, leave the checkbox unchecked.
      // Else, it is checked by default (on page load).
      if (arr[i] === 5 || arr[i] === 6) {
        object[arr[i]] = false;
      } else {
        object[arr[i]] = true; // Set to true to check the checkbox
      }
    }
    return object;
  }

  filterProtocols = () => {
    // Display filtered protocol list here.
    const protocols = this.state.protocols;
    // Initialise the protocol list as an empty array.
    let filteredProtocols = [];
    // First, filter the Protocol list by the Protocol Code (search field)
    filteredProtocols = this.filterProtocolsByString(protocols);
    // Then, filter the Protocol list by the Status (checkboxes)
    filteredProtocols = this.filterProtocolsByStatus(filteredProtocols);
    this.setState({ filteredProtocols });
  }

  render() {

    return (
      <React.Fragment>
        <div className="page-heading">
          <div className="container">
            <div className="row">          
              <div className="col-6">
                <h1>Protocols</h1>
              </div>
              <div className="col-6 text-right">
                <Link to={`${this.props.match.path}/add`} className="btn btn-primary btn--add-protocol">Add a new protocol</Link>
              </div>
            </div>
          </div>
        </div>
        <section className="protocol-management section container">
          <div className="row">
            <div className="col-lg-3">
              <ProtocolFilter
                updateSearchQuery={this.updateSearchQuery}
                updateStatusFilter={this.updateStatusFilter}
                existingStatuses={this.state.existingStatuses}
              />
            </div>
            <div className="col-lg-9">
              <ProtocolList
                filteredProtocols={this.state.filteredProtocols}
                pageOfItems={this.state.pageOfItems}
                onChangePage={this.onChangePage}
                isLoading={this.state.isLoading}
                deleteProtocol={this.deleteProtocol}
                checkProtocolStatus={this.checkProtocolStatus}
                checkStatusExport={this.checkStatusExport}
                updateProtocolStatusAPI={this.updateProtocolStatusAPI}
                exportProtocol={this.exportProtocol}
                getQuote={this.getQuote}
                searchQuery={this.state.searchQuery}
                existingStatuses={this.state.existingStatuses}
              />
              
            </div>
            
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default ProtocolListContainer;

ProtocolListContainer.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }),
  username: PropTypes.string,
};