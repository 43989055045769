import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

import PanelItem from './PanelItem';
import CantFindPanel from './../../../common/CantFindPanel';

import SVG from 'react-inlinesvg';

import add from './../../../../assets/images/add.svg';

import './../../../../scss/screening.scss';
import Checkbox from '../../../common/Checkbox';
import KitInformation from '../../../common/KitInformation/KitInformation';
import { Button } from 'bootstrap';
import { createRef } from 'react';

class ScreeningForm extends Component {

    // Task - 1 

    constructor(props) {
        super(props);
        this.state = {
            selectedPanels: [],
            isActive: false,
        };

    }
    render() {
        const panels = this.props.panels;
        const hasErrors = this.props.hasErrors;
        const errors = this.props.errors;
        const serverError = this.props.serverError;
        const isSubmitting = this.props.isSubmitting;
        const isSubmittingBack = this.props.isSubmittingBack;
        const isSubmittingNext = this.props.isSubmittingNext;
        const isSubmittingExit = this.props.isSubmittingExit;
        const isEditFromReviewScreen = this.props.isEditFromReviewScreen;
        const kitTubesDetail = this.props.kitTubesDetail;
        // console.log('particpantscc', this.props.targetNoOfParticipantsToBeScreened)
        // console.log('particpantSS', this.props.skipScreening)
        let skipScreening = this.props.skipScreening;
        if (!skipScreening) {
            if (this.props.targetNoOfParticipantsToBeScreened.toString() == '0') {
                this.props.updateSkipScreeningStatus(true)
            }
        } else {
            if (this.props.targetNoOfParticipantsToBeScreened.toString() != '0') {
                this.props.updateSkipScreeningStatus(false)
            }
        }

        //You have decided not to screen any participants, either enter number of participants on previous screen or skip screening tests.
        if (this.props.targetNoOfParticipantsToBeScreened.toString() == '0') {
            this.label = 'Target number of participants to be screened cannot be 0 if screening is required. Please update the value or select Skip this step, Screening is not required'
        } else {
            this.label = 'You can not skip the screening: The value entered for the participants to be screened is greater than 0';
        }
        return (
            <div className="screening-form">
                <div className="row align-items-center">
                    <div className="col-6">
                        <span>
                            <Checkbox
                                id="weightRequired"
                                name="weightRequired"
                                label={this.label}
                                checked={skipScreening}
                                onChange={(e) => {
                                    // console.log(e.target)
                                    this.props.updateSkipScreeningStatus(e.target.checked)
                                }}
                            />
                        </span>
                    </div>
                </div>
                <div className="row align-items-center" hidden={skipScreening}>
                    <div className="col-6">

                        <p>Choose panels for the screening test</p>
                    </div>
                    <div className="col-6 text-right">
                        <CantFindPanel />
                    </div>
                </div>

                {
                    serverError ?
                        <div className="form-errors__container row">
                            <div className="col-12">
                                <div className="alert alert-danger" role="alert">
                                    <small>
                                        {serverError}
                                    </small>
                                </div>
                            </div>
                        </div> : null
                }

                {
                    hasErrors ?
                        <div className="form-errors__container row">
                            <div className="col-12">
                                <div className="alert alert-danger" role="alert">
                                    <p><strong>Please review the error(s) highlighted in red below</strong></p>
                                    <small>
                                        <ul>
                                            {
                                                errors.emptyPanel ? <li><strong>Panel – </strong> <span>Please select a panel</span></li> : null
                                            }
                                            {
                                                errors.duplicatePanels ? <li><strong>Panel – </strong> <span>This panel is duplicated</span></li> : null
                                            }
                                        </ul>
                                    </small>
                                </div>
                            </div>
                        </div> : null
                }


                <div className="screening-form__table flex-table-form flex-table container" hidden={skipScreening}>
                    <div className="row list--heading">
                        <div className="item item__panel">Panel</div>
                        <div className="item item__actions"></div>
                    </div>

                    {/* Task-1  */}
                    {
                        panels.length && panels.map((panel, index) => {
                            return (
                                <PanelItem
                                    key={index}
                                    panel={panel}
                                    index={index}
                                    skipScreening={skipScreening}
                                    selectedPanels={panels}
                                    fetchedPanels={this.props.fetchedPanels}
                                    updatePanel={this.props.updatePanel}
                                    removePanel={this.props.removePanel}

                                />
                            )
                        })
                    }


                </div>
                <div className="form-add-container" hidden={skipScreening}>
                    <a onClick={() => this.props.addPanel()} className="form-add link">
                        <SVG className="icon icon--add" src={add} />
                        <span>Add a panel</span>
                    </a>
                </div>

                <div hidden={skipScreening}>
                    <KitInformation className='p-2'
                        ref={instance => { this.child = instance; }}
                        updateRegenrationChoice={this.props.updateRegenrationChoice}
                        Kitcode={this.props.Kitcode}
                        kitTubesDetail={kitTubesDetail}
                        addItems={this.props.addItems}
                        removeItem={this.props.removeItem}
                        updateQuantity={this.props.updateQuantity}
                        panels={this.props.panels}
                        checkFSH_HSG={this.props.checkFSH_HSG}
                        enableWOCBP={this.props.enableWOCBP}
                        CheckWOCBP={this.props.CheckWOCBP}
                    />

                    {/* <button className={this.state.isActive ? 'btn btn-primary btn--next' : 'btn btn-outline-primary btn--back'}
                        onClick={this.toggleClass}>
                        Display WOCBP
                    </button> */}

                </div>







                <div className="form-btn-container btn-container">
                    <div className="row">
                        {
                            isEditFromReviewScreen ?
                                // If user is coming from Review and export screen...
                                <div className="col text-right">
                                    <button
                                        className="btn btn-primary btn--next"
                                        onClick={(e) => this.props.handleSubmit(e, 4)}
                                        disabled={isSubmitting}
                                    >
                                        {isSubmittingNext ? <span className="spinner__wrapper"><span className="loading-spinner"></span></span> : 'Confirm changes'}
                                    </button>
                                </div>
                                :
                                // Otherwise (normal flow)...
                                <React.Fragment>
                                    <div className="col">
                                        <button
                                            className="btn btn-secondary btn--save-exit"
                                            onClick={(e) => this.props.handleSubmit(e, 'home')}
                                            disabled={isSubmitting}
                                        >
                                            {isSubmittingExit ? <span className="spinner__wrapper"><span className="loading-spinner"></span></span> : 'Save and exit'}
                                        </button>
                                    </div>
                                    <div className="col text-right">
                                        <button
                                            className="btn btn-secondary btn--back"
                                            onClick={(e) => this.props.handleSubmit(e, 1)}
                                            disabled={isSubmitting}
                                        >
                                            {isSubmittingBack ? <span className="spinner__wrapper"><span className="loading-spinner"></span></span> : 'Back'}
                                        </button>
                                        <button
                                            className="btn btn-primary btn--next"
                                            onClick={(e) => this.props.handleSubmit(e, 3)}
                                            disabled={isSubmitting}
                                        >
                                            {isSubmittingNext ? <span className="spinner__wrapper"><span className="loading-spinner"></span></span> : 'Next'}
                                        </button>
                                    </div>
                                </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default ScreeningForm;

ScreeningForm.propTypes = {
    Kitcode: PropTypes.string.isRequired,
    panels: PropTypes.arrayOf(PropTypes.shape({
        Panel_code: PropTypes.string.isRequired,
        Panel_ID: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
    })).isRequired,
    hasErrors: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    fetchedPanels: PropTypes.array.isRequired,
    updatePanel: PropTypes.func.isRequired,
    removePanel: PropTypes.func.isRequired,
    addPanel: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    serverError: PropTypes.string.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    isSubmittingBack: PropTypes.bool.isRequired,
    isSubmittingNext: PropTypes.bool.isRequired,
    isSubmittingExit: PropTypes.bool.isRequired,
    isEditFromReviewScreen: PropTypes.bool.isRequired,
    skipScreening: PropTypes.bool.isRequired,
    updateSkipScreeningStatus: PropTypes.func.isRequired,
    kitTubesDetail: PropTypes.array.isRequired,
    addItems: PropTypes.func.isRequired,
    removeItem: PropTypes.func.isRequired,
    updateQuantity: PropTypes.func.isRequired,
    updateRegenrationChoice: PropTypes.func.isRequired,
    targetNoOfParticipantsToBeScreened: PropTypes.any.isRequired,
};