import React, { Component } from 'react';
import PropTypes from 'prop-types';

import PanelItem from './../../Screening/PanelItem';

import SVG from 'react-inlinesvg';
import add from './../../../../../assets/images/add.svg';
import KitInformation from '../../../../common/KitInformation/KitInformation';
import ConfirmationDialog from '../../../../common/ConfirmationDialog';

class PanelsForm extends Component {

    state = {
        border: false,
    }

    componentDidMount() {
        const panelsList = document.querySelector('.episodes__panels__content');
        panelsList.addEventListener('scroll', this.toggleBorder);
    }
    
    componentWillUnmount() {
        const panelsList = document.querySelector('.episodes__panels__content');
        panelsList.removeEventListener('scroll', this.toggleBorder);
    }

    toggleBorder = (e) => {
        const scrollTop = e.srcElement.scrollTop;

        if (scrollTop === 0) {
            this.setState({ border: false, });
        } else {
            this.setState({ border: true, });
        }
    }

    render() {
        const panels = this.props.panels;
        const hasErrors = this.props.hasErrors;
        const errors = this.props.errors;
        const serverError = this.props.serverError;

        const border = this.state.border;
        const kitTubesDetail = this.props.kitTubesDetail;
        
        console.log('WOCBP', this.props.CheckWOCBP)
        return (
            <React.Fragment>
                <div className="episodes__panels__form episodes__panels__table-heading container">

                    {
                        serverError ?
                            <div className="form-errors__container row">
                                <div className="col-12">
                                    <div className="alert alert-danger" role="alert">
                                        <small>
                                            {serverError}
                                        </small>
                                    </div>
                                </div>
                            </div> : null
                    }

                    {
                        hasErrors ?
                            <div className="form-errors__container row">
                                <div className="col-12">
                                    <div className="alert alert-danger" role="alert">
                                        <p><strong>Please review the error(s) highlighted in red below</strong></p>
                                        <small>
                                            <ul>
                                                {
                                                    errors.emptyPanel ? <li><strong>Panel – </strong> <span>Please select an panel</span></li> : null
                                                }
                                                {
                                                    errors.duplicatePanels ? <li><strong>Panel – </strong> <span>This panel is duplicated</span></li> : null
                                                }
                                            </ul>
                                        </small>
                                    </div>
                                </div>
                            </div> : null
                    }

                    <div className={`episodes-panels-form__table screening-form__table flex-table-form flex-table container ${border ? 'bordered' : ''}`}>
                        <div className="row list--heading">
                            <div className="item item__panel">Panel</div>
                            <div className="item item__actions"></div>
                        </div>
                    </div>
                </div>
                <div className="episodes__panels__form episodes__panels__content container">
                    <div className="episodes-panels-form__table screening-form__table flex-table-form flex-table container">

                        {
                            panels.length && panels.map((panel, index) => {
                                return (
                                    <PanelItem
                                        key={index}
                                        panel={panel}
                                        index={index}
                                        selectedPanels={panels}
                                        fetchedPanels={this.props.fetchedPanels}
                                        updatePanel={this.props.updatePanel}
                                        removePanel={this.props.removePanel}
                                    />
                                )
                            })
                        }
                    </div>
                    <div className="form-add-container">
                        <a onClick={() => this.props.addPanel()} className="form-add link">
                            <SVG className="icon icon--add" src={add} />
                            <span>Add a panel</span>
                        </a>
                    </div>
                    <div>
                    <KitInformation
                        updateRegenrationChoice={this.props.updateRegenrationChoice}
                        Kitcode={this.props.Kitcode}
                        kitTubesDetail={kitTubesDetail}
                        addItems={this.props.addItems}
                        removeItem={this.props.removeItem}
                        updateQuantity={this.props.updateQuantity}
                        CheckWOCBP={this.props.CheckWOCBP}
                        enableWOCBP={this.props.enableWOCBP}

                    />
                </div>
                </div>
            </React.Fragment>
        )
    }
}

export default PanelsForm;

PanelsForm.propTypes = {
    panels: PropTypes.array.isRequired,
    hasErrors: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    serverError: PropTypes.string.isRequired,
    fetchedPanels: PropTypes.array.isRequired,
    updatePanel: PropTypes.func.isRequired,
    removePanel: PropTypes.func.isRequired,
    addPanel: PropTypes.func.isRequired,
    kitTubesDetail: PropTypes.any.isRequired,
    Kitcode: PropTypes.string.isRequired,
    addItems: PropTypes.func.isRequired,
    removeItem: PropTypes.func.isRequired,
    updateQuantity: PropTypes.func.isRequired,
    updateRegenrationChoice: PropTypes.func.isRequired
};