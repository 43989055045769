import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import moment from 'moment';

import './../../../scss/lib/react-day-picker/style.css';
import './../../../scss/react-day-picker.scss';

import { formatDate, parseDate } from 'react-day-picker/moment';
import { dateFormat } from './../../../utils/constants';

class ExpiryDatePicker extends Component {

    expiryDateInput = React.createRef();

    handleDayChange = (date) => {
        const { updateExpiryDate } = this.props;
        
        let value = '';
        
        if (date === undefined) {
            const input = this.expiryDateInput;
            value = input.current.value;
        } else {
            value = moment(date).format(dateFormat);
        }
        //console.log(value);
        updateExpiryDate(value);
    };

    render() {

        const {
            error,
        } = this.props;
        
        /**
         * Props for the input field
         * http://react-day-picker.js.org/api/DayPickerInput
         */
        const inputProps = {
            className: `form-control form-control--datepicker ${error ? 'has-error' : ''}`,
            name: 'expiry_date',
            ref: this.expiryDateInput,
        }

        return (
            <DayPickerInput
                placeholder={dateFormat}
                onDayChange={this.handleDayChange}
                inputProps={inputProps}
                value={this.props.date}
                format={dateFormat}
                formatDate={formatDate}
                parseDate={parseDate}
            />
        );
    }
}

export default ExpiryDatePicker;

ExpiryDatePicker.propTypes = {
    updateExpiryDate: PropTypes.func.isRequired,
    error: PropTypes.bool.isRequired,
    date: PropTypes.any,
};