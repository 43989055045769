import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import SearchContainer from './SearchContainer';
import { resetFilters } from '../../utils/constants';
// import KitDetails from './KitDetails/KitDetails';

class SearchManagement extends Component {
    render() {
        resetFilters();
        return (
            <Switch>
                <Route exact path={this.props.match.path}
                    render={(props) => <SearchContainer {...props} username={this.props.username} />}
                />
            </Switch>
        )
    }
}

export default SearchManagement;

SearchManagement.propTypes = {
    username: PropTypes.string,
    match: PropTypes.shape({
        path: PropTypes.string,
    }),
};