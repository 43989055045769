import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

import SVG from 'react-inlinesvg';

import dots from './../../../../assets/images/dots.svg';

import './../../../../scss/action-dropdown.scss';

class ActionDropdown extends Component {

    state = {
        toggled: false,
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleOutsideClick, false);
    }

    /**
     * Handle clicks outside the component.
     * Hide the dropdown when someone clicks outside of it.
     */
    handleOutsideClick = (e) => {
        // ignore clicks on the component itself
        if (!e.target.closest('.action-dropdown')) {
            this.hideDropdown();
            document.removeEventListener('click', this.handleOutsideClick, false);
        }
    }
    
    toggleDropdown = () => {
        const toggled = !this.state.toggled;
        this.setState({ toggled });
        //console.log('Toggle the dropdown!');
        
        setTimeout(() => {
            if (toggled) {
                // attach/remove event handler
                document.addEventListener('click', this.handleOutsideClick, false);
            } else {
                document.removeEventListener('click', this.handleOutsideClick, false);
            }
        }, 100);
    }
    
    hideDropdown = () => {
        const toggled = false;
        this.setState({ toggled });
    }

    handleDuplicateCohortClick = (index) => {
        this.props.handleDuplicateCohort(index);
        this.toggleDropdown();
    }

    render() {
        const toggled = this.state.toggled;
        const index = this.props.index;
        const cohortsLength = this.props.cohortsLength;
        
        return (
            <div className="action-dropdown__container">
                <button className="btn-action" onClick={() => this.toggleDropdown()}>
                    <SVG className="icon icon--dots" src={dots} />
                </button>
                <div className={`action-dropdown dropdown-menu ${toggled ? 'show' : ''}`}>
                    <a className="dropdown-item" onClick={() => this.props.openAlternativeCohortNameModal(index)}>Alternative Cohort Names</a>
                    <a className="dropdown-item" onClick={() => this.handleDuplicateCohortClick(index)}>Duplicate</a>
                    {
                        cohortsLength && cohortsLength > 1 &&
                        <a className="dropdown-item" onClick={() => this.props.openModal(index)}>Delete</a>
                    }
                </div>
            </div>
        )
    }
}

export default ActionDropdown;

ActionDropdown.propTypes = {
    handleDuplicateCohort: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    cohortsLength: PropTypes.number.isRequired,
    openModal: PropTypes.func.isRequired,
    openAlternativeCohortNameModal: PropTypes.func.isRequired,
}