import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import SettingsContainer from './SettingsContainer';

class SettingsManagement extends Component {
    render() {
        // resetFilters();
        return (
            <Switch>
                <Route exact path={this.props.match.path}
                    render={(props) => <SettingsContainer {...props} username={this.props.username} />}
                />
            </Switch>
        )
    }
}

export default SettingsManagement;

SettingsManagement.propTypes = {
    username: PropTypes.string,
    match: PropTypes.shape({
        path: PropTypes.string,
    }),
};