import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

import '../../../../src/scss/kit-information.scss';
import KitInformationItem from './KitInformationItem';
import SVG from 'react-inlinesvg';
import add from '../../../../src/assets/images/add.svg';
import NewTubeModal from './NewTubeModal';
import { getAllTubes } from '../../../utils/constants';
class KitInformation extends Component {


    state = {
        modalIsOpen: false,
        packaging_list: [],
        fetchedTubes: null,//[{"tube_id":1,"Tube":"SST"},{"tube_id":2,"Tube":"EDTA"},{"tube_id":3,"Tube":"Sodium Citrate"},{"tube_id":4,"Tube":"Yellow Pot"},{"tube_id":5,"Tube":"Quantiferon Gold Kit"},{"tube_id":6,"Tube":"Pearl PTH"},{"tube_id":7,"Tube":"FlOx"},{"tube_id":8,"Tube":"Citrate"},{"tube_id":10,"Tube":"4L Urine"},]
        selectedPanels: [],
    }
   


    closeModal = () => {
        this.setState({
            modalIsOpen: false,
        });
    }
    openModal = () => {
        // console.log('open modla called')
        this.setState({
            modalIsOpen: true,
        });
    }
    // How to add a logic when isActive is true then it will go

    componentDidMount() {
        this.setState({ fetchedTubes: getAllTubes() });
    }
    
    displayPackingList = () => {
        const { kitTubesDetail } = this.props;
        const listItems = kitTubesDetail.map((item, index) => {
            //  console.log('BuggyPanels',item )
            return (
                <KitInformationItem
                    key={index}
                    item={item}
                    index={index}
                    fetchedTubes={this.state.fetchedTubes}
                    removeItem={this.props.removeItem}
                    updateQuantity={this.props.updateQuantity}
                />
            )
        });

        return (
            <ul>
                {listItems}
            </ul>
        )
    }
    render() {
        if (this.state.fetchedTubes == null) {
            return <div></div>
        }
        return (
            // How can I add bootstrap that ki-information box and <NewTubeModal /> on left side vertically and button on right side
            <div className="ki-container">
                <div className="ki-heading">
                    <span style={{ marginLeft: 15, marginTop: 15 }}>Kit Information</span>
                </div>

                <div className='row'>
                    <div className='col-6'>
                        <div className="ki-information-box">
                            <div className="row" style={{ paddingTop: 10 }}>
                                <div className="col-lg-2">
                                    <span style={{ marginLeft: 15, marginTop: 15 }}>{this.props.Kitcode}</span>
                                </div>
                                <div className="col-lg-6"></div>
                                <div className="col-lg-4">
                                    <a onClick={() => this.props.updateRegenrationChoice()} className="form-add link">
                                        <span>New kit number</span>
                                    </a>
                                </div>
                            </div>
                            <div className="row" style={{ margin: 'unset' }}>
                                <div className="col-lg-12 col-md-12 kit__packing-list">
                                    <div className="small-heading ki-heading">
                                        Packing list
                                    </div>
                                    <div className="content">
                                        {this.displayPackingList()}
                                    </div>
                                </div>
                            </div>

                            <div className="form-add-container">
                                <a onClick={() => this.openModal()} className="form-add link">
                                    <SVG className="icon icon--add" src={add} />
                                    <span>Add Items</span>
                                </a>
                            </div>


                        </div>

                        <NewTubeModal
                            addTube={this.props.addItems}
                            closeModal={this.closeModal}
                            modalIsOpen={this.state.modalIsOpen}
                            addItems={this.props.addItems}
                            fetchedTubes={this.state.fetchedTubes}
                            indexToBeRemoved={this.state.indexToBeRemoved}
                        />
                    </div>
                    <div className='col-6'>
                        <button className={this.props.CheckWOCBP ? 'btn btn-primary btn--next' : 'btn btn-outline-primary btn--back'}
                            onClick={this.props.enableWOCBP}>
                            Display WOCBP button
                        </button>

                    </div>
                </div>



            </div>
        )
    }
}

export default KitInformation;
KitInformation.propTypes = {
    kitTubesDetail: PropTypes.array.isRequired,
    addItems: PropTypes.func.isRequired,
    removeItem: PropTypes.func.isRequired,
    updateRegenrationChoice: PropTypes.func.isRequired,
    updateQuantity: PropTypes.func.isRequired,
    Kitcode: PropTypes.string.isRequired,
};