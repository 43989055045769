import React, { Fragment } from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import './../../../../scss/tag-view.scss';

const LocationTag = (props) => {
    return (
        <div
            key={props.location.name}
            className='location-tag'
            title='click to remove'
            onClick={()=>props.removeLocation(props.location)}
        >
           {props.location.name}
        </div>
    )
};

export default LocationTag