import React, { Component } from 'react';

class Preloader extends Component {
    render() {
        return (
            <div className="loading-overlay">
                <span className="spinner__wrapper">
                    <span className="loading-spinner"></span>
                </span>
            </div>
        )
    }
}

export default Preloader;