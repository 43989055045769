/**
 * Checks if the user is logged in.
 * 
 * If they're logged in, they can go to proceed to one of the pages in the switch statement.
 * If they're not logged in, redirect them to the login page.
 */

import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import Styleguide from './common/Styleguide';
import ProtocolManagement from './ProtocolManagement/ProtocolManagement';
import AnalytePanelManagement from './AnalytePanelManagement/AnalytePanelManagement';
import KitsManagement from './KitsManagement/KitsManagement';
import SearchManagement from './SearchManagement/SearchManagement';

import { LOGIN_URL, generatePortalUrl } from './../utils/constants';
import SettingsManagement from './SettingsManagement/SettingsManagement';
class AuthenticatedRoute extends Component {

    render() {
        // const { component: Component, ...rest } = this.props

        // return (
        //     <Route {...rest} render={(props) => (
        //         this.props.isAuthenticated === true
        //             ? <Component {...props} />
        //             : <Redirect to='/login' />
        //     )} />
        // )

        if (!this.props.isAuthenticated) {
            return (
                <Redirect to={LOGIN_URL} />
            )
        } else {
            return (
                <main>
                    <Switch>
                        <Route path={generatePortalUrl('protocols')} 
                            render={(props) => <ProtocolManagement {...props} username={this.props.username} />}
                        />
                        <Route path={generatePortalUrl('analytes-and-panels')}
                            render={(props) => <AnalytePanelManagement {...props} username={this.props.username} />}
                        />
                        <Route path={generatePortalUrl('kits')}
                            render={(props) => <KitsManagement {...props} username={this.props.username} />}
                        />
                        <Route path={generatePortalUrl('search')}
                            render={(props) => <SearchManagement {...props} username={this.props.username} />}
                        />
                        <Route path={generatePortalUrl('settings')}
                            render={(props) => <SettingsManagement {...props} username={this.props.username} />}
                        />
                        <Route path={generatePortalUrl('styleguide')} component={Styleguide} />
                        <Redirect to={generatePortalUrl('protocols')} />
                    </Switch>
                </main>
            )
        }
    }
}

export default AuthenticatedRoute;

AuthenticatedRoute.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    component: PropTypes.func,
    username: PropTypes.string.isRequired,
};