import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import Modal from 'react-modal';

import SVG from 'react-inlinesvg';
import close from './../../../../assets/images/close.svg';

Modal.setAppElement('#root');
class UpdateProtocolStatusConfirmModal extends Component {

    handleConfirm = () => {
        const { fromProtocolList } = this.props;
        if (fromProtocolList) {
            const { 
                protocol, 
                selectedStatus,
                updateProtocolStatusAPI,
                closeModal,
            } = this.props;
            updateProtocolStatusAPI(protocol, selectedStatus);
            closeModal();
        } else {
            this.props.updateProtocolStatusAPI();
        }
    }

    renderHeading = () => {
        const { selectedStatus } = this.props;
        switch (selectedStatus) {
            case '3': // Live – pending amendments
                return (
                    'Confirm changing protocol status to Live – Pending Amendments'
                )
            case '4': // Live
                return (
                    'Confirm changing protocol status to Live'
                )
            case '5': // Completed
                return (
                    'Confirm changing protocol status to Complete'
                )
            default:
                return '';
        }
    }

    renderBody = () => {
        const { selectedStatus } = this.props;
        switch (selectedStatus) {
            case '3': // Live – pending amendments
                return (
                    <React.Fragment>
                        <p>You should only use this status if you are certain about the changes you need to make to the protocol.</p>
                        <p>With this status, the protocol will still appear in the Pathology Collection app, but its cohorts and episodes won’t be viewable.</p>
                        <p>You should change the status back to ‘Live’ once you have completed your changes.</p>
                    </React.Fragment>
                )
            case '4': // Live
                return (
                    <React.Fragment>
                        <p>If you want to make any changes to the protocol after it goes live, you will first have to agree with the Clinical Research Coordinator on an outage period. Please make sure all the details in the protocol are correct before changing the status to Live.</p>
                    </React.Fragment>
                )
            case '5': // Completed
                return (
                    <p>Once the protocol is completed, it can no longer be viewed on the Pathology Collection app</p>
                )
            default:
                return '';
        }
    }

    render() {
        const { modalIsOpen, closeModal } = this.props;
        return (
            <Modal
                className="modal__bootstrap modal--update-protocol-status modal-dialog"
                overlayClassName="overlay"
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title">{this.renderHeading()}</h3>
                        <button type="button" className="close" onClick={closeModal}>
                            <span aria-hidden="true">
                                <SVG className="icon icon--close" src={close} />
                            </span>
                            <span className="sr-only"></span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {this.renderBody()}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={closeModal}>Go back</button>
                        <button type="button" className="btn btn-primary" onClick={this.handleConfirm}>Change protocol status</button>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default UpdateProtocolStatusConfirmModal;

UpdateProtocolStatusConfirmModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    modalIsOpen: PropTypes.bool.isRequired,
    selectedStatus: PropTypes.string.isRequired,
    updateProtocolStatusAPI: PropTypes.func.isRequired,
    fromProtocolList: PropTypes.bool,
    protocol: PropTypes.object.isRequired,
};