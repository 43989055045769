import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

import DownloadNotReadyModal from './DownloadNotReadyModal';

import axios from 'axios';
import { Auth } from 'aws-amplify';

import { GET_QUOTE_BY_PROTOCOLID_API, GET_EXPORT_BY_PROTOCOLID_API } from './../../../../utils/constants';

import SVG from 'react-inlinesvg';
import infoOrange from './../../../../assets/images/info-orange.svg';

class ReviewQuote extends Component {

    state = {
        quote: '',
        isDownloadingQuote: false,
        isDownloadingProtocol: false,
        modalIsOpen: false,
    }

    componentDidMount() {
        // this.getQuote();
    }

    /**
     * Getting the quote for protocol
     */
    getQuote = () => {

        this.setState({ isDownloadingQuote: true });

        /* Uncomment to use API */
        const url = GET_QUOTE_BY_PROTOCOLID_API.GET;

        // Get the current session
        Auth.currentSession().then((response) => {
            // Grab the jwtToken to use as Authorization header
            const headers = { Authorization: response.idToken.jwtToken };

            const protocolId=this.props.protocolId;

            // Fetch the Quote using protocol id
            axios.get(`${url}?protocolId=${protocolId}`, { headers })
                .then((response) => {
                    
                //    console.log(protocolId);
                    //const quote=response.data[0].result[0].quote;
                    const URL = response.data[0].result[0].URL;

                   //console.log(response.data[0].result[0].fileExists);

                    if (response.data[0].result[0].fileExists === 0) {                       
                        this.openModal();
                    } else {
                        //downloading the file in browser
                        window.open(URL);
                        //console.log(URL);
                        //console.log(response);
                    }
                    
                     
                    
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    this.setState({ isDownloadingQuote: false });
                });

        }).catch((error) => {
            console.error(error);
            this.setState({ isDownloadingQuote: false });
        });
    }

    /**
     * Export the protocol using protocol id
     */
    getProtocol = () => {

        this.setState({ isDownloadingProtocol: true });

        /* Uncomment to use API */
        const url = GET_EXPORT_BY_PROTOCOLID_API.GET;

        // Get the current session
        Auth.currentSession().then((response) => {
            // Grab the jwtToken to use as Authorization header
            const headers = { Authorization: response.idToken.jwtToken };

            const protocolId = this.props.protocolId;

            // Export the protocol
            axios.get(`${url}?protocolId=${protocolId}`, { headers })
                .then((response) => {

                    // console.log(protocolId);
                    const file = response.data[0].result[0].URL;

                    if (response.data[0].result[0].fileExists === 0) {                       
                        this.openModal();
                    } else {
                        //downloading the file in browser
                        window.open(file);
                        //console.log(file);
                    }
                    //console.log(response);

                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    this.setState({ isDownloadingProtocol: false });
                });

        }).catch((error) => {
            console.error(error);
            this.setState({ isDownloadingProtocol: false });
        });
    }

    handleDownloadQuote = () => {
        //console.log('Downloading quote');
        this.getQuote();
    }

    handleDownloadProtocol = () => {
        //console.log('Downloading protocol summary');
        this.getProtocol();
    }

    openModal = () => {
        this.setState({
            modalIsOpen: true,
        });
    }

    closeModal = () => {
        this.setState({
            modalIsOpen: false,
        });
    }

    render() {
        const {
            isDownloadingQuote,
            isDownloadingProtocol,
            modalIsOpen,
        } = this.state;

        return (
            <React.Fragment>
            <div className="review-section review__quote">
                <h3>
                    <SVG className="icon icon--info" src={infoOrange} />
                    <span className="heading">Quote and protocol summary</span>
                </h3>
                <p>If you changed the protocol status just now, it will take a few minutes for the quote and protocol summary to be ready for download.</p>
                <p>When they are ready, a copy of each will be sent to your email address. Please make sure the quote and protocol summary are reviewed and approved by the clinical research company before you change the protocol status to Live.</p>
                <div className="btn-container text-right">
                    <button 
                        className="btn btn-primary btn--download-quote"
                        onClick={this.handleDownloadQuote}
                        disabled={isDownloadingQuote}
                    >
                        {isDownloadingQuote ? <span className="spinner__wrapper"><span className="loading-spinner"></span></span> : ' Download quote'}
                    </button>
                    <button
                        className="btn btn-primary btn--download-protocol-summary"
                        onClick={this.handleDownloadProtocol}
                        disabled={isDownloadingProtocol}
                    >
                        {isDownloadingProtocol ? <span className="spinner__wrapper"><span className="loading-spinner"></span></span> : 'Download protocol summary'}
                    </button>
                </div>
            </div>
           {
               modalIsOpen ? 
               <DownloadNotReadyModal
                    modalIsOpen={modalIsOpen}
                    closeModal={this.closeModal}
               /> : null
           }
           </React.Fragment>
        )
    }
}

export default ReviewQuote;

ReviewQuote.propTypes = {
    protocolId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),    
};
