import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './../../../scss/mbs-codes.scss';

import MBSItem from '../../AnalytePanelManagement/MBSCodes/MBSItem';

import SVG from 'react-inlinesvg';
import add from './../../../assets/images/add.svg';

class MBSCodesForm extends Component {
    render() {
      
        const {
            MBSCodes,
        } = this.props;


        return (

            <div className="container mbs-form__table flex-table-form flex-table">
                <div className="row list--heading">
                    <div className="item item__labels"></div>
                    <div className="item item__1analyte">1 analyte</div>
                    <div className="item item__2analyte">2 analytes</div>
                    <div className="item item__3analyte">3 analytes</div>
                    <div className="item item__4analyte">4 analytes</div>
                    <div className="item item__5analyte">5+ analytes</div>
                    <div className="item item__actions"></div>
                    
                </div>

                    {
                        MBSCodes.length && MBSCodes.map((MBSCodeItem, index) => {
                            return (
                                <MBSItem
                                    key={index}
                                    values={MBSCodeItem.values}
                                    errors={MBSCodeItem.errors}
                                    index={index}
                                    updateMBS={this.props.updateMBS}
                                    removeMBS={this.props.removeMBS}
                                />
                            )
                        })
                    }
                
                <div className="form-add-container">
                <a onClick={() => this.props.addMBS()} className="form-add link">
                    <SVG className="icon icon--add" src={add} />
                    <span>Add a new line</span>
                </a>
            </div>
         
         </div>
         
        )
    }
}

export default MBSCodesForm;

MBSCodesForm.propTypes = {
    MBSCodes: PropTypes.array.isRequired,
    updateMBS: PropTypes.func.isRequired,
    removeMBS: PropTypes.func.isRequired,
    addMBS: PropTypes.func.isRequired,
};