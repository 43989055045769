import React, { Component } from 'react';
import PropTypes from 'prop-types';

import '../../../../scss/kits_printing.scss';

import { formatEpisodeForKit } from '../../../../utils/episodes';

class KitPrintingFilters extends Component {

    

    render() {
        const {
            selectedFilters,
        } = this.props;

        return (
            <div className="selected-filters">
                {
                    selectedFilters &&
                    <React.Fragment>
                        <div className="flex-table kit-label__details container">
                            <div className="row list--item">
                                <div className="item filter-label__title">
                                    <strong>Date Range</strong>
                                </div>
                                <div className="item filter-label__value">
                                    <span>{selectedFilters.dateRange}</span>
                                </div>
                            </div>
                            <div className="row list--item">
                                <div className="item filter-label__title">
                                    <strong>Protocol</strong>
                                </div>
                                <div className="item filter-label__value">
                                    <span>{selectedFilters.protocol || ''}</span>
                                </div>
                            </div>
                            <div className="row list--item">
                                <div className="item filter-label__title">
                                    <strong>Packing Status</strong>
                                </div>
                                <div className="item filter-label__value">
                                    <span>{selectedFilters.packingStatus || ''}</span>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>
        )
    }
}

export default KitPrintingFilters;

KitPrintingFilters.propTypes = {
    formatDateForDisplay: PropTypes.func,
    selectedFilters: PropTypes.object.isRequired, // should be shaped
};