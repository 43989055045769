import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ProgressBar from './../common/ProgressBar';
import SVG from 'react-inlinesvg';

import chevronRight from './../../assets/images/chevron-right-24px.svg';
class ScreeningKits extends Component {
    
    render() {
        const { 
            pageOfItems,
            handleViewKitDetails
        } = this.props;
        return (
            pageOfItems.map((kit, index) => {
                return (
                    <div 
                        key={index} 
                        className={`row list--item list--item-${index}`}
                        onClick={() => handleViewKitDetails(kit.kit_code, 'SCREENING')}
                    >
                        <div className="item item__kit-code">
                            {kit.kit_code}
                        </div>
                        <div className="item item__protocol">
                            {kit.protocol}
                        </div>
                        <div className="item item__cohort">
                            –
                        </div>
                        <div className="item item__episode">
                            Screening
                        </div>
                        <div className="item item__packed-required">
                            <span className={`packed-value ${kit.packed === kit.required ? 'packing--complete' : 'packing--incomplete'}`}>
                                {kit.packed} / {kit.required}
                            </span>
                            <span className="packed-progress-bar">
                                <ProgressBar percentage={kit.packed / kit.required * 100} />
                            </span>
                        </div>
                        <div className="item item__episode">
                            23/09/2020
                        </div>
                        <div className="item item__actions">
                            <SVG className="icon--chevron-right-24" src={chevronRight} />
                        </div>
                    </div>
                )
            })
        )
    }
}

export default ScreeningKits;

ScreeningKits.propTypes = {
    pageOfItems: PropTypes.array,
    handleViewKitDetails: PropTypes.func.isRequired,
};