import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

import CohortItem from './CohortItem';
import RemoveCohortModal from './RemoveCohortModal';

import SVG from 'react-inlinesvg';

import add from './../../../../assets/images/add.svg';

import './../../../../scss/cohorts.scss';
import AddAlternativeCohortNameModal from './AddAlternativeCohortNameModal';

class CohortsForm extends Component {

    state = {
        modalIsOpen: false,
        alternativeCohortNameModalIsOpen: false,
        indexToBeRemoved: null,
        indextoAddName: 0,
    };

    openModal = (index) => {
        this.setState({ 
            modalIsOpen: true,
            indexToBeRemoved: index,
        });
        //console.log(index);
    }
    openAlternativeCohortNameModal = (index) => {
        this.setState({ 
            alternativeCohortNameModalIsOpen: true,
            indextoAddName: index,
        });
        //console.log(index);
    }

    closeModal = () => {
        this.setState({ 
            modalIsOpen: false,
            alternativeCohortNameModalIsOpen: false,
            indexToBeRemoved: null,
            indextoAddName: 0,
        });
    }
    removeCohortName = (index) => {
        let names = this.props.cohorts[this.state.indextoAddName].values.alternativeNames.split('$');
        names.splice(index,1);
        names = names.join('$')
        this.props.updateCohort(this.state.indextoAddName, 'alternativeNames', names)
    }
    addCohortName = (name) => {
        let names = this.props.cohorts[this.state.indextoAddName].values.alternativeNames.split('$');
        names.push(name);
        names = names.join('$')
        this.props.updateCohort(this.state.indextoAddName, 'alternativeNames', names)
    }
    render() {
        const cohorts = this.props.cohorts;
        const cohortsLength = cohorts.length;
        const { targetNoOfOnStudyParticipants } = this.props.protocolSummary;
        const { targetNoOfParticipantsToBeScreened } = this.props.protocolSummary;
        const { 
            hasErrors, 
            errorCohortNo, 
            errorFirstDosingDate,
            errorScreeningDate,
            errorScreeningSkipped,
            errorTargetNoOfOnStudyParticipants,
            errorTargetNoOfScreeningParticipants,
            errorTargetNoOfDayMinusOneParticipants,
            errorTargetNoOfOnStudyParticipantsSum,
            errorTargetNoOfScreeningParticipantsSum,
            serverError,
            isSubmitting,
            isSubmittingBack,
            isSubmittingNext,
            isSubmittingExit,
            protocolStatus,
            isEditFromReviewScreen,
        } = this.props;

        return (
            <div className="cohorts-form__container">

                <div className="cohorts-form">

                    {
                        serverError ?
                            <div className="form-errors__container row">
                                <div className="col-12">
                                    <div className="alert alert-danger" role="alert">
                                        <small>
                                            {serverError}
                                        </small>
                                    </div>
                                </div>
                            </div> : null
                    }

                    {
                        hasErrors ?
                            <div className="form-errors__container row">
                                <div className="col-12">
                                    <div className="alert alert-danger" role="alert">
                                        <p><strong>Please review the error(s) highlighted in red below</strong></p>
                                        <small>
                                            <ul>
                                                {
                                                    errorCohortNo ? <li><strong>Cohort name – </strong>Please enter a cohort name</li> : null
                                                }
                                                {
                                                    errorTargetNoOfOnStudyParticipants ? <li><strong>Target number of on-study participants – </strong>Please enter a number between 1 and 99</li> : null
                                                }
                                                {
                                                    errorTargetNoOfDayMinusOneParticipants ? <li><strong>Target no. of Day -1 participants – </strong>Please enter a number between 1 and 99</li> : null
                                                }
                                                {
                                                    errorTargetNoOfOnStudyParticipantsSum ? <li><strong>Target number of on-study participants – </strong>Please make sure these numbers add up to the target number of on-study participants of the protocol, which is {targetNoOfOnStudyParticipants}.</li> : null
                                                }
                                                {
                                                    errorTargetNoOfScreeningParticipantsSum ? <li><strong>Target number of participants to be screened – </strong>Please make sure these numbers add up to the target number of participants to be screened of the protocol, which is {targetNoOfParticipantsToBeScreened}.</li> : null
                                                }
                                                {
                                                    errorFirstDosingDate ? <li><strong>First dosing date – </strong>The date must be { protocolStatus == 3 ? '' : 'in the future, and' } in the format of DD/MM/YYYY</li> : null
                                                }
                                                {
                                                    errorScreeningDate ? <li><strong>Screening date – </strong>The date must be { protocolStatus == 3 ? '' : 'in the future, and' } in the format of DD/MM/YYYY</li> : null
                                                }
                                                {
                                                    errorScreeningSkipped ? <li><strong>Initial Screening – </strong>You must have to provide initial screening information</li> : null
                                                }
                                                {
                                                    errorTargetNoOfScreeningParticipants ? <li><strong>Target number of participants to be screened – </strong>Please enter a number between 1 and 99</li> : null
                                                }
                                            </ul>
                                        </small>
                                    </div>
                                </div>
                            </div> : null
                    }

                    <div className="cohorts-form__table flex-table-form flex-table container">
                        <div className="row list--heading">
                            <div className="item item__cohort-no">Cohort name</div>
                            <div className="item item__target-no-of-on-study-participants">Target no. of on-study participants</div>
                            <div className="item item__target-no-of-day-minus-one-participants">Target no. of Day -1 participants</div>
                            <div className="item screening-required">Screening Required</div>
                            <div className="item item__target-no-of-day-minus-one-participants">Target no. of screening participants</div>
                            <div className="item item__first-dosing-date">Screening commencement date</div>
                            <div className="item item__first-dosing-date">First dosing date</div>
                            <div className="item item__episodes">Episodes</div>
                            <div className="item item__required">Required</div>
                            <div className="item item__actions"></div>
                        </div>

                        {
                            cohorts.map((cohort, index) => {
                                return (
                                    <CohortItem 
                                        Screening_skipped = {this.props.Screening_skipped}
                                        key={index} 
                                        index={index} 
                                        values={cohort.values} 
                                        errors={cohort.errors} 
                                        errorTargetNoOfOnStudyParticipantsSum={errorTargetNoOfOnStudyParticipantsSum} 
                                        errorTargetNoOfScreeningParticipantsSum={errorTargetNoOfScreeningParticipantsSum} 
                                        cohortsLength={cohortsLength}
                                        updateCohort={this.props.updateCohort}
                                        removeCohort={this.props.removeCohort}
                                        handleDuplicateCohort={this.props.handleDuplicateCohort}
                                        openModal={this.openModal}
                                        openAlternativeCohortNameModal={this.openAlternativeCohortNameModal}
                                        handleOpenEpisodes={this.props.handleOpenEpisodes}
                                    />
                                )
                            })
                        }
                    </div>

                    <div className="form-add-container">
                        <a onClick={() => this.props.addCohort()} className="form-add link">
                            <SVG className="icon icon--add" src={add} />
                            <span>Add a new cohort</span>
                        </a>
                    </div>


                    <RemoveCohortModal
                        removeCohort={this.props.removeCohort}
                        closeModal={this.closeModal}
                        modalIsOpen={this.state.modalIsOpen}
                        indexToBeRemoved={this.state.indexToBeRemoved}
                    />
                    <AddAlternativeCohortNameModal
                        removeCohortName={this.removeCohortName}
                        addCohortName={this.addCohortName}
                        closeModal={this.closeModal}
                        alternativeNames={cohorts[this.state.indextoAddName].values.alternativeNames}
                        cohortName={cohorts[this.state.indextoAddName].values.Cohort_No}
                        modalIsOpen={this.state.alternativeCohortNameModalIsOpen}
                        indexToBeRemoved={this.state.indexToBeRemoved}
                    />

                    <div className="form-btn-container btn-container">
                        <div className="row">
                            {
                                isEditFromReviewScreen ?
                                // If user is coming from Review and export screen...
                                <div className="col text-right">
                                    <button
                                        className="btn btn-primary btn--next"
                                        onClick={(e) => this.props.handleSubmit(e, 4)}
                                        disabled={isSubmitting}
                                    >
                                        {isSubmittingNext ? <span className="spinner__wrapper"><span className="loading-spinner"></span></span> : 'Confirm changes'}
                                    </button>
                                </div>
                                :
                                // Otherwise (normal flow)...
                                <React.Fragment>
                                <div className="col">
                                    <button
                                        className="btn btn-secondary btn--save-exit"
                                        onClick={(e) => this.props.handleSubmit(e, 'home')}
                                        disabled={isSubmitting}
                                    >
                                        {isSubmittingExit ? <span className="spinner__wrapper"><span className="loading-spinner"></span></span> : 'Save and exit'}
                                    </button>
                                </div>
                                <div className="col text-right">
                                    <button
                                        className="btn btn-secondary btn--back"
                                        onClick={(e) => this.props.handleSubmit(e, 2)}
                                        disabled={isSubmitting}
                                    >
                                        {isSubmittingBack ? <span className="spinner__wrapper"><span className="loading-spinner"></span></span> : 'Back'}
                                    </button>
                                    <button
                                        className="btn btn-primary btn--next"
                                        onClick={(e) => this.props.handleSubmit(e, 4)}
                                        disabled={isSubmitting}
                                    >
                                        {isSubmittingNext ? <span className="spinner__wrapper"><span className="loading-spinner"></span></span> : 'Next'}
                                    </button>
                                </div>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default CohortsForm;

CohortsForm.propTypes = {
    cohorts: PropTypes.arrayOf(PropTypes.shape({
        values: PropTypes.shape({
            Cohort_No: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]).isRequired,
            Study_participants: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]).isRequired,
            Day1_participants: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]).isRequired,
            First_dosing_date: PropTypes.string.isRequired,
            Episodes: PropTypes.number,
            Required: PropTypes.bool.isRequired,
        }),
        errors: PropTypes.object,
    })).isRequired,
    updateCohort: PropTypes.func.isRequired,
    removeCohort: PropTypes.func.isRequired,
    handleDuplicateCohort: PropTypes.func.isRequired,
    addCohort: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    hasErrors: PropTypes.bool,
    errorCohortNo: PropTypes.bool,
    errorFirstDosingDate: PropTypes.bool,
    errorScreeningDate: PropTypes.bool,
    errorScreeningSkipped: PropTypes.bool,
    errorTargetNoOfOnStudyParticipants: PropTypes.bool,
    errorTargetNoOfDayMinusOneParticipants: PropTypes.bool,
    errorTargetNoOfOnStudyParticipantsSum: PropTypes.bool,
    errorTargetNoOfScreeningParticipantsSum: PropTypes.bool,
    errorTargetNoOfScreeningParticipants: PropTypes.bool,
    serverError: PropTypes.string,
    isSubmitting: PropTypes.bool,
    isSubmittingBack: PropTypes.bool,
    isSubmittingNext: PropTypes.bool,
    isSubmittingExit: PropTypes.bool,
    handleOpenEpisodes: PropTypes.func.isRequired,
    protocolSummary: PropTypes.shape({
        targetNoOfOnStudyParticipants: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
        targetNoOfParticipantsToBeScreened: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
    }).isRequired,
    protocolStatus: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    isEditFromReviewScreen: PropTypes.bool.isRequired,
    Screening_skipped: PropTypes.bool,
};