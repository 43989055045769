import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import { generatePortalUrl } from './../../../utils/constants';

class SetPasswordSuccess extends Component {

    handleClick = () => {
        //console.log('Logged in!');
        // Update isAuthenticated to true
        this.props.updateAuthState(true);
        // Go to the Protocols page
        this.props.history.push(generatePortalUrl('protocols'));
    }

    render() {
        return (
            <div className="set-password">
                <div className="unauthenticated-layout__subheading">
                    <h3>Ready to go</h3>
                </div>
                <p>Your new password has been created and you can start using the Protocol Admin Portal now.</p>
                <button onClick={this.handleClick} className="btn btn-primary btn-block">Start</button>
            </div>
        )
    }
}

export default withRouter(SetPasswordSuccess);

SetPasswordSuccess.propTypes = {
    updateAuthState: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func,
    }),
};