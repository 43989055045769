import moment from 'moment';

import { dateFormat, dateFormatAPI } from './constants';

export function getWeekDays(weekStart) {
    const days = [weekStart];
    for (let i = 1; i < 7; i += 1) {
        days.push(
            moment(weekStart)
                .add(i, 'days')
                .toDate()
        );
    }
    return days;
}

export function getWeekRange(date) {
    return {
        from: moment(date)
            .startOf('isoWeek') // Start the week on Monday
            .toDate(),
        to: moment(date)
            .endOf('isoWeek')
            .toDate(),
    };
}

export function formatDateForDisplay(date) {
    return moment(date, dateFormatAPI).format(dateFormat);
}

export function formatDateForAPI(date) {
    return moment(date, dateFormat).format(dateFormatAPI);
}