import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';

import KitDetailsForm from './KitDetailsForm';
import KitPrint from './KitPrint';
import Preloader from './../../common/Preloader';

import { SCREENING_KITS_API, EPISODE_KITS_API, UPDATE_KIT_API, UPSERT_PACKING_DETAILS_BY_KITCODE, GET_PACKING_DETAILS_BY_KITCODE } from './../../../utils/constants';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { isInt } from './../../../utils/utils';
import { generatePortalUrl, dateFormat } from './../../../utils/constants';
import { formatDateForAPI, formatDateForDisplay } from './../../../utils/dates';

import moment from 'moment';

import SVG from 'react-inlinesvg';
import chevronLeft from './../../../assets/images/chevron-left.svg';

import './../../../scss/kit-details.scss';

class KitDetails extends Component {

    state = {
        isLoading: false,
        isSubmitting: false,
        serverError: '',
        kit: {},
        errors: {
            expiry_date: false,
            packed: false,
        },
        hasErrors: false,
        displayPrint: false,
        kitPackingDetails: [],
        editingPackingDetails: false
    }

    componentDidMount() {

        this.fetchKitDetails();
    }

    fetchKitDetails = () => {
        const { kitCode, kitType } = this.props.match.params;
        //console.log({kitCode, kitType});
        
        // Get the API URL based on the URL
        let url = '';
        if (kitType === 'screening') {
            url = SCREENING_KITS_API.GET;
        } else {
            url = `${EPISODE_KITS_API.GET}?kitCode=${kitCode}`;
        }

        //console.log(url);

        // Page is loading
        this.setState({ isLoading: true });

        Auth.currentSession().then((response) => {
            const headers = { Authorization: response.idToken.jwtToken };

            // Fetch the Panels list
            axios.get(`${url}`, { headers })
                .then((response) => {
                    // console.log('responseee',response);

                    const result = response.data[0].result;
                    console.log('Result :' , result[0]);

                    // Status code 400 is a server error.
                    if (response.data.statusCode && response.data.statusCode === 400) {
                        this.setState({
                            serverError: 'There was an error loading the kits. Please try again.',
                        });
                        return;
                    }

                    // If successful:
                    this.loadKitDetailToState(result);
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    this.setState({ isLoading: false });
                });

        }).catch((error) => {
            console.error(error);
            this.setState({ isLoading: false });
        });
    }

    loadKitDetailToState = (apiResult) => {
        const { kitCode } = this.props.match.params;
        let result = {};
        if (apiResult.length === 1) {
            result = apiResult[0];
        } else {
            // Error: might be
            result = apiResult.find(item => item.kit_code === kitCode);
        }
        if(result.expiry_date === null){
            result.expiry_date = null;
        }
        else{
            result.expiry_date = formatDateForDisplay(new Date(result.expiry_date));
        }
        
        this.setState({
            kit: result,
            isLoading: false,
        });
        this.fetchKitPackingDetails(this.packingDetailsToObject(result.packing_details))
    }
    packingDetailsToObject = (item)=>{
        if(item == null){
            return null;
        } else {
            let packingDetailList = [];
            let _packingDetailList = item.split('&&');
            _packingDetailList.forEach(obj =>{
                if(obj.toString().trim() != ''){
                    let _objs = obj.toString().trim().split(',');
                    packingDetailList.push({
                        Id: _objs[0].toString().trim(),
                        Kits_packed: _objs[1].toString().trim(),
                        Packed_by: _objs[2].toString().trim(),
                        Date_packed: _objs[3].toString().trim(),
                        QC_by: _objs[4].toString().trim(),
                        QC_date: _objs[5].toString().trim(),
                        Created_at: _objs[6].toString().trim(),
                        Kitcode: _objs[7].toString().trim(),
                    })
                }
                 
            })
            return packingDetailList;
        }
    }
    fetchKitPackingDetails = (data)=>{
        this.populateKitPackingDetails(data);
    }
    populateKitPackingDetails = (data)=>{

        let temp = this.state.kitPackingDetails;
        if(data != null){
            data.forEach(element => {
                let item = {
                    kitsPacked: {
                        errorCount: 0,
                        value: element.Kits_packed,
                        errorDetails: {}
                    },
                    packedBy: {
                        errorCount: 0,
                        value: element.Packed_by,
                        errorDetails: {}
                    },
                    datePacked: {
                        errorCount: 0,
                        value: element.Date_packed,
                        errorDetails: {}
                    },
                    QCBy: {
                        errorCount: 0,
                        value: element.QC_by,
                        errorDetails: {}
                    },
                    QCDate: {
                        errorCount: 0,
                        value: element.QC_date,
                        errorDetails: {}
                    },
                    edit: false,
                    status: 'E',
                    id: element.Id
                }
                temp.push(item);
            });
        }
        this.setState({kitPackingDetails: temp, editingPackingDetails: false})

    }
    updateKitAPI = () => {

        // console.log('updateKitAPI called')
        this.setState({ isSubmitting: true });

        const url = UPDATE_KIT_API.POST;
        const { kit } = this.state;


        let packed = 0;
        if(kit.packed == null){
            this.state.kitPackingDetails.forEach(element => {
                packed += Number(element.kitsPacked.value)
            });
        } else {
            packed = kit.packed
        }

        Auth.currentSession().then((response) => {
            const headers = { Authorization: response.idToken.jwtToken };

            let data = JSON.stringify({
                body: {
                    kit_code: kit.kit_code,
                    packed: packed,
                    expiry_date: formatDateForAPI(kit.expiry_date),
                    packing_details: JSON.stringify(this.state.kitPackingDetails),
                }
            })
            // console.log(data);

            // Fetch the Panels list
            axios.post(url, data, { headers })
                .then((response) => {
                    // console.log('res:',response);
                    // Status code 400 is a server error.
                    if (response.data.statusCode && response.data.statusCode === 400) {
                        this.setState({
                            serverError: 'There was an error submitting the form. Please try again.',
                        });
                        return;
                    }

                    // If successful:
                    this.props.history.push(generatePortalUrl('kits'));

                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    //this.setState({ isSubmitting: false });
                });

        }).catch((error) => {
            console.error(error);
            this.setState({ isSubmitting: false });
        });
    }

    updateExpiryDate = (date) => {
        const { kit } = this.state;
        //console.log('updating date');
        //console.log(date);
        this.setState({
            kit: {
                ...kit,
                expiry_date: date,
            }
        });
    }

    updateKitPacked = (incrementOrDecrement) => {
        const { kit } = this.state;
        const packed = kit.packed;
        let newPacked;

        if (incrementOrDecrement === 'INCREMENT') {
            newPacked = packed + 1;
        } else if (incrementOrDecrement === 'DECREMENT') {
            newPacked = packed - 1;
        }

        this.updateKit('packed', newPacked);
    }

    updateKit = (name, value) => {
        const { kit } = this.state;

        this.setState({
            kit: {
                ...kit,
                [name]: value,
            }
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();

        // Validations first
        const validation = this.validateForm();
        const {
            hasErrors,
        } = validation;
        //console.log(validation);

        // Don't submit the form if the form hasErrors
        if (hasErrors) {
            console.log('form has errors')
            return;
        }

        //console.log('Handling submit...');

        this.updateKitAPI();
    }

    handlePrint = () => {
        // Validations first
        const validation = this.validateForm();
        const {
            hasErrors,
        } = validation;
        //console.log(validation);

        // Don't submit the form if the form hasErrors
        if (hasErrors) {
            return;
        }

        this.displayPrintPage();
    }

    validateForm = () => {
        const kit = this.state.kit;
        let hasErrors = false;
        const errors = {};

        if (!this.validateExpiryDate(kit.expiry_date)) {
            hasErrors = true;
            errors.expiry_date = true;
        } else {
            errors.expiry_date = false;
        }

        if (!this.validatePacked()) {
            hasErrors = true;
            errors.packed = true;
        } else {
            errors.packed = false;
        }

        // Set the state with the values returned from validateForm
        this.setState({
            hasErrors,
            errors,
        });

        return { hasErrors };
    }

    validateExpiryDate = (expiry_date) => {
        // Needs to be DD/MM/YYYY
        // Needs to be today or in the future

        // Empty date
        if (!expiry_date) {
            return false;
        }

        // Invalid format
        const validFormat = moment(expiry_date, dateFormat, true).isValid();
        if (!validFormat) {
            return false;
        }

        // Is today or in the future
        const today = moment().startOf('day');
        const dateParsed = moment(expiry_date, dateFormat);
        const validDate = moment(dateParsed).isSameOrAfter(today);
        if (!validDate) {
            return false;
        }

        return true;
    }




    validateKitsPacked = (item) => {
        if(Number(item.kitsPacked.value) <= 0){
            return false;
        } 
        if(item.packedBy.value ==  null && item.packedBy.value ==  ''){
            return false;
        }
        if(item.datePacked.value == null){
            return false;
        } 
        if(item.QCBy.value ==  null && item.QCBy.value ==  ''){
            return false;
        }
        if(item.QCDate.value == null){
            return false;
        }
        return true;
    }


   


    validatePacked = () => {
        for(let i =0; i < this.state.kitPackingDetails.length; i++){
            if(
                this.state.kitPackingDetails[i].kitsPacked.errorCount > 0
                || this.state.kitPackingDetails[i].packedBy.errorCount > 0
                || this.state.kitPackingDetails[i].datePacked.errorCount > 0
                || this.state.kitPackingDetails[i].QCBy.errorCount > 0
                || this.state.kitPackingDetails[i].QCDate.errorCount > 0
            ){
                return false;
            }
        }
        let errorCount = 0;
        let packed = 0;
        this.state.kitPackingDetails.forEach(element => {
            if(!this.validateKitsPacked(element)){
                errorCount++;
                console.log('error found', errorCount)
            }
            packed += Number(element.kitsPacked.value)
        });

        if(errorCount > 0){
            return false;
        }
        // It should be numeric, an integer and is mandatory
        console.log('Packed:', packed);
        if (!packed || !isInt(packed)) {
            return false;
        }

        const { required } = this.state.kit;

        // It should not be less than 0 more than the number required
        if (packed <= 0 || packed > required) {
            console.log('Packed', packed)
            console.log('required', required)
            return false;
        }

        const { kit } = this.state;
        //console.log('updating date');
        //console.log(date);
        this.setState({
            kit: {
                ...kit,
                packed: packed,
            }
        });

        return true;
    }

    displayPrintPage = () => {
        this.setState({
            displayPrint: true,
        })
    }

    hidePrintPage = () => {
        this.setState({
            displayPrint: false,
        })
    }

    addNewPackingDetail = () => {

        const datePacked = new Date();
        let item = {
            kitsPacked: {
                errorCount: 0,
                value: 0,
                errorDetails: {}
            },
            packedBy: {
                errorCount: 0,
                value: '',
                errorDetails: {}
            },
            datePacked: {
                errorCount: 0,
                value: datePacked,
                errorDetails: {}
            },
            QCBy: {
                errorCount: 0,
                value: '',
                errorDetails: {}
            },
            QCDate: {
                errorCount: 0,
                value: datePacked,
                errorDetails: {}
            },
            edit: true,
            status: 'N',
            id: -1
        }
        let temp = this.state.kitPackingDetails;
        temp.push(item);
        this.setState({kitPackingDetails: temp, editingPackingDetails: true})
    }
    enableEditModeOfKitPackingDetails = (index, item)=>{
        item.edit = true;
        let temp = this.state.kitPackingDetails;
        temp.splice(index, 1, item)
        this.setState({kitPackingDetails: temp, editingPackingDetails: true})
    }
    upsertPackingDetailItem = (index, item)=>{
        
        item.datePacked.value = item.datePacked.value;
        item.QCDate.value = item.QCDate.value;
        let temp = this.state.kitPackingDetails;
        if(index == -1){
            temp.push(item);
        } else {
            temp.splice(index, 1, item)
        }
        let editing = temp.findIndex(item=> item.edit == true) > 0;
        this.setState({kitPackingDetails: temp, editingPackingDetails: editing},()=>{
            console.log(this.state.kitPackingDetails)
        })
    }
    render() {
        const {
            kit,
            hasErrors,
            errors,
            isLoading,
            serverError,
            isSubmitting,
            displayPrint,
        } = this.state;
        return (
            <React.Fragment>
                <div className="page-heading">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1>Kits</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={`kits-management section container ${displayPrint ? 'hide' : ''}`}>
                    <div className="kit-details">
                        <div className="kit-details__back page__back">
                            <Link
                                to={generatePortalUrl('kits')}
                                className="kit-details__back__link page__back__link"
                            >
                                <SVG className="icon icon--back" src={chevronLeft} />
                                <span className="link">Kits</span>
                            </Link>
                        </div>
                        <div className="kit-details__heading">
                            <h3>{kit.kit_code}</h3>
                        </div>

                        {
                            serverError ?
                                <div className="form-errors__container row">
                                    <div className="col-12">
                                        <div className="alert alert-danger" role="alert">
                                            <small>
                                                {serverError}
                                            </small>
                                        </div>
                                    </div>
                                </div> : null
                        }

                        {
                            hasErrors &&
                            <div className="form-errors__container row">
                                <div className="col-12">
                                    <div className="alert alert-danger" role="alert">
                                        <p><strong>Please review the error(s) highlighted in red below</strong></p>
                                        <small>
                                            <ul>
                                                {
                                                    errors.expiry_date && <li><strong>Expiry date – </strong>Please enter an expiry date in the format of DD/MM/YYYY. The date must be in the future.</li>
                                                }
                                                {
                                                    errors.packed && <li><strong>Number packed – </strong>Total number of packed kits can not be 0 or greater than number of required kits.</li>
                                                }
                                            </ul>
                                        </small>
                                    </div>
                                </div>
                            </div>
                        }

                        <KitDetailsForm
                            kit={kit}
                            errors={errors}
                            updateExpiryDate={this.updateExpiryDate}
                            updateKitPacked={this.updateKitPacked}
                            updateKit={this.updateKit}
                            handlePrint={this.handlePrint}
                            kitPackingDetails={this.state.kitPackingDetails}
                            addNewPackingDetail={this.addNewPackingDetail}
                            upsertPackingDetailItem={this.upsertPackingDetailItem}
                            enableEditModeOfKitPackingDetails={this.enableEditModeOfKitPackingDetails}
                        />

                        <div className="form-btn-container btn-container">
                            <div className="row">
                                <div className="col">
                                    <Link 
                                        className="btn btn-secondary btn--cancel"
                                        to={generatePortalUrl('kits')}
                                    >
                                        Cancel
                                    </Link>
                                </div>
                                <div className="col text-right">
                                    <button 
                                        className="btn btn-primary btn--done"
                                        onClick={(e) => this.handleSubmit(e)}
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? <span className="spinner__wrapper"><span className="loading-spinner"></span></span> : 'Done'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    displayPrint &&
                    <KitPrint
                        kit={kit}
                        hidePrintPage={this.hidePrintPage}
                    />
                }
                {
                    (isLoading || isSubmitting) ?
                        <Preloader />
                        : null
                }
            </React.Fragment>
        )
    }
}

export default withRouter(KitDetails);

KitDetails.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            kitType: PropTypes.string.isRequired,
            kitCode: PropTypes.string.isRequired,
        }),
    }),
    history: PropTypes.shape({
        push: PropTypes.func,
    }),
};
