import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';

import axios from 'axios';
import { Auth } from 'aws-amplify';
import _ from 'lodash';

import MBSCodesForm from './MBSCodesForm';
import CancelMBS from './CancelMBS';
import Preloader from './../../common/Preloader';
import { isInt } from './../../../utils/utils';
import { decimalPlaces , round } from './../../../utils/utils';

import { GET_MBS_CODES_API, UPSERT_MBS_CODES_API , generatePortalUrl } from './../../../utils/constants';

import './../../../scss/mbs-codes.scss';

let previousState = [];
let existingMBSCodes = [];

class MBSCodesContainer extends Component {

    state = {
        editModalIsOpen: false,
        isSubmitting: false,
        isFetchingMBSCodes: false,
        serverError: '',
        MBSCodes: [
            {
                values: {
                    MBS_code: '',
                    Two_codes: '',
                    Three_codes: '',
                    Four_codes: '',
                    Five_codes: '',
                    Fee_for_one: '',
                    Fee_for_five: '',
                    Fee_for_four: '',
                    Fee_for_three: '',
                    Fee_for_two: '',
                },
                errors: {
                    MBS_code: false,
                    Two_codes: false,
                    Three_codes: false,
                    Four_codes: false,
                    Five_codes: false,
                    Fee_for_one: false,
                    Fee_for_five: false,
                    Fee_for_four: false,
                    Fee_for_three: false,
                    Fee_for_two: false,
                }
            }
        ],
    }

    componentDidMount() {
        // Reset the existing arrays
        previousState = [];
        existingMBSCodes = [];
        // Fetch APIs
        this.fetchMBSCodeAPI();
    }

    /* Fetch the list of MBS Codes */
    fetchMBSCodeAPI = () => {
        //console.log('fetching list of MBS Codes');
        // Fetching...
        this.setState({ isFetchingMBSCodes: true, })

        const url = GET_MBS_CODES_API.GET;

        Auth.currentSession().then((response) => {
            const headers = { Authorization: response.idToken.jwtToken };

            // Fetch the Panels list
            axios.get(url, { headers })
                .then((response) => {
                    if (response.data) {
                        const result = response.data.result;
                        if (result && result.length) {
                            this.populateMBSCodesToState(result);
                            this.mapMBSCodesToPreviousState(result);
                            this.populateMBSCodes(result);
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    this.setState({ isFetchingMBSCodes: false, })
                });

        }).catch((error) => {
            console.error(error);
            this.setState({ isFetchingMBSCodes: false, })
        });
    }

    /* Add MBS codes from the API to the state */
    populateMBSCodesToState = (result) => {
        const formatted = result.map(item => {
            // Format the fees to a 2-digit string for display
            const Fee_for_one = item.Fee_for_one ? (item.Fee_for_one).toFixed(2) : '';
            const Fee_for_two = item.Fee_for_two ? (item.Fee_for_two).toFixed(2) : '';
            const Fee_for_three = item.Fee_for_three ? (item.Fee_for_three).toFixed(2) : '';
            const Fee_for_four = item.Fee_for_four ? (item.Fee_for_four).toFixed(2) : '';
            const Fee_for_five = item.Fee_for_five ? (item.Fee_for_five).toFixed(2) : '';
            return {
                values: {
                    ...item,
                    Fee_for_one: Fee_for_one,
                    Fee_for_two: Fee_for_two,
                    Fee_for_three: Fee_for_three,
                    Fee_for_four: Fee_for_four,
                    Fee_for_five: Fee_for_five,
                    isExisting: true, // This flag will be used to hide the "Remove" CTA for existing MBS codes
                },
                errors: {
                    MBS_code: false,
                    Two_codes: false,
                    Three_codes: false,
                    Four_codes: false,
                    Five_codes: false,
                    Fee_for_one: false,
                    Fee_for_five: false,
                    Fee_for_four: false,
                    Fee_for_three: false,
                    Fee_for_two: false,
                },
            }
        });
        
        this.setState({
            MBSCodes: formatted,
        });
    }

    /* Extract the MBS codes from the API result and store it in an external array */
    populateMBSCodes = (result) => {
        const MBSCodes = [];
        result.map(item => {
            const items = [];
            if (item.MBS_code) {
                items.push(item.MBS_code);
            }
            if (item.Two_codes) {
                items.push(item.Two_codes);
            }
            if (item.Three_codes) {
                items.push(item.Three_codes);
            }
            if (item.Four_codes) {
                items.push(item.Four_codes);
            }
            if (item.Five_codes) {
                items.push(item.Five_codes);
            }
            const uniqueItems = [...new Set(items)]; // Only get the unique codes from a line
            MBSCodes.push({
                values: uniqueItems,
            })
        });
        existingMBSCodes = MBSCodes;
        // console.log(existingMBSCodes);
    }

    /* Map MBS codes from the API to a previousState array for later comparison */
    mapMBSCodesToPreviousState = (result) => {
        const mapped = result.map(item => {
            delete(item.Unit_consumables);
            return item;
        })
        previousState = [...mapped];
        //console.log(previousState);
    }

    addMBS = () => {
        const MBSCodes = this.state.MBSCodes;
        
        const MBSItem = {
            values: {
                MBS_code: '',
                Two_codes: '',
                Three_codes: '',
                Four_codes: '',
                Five_codes: '',
                Fee_for_one: '',
                Fee_for_five: '',
                Fee_for_four: '',
                Fee_for_three: '',
                Fee_for_two: '',
            },
            errors: {
                MBS_code: false,
                Two_codes: false,
                Three_codes: false,
                Four_codes: false,
                Five_codes: false,
                Fee_for_one: false,
                Fee_for_five: false,
                Fee_for_four: false,
                Fee_for_three: false,
                Fee_for_two: false,
            },
        }
        
        this.setState({
            MBSCodes: [...MBSCodes, MBSItem],
        })
    }

    removeMBS = (index) => {
        const MBSCodes = this.state.MBSCodes;
        
        // Don't let them delete if there's only one left
        if (MBSCodes.length <= 1) {
            return;
        }
        MBSCodes.splice(index, 1);
        
        this.setState({ 
            MBSCodes,
        });
    }

    updateMBS = (index, name, value) => {

        const MBSCodes = this.state.MBSCodes;

        const newMBSCodes = MBSCodes.map((MBSCodeItem, i) => {
            if (i === index) {
                MBSCodeItem = { ...MBSCodeItem };
                MBSCodeItem.values = { ...MBSCodeItem.values, [name]: value }
            }
            return MBSCodeItem;
        })

        this.setState({
            MBSCodes: newMBSCodes,
        })
    }
    
    updateMBSCodesAPI = () => {

        const { added, updated } = this.returnUpdates();

        const MBS = [];

        for (let item of updated) {
            MBS.push(
                {
                    mbs_code: item.MBS_code,
                    One_codes: item.One_codes,
                    Two_codes: item.Two_codes,
                    Three_codes: item.Three_codes,
                    Four_codes: item.Four_codes,
                    Five_codes: item.Five_codes,
                    Fee_for_one: item.Fee_for_one,
                    Fee_for_two: item.Fee_for_two,
                    Fee_for_three: item.Fee_for_three,
                    Fee_for_four: item.Fee_for_four,
                    Fee_for_five: item.Fee_for_five,
                    Status: 'U',
                }
            )
        }

        for (let item of added) {
            MBS.push(
                {
                    mbs_code: item.MBS_code,
                    One_codes: item.One_codes,
                    Two_codes: item.Two_codes,
                    Three_codes: item.Three_codes,
                    Four_codes: item.Four_codes,
                    Five_codes: item.Five_codes,
                    Fee_for_one: item.Fee_for_one,
                    Fee_for_two: item.Fee_for_two,
                    Fee_for_three: item.Fee_for_three,
                    Fee_for_four: item.Fee_for_four,
                    Fee_for_five: item.Fee_for_five,
                    Status: 'N',
                }
            )
        }
        //console.log(MBS);

        this.setState({ isSubmitting: true, });

        //console.log('Added:');
        //console.log(added);
        //console.log('Updated:');
        //console.log(updated);

        Auth.currentSession().then((response) => {
            const headers = { Authorization: response.idToken.jwtToken };
            const url = UPSERT_MBS_CODES_API.POST;  

            let data = JSON.stringify({
                body : {
                    MBS: MBS
			
                }
            })
            //console.log('upsert Method console enabled for Time Out error testing !!');
            //console.log(data);

            axios.post(url, data, { headers })
                .then((response) => {

                    // No longer submitting
                    this.setState({
                        isSubmitting: false,
                    });

                    //console.log(response);

                    // Status code 400 is a server error.
                    if (response.data.statusCode && response.data.statusCode === 400) {
                        console.error('Server error with status code 400 returned');
                        this.setState({
                            serverError: 'There was an error submitting the form. Please try again.',
                        });
                        return;
                    }

                    // If successful:
                    this.props.history.push(generatePortalUrl('analytes-and-panels'));
                   
                })
                .catch((error) => {
                    console.error(error);
                    this.setState({
                        serverError: 'There was an error submitting the form. Please try again.',
                        isSubmitting: false,
                    });
                });
        });
    }

    validateForm = () =>{
        const MBSCodes = _.cloneDeep(this.state.MBSCodes);
        let hasErrors = false;
        let errorMBS_code = false;
        let errorMBS_codeDuplicate = false;
        let errorFee = false;

        const newMBSCodes = MBSCodes.map((MBSCode, index) => {

            const newErrors = {};

            // Validate each field inside
            if (!this.validateCode(MBSCode.values.MBS_code)) {
                errorMBS_code = true;
                hasErrors = true;
                newErrors.MBS_code = true;
            } else if (!MBSCode.values.isExisting && !this.checkDuplicateMBS(MBSCode.values.MBS_code, index)) {
                errorMBS_codeDuplicate = true;
                hasErrors = true;
                newErrors.MBS_code = true;
            } else {
                newErrors.MBS_code = false;
            }

            if (!this.validateCode(MBSCode.values.Two_codes)) {
                errorMBS_code = true;
                hasErrors = true;
                newErrors.Two_codes = true;
            } else if (!this.checkDuplicateMBS(MBSCode.values.Two_codes, index)) {
                errorMBS_codeDuplicate = true;
                hasErrors = true;
                newErrors.Two_codes = true;
            } else {
                newErrors.Two_codes = false;
            }

            if (!this.validateCode(MBSCode.values.Three_codes)) {
                errorMBS_code = true;
                hasErrors = true;
                newErrors.Three_codes = true;
            } else if (!this.checkDuplicateMBS(MBSCode.values.Three_codes, index)) {
                errorMBS_codeDuplicate = true;
                hasErrors = true;
                newErrors.Three_codes = true;
            } else {
                newErrors.Three_codes = false;
            }

            if (!this.validateCode(MBSCode.values.Four_codes)) {
                errorMBS_code = true;
                hasErrors = true;
                newErrors.Four_codes = true;
            } else if (!this.checkDuplicateMBS(MBSCode.values.Four_codes, index)) {
                errorMBS_codeDuplicate = true;
                hasErrors = true;
                newErrors.Four_codes = true;
            } else {
                newErrors.Four_codes = false;
            }

            if (!this.validateCode(MBSCode.values.Five_codes)) {
                errorMBS_code = true;
                hasErrors = true;
                newErrors.Five_codes = true;
            } else if (!this.checkDuplicateMBS(MBSCode.values.Five_codes, index)) {
                errorMBS_codeDuplicate = true;
                hasErrors = true;
                newErrors.Five_codes = true;
            } else {
                newErrors.Five_codes = false;
            }

            if (!this.validateFee(MBSCode.values.Fee_for_one)) {
                errorFee = true;
                hasErrors = true;
                newErrors.Fee_for_one = true;
            } else {
                newErrors.Fee_for_one = false;
            }

            if (!this.validateFee(MBSCode.values.Fee_for_two)) {
                errorFee = true;
                hasErrors = true;
                newErrors.Fee_for_two = true;
            } else {
                newErrors.Fee_for_two = false;
            }

            if (!this.validateFee(MBSCode.values.Fee_for_three)) {
                errorFee = true;
                hasErrors = true;
                newErrors.Fee_for_three = true;
            } else {
                newErrors.Fee_for_three = false;
            }

            if (!this.validateFee(MBSCode.values.Fee_for_four)) {
                errorFee = true;
                hasErrors = true;
                newErrors.Fee_for_four = true;
            } else {
                newErrors.Fee_for_four = false;
            }

            if (!this.validateFee(MBSCode.values.Fee_for_five)) {
                errorFee = true;
                hasErrors = true;
                newErrors.Fee_for_five = true;
                round(MBSCode.values.Fee_for_five);
            } else {
                newErrors.Fee_for_five = false;
            }

            MBSCode = { ...MBSCode };
            MBSCode.errors = { ...MBSCode.errors, ...newErrors };

            return MBSCode;
        });
           
        return {
            newMBSCodes,
            hasErrors,
            errorMBS_code,
            errorMBS_codeDuplicate,
            errorFee,
        };
    }

    validateCode = (code) => {
        if(!code || !isInt(code)){
            return false;
        }
        return true;
    }

    validateFee = (fee) => {
        // - 3-digit numeric field (Mandatory)
        if (!fee || isNaN(fee) || fee < 1 || fee > 999 || decimalPlaces(fee) > 2) {
            return false;
        }
        return true;
    }

    handleSubmit = (e) => {
        e.preventDefault();

        // Validations first
        const validation = this.validateForm();
        const { 
            newMBSCodes, 
            hasErrors,
            errorMBS_code,
            errorMBS_codeDuplicate,
            errorFee,
        } = validation;
        //console.log(validation);

        // Set the state with the values returned from validateForm
        this.setState({
            MBSCodes: newMBSCodes,
            hasErrors,
            errorMBS_code,
            errorMBS_codeDuplicate,
            errorFee,
        });

        // Don't submit the form if the form hasErrors
        if (hasErrors) {
            return;
        }
        
        //console.log('Handling submit...');

        
        this.updateMBSCodesAPI();
    }

    returnUpdates = () => {
        // Get copies of current state and previous state
        const prevStateCopy = previousState.map(MBSCodeItem => ({ ...MBSCodeItem }));
        const MBSCodes = this.state.MBSCodes.map(MBSCodeItem => ({ ...MBSCodeItem.values }));
        //console.log('Previous state: ');
        //console.log(prevStateCopy);
        // console.log(MBSCodes);

        // Filter out new and existing using the isExisting flag
        const existingMBSCodes = MBSCodes.filter(MBSCodeItem => MBSCodeItem.isExisting );
        const newMBSCodes = MBSCodes.filter(MBSCodeItem => MBSCodeItem.isExisting == null );
        // console.log(existingMBSCodes);
        // console.log(newMBSCodes);

        // Parse it so that it's ready for comparison and API sending
        const existingMBSCodesParsed = existingMBSCodes.map(MBSCodeItem => this.parseMBSItemForAPI(MBSCodeItem));
        const newMBSCodesParsed = newMBSCodes.map(MBSCodeItem => this.parseMBSItemForAPI(MBSCodeItem));
        //console.log('Existing:');
        // console.log(existingMBSCodesParsed);
        //console.log('Added:');
        // console.log(newMBSCodesParsed);

        // Compare the parsed existing and previousState arrays for MBSItems that have been updated
        const difference = existingMBSCodesParsed.filter((MBSCodeItem, index) => {
            return !(_.isEqual(MBSCodeItem, prevStateCopy[index]));
        });
        //console.log('Updated:');
        // console.log(difference);


        const added = newMBSCodesParsed;
        const updated = difference;

        return { added, updated };
    }

    parseMBSItemForAPI = (MBSItem) => {
        
        const newMBSItem = {
            MBS_code: MBSItem.MBS_code,
            Two_codes: MBSItem.Two_codes, 
            Three_codes: MBSItem.Three_codes, 
            Four_codes: MBSItem.Four_codes, 
            Five_codes: MBSItem.Five_codes, 
            // Convert the fees back to a number format for comparison and API sending
            Fee_for_one: parseFloat(MBSItem.Fee_for_one),
            Fee_for_two: parseFloat(MBSItem.Fee_for_two),
            Fee_for_three: parseFloat(MBSItem.Fee_for_three),
            Fee_for_four: parseFloat(MBSItem.Fee_for_four),
            Fee_for_five: parseFloat(MBSItem.Fee_for_five),
        }

        return newMBSItem;
    }

    checkDuplicateMBS = (MBS_code, index) => {
        // New MBS codes: Need to check that the MBS_Code is not duplicated with another 1 Analyte/MBS Code.
        // Just use state to compare.
        const { MBSCodes } = this.state;
        const duplicate = MBSCodes.find((item, itemIndex) => {
            if (index !== itemIndex) { // Don't compare it against itself!
                return item.values.MBS_code === MBS_code;
            }
        });
        // console.log(duplicate);

        if (duplicate) {
            return false;
        }
        return true;
        
        // 2 Analyte... 5 Analyte MBS_Codes - should not be used in other lines/indexes?
        // use existingMBSCodes
    }

    render() {
        const {
            isSubmitting,
            isFetchingMBSCodes,
            hasErrors,
            serverError,
            errorMBS_code,
            errorMBS_codeDuplicate,
            errorFee,
        } = this.state;
        return (
            <React.Fragment>
                <div className="page-heading">
                    <div className="container">
                        <h1>Manage MBS codes</h1>
                    </div>
                </div>
                <section className="mbs__container section container">

                    {
                        serverError ?
                            <div className="form-errors__container row">
                                <div className="col-12">
                                    <div className="alert alert-danger" role="alert">
                                        <small>
                                            {serverError}
                                        </small>
                                    </div>
                                </div>
                            </div> : null
                    }

                    {
                        hasErrors ?
                            <div className="form-errors__container row">
                                <div className="col-12">
                                    <div className="alert alert-danger" role="alert">
                                        <p><strong>Please review the error(s) highlighted in red below</strong></p>
                                        <small>
                                            <ul>
                                                {
                                                    errorMBS_code ? <li><strong>Invalid MBS Code – </strong>Please enter a valid MBS code</li> : null
                                                }

                                            </ul>
                                            <ul>
                                                {
                                                    errorFee ? <li><strong>Invalid Fee – </strong>Please enter a valid number between 1 and 999, rounded to 2 decimal places</li> : null
                                                }

                                            </ul>
                                            <ul>
                                                {
                                                    errorMBS_codeDuplicate ? <li><strong>Invalid MBS Code – </strong>This MBS code already exists</li> : null
                                                }
                                            </ul>

                                        </small>
                                    </div>
                                </div>
                            </div> : null
                    }

                    <MBSCodesForm 
                        MBSCodes={this.state.MBSCodes}
                        addMBS={this.addMBS}  
                        removeMBS={this.removeMBS}
                        updateMBS={this.updateMBS}
                        
                    />
                    <div className="form-btn-container btn-container">                            
                        <div className="row">
                            <div className="col">
                                <CancelMBS
                                    isSubmitting={isSubmitting}
                                    isEdit={this.state.isEdit}
                                />
                            </div>
                            <div className="col text-right">
                                <button
                                    className="btn btn-primary btn--done"
                                    onClick={(e) => this.handleSubmit(e)}
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? <span className="spinner__wrapper"><span className="loading-spinner"></span></span> : 'Done'}
                                </button>
                                
                            </div>
                        </div>
                    </div>
                   
                </section>
                {
                    (isFetchingMBSCodes || isSubmitting) ? <Preloader /> : null
                }
            </React.Fragment>
            
        )
    }
}

export default withRouter(MBSCodesContainer);

MBSCodesContainer.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
    }),
};