import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { passwordRegexErrorFlag } from './../../../utils/utils';

class SetPasswordForm extends Component {

    newPassword = React.createRef();

    invalidPasswordMessage = (errorMessage) => {
        if (errorMessage === passwordRegexErrorFlag) {
            const specialCharacters = '^$*.[]{}()?-"!@#%&/\\,><\':;|_~`';
            return (
                <small id="newPasswordHelp" className="form-text">
                    <div>Please make sure your new password contains:</div>
                    <ul>
                        <li>at least 8 characters</li>
                        <li>at least 1 uppercase alphabetic character</li>
                        <li>at least 1 lowercase alphabetic character</li>
                        <li>at least 1 special character {specialCharacters}</li>
                        <li>at least 1 number</li>
                    </ul>
                </small>
            )
        } else {
            return (
                <small id="newPasswordHelp" className="form-text">{errorMessage}</small>
            )
        }
    }

    render() {
        const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
        } = this.props;

        return (
            <div className="set-password">
                <div className="unauthenticated-layout__subheading">
                    <h3>Create a new password</h3>
                </div>
                    
                {/* Display error message for the whole form if login was invalid */}
                {errors.form &&
                    <p className="form-message__container has-error">
                        <small id="formHelp" className="form-text">{errors.form}</small>
                    </p>
                }

                <form onSubmit={handleSubmit}>
                    <p>As this is your first time signing in, you need to create your own password before using the Portal. Please enter your new password below.</p>
                    
                    <div className={`form-group ${errors.newPassword && touched.newPassword ? 'has-error' : ''}`}>
                        <label htmlFor="newPassword">New password</label>
                        <input type="password" name="newPassword" id="newPassword" className="form-control" value={values.newPassword} onChange={handleChange} onBlur={handleBlur} ref={this.newPassword} />
                        {errors.newPassword && touched.newPassword && this.invalidPasswordMessage(errors.newPassword)}
                    </div>
                    <div className={`form-group ${errors.confirmNewPassword && touched.confirmNewPassword ? 'has-error' : ''}`}>
                        <label htmlFor="confirmNewPassword">Confirm new password</label>
                        <input type="password" name="confirmNewPassword" id="confirmNewPassword" className="form-control" value={values.confirmNewPassword} onChange={handleChange} onBlur={handleBlur} />
                        {errors.confirmNewPassword && touched.confirmNewPassword && <small id="confirmNewPasswordHelp" className="form-text">{errors.confirmNewPassword}</small>}
                    </div>
                    <div className="form-group form-group--button">
                        <button type="submit" className="btn btn-primary btn-block">
                            {isSubmitting ? <span className="spinner__wrapper"><span className="loading-spinner"></span></span> : 'Create new password'}
                        </button>
                    </div>
                </form>
            </div>
        )
    }
}

export default SetPasswordForm;

SetPasswordForm.propTypes = {
    values: PropTypes.object,
    touched: PropTypes.object,
    errors: PropTypes.object,
    isSubmitting: PropTypes.bool,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    handleSubmit: PropTypes.func,
};