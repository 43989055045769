import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import Modal from 'react-modal';

import SVG from 'react-inlinesvg';
import close from './../../assets/images/close.svg';

Modal.setAppElement('#root');
class DeletePanelModal extends Component {

    handleDeletePanel = () => {
        const { panel, deletePanel, closeDeleteModal } = this.props;
        //console.log('delete panel');
        //console.log(panel);
        deletePanel(panel);
        closeDeleteModal();
    }

    render() {
        const { 
            deleteModalIsOpen, 
            closeDeleteModal,
        } = this.props;
        
        return (
            <Modal
                className="modal__bootstrap modal--delete-panel modal-dialog"
                overlayClassName="overlay"
                isOpen={deleteModalIsOpen}
                onRequestClose={closeDeleteModal}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title">Please confirm that you want to delete this panel</h3>
                        <button type="button" className="close" onClick={closeDeleteModal}>
                            <span aria-hidden="true">
                                <SVG className="icon icon--close" src={close} />
                            </span>
                            <span className="sr-only"></span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p>This action cannot be undone and deleting the panel will remove all the details in it</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={closeDeleteModal}>Go back</button>
                        <button type="button" className="btn btn-primary" onClick={() => this.handleDeletePanel()}>Delete panel</button>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default DeletePanelModal;

DeletePanelModal.propTypes = {
    deletePanel: PropTypes.func.isRequired,
    closeDeleteModal: PropTypes.func.isRequired,
    deleteModalIsOpen: PropTypes.bool.isRequired,
    panel: PropTypes.object.isRequired, // should be shaped
};