/**
 * Provides routing for the protocols section
 */

import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import ProtocolListContainer from './ProtocolListContainer';
import AddEditProtocol from './AddEditProtocol';
import { resetFilters, TUBE_API, setFetchTubes } from '../../utils/constants';
import { Auth } from 'aws-amplify';
import Axios from 'axios';

class ProtocolManagement extends Component {
    componentDidMount(){
        this.fetchTubesAPI();
    }
    fetchTubesAPI = () => {

        const url = TUBE_API.GET;

        Auth.currentSession().then((response) => {
            const headers = { Authorization: response.idToken.jwtToken };

            // Fetch the Panels list
            Axios.get(url, { headers })
                .then((response) => {
                    if (response.data) {
                        // console.log(response.data);
                        const result = response.data.result;
                        if (result && result.length) {
                            // console.log('result111111111999999',result);
                            setFetchTubes(result);
                        }

                    }
                })
                .catch((error) => {
                    console.error(error);
                })

        }).catch((error) => {
            console.error(error);
        });
    }
    render() {
        resetFilters();
        return (
            <Switch>
                <Route exact path={this.props.match.path}
                    render={(props) => <ProtocolListContainer {...props} username={this.props.username} />}
                />
                <Route path={`${this.props.match.path}/add`} 
                    render={(props) => <AddEditProtocol {...props} username={this.props.username} />}
                />
                <Route path={`${this.props.match.path}/edit/:protocolId`} 
                    render={(props) => <AddEditProtocol {...props} username={this.props.username} />}
                />
            </Switch>
        )
    }
}

export default ProtocolManagement;

ProtocolManagement.propTypes = {
    username: PropTypes.string,
    match: PropTypes.shape({
        path: PropTypes.string,
    }),
};