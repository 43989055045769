import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'normalize.css';
import 'bootstrap/dist/css/bootstrap.css';
import './scss/main.scss';
import App from './components/App';
import Amplify from 'aws-amplify';

import registerServiceWorker from './registerServiceWorker';

Amplify.configure({
    Auth: {
      //DEV
        // REQUIRED - Amazon Cognito Identity Pool ID
        // // REQUIRED - Amazon Cognito Identity Pool ID
        // identityPoolId: 'ap-southeast-2:c68305bc-937b-439f-bd56-a204436e9a9c',
        // // REQUIRED - Amazon Cognito Region
        // region: 'ap-southeast-2',
        // // OPTIONAL - Amazon Cognito User Pool ID
        // userPoolId: 'ap-southeast-2_ZiQEqeQgB',
        // // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        // userPoolWebClientId: '76nbhnafvnuafiog9spa290agj',

        //ALL
        identityPoolId: 'ap-southeast-2:c68305bc-937b-439f-bd56-a204436e9a9c',
        // REQUIRED - Amazon Cognito Region
        region: 'ap-southeast-2',
        // OPTIONAL - Amazon Cognito User Pool ID
//PROD
        //  userPoolId: 'ap-southeast-2_VQEGb6kXa',//PROD
        userPoolId: 'ap-southeast-2_6FzpgkvnA', //UAT

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        //  userPoolWebClientId: '2rl5rad5c85jb6svdjctbcu94',//PROD
        userPoolWebClientId: '4o5bb1umg2ret0a8i6ac1hdpnp', //UAT


        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        // mandatorySignIn: false,
        // OPTIONAL - Configuration for cookie storage
        // cookieStorage: {
        // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        // domain: '.yourdomain.com',
        // OPTIONAL - Cookie path
        // path: '/',
        // OPTIONAL - Cookie expiration in days
        // expires: 365,
        // OPTIONAL - Cookie secure flag
        // secure: true
        // }
    },
});

ReactDOM.render((
    <BrowserRouter>
        <App />
    </BrowserRouter>
), document.getElementById('root'));
// registerServiceWorker();