import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import EpisodesForm from './EpisodesForm';
import PanelsContainer from './Panels/PanelsContainer';
import Preloader from './../../../common/Preloader';

import axios from 'axios';
import { Auth } from 'aws-amplify';

import _ from 'lodash';

import { isInt } from './../../../../utils/utils';
import { GET_EPISODE_BY_COHORTID_API } from './../../../../utils/constants';
import { UPSERT_EPISODE_BY_COHORTID_API } from './../../../../utils/constants';
import SVG from 'react-inlinesvg';
import chevronLeft from './../../../../assets/images/chevron-left.svg';

import './../../../../scss/episodes.scss';
let previousStateEpisodes = [];

class EpisodesContainer extends Component {

    state = {
        isLoading: false,
        isSubmitting: false,
        isSubmittingNext: false,
        isSubmittingClose: false,
        episodes: [
            {
                values: {
                    Protocol_cohort_id: this.props.activeProtocolCohort.Protocol_cohort_ID,
                    Cohort_days_id: null,
                    Day_period_id: null, // is the unique episode ID
                    Day_value: '',
                    Period_value: '',
                    Hour_value: '', // changed from null
                    Minute_value: '', // changed from null
                    Urgent_flag: false,
                    Panels: 0,
                    Sequence: 0, // Ordering the Episodes
                    hasPeriodDetails: false, // additional state to toggle the period details
                },
                errors: {
                    Day_value: false,
                    Period_value: false,
                    Hour_value: false,
                    Minute_value: false,
                }

            }
        ],
        serverError: '',
        openPanelsInEpisode: false,
    }

    componentDidMount() {
        const { Protocol_cohort_ID, Episodes } = this.props.activeProtocolCohort;
        // this.checkIfCohortHasProtocolCohortId();
        if (Episodes !== 0) {
            //console.log('There are episodes in this cohort, fetching episodes...');
            this.getEpisodebyCohortId_API(Protocol_cohort_ID);
        } else {
            //console.log('There are no episodes in this cohort, reset to a blank slate instead...');
            this.blankSlate();
            // Reset previousStateEpisodes
            previousStateEpisodes = [];
        }
        this.checkIfNextCohortHasProtocolCohortId();
    }

    /* Reset the episodeFromReviewScreen and episodeFromReviewScreenIndex states since it's already being used */
    resetUpdateEditEpisodeFromReviewScreen = () => {
        const { 
            episodeFromReviewScreen,
            updateEditEpisodeFromReviewScreen 
        } = this.props;

        if (episodeFromReviewScreen) {
            updateEditEpisodeFromReviewScreen('', '');
        }
    }

    checkIfCohortHasProtocolCohortId = () => {
        const { activeProtocolCohort } = this.props;
        const Protocol_cohort_ID = activeProtocolCohort.Protocol_cohort_ID;
        if (Protocol_cohort_ID) {
            //console.log(`Cohort has Protocol_cohort_ID of ${Protocol_cohort_ID}`);
            return true;
        } else {
            //console.log('Cohort does not have a Protocol_cohort_ID');
            return false;
        }
    }

    displayCohortNo = () => {
        const { activeProtocolCohort } = this.props;
        if (!activeProtocolCohort) {
            return;
        }

        if (activeProtocolCohort.Cohort_No) {
            return `Cohort ${activeProtocolCohort.Cohort_No}`;
        } else {
            return 'New cohort';
        }
    }

    addEpisode = () => {
        const episodes = this.state.episodes;
        const activeProtocolCohort = this.props.activeProtocolCohort;
        const Protocol_cohort_ID = activeProtocolCohort.Protocol_cohort_ID;
        // const item = episodeData;
        const item = { // this should be moved to the top
            values: {
                Protocol_cohort_id: Protocol_cohort_ID,
                Cohort_days_id: null,
                Day_period_id: null, // is the unique episode ID
                Day_value: '',
                Period_value: '',
                Hour_value: '',
                Minute_value: '',
                Urgent_flag: false,
                Panels: 0,
                Sequence: episodes.length, // New episodes are added to the end of the list, so increment the sequence value
                hasPeriodDetails: false,
            },
            errors: {
                Day_value: false,
                Period_value: false,
                Hour_value: false,
                Minute_value: false,
            }
        };
        this.setState({ 
            episodes: [...episodes, item],
        });
    }

    updateEpisode = (index, name, value) => {

        const episodes = this.state.episodes;

        let extraUpdates = {};
        // Reset the hour and minute values if the period value is changed
        if (name === 'Period_value') {
            extraUpdates = {
                Hour_value: '',
                Minute_value: '',
            };
            if (value === 'Dose +') {
                extraUpdates.hasPeriodDetails = false;
            }
        }

        const newEpisodes = episodes.map((episode, i) => {
            if (i === index) {
                episode = { ...episode };
                episode.values = { ...episode.values, ...extraUpdates, [name]: value }
            }
            return episode;
        });

        this.setState({
            episodes: newEpisodes,
        });
    }

    removeEpisode = (index) => {
        // Remove the episode from the episodes array
        const episodes = this.state.episodes;
        episodes.splice(index, 1);

        // Update sequence values for all episodes to be same as their updated index position
        const newEpisodes = episodes.map((episode, index) => {
            episode = { ...episode };
            episode.values = { 
                ...episode.values, 
                Sequence: index,
            }
            return episode;
        });

        // Update the state
        this.setState({
            episodes: newEpisodes,
        });
    }

    togglePeriodDetails = (index) => {
        //console.log(`toggling period details for episode ${index}`);

        const episodes = this.state.episodes;

        // Loop through each episode and find the one episode to update values
        // Return a new array
        const newEpisodes = episodes.map((episode, i) => {
            if (i === index) {
                const newHasPeriodDetails = !episode.values.hasPeriodDetails;
                episode = { ...episode };
                episode.values = {
                    ...episode.values,
                    hasPeriodDetails: newHasPeriodDetails,
                    Hour_value: '',
                    Minute_value: '',
                };
            }
            return episode;
        })

        this.setState({
            episodes: newEpisodes,
        })
    }

    reorderEpisode = (index, upOrDown) => {
        const episodes = this.state.episodes;

        // Calculate the new Sequence/index to be set for the current episode
        let newSequence = 0; // Default value
        if (upOrDown === 'up') {
            newSequence = index - 1;
        } else if (upOrDown === 'down') {
            newSequence = index + 1;
        }
        
        // Loop through each episode, and derive the sequence numbers directly through the index.
        // Swap the Sequence values based on indexes.
        const newEpisodes = episodes.map((episode, i) => {
            // Current episode -> change Sequence to the newSequence.
            if (i === index) {
                episode = { ...episode };
                episode.values = {
                    ...episode.values,
                    Sequence: newSequence,
                };
            }
            // Episode that will be swapped => it's current index will be the newSequence.
            // We want to swap its Sequence value to the index.
            if (i === newSequence) {
                episode = { ...episode };
                episode.values = {
                    ...episode.values,
                    Sequence: index,
                };
            }
            return episode;
        });

        // Swap the two episodes' positions in the array.
        [newEpisodes[index], newEpisodes[newSequence]] = [newEpisodes[newSequence], newEpisodes[index]];

        // Set the state with the newEpisodes array.
        this.setState({
            episodes: newEpisodes,
        });
    }


// Display the list of Episodes
    getEpisodebyCohortId_API = (cohortId) => {
        this.setState({ isLoading: true });

        // Reset previousStateEpisodes
        previousStateEpisodes = [];

        const url = GET_EPISODE_BY_COHORTID_API.GET;
        Auth.currentSession().then((response) => {
            const headers = { Authorization: response.idToken.jwtToken };
            let beforeCallTime = new Date().getTime();
            axios.get(`${url}?pcId=${cohortId}`, { headers })
                .then((response) => {
                    if (response.data) {

                        const _result = response.data[0].result;
                        let result = [];
                        _result.forEach(element=>{
                            if(element.Period_value != '-'){
                                result.push(element);
                            }
                        })
                        let afterCallTime = new Date().getTime();
                        let loadTime = afterCallTime - beforeCallTime;

                        let array = url.split('/');
                        let endpoint = array[array.length-1];

                        //console.log(endpoint + ' response time: ' + loadTime);
                        
                        //Google analytics tracking
                        ReactGA.timing({
                        category: endpoint,
                        variable: 'responseTime',
                        value: loadTime, // in milliseconds
                        label: null
                        });

                        //console.log('Episode List !!');
                        //console.log(result);

                        // Return the ProtocolCohortId and set it to the state if the cohort ID is null?
                        // this.handleUpdateProtocolCohortId(result.Protocol_cohort_id);

                        //this.props.updateScreeningId(response.data[0].result[0].ProtocolId);
                        //console.log(response.data[0].result[0].Protocol_cohort_ID);
                        if (result && result.length) {
                            this.mapResultToPreviousState(result);
                        } else {
                            this.blankSlate();
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    this.setState({ isLoading: false });
                });

        }).catch((error) => {
            console.error(error);
            this.setState({ isLoading: false });
        });

    }

    blankSlate = () => {
        const Protocol_cohort_ID = this.props.activeProtocolCohort.Protocol_cohort_ID;
        this.setState({
            episodes: [
                {
                    values: {
                        Protocol_cohort_id: Protocol_cohort_ID,
                        Cohort_days_id: null,
                        Day_period_id: null, // is the unique episode ID
                        Day_value: '',
                        Period_value: '',
                        Hour_value: '',
                        Minute_value: '',
                        Urgent_flag: false,
                        Panels: 0,
                        Sequence: 0,
                        hasPeriodDetails: false,
                    },
                    errors: {
                        Day_value: false,
                        Period_value: false,
                        Hour_value: false,
                        Minute_value: false,
                    }
                }
            ],
        });
    }


    /**
     * Map the result to a previousStateEpisodes array which we can use for display
     * and comparing values
     */
    mapResultToPreviousState = (result) => {
        const newResult = result.map((item, index) => {

            // Create a shell with empty errors
            const episode = {
                values: {},
                errors: {
                    Day_value: false,
                    Period_value: false,
                    Hour_value: false,
                    Minute_value: false,
                },
            };

            // Parse each property
            episode.values.Panels = item.Panels;
            episode.values.Cohort_days_id = item.Cohort_days_id;
            episode.values.Day_period_id = item.Day_period_id;
            episode.values.Day_value = item.Day_value;
            episode.values.Hour_value = item.Hour_value;
            episode.values.Minute_value = item.Minute_value;
            episode.values.Period_value = item.Period_value;
            episode.values.Protocol_cohort_id = item.Protocol_cohort_id;
            // Use the index as the Sequence value
            episode.values.Sequence = index;

            // Add additional state hasPeriodDetails for UI
            if ((item.Hour_value == null && item.Minute_value == null) || (item.Period_value === 'Dose +')) {
                episode.values.hasPeriodDetails = false;
            } else {
                episode.values.hasPeriodDetails = true;
            }

            // Convert null values to empty string
            if (item.Period_value == null) {
                episode.values.Period_value = '';
            }

            if (item.Hour_value == null) {
                episode.values.Hour_value = '';
            } else {
                if (item.Hour_value < 10 && item.Period_value !== 'Dose +') {
                    episode.values.Hour_value = `0${item.Hour_value}`;
                }
            }

            if (item.Minute_value == null) {
                episode.values.Minute_value = '';
            } else {
                if (item.Minute_value < 10 && item.Period_value !== 'Dose +') {
                    episode.values.Minute_value = `0${item.Minute_value}`;
                }
            }

            // Urgent flag - Convert 'Y'/null to true/false
            if (item.Urgent_flag === 'Y') {
                episode.values.Urgent_flag = true;
            } else {
                episode.values.Urgent_flag = false;
            }

            return episode;
        });

        previousStateEpisodes = newResult;

        this.loadEpisodeDetails();
    }

    /**
     * Loads previous episode details (if cohort is already created)
     */
    loadEpisodeDetails = () => {
        // Just override the state
        if (previousStateEpisodes.length) {
            const episodes = [];
            for (let item of previousStateEpisodes) {
                episodes.push(item);
            }

            // Just override the state
            this.setState({ episodes });
        }
    }

    upsertEpisodeDetailsAPI = (nextStep) => {

        //console.log(`The next step is: ${nextStep}`);
        let button = '';

        if (nextStep === 'next') {
            button = 'isSubmittingNext';
        } else if (nextStep === 'close' || nextStep === 'review') {
            button = 'isSubmittingClose';
        }


        // Set isSubmitting to true
        this.setState({
            isSubmitting: true,
            [button]: true,
        });

        const { added, deleted, updated } = this.returnUpdates();
        //console.log(deleted);
        //console.log(added);
        //console.log(updated);

        const episodes = [];

        for (let item of deleted) {
            episodes.push(
                {
                    //Protocol_cohort_id: item.Protocol_cohort_id,
                    Cohort_days_id: item.Cohort_days_id,
                    Day_period_id: item.Day_period_id,
                    Day_value: item.Day_value,
                    Period_value: item.Period_value,
                    Hour_value: item.Hour_value,
                    Minute_value: item.Minute_value,
                    Urgent_flag:item.Urgent_flag,
                    Panels:item.Panels,
                    Sequence: item.Sequence,
                    Status: 'R',
                }
            )
        }

        for (let item of added) {
            episodes.push(
                {
                    //Protocol_cohort_id: item.Protocol_cohort_id,
                    Cohort_days_id: item.Cohort_days_id,
                    Day_period_id: item.Day_period_id,
                    Day_value: item.Day_value,
                    Period_value: item.Period_value,
                    Hour_value: item.Hour_value,
                    Minute_value: item.Minute_value,
                    Urgent_flag:item.Urgent_flag,
                    Panels:item.Panels,
                    Sequence: item.Sequence,
                    Status: 'N',
                }
            )
        }

         for (let item of updated) {
            episodes.push(
                {
                    //Protocol_cohort_id: item.Protocol_cohort_id,
                    Cohort_days_id: item.Cohort_days_id,
                    Day_period_id: item.Day_period_id,
                    Day_value: item.Day_value,
                    Period_value: item.Period_value,
                    Hour_value: item.Hour_value,
                    Minute_value: item.Minute_value,
                    Urgent_flag:item.Urgent_flag,
                    Panels:item.Panels,
                    Sequence: item.Sequence,
                    Status: 'U',
                }
            )
        }


        /* Uncomment to use API */
        const url = UPSERT_EPISODE_BY_COHORTID_API.POST;

        // Get the current session
         Auth.currentSession().then((response) => {
             // Grab the jwtToken to use as Authorization header
             const headers = { Authorization: response.idToken.jwtToken };

        //     console.log(headers);
        //     // add Protocol list
            let data = JSON.stringify({
                 body: {
                     Protocol_Id: this.props.protocolId,
                     user: this.props.username,
                     Protocol_cohort_id: this.props.activeProtocolCohort.Protocol_cohort_ID,
                     episodes:episodes

                 }
             })
             //console.log('Data!!!');
             //console.log(data);
            axios.post(url, data, { headers })
                .then((response) => {
                    //console.log(response);

                    // No longer submitting
                    this.setState({
                        serverError: '',
                        isSubmitting: false,
                        [button]: false,
                    });

                    // Status code 400 is a server error.
                    if (response.data.statusCode && response.data.statusCode === 400) {
                        this.setState({
                            serverError: 'There was an error submitting the form. Please try again.',
                        });
                        return;
                    }

                    // When success:
                    const result = response.data.body[0].result[0];

                    // Update the Cohort's ID and new Episode length
                    if (result.Protocol_Cohort_id) {
                        this.handleUpdateProtocolCohortId(result.Protocol_Cohort_id);
                        this.handleUpdateProtocolCohortEpisodes();
                    }

                    this.resetUpdateEditEpisodeFromReviewScreen();

                    if (nextStep === 'next') {
                        this.handleNextCohort();
                    } else if (nextStep === 'close') {
                        this.handleCloseEpisodes();
                    } else if (nextStep === 'review') {
                        this.props.updateCurrentStep(4);
                        this.props.updateIsEditFromReviewScreen(false);
                    }

                })
                .catch((error) => {
                    //console.log(error);
                    this.setState({
                        serverError: 'There was an error submitting the form. Please try again.',
                        isSubmitting: false,
                        [button]: false,
                    });
                });

        });
    }

    validateForm = () => {
        //console.log('Validating form...');
        let hasErrors = false;
        let errorDayValue = false;
        let errorPeriodValue = false;
        let errorHourValue = false;
        let errorHourValueDosePlus = false;
        let errorMinuteValue = false;

        const episodes = _.cloneDeep(this.state.episodes);
        const newEpisodes = episodes.map((episode) => {

            const newErrors = {};
            const requireValidPeriodDetails = episode.values.Period_value === 'Dose +' || episode.values.hasPeriodDetails;

            // Validate each field inside
            // Day_value
            if (!this.validateDayValue(episode.values.Day_value)) {
                errorDayValue = true;
                hasErrors = true;
                newErrors.Day_value = true;
            } else {
                newErrors.Day_value = false;
            }

            // Mandatory field
            // Period_value
            if (!this.errorPeriodValue(episode.values.Period_value)) {
                errorPeriodValue = true;
                hasErrors = true;
                newErrors.Period_value = true;
            } else {
                newErrors.Period_value = false;
            }

            // Hour_value and Minute_value are mandatory for Dose +
            // For others, only if hasPeriodDetails is true, check if it's filled in and valid.
            // Otherwise it's not mandatory
            // Hour_value
            if (episode.values.hasPeriodDetails) {
                if (!this.validateHourValue(episode.values.Hour_value)) {
                    errorHourValue = true;
                    hasErrors = true;
                    newErrors.Hour_value = true;
                } else {
                    newErrors.Hour_value = false;
                }
            }

            if (episode.values.Period_value === 'Dose +') {
                if (!this.validateHourValueDosePlus(episode.values.Hour_value)) {
                    errorHourValueDosePlus = true;
                    hasErrors = true;
                    newErrors.Hour_value = true;
                } else {
                    newErrors.Hour_value = false;
                }
            }


            // Minute_value
            if (requireValidPeriodDetails && !this.validateMinuteValue(episode.values.Minute_value)) {
                errorMinuteValue = true;
                hasErrors = true;
                newErrors.Minute_value = true;
            } else {
                newErrors.Minute_value = false;
            }

            episode = { ...episode };
            episode.errors = { ...episode.errors, ...newErrors };

            return episode;

        });

        return {
            newEpisodes,
            hasErrors,
            errorDayValue,
            errorPeriodValue,
            errorHourValue,
            errorHourValueDosePlus,
            errorMinuteValue,
        };
    }

    validateDayValue = (Day_value) => {
        // if (Day_value === '' || !isInt(Day_value) || Day_value < -999 || Day_value > 999) {
        //     return false;
        // }
        if (Day_value === '') {
            return false;
        }
        return true;
    }

    errorPeriodValue = (Period_value) => {
        if (!Period_value) {
            return false;
        }
        return true;
    }

    validateHourValue = (Hour_value) => {
        if (Hour_value === '' || !isInt(Hour_value) || Hour_value < 0 || Hour_value > 23) {
            return false;
        }
        if (typeof Hour_value === 'string' && Hour_value.includes('-')) {
            return false;
        }
        return true;
    }

    validateHourValueDosePlus = (Hour_value) => {
        if (Hour_value === '' || !isInt(Hour_value) || Hour_value < 0 || Hour_value > 999) {
            return false;
        }
        if (typeof Hour_value === 'string' && Hour_value.includes('-')) {
            return false;
        }
        return true;
    }

    validateMinuteValue = (Minute_value) => {
        if (Minute_value === '' || !isInt(Minute_value) || Minute_value < 0 || Minute_value > 59) {
            return false;
        }
        if (typeof Minute_value === 'string' && Minute_value.includes('-')) {
            return false;
        }
        return true;
    }

    handleSubmit = (nextStep) => {

        // Validate the form
        const validation = this.validateForm();
        const {
            newEpisodes,
            hasErrors,
            errorDayValue,
            errorPeriodValue,
            errorHourValue,
            errorHourValueDosePlus,
            errorMinuteValue,
        } = validation;

        this.setState({
            episodes: newEpisodes,
            hasErrors,
            errorDayValue,
            errorPeriodValue,
            errorHourValue,
            errorHourValueDosePlus,
            errorMinuteValue,
        });

        if (hasErrors) {
            //console.log('Dont submit, has errors');
            // If there's no errors, submit the form
        } else {
            //console.log('Submitting');
            this.upsertEpisodeDetailsAPI(nextStep);
        }
    }

    returnUpdates = () => {
        //console.log('returning updates');
        // Get copies of current state and previous state
        const episodes = this.state.episodes.map(episode => ({ ...episode.values }));
        const prevStateCopy = previousStateEpisodes.map(episode => ({ ...episode.values }));

        // Parse it so that it's ready for comparison and API sending
        const episodesParsed = episodes.map(episode => this.parseEpisodeForAPI(episode));
        const previousStateParsed = prevStateCopy.map(episode => this.parseEpisodeForAPI(episode));

        // Added episodes have no Day_period_id assigned to them
        const added = episodesParsed.filter(episode => episode.Day_period_id === null);
        // console.log('Added (you can tell that theres no Day_period_id assigned to it:');
        // console.log(added);

        // Use existing to distinguish between deleted, updated and noChange.
        const existing = episodesParsed.filter(episode => episode.Day_period_id !== null);

        // No change (do not send this data)
        const noChange = _.intersectionWith(previousStateParsed, existing, _.isEqual);
        // console.log('No change (do not send this data):');
        // console.log(noChange);

        // Deleted will have no more Day_period_id
        const deleted = _.differenceBy(previousStateParsed, existing, 'Day_period_id');
        // console.log('Deleted:');
        // console.log(deleted);

        // The rest is an update
        const mergeDeletedNoChange = [...noChange, ...deleted];

        const updated = _.differenceWith(existing, mergeDeletedNoChange, _.isEqual);
        // console.log('Updated: ');
        // console.log(updated);

        return { added, deleted, updated };
    }

    parseEpisodeForAPI = (episode) => {

        // Convert Day_value, Hour_value, Minute_value to numbers
        // episode.Day_value = parseInt(episode.Day_value);

        // Hour_value and Minute_value are null if empty
        if (episode.Hour_value !== null && episode.Hour_value !== '') {
            episode.Hour_value = parseInt(episode.Hour_value);
        } else {
            episode.Hour_value = null;
        }

        if (episode.Minute_value !== null && episode.Minute_value !== '') {
            episode.Minute_value = parseInt(episode.Minute_value);
        } else {
            episode.Minute_value = null;
        }

        // Change Urgent to 'Y' and null
        if (episode.Urgent_flag) {
            episode.Urgent_flag = 'Y';
        } else {
            episode.Urgent_flag = null;
        }

        return episode;
    }

    handleCloseEpisodes = () => {
        const { activeProtocolCohort } = this.props;
        this.props.handleCloseEpisodes(activeProtocolCohort);
    }

    handleNextCohort = () => {
        const nextCohort = this.props.goToNextCohortInEpisodes();
        //console.log(`update the episode component to fetch the next protocol: ${nextCohort.Protocol_cohort_ID}`);
        // Fetch the list of episodes for the next Cohort
        const { Protocol_cohort_ID, Episodes } = nextCohort;
        // this.checkIfCohortHasProtocolCohortId();
        if (Episodes !== 0) {
            //console.log('There are episodes in this cohort, fetching episodes...');
            this.getEpisodebyCohortId_API(Protocol_cohort_ID);
        } else {
            //console.log('There are no episodes in this cohort, reset to a blank slate instead...');
            this.blankSlate();
            // Reset previousStateEpisodes
            previousStateEpisodes = [];
        }
    }

    /**
     * Update the Protocol Cohort's ID for the open cohort.
     * Open cohort's index is in activeProtocolCohortIndex.
     */
    handleUpdateProtocolCohortId = (id) => {
        const {
            activeProtocolCohortIndex,
        } = this.props;

        id = parseInt(id);

        this.props.updateCohort(activeProtocolCohortIndex, 'Protocol_cohort_ID', id);
    }

    /**
     * Update the Protocol Cohort's new Episodes Length for the open cohort.
     * Open cohort's index is in activeProtocolCohortIndex.
     */
    handleUpdateProtocolCohortEpisodes = () => {
        const {
            activeProtocolCohortIndex,
        } = this.props;

        const episodesLength = this.state.episodes.length;

        this.props.updateCohort(activeProtocolCohortIndex, 'Episodes', episodesLength);
    }

    handleOpenPanelsInEpisode = (episode, index) => {
        const { Day_period_id } = episode;
        //console.log(`Day_period_id: ${Day_period_id}`);
        // If the Day_period_id is null, it's new.
        // Call upsertEpisode before opening Episodes.
        if (Day_period_id !== null) {
            this.openPanelsInEpisode(episode, index);
        } else {
            this.handleSubmitNewEpisode(episode, index);
        }
    }

    handleSubmitNewEpisode = (episode, index) => {
        const validation = this.validateForm();
        const {
            newEpisodes,
            hasErrors,
            errorDayValue,
            errorPeriodValue,
            errorHourValue,
            errorHourValueDosePlus,
            errorMinuteValue,
        } = validation;

        this.setState({
            episodes: newEpisodes,
            hasErrors,
            errorDayValue,
            errorPeriodValue,
            errorHourValue,
            errorHourValueDosePlus,
            errorMinuteValue,
        });

        if (hasErrors) {
            console.log('Dont submit, has errors');
            // If there's no errors, submit the form
        } else {
            this.upsertNewEpisodeAPI(episode, index);
        }
    }

    upsertNewEpisodeAPI = (episode, index) => {
        const url = UPSERT_EPISODE_BY_COHORTID_API.POST;

        const newEpisode = _.cloneDeep(episode);
        const episodeParsed = this.parseEpisodeForAPI(newEpisode);

        const episodes = [
            {
                Cohort_days_id: episodeParsed.Cohort_days_id,
                Day_period_id: episodeParsed.Day_period_id,
                Day_value: episodeParsed.Day_value,
                Period_value: episodeParsed.Period_value,
                Hour_value: episodeParsed.Hour_value,
                Minute_value: episodeParsed.Minute_value,
                Urgent_flag: episodeParsed.Urgent_flag,
                Sequence: episodeParsed.Sequence,
                Panels: episodeParsed.Panels,
                Status: 'N',
            }
        ];
        this.setState({ isLoading: true, });

         Auth.currentSession().then((response) => {
             const headers = { Authorization: response.idToken.jwtToken };

            let data = JSON.stringify({
                 body: {
                     Protocol_Id: this.props.protocolId,
                     user: this.props.username,
                     Protocol_cohort_id: this.props.activeProtocolCohort.Protocol_cohort_ID,
                     episodes:episodes
                 }
             })
             //console.log('Data!!!');
             //console.log(data);
            axios.post(url, data, { headers })
                .then((response) => {
                    // No longer submitting
                    this.setState({
                        serverError: '',
                        isLoading: false,
                    });

                    // Status code 400 is a server error.
                    if (response.data.statusCode && response.data.statusCode === 400) {
                        this.setState({
                            serverError: 'There was an error submitting the form. Please try again.',
                        });
                        return;
                    }

                    // When success:
                    // Get and save the new Day_period_id to the state
                    const result = response.data.body[0].result[0];
                    const Day_period_id = result.Day_period_id;
                    //console.log(`New Day_period_id: ${Day_period_id}`);
                    if (Day_period_id) {
                        this.handleOpenPanelsForNewEpisode(episode, index, Day_period_id);
                    }

                })
                .catch((error) => {
                    //console.log(error);
                    this.setState({
                        serverError: 'There was an error submitting the form. Please try again.',
                        isLoading: false,
                    });
                });

        });

    }

    handleOpenPanelsForNewEpisode = (episodeToOpen, index, Day_period_id) => {
        const episodes = this.state.episodes;

        // Loop through each episode and find the one episode to update
        // Return a new array
        const newEpisodes = episodes.map((episode, i) => {
            if (i === index) {
                episode = { ...episode };
                episode.values = { ...episode.values, Day_period_id: Day_period_id }
            }
            return episode;
        });

        // Finally, open Panels
        this.setState({
            episodes: newEpisodes,
        }, () => {
            const newEpisode = this.state.episodes[index];
            this.openPanelsInEpisode(newEpisode.values, index);
            previousStateEpisodes.push(newEpisode);
            //console.log('Added the new episode to initial state:');
            //console.log(previousStateEpisodes);
        }
        );
    }

    openPanelsInEpisode = (episode, index) => {
        this.setState({
            openPanelsInEpisode: true,
            activeEpisode: episode,
            activeEpisodeIndex: index,
        });
    }

    closePanelsInEpisode = () => {
        this.setState({
            openPanelsInEpisode: false,
            activeEpisode: '',
            activeEpisodeIndex: '',
        });
    }

    checkIfNextCohortHasProtocolCohortId = () => {
        const { 
            activeProtocolCohortIndex, 
            cohorts 
        } = this.props;
        const cohortsLength = cohorts.length;
        // If it's the last cohort, stop!
        if (activeProtocolCohortIndex >= (cohortsLength - 1)) {
            return false;
        }

        // Check if the next cohort is new or not.
        const nextCohort = cohorts[activeProtocolCohortIndex + 1];
        if (nextCohort.values && nextCohort.values.Protocol_cohort_ID !== null) {
            return true;
        } else {
            return false;
        }
    }


    render() {
        const {
            activeProtocolCohortIndex,
            cohorts,
            episodeFromReviewScreen,
        } = this.props;

        const {
            isLoading,
            isSubmitting,
            isSubmittingNext,
            isSubmittingClose,
            openPanelsInEpisode,
        } = this.state;

        const cohortsLength = cohorts.length;
        const nextCohortHasProtocolCohortId = this.checkIfNextCohortHasProtocolCohortId();

        return (
            <div className="episodes">
                {
                    episodeFromReviewScreen ? null :
                    <div className="episodes__back page__back">
                        <span
                            onClick={() => this.handleSubmit('close')}
                            className={`episodes__back__link page__back__link ${isSubmitting ? 'disabled' : ''}`}
                        >
                            <SVG className="icon icon--back" src={chevronLeft} />
                            <span className="link">Cohorts</span>
                        </span>
                    </div>
                }
                <div className="episodes__heading">
                    <h3>Episodes</h3>
                </div>
                <div className="episodes__summary">
                    <h4>{this.displayCohortNo()}</h4>
                </div>
                <EpisodesForm
                    Screening_skipped = {this.props.Screening_skipped}
                    episodes={this.state.episodes}
                    togglePeriodDetails={this.togglePeriodDetails}
                    addEpisode={this.addEpisode}
                    updateEpisode={this.updateEpisode}
                    removeEpisode={this.removeEpisode}
                    reorderEpisode={this.reorderEpisode}
                    hasErrors={this.state.hasErrors}
                    errorDayValue={this.state.errorDayValue}
                    errorPeriodValue={this.state.errorPeriodValue}
                    errorHourValue={this.state.errorHourValue}
                    errorHourValueDosePlus={this.state.errorHourValueDosePlus}
                    errorMinuteValue={this.state.errorMinuteValue}
                    serverError={this.state.serverError}
                    handleOpenPanelsInEpisode={this.handleOpenPanelsInEpisode}
                />

                <div className="form-btn-container btn-container">
                    <div className="row">
                        <div className="col text-right">
                            {/* Only display the "Next cohort" button if it's not the last cohort in the list */}
                            {
                                ((activeProtocolCohortIndex < (cohortsLength - 1)) && !episodeFromReviewScreen) ?
                                    <button
                                        className="btn btn-secondary btn--back"
                                        onClick={() => this.handleSubmit('next')}
                                        disabled={isSubmitting || !nextCohortHasProtocolCohortId}
                                    >
                                        {isSubmittingNext ? <span className="spinner__wrapper"><span className="loading-spinner"></span></span> : 'Next cohort'}
                                    </button> : null
                            }
                            {
                                episodeFromReviewScreen ? 
                                <button
                                    className="btn btn-primary btn--next"
                                    onClick={() => this.handleSubmit('review')}
                                    disabled={isSubmitting}
                                >
                                    {isSubmittingClose ? <span className="spinner__wrapper"><span className="loading-spinner"></span></span> : 'Confirm changes'}
                                </button>
                                :
                                <button
                                    className="btn btn-primary btn--next"
                                    onClick={() => this.handleSubmit('close')}
                                    disabled={isSubmitting}
                                >
                                    {isSubmittingClose ? <span className="spinner__wrapper"><span className="loading-spinner"></span></span> : 'Done'}
                                </button>
                            }
                        </div>
                    </div>
                </div>
                {
                    (isLoading || isSubmitting || isSubmittingNext || isSubmittingClose) ?
                        <Preloader />
                        : null
                }

                {
                    openPanelsInEpisode ?
                    <PanelsContainer
                        closePanelsInEpisode={this.closePanelsInEpisode}
                        activeEpisode={this.state.activeEpisode}
                        activeEpisodeIndex={this.state.activeEpisodeIndex}
                        activeProtocolCohort={this.props.activeProtocolCohort}
                        username={this.props.username}
                        updateProtocolId={this.props.updateProtocolId}
                        protocolId={this.props.protocolId}
                        updateEpisode={this.updateEpisode}
                    />
                    : null
                }

            </div>
        )
    }
}

export default EpisodesContainer;

EpisodesContainer.propTypes = {
    activeProtocolCohort: PropTypes.oneOfType([
        PropTypes.shape({
            Protocol_cohort_ID: PropTypes.number.isRequired,
        }),
        PropTypes.string,
    ]),
    protocolId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    username: PropTypes.string.isRequired,
    handleCloseEpisodes: PropTypes.func.isRequired,
    goToNextCohortInEpisodes: PropTypes.func.isRequired,
    activeProtocolCohortIndex: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    updateCohort: PropTypes.func.isRequired,
    cohorts: PropTypes.array.isRequired,
    updateProtocolId: PropTypes.func.isRequired,
    episodeFromReviewScreen: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    updateEditEpisodeFromReviewScreen: PropTypes.func.isRequired,
    updateCurrentStep: PropTypes.func.isRequired,
    updateIsEditFromReviewScreen: PropTypes.func.isRequired,
    Screening_skipped: PropTypes.bool,
};