import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

import { sortByObjectProperty } from './../../../../utils/utils';
import { createRef } from 'react';

class PanelItem extends Component {

   
    render() {
        const panel = this.props.panel;
        const index = this.props.index;
        const selectedPanels = this.props.selectedPanels;
        const fetchedPanels = this.props.fetchedPanels;
        

        return (
            <div className="panel-item flex-table-form-item row list--item" key={index}>
                <div className="item item__panel">
                    <div className={`form-group ${panel.error ? 'has-error' : ''}`}>
                        <select

                            name={panel.Panel_code}
                            value={panel.Panel_ID}
                            className="form-control"
                            onChange={(e) => {
                                this.props.updatePanel(e, index)
                            }}

                        >
                            <option value="">Select a panel</option>
                            {/* List of panels should be fetched from somewhere */}
                            {
                                fetchedPanels && fetchedPanels
                                    .sort(sortByObjectProperty('Panel_code'))
                                    .map(fetchedPanel => {
                                        return (
                                            <option value={fetchedPanel.Panel_ID} key={fetchedPanel.Panel_ID}>{fetchedPanel.Panel_code}</option>
                                        )
                                    })
                            }
                        </select>

                        {panel.error && <small id={`panel${index}Help`} className="form-text">{panel.error}</small>}
                    </div>
                </div>
                <div className="item item__actions">
                    {
                        selectedPanels.length && selectedPanels.length > 1 &&
                        <a onClick={() => this.props.removePanel(index)} className="link">Remove</a>
                    }
                </div>


            </div>
        )
    }
}

export default PanelItem;

PanelItem.propTypes = {
    panel: PropTypes.shape({
        Panel_code: PropTypes.string.isRequired,
        Panel_ID: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
    }),
    index: PropTypes.number.isRequired,
    selectedPanels: PropTypes.array,
    fetchedPanels: PropTypes.array.isRequired,
    updatePanel: PropTypes.func.isRequired,
    removePanel: PropTypes.func.isRequired,
};