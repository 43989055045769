import React, { Component } from 'react'
import '../../scss/settings.scss'
import SaveIcon from '@material-ui/icons/CheckOutlined'
import ClearIcon from '@material-ui/icons/ClearOutlined'
class LocationListItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            item: this.props.item,
            errors: {},
        }
    }
    handleEdit = () => {
        this.props.editLocation(this.props.index)
    }

    handleDone = () => {

        let temp = { ...this.state.item }
        temp.nameError = null
        temp.docCodeError = null
        temp.accCodeError = null
        let hasError = false;
        if (this.state.item.name == '') {
            temp.nameError = 'Please add location name'
            hasError = true
        }
        if (this.state.item.docCode == '') {
            temp.docCodeError = 'Please add doctor code'
            hasError = true
        }
        if (this.state.item.accCode == '') {
            temp.accCodeError = 'Please add acc cdoe'
            hasError = true
        }
        this.setState({ item: temp })

        if (hasError === false) {
            this.props.saveLocation(this.props.index, temp)
        }

    }
    onSave = (e) => {
        let temp = { ...this.state.item }
        temp[e.target.name] = e.target.value;
        this.setState({ item: temp })
        console.log(temp)
    }
    onCancel = () => {
        if (this.props.item.name == '' && this.props.item.docCode == '' && this.props.item.accCode == '') {
            this.props.onDelete(this.props.index)
        } else {
            console.log('cancel called');
            this.setState({ item: this.props.item })
            this.props.onCancel(this.props.index)
        }
    }
    renderEditbleRow() {
        const hasErrors = this.state.item.nameError || this.state.item.docCodeError || this.state.item.accCodeError;
        return (
            <div
                className={`row flex-table-form-item row list--item ${hasErrors ? 'packing-details-row-error' : ''}`}
            >
                <div className="col-lg-3 location_input_container margin-right-10">
                    <input
                        className={`form-control cohort-no ${this.state.item.nameError ? 'has-error' : ''}`}
                        type="text"
                        name="name"
                        defaultValue={this.state.item.name}
                        disbaled={this.state.edit ? 'disabled' : ''}
                        onChange={this.onSave}
                    />
                </div>
                <div className="col-lg-3 location_input_container margin-right-10">
                    <input
                        className={`form-control cohort-no ${this.state.item.docCodeError ? 'has-error' : ''}`}
                        type="text"
                        name="docCode"
                        defaultValue={this.state.item.docCode}
                        disbaled={this.state.edit ? 'disabled' : ''}
                        onChange={this.onSave}

                    />
                </div>
                <div className="col-lg-3 location_input_container margin-right-10">
                    <input
                        className={`form-control cohort-no ${this.state.item.accCodeError ? 'has-error' : ''}`}
                        type="text"
                        name="accCode"
                        defaultValue={this.state.item.accCode}
                        disbaled={this.state.edit ? 'disabled' : ''}
                        onChange={this.onSave}

                    />
                </div>
                <div className="col-lg-2 location-item-edit-icons">
                    {/* <button onClick={this.onCancel}><ClearIcon fontSize="large" style={{ color: 'red' }}/></button>
                    <button onClick={this.handleDone}><SaveIcon fontSize="large" style={{ color: '#753bbd' }}/></button> */}
                    <span
                        style={{
                            marginRight: 10,
                            color: 'grey',
                            cursor: 'pointer'
                        }}
                        onClick={this.onCancel}><u>Cancel</u></span>
                    <span
                        style={{
                            marginRight: 10,
                            color: '#753bbd',
                            cursor: 'pointer'
                        }}
                        onClick={this.handleDone}><u>Save</u></span>
                </div>
            </div>
        );
    }

    renderNormalRow() {
        return (
            <div className="row border-bottom-primary-color">
                <div className="col-lg-3 location_input_container margin-right-10">
                    <label>{this.state.item.name}</label>
                </div>
                <div className="col-lg-3 location_input_container margin-right-10">
                    <label>{this.state.item.docCode}</label>
                </div>
                <div className="col-lg-3 location_input_container margin-right-10">
                    <label>{this.state.item.accCode}</label>
                </div>

                <div className="col-lg-2 location_input_container "
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingRight: 0
                    }}
                >
                    <div>
                        <div className="item item__actions">
                            <span
                                style={{
                                    marginRight: 10,
                                    color: '#753bbd',
                                    cursor: 'pointer'
                                }}
                                onClick={this.handleEdit}><u>Edit</u></span>
                            {
                                this.props.item.usage_count > 0 ? < span

                                    title="In use, can not be deleted"
                                    style={{
                                        marginRight: 10,
                                        color: 'grey',
                                    }}><u>Delete</u></span> : <span
                                        style={{
                                            marginRight: 10,
                                            color: 'red',
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => { this.props.onDelete(this.props.index) }}><u>Delete</u></span>
                            }
                        </div>
                    </div>
                </div>
            </div >
        );
    }
    render() {
        // console.log('render called')
        return this.props.item.edit ? this.renderEditbleRow() : this.renderNormalRow()
    }
}
export default LocationListItem;
