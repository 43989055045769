import React, { Component } from 'react';
import PropTypes from 'prop-types';

import KitLabel from './KitLabel';
import ExpiryDatePicker from './ExpiryDatePicker';
import NumberIncrementer from './../../common/NumberIncrementer';

import moment from 'moment';
import SVG from 'react-inlinesvg';
import add from '../../../assets/images/add.svg';
import { formatEpisodeForKit } from './../../../utils/episodes';
import KitPackingDetailsItem from './KitPackingDetailsItem';
import { dateFormatAPI, dateFormat } from '../../../utils/constants';
class KitDetailsForm extends Component {

    displayEpisodeDetails = () => {
        const { kit } = this.props;
        if (kit.day_value.toLowerCase() == 'screening' && kit.period_value == null && kit.hour_value == null && kit.minute_value == null) {
            return 'Screening';
        } else {
            return formatEpisodeForKit(kit);
        }
    }
    
    displayPackingList = () => {
        const { kit } = this.props;
        if (!kit.packaging_list) { // error check
            return;
        }

        const parsed = JSON.parse(kit.packaging_list);

        if (!Array.isArray(parsed)) { // error check
            return;
        }

        const listItems = parsed.map((item, index) => {
            const formattedItem = item.replace('*', ' x ');
            return (
                <li key={index}>{formattedItem}</li>
            )
        });
        
        const numOfBarcodeSheets = this.calculateNumOfBarcodeSheets(parsed);
        
        return (
            <ul>
                {listItems}
                <li>{numOfBarcodeSheets} x Barcode sheet</li>
            </ul>
        )
    }
    
    // There are separate barcode sheets for blood and urine tubes - Pot is the only urine tube.
    // Calculate the number of barcode sheets through the packaging list.
    calculateNumOfBarcodeSheets = (packaging_list) => {
        const pot = '1*Pot';
        const bloodTubes = packaging_list.find(item => item !== pot) ? 1 : 0;
        const urineTubes = packaging_list.find(item => item === pot) ? 1 : 0;
        const numOfBarcodeSheets = bloodTubes + urineTubes;
        return numOfBarcodeSheets;
    }

    handleInputChange = (e) => {
        const target = e.target;
        const value = parseInt(target.value);
        const name = target.name;

        this.props.updateKit(name, value);
    }
    renderPackingDetailsHeader = ()=>{
        return (
            <div className="row">
                <div className="col-lg-2 packing-details-item">
                    Kits Packed
                </div>
                <div className="col-lg-2 packing-details-item">
                    Packed By
                </div>
                <div className="col-lg-3 packing-details-item">
                    Packing Date
                </div>
                <div className="col-lg-2 packing-details-item">
                    QC By
                </div>
                <div className="col-lg-3 packing-details-item">
                    QC Date
                </div>
            </div>
        );
    }
    render() {
        const {
            kit,
            errors,
            handlePrint,
        } = this.props;
        let kitsPacked = 0;
        this.props.kitPackingDetails.forEach(element => {
            kitsPacked += Number(element.kitsPacked.value)
        });
        // console.log(kit)
        if(kit.day_value == null){
            return <div></div>;
        }
        let dateLabel = kit.day_value.toLowerCase() == 'screening' ? 'Commencement Date' : 'First Dosing Date';
        let dateValue = kit.day_value.toLowerCase() == 'screening' ? kit.commencement_date : kit.first_dosing_date;
        return (
            <div className="kit-details__form row">
                <div className="col-lg-4 col-md-6 kit__packing-list">
                    <div className="small-heading">
                    Packing list
                    </div>
                    <div className="content">
                        {this.displayPackingList()}
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 kit__packing-progress">
                    <div className="small-heading">
                    Packing progress
                    </div>
                    <div className="content">
                        <div className="kit-detail">
                            <div className="label"><strong>Protocol</strong></div>
                            <div className="value">{kit.protocol}</div>
                        </div>
                        <div className="kit-detail">
                            <div className="label"><strong>Cohort</strong></div>
                            <div className="value">{kit.cohort || '–'}</div>
                        </div>
                        <div className="row kit-detail">
                            <div className="col-lg-6">
                                <div className="label"><strong>Episode</strong></div>
                                <div className="value">{this.displayEpisodeDetails()}</div>
                            </div>
                            <div className="col-lg-6">
                                <div className="label"><strong>{dateLabel}</strong></div>
                                <div className="value">{moment(dateValue).format(dateFormat).replace('Invalid date', '-')}</div>
                            </div>
                        </div>
                        <div className="row kit-detail">
                            <div className="col-lg-6">
                                <div className="label"><strong>Required</strong></div>
                                <div className="value">{kit.required}</div>
                            </div>
                            <div className="col-lg-6">
                                <div className="label"><strong>Packing Required Date</strong></div>
                                <div className="value">{moment(dateValue).subtract(7,'d').format(dateFormat).replace('Invalid date', '-')}</div>
                            </div>
                        </div>
                        <div className="row kit-detail">
                            <div className="col-lg-6">
                                <div className="label"><strong>Number packed</strong></div>
                                <div className="value">
                                    {
                                        // <NumberIncrementer
                                        //     updateNumber={updateKitPacked}
                                        //     value={kit.packed}
                                        //     min="0"
                                        //     max={kit.required}
                                        //     onChange={this.handleInputChange}
                                        //     error={errors.packed}
                                        // />
                                        kitsPacked
                                    }
                                </div>
                            </div>
                            <div className="col-lg-1"></div>
                            <div className="col-lg-6">
                                <div className="label"><strong>Number used</strong></div>
                                <div className="value">{kit.number_used}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 kit__print-label">
                    <div className="small-heading">
                    Print label
                    </div>
                    <div className="content">

                        <div className="kit-detail">
                            <div className="label"><strong>Expiry date</strong></div>
                            <div className="value">
                                <ExpiryDatePicker
                                    date={kit.expiry_date == null ? '' : kit.expiry_date}
                                    error={errors.expiry_date}
                                    name="expiry_date"
                                    updateExpiryDate={this.props.updateExpiryDate}
                                />
                            </div>
                        </div>

                        <div className="kit-detail">
                            <div className="label"><strong>Label preview</strong></div>
                            <KitLabel
                                kit={kit}
                                errors={errors}
                            />
                            <p className="muted">Labels will be printed on A4 label sheets. each sheet will contain 16 labels</p>
                        </div>

                        <div className="text-right">
                            <button 
                                className="btn btn-primary btn--print-kit"
                                onClick={() => handlePrint()}
                            >
                                Print
                            </button>
                        </div>
                    </div>
                </div>
            
                <div className="col-lg-12 col-md-12">
                    <div className="small-heading">
                        Packing Details
                    </div>
                    <div className="content">
                        <div className="form-add-container">
                            {
                                this.props.kitPackingDetails.length > 0 && this.renderPackingDetailsHeader()
                            } 
                            {
                                this.props.kitPackingDetails.map((item, index) => {
                                    return (
                                        <KitPackingDetailsItem 
                                            key={index} 
                                            index={index} 
                                            item={item} 
                                            upsertPackingDetailItem={this.props.upsertPackingDetailItem}
                                            enableEditModeOfKitPackingDetails={this.props.enableEditModeOfKitPackingDetails}
                                        />
                                    )
                                })
                            }
                            <a onClick={() => {this.props.addNewPackingDetail()}} className="form-add link">
                                <SVG className="icon icon--add" src={add} />
                                <span>Add a new number of Kits packed</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default KitDetailsForm;

KitDetailsForm.propTypes = {
    kit: PropTypes.object.isRequired, // should be shaped
    updateKit: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired, // should be shaped
    packingDetailsErrors: PropTypes.object, // should be shaped
    handlePrint: PropTypes.func.isRequired,
    updateKitPacked: PropTypes.func.isRequired,
    updateExpiryDate: PropTypes.func.isRequired,
    addNewPackingDetail: PropTypes.func.isRequired,
    kitPackingDetails: PropTypes.array.isRequired,
    upsertPackingDetailItem: PropTypes.func.isRequired,
    enableEditModeOfKitPackingDetails: PropTypes.func.isRequired,
};