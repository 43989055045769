import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { generateLoginUrl } from './../../../utils/constants';

/**
 * Basic form that will be wrapped with Formik
 */
class LoginForm extends Component {

    render() {
        const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
        } = this.props;

        return (
            <div className="login">
                <div className="unauthenticated-layout__subheading">
                    <h3>Sign in</h3>
                </div>

                {/* Display error message for the whole form if login was invalid */}
                {errors.form &&
                    <p className="form-message__container has-error">
                        <small id="formHelp" className="form-text">{errors.form}</small>
                    </p>
                }
              
               

                <form onSubmit={handleSubmit}>
                    {/* TestAdmin/Accweb456! */}
                    <div className={`form-group ${errors.email && touched.email ? 'has-error' : ''}`}>
                        <label htmlFor="email">Email address</label>
                        <input
                            name="email"
                            id="email"
                            className="form-control"
                            autoFocus
                            value={values.email}
                            onChange={handleChange}
                            onBlur={this.handleBlur}
                        />
                        {errors.email && touched.email && <small id="emailHelp" className="form-text">{errors.email}</small>}
                    </div>
                   
                    <div className={`form-group ${errors.password && touched.password ? 'has-error' : ''}`}>
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            name="password"
                            id="password"
                            className="form-control"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {errors.password && touched.password && <small id="passwordHelp" className="form-text">{errors.password}</small>}
                    </div>
                    
                    <div className="form-group form-group--button">
                        <button type="submit" 
                              className="btn btn-primary btn-block"
                        >
                             
                            {isSubmitting ? <span className="spinner__wrapper"><span className="loading-spinner"></span></span> : 'Sign in'}
                        </button>
                    </div>
                    <div className="link--reset-password text-center">
                        <Link to={generateLoginUrl('reset-password')}>Forgot your password?</Link>
                    </div>
                    
                </form>
            </div>
        );
    }
}

export default LoginForm;

LoginForm.propTypes = {
    values: PropTypes.object,
    touched: PropTypes.object,
    errors: PropTypes.object,
    isSubmitting: PropTypes.bool,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    handleSubmit: PropTypes.func,
};
