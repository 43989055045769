/**
 * Helper functions
 */

 // https://stackoverflow.com/questions/196972/convert-string-to-title-case-with-javascript
export function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

// https://gist.github.com/thevangelist/8ff91bac947018c9f3bfaad6487fa149#gistcomment-2183914
export function toKebabCase(str) {
    return str.replace('-', '').replace(/([a-z])([A-Z])/g, '$1-$2').replace(/\s+/g, '-').toLowerCase();
}

// https://medium.com/front-end-hacking/getting-unique-values-in-javascript-arrays-17063080f836
export function filterByUnique(value, index, self) {
    return self.indexOf(value) === index;
}

export function getLoggedUser() {
    return 'sampleUser';
}

// https://stackoverflow.com/questions/1129216/sort-array-of-objects-by-string-property-value-in-javascript

export function sortByObjectProperty(property) {
	var sortOrder = 1; 
	if (property[0] === '-') {
		sortOrder = -1;
		property = property.substr(1);
	}
	return function (a, b) {
		var result = (a[property].toLowerCase() < b[property].toLowerCase()) ? -1 : (a[property].toLowerCase() > b[property].toLowerCase()) ? 1 : 0; return result * sortOrder;
	}
}
//https://stackoverflow.com/questions/14636536/how-to-check-if-a-variable-is-an-integer-in-javascript
export function isInt(value) {
	return !isNaN(value) && parseInt(Number(value)) == value && !isNaN(parseInt(value, 10));
}

// Using this one
// https://dzone.com/articles/use-regex-test-password
/* eslint-disable */
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%,><':;|_~`\^\\\/&\*\"\[\]\{\}\(\).?-])(?=.{8,})/;
/* eslint-enable */
export const passwordRegexErrorFlag = 'regex';

export function checkDuplicateProtocolNo(list, input) {
	let duplicate = false;
	for (let protocolNo of list) {
		if (protocolNo.toLowerCase() === input.toLowerCase()) {
			duplicate = true;
		}
	}
	return duplicate;
}

export function isAlphanumeric(string) {
	return /^[a-z0-9]+$/i.test(string);
}

export function isAlphabetic(string) {
	return /^[A-Z]+$/.test(string);
}

export function decimalPlaces(num) {  
	var match = (''+num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);  
	if (!match) 
	{ 
		return 0; 
	}  
	return Math.max(0,
	// Number of digits right of decimal point.
	(match[1] ? match[1].length : 0)// Adjust for scientific notation.       
	- (match[2] ? +match[2] : 0));
}

export function round(num)
{
	var result=Math.round(num*100)/100;
	return result;
}