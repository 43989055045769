import React, { Fragment, useEffect } from 'react';
import LocationListItem from './LocationListItem';
import SVG from 'react-inlinesvg';
import add from '../../assets/images/add.svg';
import '../../scss/settings.scss'
export default (props) => {



    


    const renderPackingDetailsHeader = () => {
        return (
            <div className="row ">
                <div className="col-lg-3 location-list-item-header margin-right-10">
                    <label><b>Location</b></label>
                </div>
                <div className="col-lg-3 location-list-item-header margin-right-10">
                    <label><b>Doctor Code</b></label>
                </div>
                <div className="col-lg-3 location-list-item-header margin-right-10">
                    <label><b>Acc Code</b></label>
                </div>
            </div>
        );
    }
    return (
        <div style={{ marginLeft: '25%', width: '70%', marginBottom: 30 }}>
            {
                props.locations.length > 0 && renderPackingDetailsHeader()
            }
            {
                props.locations.map((item, index) => {
                    return (
                        <LocationListItem
                            key={item.name + index}
                            index={index}
                            item={item}
                            editLocation={props.editLocation}
                            saveLocation={props.saveLocation}
                            onDelete={props.onDelete}
                            onCancel={props.onCancel}
                        />
                    )
                })
            }
            

        </div>
    )
}