import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import { generatePortalUrl } from './../../utils/constants';

import UpdateProtocolStatus from './UpdateProtocolStatus';

import SVG from 'react-inlinesvg';

import dots from './../../assets/images/dots.svg';
import chevronRight from './../../assets/images/chevron-right.svg';

import '../../../src/scss/action-dropdown.scss';

class ActionDropdown extends Component {

    state = {
        toggled: false,
        submenuToggled: false,
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleOutsideClick, false);
    }

    /**
     * Handle clicks outside the component.
     * Hide the dropdown when someone clicks outside of it.
     */
    handleOutsideClick = (e) => {
        // ignore clicks on the component itself
        if (!e.target.closest('.action-dropdown')) {
            this.hideDropdown();
            document.removeEventListener('click', this.handleOutsideClick, false);
        }
    }
    
    toggleDropdown = () => {
        const toggled = !this.state.toggled;
        this.setState({ toggled });
       
        setTimeout(() => {
            if (toggled) {
                document.addEventListener('click', this.handleOutsideClick, false);
            } else {
                document.removeEventListener('click', this.handleOutsideClick, false);
            }
        }, 100);
        
    }
    
    hideDropdown = () => {
        const toggled = false;
        const submenuToggled = false;
        this.setState({ toggled, submenuToggled });
    }
    
    showSubmenu = () => {
        const submenuToggled = true;
        this.setState({ submenuToggled });
    }

    hideSubmenu = () => {
        const submenuToggled = false;
        this.setState({ submenuToggled });
    }
    render() {
        const toggled = this.state.toggled;
        const submenuToggled = this.state.submenuToggled;
        const protocol = this.props.protocol;


        return (
            <div className="action-dropdown__container">
                <button className="btn-action" onClick={() => this.toggleDropdown()}>
                    <SVG className="icon icon--dots" src={dots} />
                </button>
                {/* { toggled ? this.renderDropdown() : null } */}
                <div className={`action-dropdown dropdown-menu ${toggled ? 'show' : ''}`}>
                    <Link to={generatePortalUrl(`protocols/edit/${protocol.Protocol_ID}`)} className="dropdown-item">Edit</Link>

                    <span className="dropdown-item"
                        onMouseEnter={() => this.showSubmenu()}
                        onMouseLeave={() => this.hideSubmenu()}
                    >
                        <span>Change Status
                            <SVG className="icon icon--chevron-right" src={chevronRight} />
                        </span>
                        <div className={`submenu ${submenuToggled ? 'show' : ''}`}>
                            <UpdateProtocolStatus
                                protocol={protocol}
                                updateProtocolStatusAPI={this.props.updateProtocolStatusAPI}
                                toggleDropdown={this.toggleDropdown}
                                hideSubmenu={this.hideSubmenu}
                            />
                        </div>
                    </span>

                    {this.props.checkStatusExport(protocol.Protocol_ID,protocol.Status_id)?
                    <a className="dropdown-item" onClick={() => this.props.exportProtocol(protocol.Protocol_ID)}>Download protocol summary</a>:''
                    }
                    {this.props.checkStatusExport(protocol.Protocol_ID,protocol.Status_id)?
                    <a className="dropdown-item" onClick={() => this.props.getQuote(protocol.Protocol_ID)}>Download quote</a>:''
                    }
                    
                    {this.props.checkProtocolStatus(protocol.Protocol_ID,protocol.Status_id)?
                    <a className="dropdown-item" onClick={() => this.props.openModal(protocol.Protocol_ID,protocol.Protocol_code)}>Delete protocol</a> :''
                   
                    }
                </div>
            </div>
        )
    }
}

export default ActionDropdown;

ActionDropdown.propTypes = {
    protocol: PropTypes.object,
    updateProtocolStatusAPI: PropTypes.func.isRequired,
    exportProtocol: PropTypes.func.isRequired,
    getQuote: PropTypes.func.isRequired,
    checkProtocolStatus: PropTypes.func.isRequired,
    checkStatusExport: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
}