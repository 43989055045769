import React, { Component } from 'react';

import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import axios from 'axios';
import { Auth } from 'aws-amplify';


import ProtocolSummary from './../ProtocolSummary';
import CohortsForm from './CohortsForm';
import EpisodesContainer from './../Episodes/EpisodesContainer';
import Preloader from './../../../common/Preloader';

import { GET_SCREENING_BY_PROTOCOLID_API, GET_COHORTLIST_BY_PROTOCOLID_API } from './../../../../utils/constants';
import { UPSERT_COHORTLIST_API } from './../../../../utils/constants';
import { PROTOCOL_API } from './../../../../utils/constants';
import { dateFormat } from './../../../../utils/constants';
import { dateFormatAPI } from './../../../../utils/constants';



import _ from 'lodash';
import { isInt } from './../../../../utils/utils';
import { generatePortalUrl } from './../../../../utils/constants';
import moment from 'moment';

let previousState = [];

const cohortData = {
    values: {
        Protocol_cohort_ID: null,
        Cohort_No: '',
        Study_participants: '',
        Target_participants_to_screen: 0,
        Screening_required: false,
        Day1_participants: '',
        First_dosing_date: '',
        Screening_commence_date: '',
        Episodes: 0,
        Required: true,
        alternativeNames: ''
    },
    errors: {
        Cohort_No: false,
        Study_participants: false,
        Day1_participants: false,
        Screening_participants: false,
        First_dosing_date: false,
        Screening_date: false,
        Screening_skipped: false,
        Episodes: false,
    },
};


class CohortsContainer extends Component {

    state = {
        isLoading: false,
        isLoadingProtocolStatus: false,
        isSubmitting: false,
        isSubmittingBack: false,
        isSubmittingNext: false,
        isSubmittingExit: false,
        willOpenEpisodes: false,
        openEpisodes: false,
        willDuplicateCohort: false,
        protocolStatus: null,
        cohorts: [
            cohortData,
        ],
        hasErrors: false,
        Screening_skipped: false
    }

    componentDidMount() {
        this.getScreeningSkippedStatus();
        this.getCohortsbyProtocolId_API();
        this.getProtocolStatus();
        /* 
        If the user is taken to the Cohorts page from Review and Export,
        If they clicked on "Edit episode", episodeFromReviewScreen will be filled out.
        Then open the EpisodeContainer.
        */
        const { episodeFromReviewScreen, episodeFromReviewScreenIndex } = this.props;
        if (episodeFromReviewScreen) {
            //console.log('Its editing Episodes list');
            this.openEpisodes(episodeFromReviewScreen, episodeFromReviewScreenIndex);
        }
    }

    getScreeningSkippedStatus = () => {


        this.setState({ isLoading: true });
        const url = GET_SCREENING_BY_PROTOCOLID_API.GET;
        // Get the current session
        Auth.currentSession().then((response) => {
            // Grab the jwtToken to use as Authorization header
            const headers = { Authorization: response.idToken.jwtToken };
            let beforeCallTime = new Date().getTime();

            // Fetch the Screening Panels list
            axios.get(`${url}?protocolId=${this.props.protocolId}`, { headers })
                .then((response) => {
                    
                    let afterCallTime = new Date().getTime();
                    let loadTime = afterCallTime - beforeCallTime;

                    let array = url.split('/');
                    let endpoint = array[array.length-1];

                    //console.log(endpoint + ' response time: ' + loadTime);
          
                    //Google analytics tracking
                    ReactGA.timing({
                    category: endpoint,
                    variable: 'responseTime',
                    value: loadTime, // in milliseconds
                    label: null
                    });

                    if (response.data) {

                        if(response.data.Screening_skipped == null){
                            this.setState({Screening_skipped: false})
                        } else {
                            this.setState({Screening_skipped: response.data.Screening_skipped})
                        }
                        // console.log('ScreningSkipped Value is: ' + response.data.Screening_skipped)
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    this.setState({ isLoading: false });
                });

        }).catch((error) => {
            console.error(error);
            this.setState({ isLoading: false });
        });
    }

    getCohortsbyProtocolId_API = () => {

        // resetting the previous state
        previousState = [];
        
        this.setState({ isLoading: true });

        const url = GET_COHORTLIST_BY_PROTOCOLID_API.GET;
        Auth.currentSession().then((response) => {
            const headers = { Authorization: response.idToken.jwtToken };

            // Fetch the Chorts list
            axios.get(`${url}?protocolId=${this.props.protocolId}`, { headers })
                .then((response) => {
                    if (response.data) {
                        const result = response.data[0].result;
                        if (result && result.length) {
                            this.mapResultToPreviousState(result);
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    this.setState({ isLoading: false });
                });

        }).catch((error) => {
            console.error(error);
            this.setState({ isLoading: false });
        });

    }

    /**
     * Loads previous cohort details (if cohort is already created)
     */
    loadCohortDetails = () => {
        // Just override the state
        if (previousState.length) {
            const cohorts = [];
            for (let item of previousState) {
                cohorts.push(item);
            }
            // Just override the state
            this.setState({ cohorts });
        }
    }

    /**
     * Map the result to a previousState array which we can use for display
     * and comparing values
     */
    mapResultToPreviousState = (result) => {
        const newResult = result.map(item => {

            // Create a shell with empty errors

            const cohort = {
                values: {},
                errors: {
                    Cohort_No: false,
                    Study_participants: false,
                    Day1_participants: false,
                    Screening_participants: false,
                    First_dosing_date: false,
                    Screening_date: false,
                    Screening_skipped: false,
                    Episodes: false,
                },
            };

            // Parse each property
            cohort.values.Screening_required = item.Screening_required != 0;
            cohort.values.Target_participants_to_screen = item.Target_participants_to_screen;
            cohort.values.Protocol_cohort_ID = item.Protocol_cohort_ID;
            cohort.values.Cohort_No = item.Cohort_No;
            cohort.values.Study_participants = item.Study_participants;
            cohort.values.Day1_participants = item.Day1_participants;
            // Convert MM/DD/YYYY from API to DD/MM/YYYY for display
            cohort.values.First_dosing_date = moment(item.First_dosing_date, dateFormatAPI).format(dateFormat);
            cohort.values.Screening_commence_date = moment(item.Screening_commence_date, dateFormatAPI).format(dateFormat);
            cohort.values.Episodes = item.Episodes;
            cohort.values.alternativeNames = item.alternativeNames;

            // Change Required to true and false
            if (item.Required == '1') {
                cohort.values.Required = true;
            } else {
                cohort.values.Required = false;
            }

            return cohort;
        });

        previousState = newResult;

        this.loadCohortDetails();
    }

    /**
     * Get the protocol's status. This will be used to check if 
     * "first dosing date" needs to be validated or not.
     */
    getProtocolStatus = () => {
        const url = PROTOCOL_API.GET;
        const protocolId = this.props.protocolId;
        this.setState({ isLoadingProtocolStatus: true });

        Auth.currentSession().then((response) => {
        const headers = { Authorization: response.idToken.jwtToken };
            axios.get(url, { headers })
                .then((response) => {
                    if (response.data) {
                        const result = response.data.result;

                        // Find the protocol from its ID
                        const protocol = result.filter(item => item.Protocol_ID === protocolId)[0];

                        // Set the status ID to the state
                        this.setState({
                            protocolStatus: protocol.Status_id,
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    // always executed
                    this.setState({ isLoadingProtocolStatus: false });
                });
        }).catch((error) => {
            console.error(error);
            this.setState({ isLoadingProtocolStatus: false });
        });
    }

    addCohort = () => {
        const cohorts = this.state.cohorts;
        const item = cohortData;
        this.setState({ cohorts: [...cohorts, item] });
    }

    updateCohort = (index, name, value) => {
    //    console.log(`updating cohort ${index} to change ${name} to ${value}`);

        const cohorts = this.state.cohorts;

        // Loop through each cohort and find the one cohort to update
        // Return a new array
        const newCohorts = cohorts.map((cohort, i) => {
            if (i === index) {
                cohort = { ...cohort };
                cohort.values = { ...cohort.values, [name]: value }
            }
            return cohort;
        })
        // console.log(newCohorts);

        this.setState({
            cohorts: newCohorts,
        })

    }


    removeCohort = (index) => {
        //console.log(`removing cohort ${index}`);

        const cohorts = this.state.cohorts;
        cohorts.splice(index, 1);
        this.setState({ cohorts });
    }
    

    handleDuplicateCohort = (index) => {
        //console.log(`duplicating cohort ${index}`);

        // Set willDuplicateCohort to true to avoid the targetSum validation
        this.setState({
            willDuplicateCohort: true
        }, () => {

            const validation = this.validateForm();
            const { hasErrors } = validation;
            this.updateValidationToState(validation);
            

            if (hasErrors) {
                //console.log('Dont submit, has errors');
            } else {
                // No longer need the willDuplicateCohort flag
                this.setState({ willDuplicateCohort: false });
                // Clone the cohort to duplicate as a new item
                const parentCohort = _.cloneDeep(this.state.cohorts[index]);
                //console.log(parentCohort);
                // If the Cohort is not new, we can call duplicate directly.
                if (parentCohort.values.Protocol_cohort_ID !== null) {
                    // Call API
                    this.duplicateCohortAPI(parentCohort);
                } else {
                    // Else we need to submit this new cohort first.
                    //console.log('Need to submit this new cohort to API first');
                    this.upsertNewCohortAPI(parentCohort.values, index, 'duplicateCohort');
                }
            }
        });
    }

    /* Parse the cohort for API and call the duplicate cohort API */
    duplicateCohortAPI = (cohort) => {
        const parentCohort = _.cloneDeep(cohort);
        // Parse the cohort for API
        const cohortParsed = this.parseCohortForAPI(parentCohort.values);
        
        const url = UPSERT_COHORTLIST_API.POST;

        const cohorts = [
            {
                Parent_Protocol_cohort_ID: cohortParsed.Protocol_cohort_ID,
                Protocol_cohort_ID: null,
                Cohort_No: null,
                Study_participants: cohortParsed.Study_participants,
                Day1_participants: cohortParsed.Day1_participants,
                Target_participants_to_screen: cohortParsed.Target_participants_to_screen,
                Screening_commence_date: moment(cohortParsed.Screening_commence_date, dateFormat).format(dateFormatAPI),
                Screening_required: cohortParsed.Screening_required ? this.props.protocolSummary.targetNoOfParticipantsToBeScreened : 0,
                First_dosing_date: moment(cohortParsed.First_dosing_date, dateFormat).format(dateFormatAPI),
                Required: cohortParsed.Required,
                Episodes: cohortParsed.Episodes,
                Status: 'D',
            }
        ];

        this.setState({ isLoading: true, });

        Auth.currentSession().then((response) => {
            const headers = { Authorization: response.idToken.jwtToken };
            let beforeCallTime = new Date().getTime();


            let data = JSON.stringify({
                body: {
                    Protocol_Id: this.props.protocolId,
                    user: this.props.username,
                    Cohorts: cohorts,
                }
            })
            axios.post(url, data, { headers })
                .then((response) => {
                    let afterCallTime = new Date().getTime();
                    let loadTime = afterCallTime - beforeCallTime;

                    let array = url.split('/');
                    let endpoint = array[array.length-1];

                    //console.log('Duplicate ' + endpoint + ' response time: ' + loadTime);
                    
                    //Google analytics tracking
                    ReactGA.timing({
                    category: endpoint,
                    variable: 'responseTime',
                    value: loadTime, // in milliseconds
                    label: null
                    });
                    //console.log(data);
                    //console.log(response);

                    this.setState({
                        serverError: '',
                        isLoading: false,
                    });

                    // Status code 400 is a server error.
                    if (response.data.statusCode && response.data.statusCode === 400) {
                        this.setState({
                            serverError: 'There was an error submitting the form. Please try again.',
                        });
                        return;
                    }

                    // If successful:
                    const result = response.data.body[0].result[0];
                    if (result) {
                        // Add the new cohort line to the state
                        this.duplicateCohortInState(cohort, result);
                    }

                })
                .catch((error) => {
                    //console.log(error);
                    this.setState({
                        serverError: 'There was an error submitting the form. Please try again.',
                        isLoading: false,
                    });
                });
        });
    }

    /* Add the duplicated cohort to the state and previousState */
    duplicateCohortInState = (parentCohort, result) => {
        const cohorts = this.state.cohorts;
        const newItem = _.cloneDeep(parentCohort);

        // // Reset the new item's cohort Cohort_No
        newItem.values.Protocol_cohort_ID = result.protocol_cohort_Id;
        newItem.values.Cohort_No = '';

        // Set it to state
        this.setState({
            cohorts: [...cohorts, newItem]
        });

        // And also the previousState
        previousState.push(newItem);
        //console.log('New previous state:');
        //console.log(previousState);
    }

    validateForm = () => {
        const cohorts = _.cloneDeep(this.state.cohorts);
        const { willOpenEpisodes, willDuplicateCohort } = this.state;
        let hasErrors = false;
        let errorCohortNo = false;
        let errorFirstDosingDate = false;
        let errorScreeningSkipped = false;
        let errorScreeningDate = false;
        let errorTargetNoOfScreeningParticipants = false;
        let errorTargetNoOfOnStudyParticipants = false;
        let errorTargetNoOfDayMinusOneParticipants = false;
        let errorTargetNoOfOnStudyParticipantsSum = false;
        let errorTargetNoOfScreeningParticipantsSum = false;

        let studyParticipantsSum = 0;
        let screeningParticipantSum = 0;

        const newCohorts = cohorts.map((cohort, index) => {

            const newErrors = {};

            // Validate each field inside
            if (!this.validateCohortNo(cohort.values.Cohort_No)) {
                errorCohortNo = true;
                hasErrors = true;
                newErrors.Cohort_No = true;
            } else {
                newErrors.Cohort_No = false;
            }

            if (!this.validateParticipants(cohort.values.Study_participants)) {
                errorTargetNoOfOnStudyParticipants = true;
                hasErrors = true;
                newErrors.Study_participants = true;
            } else {
                studyParticipantsSum += parseInt(cohort.values.Study_participants);
                newErrors.Study_participants = false;
            }

            if (!this.validateParticipants(cohort.values.Day1_participants)) {
                errorTargetNoOfDayMinusOneParticipants = true;
                hasErrors = true;
                newErrors.Day1_participants = true;
            } else {
                newErrors.Day1_participants = false;
            }

            if (!this.validateDate(cohort.values.First_dosing_date)) {
                errorFirstDosingDate = true;
                hasErrors = true;
                newErrors.First_dosing_date = true;
            } else {
                newErrors.First_dosing_date = false;
            }

            if(!this.state.Screening_skipped){//sjk
                if(cohort.values.Screening_required){
                    if (!this.validateDate(cohort.values.Screening_commence_date)) {
                        errorScreeningDate = true;
                        hasErrors = true;
                        newErrors.Screening_date = true;
                    } else {
                        newErrors.Screening_date = false;
                    }

                    if (!this.validateParticipants(cohort.values.Target_participants_to_screen)) {
                        errorTargetNoOfScreeningParticipants = true;
                        hasErrors = true;
                        newErrors.Screening_participants = true;
                    } else {
                        newErrors.Screening_participants = false;
                        // console.log('adding', cohort.values.Target_participants_to_screen)
                        screeningParticipantSum += parseInt(cohort.values.Target_participants_to_screen);
                    }

                }
                if(index == 0 && !cohort.values.Screening_required){
                    errorScreeningSkipped = true;
                    hasErrors = true;
                    newErrors.Screening_skipped = true;
                } else {
                    newErrors.Screening_skipped = false;
                }
            }

            cohort = { ...cohort };
            cohort.errors = { ...cohort.errors, ...newErrors };

            return cohort;

        });

        // Validate the sum of study participants
        const targetParticipants = this.props.protocolSummary.targetNoOfOnStudyParticipants;
        const targetScreeningParticipants = this.props.protocolSummary.targetNoOfParticipantsToBeScreened;
        //console.log(`studyParticipantsSum: ${studyParticipantsSum}`);
        //console.log(`targetParticipants: ${targetParticipants}`);

        // Don't validate if there's already an error for Study_particpants
        // Don't validate if user is opening episodes for a new cohort, or duplicating a new cohort
        if (!errorTargetNoOfScreeningParticipants && !errorTargetNoOfOnStudyParticipants && !willOpenEpisodes && !willDuplicateCohort) {
            if ((studyParticipantsSum == targetParticipants)) {
                //console.log('studyParticipantsSum matches targetParticipants');
            } else {
                errorTargetNoOfOnStudyParticipantsSum = true;
                hasErrors = true;
            }

            // console.log('targetScreeningParticipants', targetScreeningParticipants)
            // console.log('screeningParticipantSum', screeningParticipantSum)
            if(targetScreeningParticipants != screeningParticipantSum && !this.state.Screening_skipped){
                errorTargetNoOfScreeningParticipantsSum = true;
                hasErrors = true;
            }
        }

        return {
            newCohorts,
            hasErrors,
            errorCohortNo,
            errorFirstDosingDate,
            errorScreeningDate,
            errorScreeningSkipped,
            errorTargetNoOfOnStudyParticipants,
            errorTargetNoOfScreeningParticipants,
            errorTargetNoOfDayMinusOneParticipants,
            errorTargetNoOfOnStudyParticipantsSum,
            errorTargetNoOfScreeningParticipantsSum,
        };
    }

    validateCohortNo = (cohortNo) => {
        // Cohort no. -> Cohort name - Free text field (50 characters) (Mandatory)
        if (!cohortNo || _.trim(cohortNo) === '') {
            return false;
        }
        return true;
    }

    validateParticipants = (participantNo) => {
        // Participants - 2-digit numeric field (Mandatory)
        if (!participantNo || participantNo <= 0 || participantNo > 999 || !isInt(participantNo)) {
            return false;
        }
        return true;
    }

    validateDate = (date) => {
        // Needs to be DD/MM/YYYY
        // Needs to be today or in the future

        // Empty date
        if (!date) {
            return false;
        }

        // Invalid format
        const validFormat = moment(date, dateFormat, true).isValid();
        if (!validFormat) {
            return false;
        }

        // If the Protocol status is Live – pending amendments (3), past dates are fine.
        const { protocolStatus } = this.state;
        if (protocolStatus == 3 || protocolStatus == 4 || protocolStatus == 5) {
            return true;
        }

        // Is today or in the future
        const today = moment().startOf('day');
        const dateParsed = moment(date, dateFormat);
        const validDate = moment(dateParsed).isSameOrAfter(today);
        if (!validDate) {
            return false;
        }

        return true;
    }

    /* 
    Update the state to display any validation errors - 
    takes in a validation object received from validateForm() 
    */
    updateValidationToState = (validation) => {
        const {
            newCohorts,
            hasErrors,
            errorCohortNo,
            errorFirstDosingDate,
            errorScreeningDate,
            errorScreeningSkipped,
            errorTargetNoOfScreeningParticipants,
            errorTargetNoOfOnStudyParticipants,
            errorTargetNoOfDayMinusOneParticipants,
            errorTargetNoOfOnStudyParticipantsSum,
            errorTargetNoOfScreeningParticipantsSum,
        } = validation;

        this.setState({
            cohorts: newCohorts,
            hasErrors,
            errorCohortNo,
            errorFirstDosingDate,
            errorScreeningDate,
            errorScreeningSkipped,
            errorTargetNoOfScreeningParticipants,
            errorTargetNoOfOnStudyParticipants,
            errorTargetNoOfDayMinusOneParticipants,
            errorTargetNoOfOnStudyParticipantsSum,
            errorTargetNoOfScreeningParticipantsSum,
        });
    }

    handleSubmit = (e, nextStep) => {
        // Prevent form from submitting
        e.preventDefault();

        // Set willOpenEpisodes to false as user did not click on "View Details"
        // Set willDuplicateCohort to false as user did not click on "Duplicate cohort"
        this.setState({ 
            willOpenEpisodes: false,
            willDuplicateCohort: false,
        }, () => {
            this.afterHandleSubmit(nextStep);
        });
    }

    afterHandleSubmit = (nextStep) => {
        // Validate the form
        const validation = this.validateForm();
        const { hasErrors } = validation;
        this.updateValidationToState(validation);

        if (hasErrors) {
            //console.log('Dont submit, has errors');
            // If there's no errors, submit the form
        } else {
            //console.log('Submitting');
            this.upsertCohortDetailsAPI(nextStep);
        }
    }

    upsertCohortDetailsAPI = (nextStep) => {

        const isEditFromReviewScreen = this.props.isEditFromReviewScreen;

        // Next step to go to after successful form submit
        //console.log(nextStep);
        let button = '';

        if (nextStep === 2) {
            button = 'isSubmittingBack';
        } else if (nextStep === 4) {
            button = 'isSubmittingNext';
        } else {
            button = 'isSubmittingExit';
        }

        // Set isSubmitting to true
        this.setState({
            isSubmitting: true,
            [button]: true,
        });

        const { added, deleted, updated } = this.returnUpdates();
        //console.log(deleted);
        //console.log(added);
        //console.log(updated);

        const cohorts = [];

        for (let item of deleted) {
            cohorts.push(
                {
                    Protocol_cohort_ID: item.Protocol_cohort_ID,
                    Cohort_No: item.Cohort_No,
                    Study_participants: item.Study_participants,
                    Day1_participants: item.Day1_participants,
                    Target_participants_to_screen: item.Target_participants_to_screen,
                    Screening_commence_date: moment(item.Screening_commence_date, dateFormat).format(dateFormatAPI),
                    Screening_required: item.Screening_required ? this.props.protocolSummary.targetNoOfParticipantsToBeScreened : 0,
                    //First_dosing_date: item.First_dosing_date,
                    First_dosing_date: moment(item.First_dosing_date, dateFormat).format(dateFormatAPI),
                    Required: item.Required,
                    Episodes: item.Episodes,
                    alternativeNames: item.alternativeNames,
                    Status: 'R',
                }
            )
        }

        for (let item of added) {
            cohorts.push(
                {
                    Protocol_cohort_ID: item.Protocol_cohort_ID,
                    Cohort_No: item.Cohort_No,
                    Study_participants: item.Study_participants,
                    Day1_participants: item.Day1_participants,
                    Target_participants_to_screen: item.Target_participants_to_screen,
                    Screening_commence_date: moment(item.Screening_commence_date, dateFormat).format(dateFormatAPI),
                    Screening_required: item.Screening_required ? this.props.protocolSummary.targetNoOfParticipantsToBeScreened : 0,
                    //First_dosing_date: item.First_dosing_date,
                    First_dosing_date: moment(item.First_dosing_date, dateFormat).format(dateFormatAPI),
                    Required: item.Required,
                    Episodes: item.Episodes,
                    alternativeNames: item.alternativeNames,
                    Status: 'N',
                }
            )
        }

         for (let item of updated) {
            cohorts.push(
                {
                    Protocol_cohort_ID: item.Protocol_cohort_ID,
                    Cohort_No: item.Cohort_No,
                    Study_participants: item.Study_participants,
                    Day1_participants: item.Day1_participants,
                    Target_participants_to_screen: item.Target_participants_to_screen,
                    Screening_commence_date: moment(item.Screening_commence_date, dateFormat).format(dateFormatAPI),
                    Screening_required: item.Screening_required ? this.props.protocolSummary.targetNoOfParticipantsToBeScreened: 0,
                    //First_dosing_date: item.First_dosing_date,
                    First_dosing_date: moment(item.First_dosing_date,  dateFormat).format(dateFormatAPI),
                    Required: item.Required,
                    Episodes: item.Episodes,
                    alternativeNames: item.alternativeNames,
                    Status: 'U',
                }
            )
        }

        
        //console.log(cohorts);
        const url = UPSERT_COHORTLIST_API.POST;

         Auth.currentSession().then((response) => {
             const headers = { Authorization: response.idToken.jwtToken };
             let beforeCallTime = new Date().getTime();

            let data = JSON.stringify({
                 body: {
                     Protocol_Id: this.props.protocolId,
                     user: this.props.username,
                     Cohorts:cohorts

                 }
             })
            //  console.log('data:', data)
             axios.post(url, data, { headers })
                 .then((response) => {

                    // console.log(response)
                    let afterCallTime = new Date().getTime();
                    let loadTime = afterCallTime - beforeCallTime;

                    let array = url.split('/');
                    let endpoint = array[array.length-1];

                    //console.log(endpoint + ' response time: ' + loadTime);
                    
                    //Google analytics tracking
                    ReactGA.timing({
                    category: endpoint,
                    variable: 'responseTime',
                    value: loadTime, // in milliseconds
                    label: null
                    });

                    // No longer submitting
                    this.setState({
                        isSubmitting: false,
                        [button]: false,
                    });

                     //console.log(data);
                     //console.log(response);

                    // Status code 400 is a server error.
                    if (response.data.statusCode && response.data.statusCode === 400) {
                        this.setState({
                            serverError: 'There was an error submitting the form. Please try again.',
                        });
                    }

                    //console.log(response.data.result[0].ScreeningId);
                     //this.props.updateScreeningId(response.data.result[0].ScreeningId);
                    // After API call, go to the next step
                    if (nextStep === 2) {
                        this.props.updateStepDone(3, 2);
                        this.props.updateIsEditFromReviewScreen(false);
                    } else if (nextStep === 4 && isEditFromReviewScreen) {
                        this.props.updateCurrentStep(4);
                        this.props.updateIsEditFromReviewScreen(false);
                    } else if (nextStep === 4) {
                        this.props.updateStepDone(3, 4);
                    } else {
                        this.props.history.push(generatePortalUrl('protocols'));
                    }

                })
                .catch((error) => {
                     //console.log(error);
                     this.setState({
                         serverError: 'There was an error submitting the form. Please try again.',
                         isSubmitting: false,
                         [button]: false,
                     });
                });
        });
    }

    /* (For opening Episodes) */
    handleSubmitNewCohort = (cohort, index) => {
        const validation = this.validateForm();
        const { hasErrors } = validation;
        this.updateValidationToState(validation);

        if (hasErrors) {
            //console.log('Dont submit, has errors');
            // If there's no errors, submit the form
        } else {
            //console.log('Submitting');
            // Opening episodes now, so we can reset the willOpenEpisodes flag
            this.setState({ willOpenEpisodes: false });
            this.upsertNewCohortAPI(cohort, index, 'openEpisodes');
        }
    }

    /* 
    Function used to add a newly created cohort to the database before
    proceeding to either opening Episodes screen, or duplicating a Cohort
    (based on nextStep flag)
    */
    upsertNewCohortAPI = (cohort, index, nextStep) => {
        const url = UPSERT_COHORTLIST_API.POST;

        const newCohort = _.cloneDeep(cohort);
        const cohortParsed = this.parseCohortForAPI(newCohort);

        const cohorts = [
            {
                Protocol_cohort_ID: cohortParsed.Protocol_cohort_ID,
                Cohort_No: cohortParsed.Cohort_No,
                Study_participants: cohortParsed.Study_participants,
                Day1_participants: cohortParsed.Day1_participants,
                Target_participants_to_screen: cohortParsed.Target_participants_to_screen,
                Screening_commence_date: moment(cohortParsed.Screening_commence_date, dateFormat).format(dateFormatAPI),
                Screening_required: cohortParsed.Screening_required ? this.props.protocolSummary.targetNoOfParticipantsToBeScreened : 0,
                First_dosing_date: moment(cohortParsed.First_dosing_date, dateFormat).format(dateFormatAPI),
                Required: cohortParsed.Required,
                Episodes: cohortParsed.Episodes,
                alternativeNames: cohortParsed.alternativeNames,
                Status: 'N',
            }
        ];

        //console.log(cohorts);

        this.setState({ isLoading: true, });

        Auth.currentSession().then((response) => {
            const headers = { Authorization: response.idToken.jwtToken };

            let data = JSON.stringify({
                body: {
                    Protocol_Id: this.props.protocolId,
                    user: this.props.username,
                    Cohorts: cohorts,
                }
            })
            axios.post(url, data, { headers })
                .then((response) => {
                    this.setState({
                        serverError: '',
                        isLoading: false,
                    });

                    // Status code 400 is a server error.
                    if (response.data.statusCode && response.data.statusCode === 400) {
                        this.setState({
                            serverError: 'There was an error submitting the form. Please try again.',
                        });
                        return;
                    }

                    // If successful:
                    // Get and save the new protocol_cohort_Id to the state
                    const protocol_cohort_Id = response.data.body[0].result[0].protocol_cohort_Id;
                    //console.log(`New protocol_cohort_Id: ${protocol_cohort_Id}`);
                    if (protocol_cohort_Id) {
                        this.afterUpsertNewCohortAPI(cohort, index, protocol_cohort_Id, nextStep);
                    }

                })
                .catch((error) => {
                    //console.log(error);
                    this.setState({
                        serverError: 'There was an error submitting the form. Please try again.',
                        isLoading: false,
                    });
                });
        });
    }

    returnUpdates = () => {
        // Get copies of current state and previous state
        const cohorts = this.state.cohorts.map(cohort => ({ ...cohort.values }));
        const prevStateCopy = previousState.map(cohort => ({ ...cohort.values }));

        // Parse it so that it's ready for comparison and API sending
        const cohortsParsed = cohorts.map(cohort => this.parseCohortForAPI(cohort));
        const previousStateParsed = prevStateCopy.map(cohort => this.parseCohortForAPI(cohort));
        //console.log('Previous state:');
        //console.log(previousStateParsed);
        // console.log('Current state:');
        //console.log('All cohorts:');
        // console.log(cohortsParsed);

        // Added protocols have no cohort ID assigned to them
        const added = cohortsParsed.filter(cohort => cohort.Protocol_cohort_ID === null);
        //console.log('Added (you can tell that theres no cohort ID assigned to it:');
        //console.log(added);

        // Use existing to distinguish between deleted, updated and noChange.
        const existing = cohortsParsed.filter(cohort => cohort.Protocol_cohort_ID !== null);


        // No change (do not send this data)
        const noChange = _.intersectionWith(previousStateParsed, existing, _.isEqual);
        //console.log('No change (do not send this data):');
        //console.log(noChange);

        // Deleted will have no more cohort ID
        const deleted = _.differenceBy(previousStateParsed, existing, 'Protocol_cohort_ID');
        //console.log('Deleted:');
        //console.log(deleted);

        // The rest is an update
        const mergeDeletedNoChange = [...noChange, ...deleted];
        // console.log(mergeDeletedNoChange);

        const updated = _.differenceWith(existing, mergeDeletedNoChange, _.isEqual);
        //console.log('Updated: ');
        //console.log(updated);

        return { added, deleted, updated };
    }

    parseCohortForAPI = (cohort) => {
        
        // Trim the Cohort_No
        cohort.Cohort_No = _.trim(cohort.Cohort_No);

        // Convert Study_participants, Day1_participants to numbers
        cohort.Study_participants = parseInt(cohort.Study_participants);
        cohort.Day1_participants = parseInt(cohort.Day1_participants);

        // Change Required to '0' and '1'
        if (cohort.Required) {
            cohort.Required = '1';
        } else {
            cohort.Required = '0';
        }

        return cohort;
    }

    handleOpenEpisodes = (cohort, index) => {
        const { Protocol_cohort_ID } = cohort;
        // If the Protocol_cohort_ID is null, it's new.
        // Call upsertCohort before opening Episodes.
        if (Protocol_cohort_ID !== null) {
            this.openEpisodes(cohort, index);
        } else {
            // Set flag to avoid validating the study participants sum
            this.setState({ 
                willOpenEpisodes: true 
            }, () => {
                this.handleSubmitNewCohort(cohort, index);
            });
            
        }
    }

    handleCloseEpisodes = (cohort) => {
        this.closeEpisodes(cohort);
    }

    afterUpsertNewCohortAPI = (aCohort, index, protocol_cohort_Id, nextStep) => {
        const cohorts = this.state.cohorts;

        // Loop through each cohort and find the one cohort to update
        // Return a new array
        const newCohorts = cohorts.map((cohort, i) => {
            if (i === index) {
                cohort = { ...cohort };
                cohort.values = { ...cohort.values, Protocol_cohort_ID: protocol_cohort_Id }
            }
            return cohort;
        });

        // Finally, open Episodes
        this.setState({
            cohorts: newCohorts,
        }, () => {
            const newCohort = this.state.cohorts[index];
            previousState.push(newCohort);
            if (nextStep === 'openEpisodes') {
                this.openEpisodes(newCohort.values, index);
            } else if (nextStep === 'duplicateCohort') {
                this.duplicateCohortAPI(newCohort);
            }
        });
    }

    openEpisodes = (cohort, index) => {
        this.setState({ 
            openEpisodes: true, 
            activeProtocolCohort: cohort,
            activeProtocolCohortIndex: index,
        });
    }

    closeEpisodes = (cohort) => {
        this.setState({
            openEpisodes: false,
            activeProtocolCohort: '',
            activeProtocolCohortIndex: '',
        });
    }

    goToNextCohortInEpisodes = () => {
        const { cohorts, activeProtocolCohortIndex } = this.state;
        const newActiveProtocolCohortIndex = activeProtocolCohortIndex + 1;
        const newActiveProtocolCohort = {...cohorts[newActiveProtocolCohortIndex].values};

        this.setState({
            activeProtocolCohort: newActiveProtocolCohort,
            activeProtocolCohortIndex: newActiveProtocolCohortIndex,
        });

        return newActiveProtocolCohort;
    }

    render() {
        const { openEpisodes, isLoading, isLoadingProtocolStatus } = this.state;
        return (
            <div className="cohorts__container">
                <div className={`cohorts ${openEpisodes ? 'hide' : '' }`}>
                    <h3>Cohorts</h3>
                    <ProtocolSummary
                        protocolSummary={this.props.protocolSummary}
                    />
                    <CohortsForm
                        Screening_skipped = {this.state.Screening_skipped}
                        cohorts={this.state.cohorts}
                        addCohort={this.addCohort}
                        updateCohort={this.updateCohort}
                        removeCohort={this.removeCohort}
                        handleDuplicateCohort={this.handleDuplicateCohort}
                        handleSubmit={this.handleSubmit}
                        hasErrors={this.state.hasErrors}
                        errorCohortNo={this.state.errorCohortNo}
                        errorFirstDosingDate={this.state.errorFirstDosingDate}
                        errorScreeningDate={this.state.errorScreeningDate}
                        errorScreeningSkipped={this.state.errorScreeningSkipped}
                        errorTargetNoOfOnStudyParticipants={this.state.errorTargetNoOfOnStudyParticipants}
                        errorTargetNoOfScreeningParticipants={this.state.errorTargetNoOfScreeningParticipants}
                        errorTargetNoOfDayMinusOneParticipants={this.state.errorTargetNoOfDayMinusOneParticipants}
                        errorTargetNoOfOnStudyParticipantsSum={this.state.errorTargetNoOfOnStudyParticipantsSum}
                        errorTargetNoOfScreeningParticipantsSum={this.state.errorTargetNoOfScreeningParticipantsSum}
                        protocolSummary={this.props.protocolSummary}
                        protocolStatus={this.state.protocolStatus}
                        isSubmitting={this.state.isSubmitting}
                        isSubmittingBack={this.state.isSubmittingBack}
                        isSubmittingNext={this.state.isSubmittingNext}
                        isSubmittingExit={this.state.isSubmittingExit}
                        serverError={this.state.serverError}
                        handleOpenEpisodes={this.handleOpenEpisodes}
                        isEditFromReviewScreen={this.props.isEditFromReviewScreen}
                    />
                </div>
                {
                    (isLoading || isLoadingProtocolStatus) ? <Preloader /> : null
                }
                {
                    openEpisodes ?
                        <EpisodesContainer
                            Screening_skipped = {this.state.Screening_skipped}
                            cohorts={this.state.cohorts}
                            activeProtocolCohort={this.state.activeProtocolCohort}
                            activeProtocolCohortIndex={this.state.activeProtocolCohortIndex}
                            handleCloseEpisodes={this.handleCloseEpisodes}
                            goToNextCohortInEpisodes={this.goToNextCohortInEpisodes}
                            username={this.props.username}
                            updateProtocolId={this.props.updateProtocolId}
                            updateCohort={this.updateCohort}
                            protocolId={this.props.protocolId}
                            episodeFromReviewScreen={this.props.episodeFromReviewScreen}
                            updateEditEpisodeFromReviewScreen={this.props.updateEditEpisodeFromReviewScreen}
                            updateIsEditFromReviewScreen={this.props.updateIsEditFromReviewScreen}
                            updateCurrentStep={this.props.updateCurrentStep}
                        />
                        : null
                }
            </div>
        )
    }
}



export default withRouter(CohortsContainer);

CohortsContainer.propTypes = {
    protocolSummary: PropTypes.shape({
        protocolNo: PropTypes.string.isRequired,
        targetNoOfOnStudyParticipants: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
        targetNoOfParticipantsToBeScreened: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
    }).isRequired,
    protocolId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    username: PropTypes.string.isRequired,
    updateStepDone: PropTypes.func.isRequired,
    updateProtocolId: PropTypes.func.isRequired,
    episodeFromReviewScreen: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    episodeFromReviewScreenIndex: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    isEditFromReviewScreen: PropTypes.bool.isRequired,
    updateIsEditFromReviewScreen: PropTypes.func.isRequired,
    updateCurrentStep: PropTypes.func.isRequired,
    updateEditEpisodeFromReviewScreen: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func,
    }),
};
