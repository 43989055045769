import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AccordionPanels from './AccordionPanels';

import axios from 'axios';
import { Auth } from 'aws-amplify';
import { GET_EPISODE_BY_COHORTID_API } from './../../utils/constants';

import SVG from 'react-inlinesvg';

import expand from './../../assets/images/accordion-toggle-expand.svg';
import collapse from './../../assets/images/accordion-toggle-collapse.svg';

import './../../scss/accordion.scss';

class AccordionEpisodes extends Component {

    state = {
        isLoading: true,
        isExpanded: false,
        isTouched: false,
        result: [],
    }

    handleToggleAccordion = () => {
        const { isTouched }  = this.state;
        const { Protocol_cohort_ID }  = this.props;
        if (!isTouched) {
            this.getEpisodebyCohortIdAPI(Protocol_cohort_ID);
        } else {
            this.toggleAccordion();
        }
    }

    toggleAccordion = () => {
        const isExpanded = this.state.isExpanded;
        this.setState({
            isExpanded: !isExpanded,
        })
    }

    getEpisodebyCohortIdAPI = (cohortId) => {
        // The Episode is now touched and is loading episodes
        this.setState({ 
            isLoading: true,
            isTouched: true,
            isExpanded: true,
        });

        const url = GET_EPISODE_BY_COHORTID_API.GET;

        Auth.currentSession().then((response) => {
            const headers = { Authorization: response.idToken.jwtToken };

            axios.get(`${url}?pcId=${cohortId}`, { headers })
                .then((response) => {
                    if (response.data) {

                        const _result = response.data[0].result;

                        console.log('_result', _result)
                        let result = [];
                        _result.forEach(element=>{
                            if(element.Period_value != '-'){

                                result.push(element);
                            }
                        })


                        if (result && result.length) {
                            this.setState({ 
                                result,
                            });
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    this.setState({ isLoading: false });
                });

        }).catch((error) => {
            console.error(error);
            this.setState({ isLoading: false });
        });
    }

    handleEditEpisode = (cohort, index) => {
        const { updateEditEpisodeFromReviewScreen, handleEdit } = this.props;
        //console.log('Handling edit for Episode');
        updateEditEpisodeFromReviewScreen(cohort, index);
        handleEdit();
    }

    renderEpisodes = (episodes) => {
        let episodesItem = null;

        if (episodes.length) {
            episodesItem = episodes.map(episode => {
                return (
                    <AccordionPanels
                        displayEpisodePeriod={this.displayEpisodePeriod}
                        episode={episode}
                        key={episode.Day_period_id}
                    />
                )
            });
        }

        return (
            <div className="review__episodes flex-table container">
                <div className="list--heading row">
                    <div className="item item__day">Day</div>
                    <div className="item item__period">Period</div>
                    <div className="item item__panels">Panels</div>
                    <div className="item item__urgent">Urgent</div>
                    <div className="item item__actions"></div>
                </div>
                {
                    !this.props.Screening_skipped ? 
                        <div className="episode-group row">
                            <div className="list--item">
                                <div className="item item__day">Screen</div>
                                <div className="item item__period">Single</div>
                                <div className="item item__panels">See screening</div>
                                <div className="item item__urgent">Not urgent</div>
                                <div className="item item__actions"></div>
                            </div>
                        </div>: null
                }
                { episodesItem }
            </div>
        )
    }

    displayEpisodePeriod = (episode) => {
        if (!episode) {
            return;
        }

        const { Period_value } = episode;
        let string = `${Period_value} `;

        const { Hour_value, Minute_value } = episode;

        if (Period_value === 'Dose +') {
            string += '('
            string += (Hour_value !== null && Hour_value !== '') ? `${Hour_value}hr ` : '';
            string += (Minute_value !== null && Minute_value !== '') ? `${Minute_value}min` : '';
            string += ')'
        } else {
            if (Hour_value !== null || Minute_value !== null) {
                string += '('
                if (Hour_value !== null) {
                    string += (Hour_value < 10) ? `0${Hour_value}` : `${Hour_value}`;
                }
                if (Minute_value !== null) {
                    string += (Minute_value < 10) ? `:0${Minute_value}` : `:${Minute_value}`;
                }
                string += ')'
            }
        }

        return string;
    }

    displayEpisodeCount = (episodeCount) => {
        // Display the Episode count with Screening (+1)
        let temp = this.props.Screening_skipped ? 0 : 1;
        const countWithScreening = episodeCount + temp;
        return (
            // Highlight if the episodeCount is 0.
            // Also fix grammar
            <span className={`episode-count ${episodeCount === 0 ? 'review__error-highlight' : ''}`}>
                {countWithScreening} {countWithScreening === 1 ? 'Episode' : 'Episodes'}
            </span>
        )
    }

    render() {

        const {
            isExpanded,
            result,
            isLoading,
        } = this.state;

        const {
            episodesCount,
            cohort,
            index
        } = this.props;

        return (
            <div className={`accordion--episodes ${isExpanded ? 'is-expanded' : ''}`}>
                <div className="accordion__heading">
                    <div className="row align-items-center">
                        <div className="col">
                            <div 
                                className="accordion-toggle__container"
                            >
                                <span
                                    className={`accordion-toggle ${isExpanded ? 'is-expanded' : ''}`}
                                    onClick={this.handleToggleAccordion}
                                >
                                    {
                                        isExpanded ?
                                            <SVG className="icon icon--collapse" src={collapse} /> :
                                            <SVG className="icon icon--expand" src={expand} />
                                    }
                                    <h4>{this.displayEpisodeCount(episodesCount)}</h4>
                                </span>
                            </div>
                        </div>
                        <div className="col text-right">
                            <a
                                className="link"
                                onClick={() => this.handleEditEpisode(cohort, index)}
                            >
                                Edit episodes
                            </a>
                        </div>
                    </div>

                </div>
                <div className={`accordion__content ${isExpanded ? '' : 'hide'}`}>
                    {
                        isLoading ? 'Loading episodes...' : this.renderEpisodes(result)
                    }
                </div>
            </div>
        )
    }
}

export default AccordionEpisodes;

AccordionEpisodes.propTypes = {
    episodesCount: PropTypes.number.isRequired,
    Protocol_cohort_ID: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    updateEditEpisodeFromReviewScreen: PropTypes.func.isRequired,
    handleEdit: PropTypes.func.isRequired,
    cohort: PropTypes.object.isRequired, // should be shaped
    index: PropTypes.number.isRequired,
    Screening_skipped: PropTypes.bool,
};