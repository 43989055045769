import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import PackingDetailsActionDropdown from './PackingDetailsActionDropdown'
import { dateFormat } from '../../../utils/constants';
import moment from 'moment';
import CustomisedDayPicker from '../../common/CustomisedDayPicker';
class KitPackingDetailsItem extends Component {

    item = null;
    constructor(props){
        super(props)
        this.item = this.props.item;
        this.state = {
            errors: {}
        }
    }
    handleEdit = ()=>{
        this.item.edit = true;
        this.props.enableEditModeOfKitPackingDetails(this.props.index, this.item)
    }

    handleDone = ()=>{

        this.props.upsertPackingDetailItem(this.props.index, this.item)
    }
    onSave = (key,val)=>{
        if(this.props.item.edit) {
            this.item[key].value = val;
            // console.log(val)
            this.handleDone()
        }
    }
    renderEditbleRow(){
        const hasErrors = this.item.kitsPacked.errorCount + 
        this.item.packedBy.errorCount + 
        this.item.datePacked.errorCount + 
        this.item.QCBy.errorCount + 
        this.item.QCDate.errorCount;
        return (
            <div 
                className={`row flex-table-form-item row list--item ${hasErrors > 0 ? 'packing-details-row-error': ''}` }
                style={{marginLeft:0, marginRight:0}}
            >
                <div className="col-lg-2 packing-details-item">
                    <input
                        className={`form-control cohort-no ${this.item.kitsPacked.errorCount > 0 ? 'has-error': ''}` }
                        type="text"
                        defaultValue={this.item.kitsPacked.value}
                        disbaled={this.state.edit ? 'disabled' : ''}
                        onChange={(e)=>{this.onSave('kitsPacked', e.target.value)}}
                    />
                </div>
                <div className="col-lg-2 packing-details-item">
                    <input
                        className={`form-control cohort-no ${this.item.packedBy.errorCount > 0 ? 'has-error': ''}` }
                        type="text"
                        defaultValue={this.item.packedBy.value}
                        disbaled={this.state.edit ? 'disabled' : ''}
                        onChange={(e)=>{this.onSave('packedBy', e.target.value)}}

                    />
                </div>
                <div className="col-lg-3 packing-details-item">
                    <CustomisedDayPicker
                        date={new Date(this.item.datePacked.value)}
                        updateSelectedDays={(value)=>{this.onSave('datePacked', value)}}
                    />
                </div>
                <div className="col-lg-2 packing-details-item">
                    <input
                        className={`form-control cohort-no ${this.item.QCBy.errorCount > 0 ? 'has-error': ''}` }
                        type="text"
                        defaultValue={this.item.QCBy.value}
                        disbaled={this.state.edit ? 'disabled' : ''}
                        onChange={(e)=>{this.onSave('QCBy', e.target.value)}}
                    />
                </div>
                <div className="col-lg-3 packing-details-item"
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems: 'center',
                        paddingRight: 0
                    }}
                    >
                    <CustomisedDayPicker
                        date={new Date(this.item.QCDate.value)}
                        updateSelectedDays={(value)=>{this.onSave('QCDate', value)}}
                    />

                <div>
            </div>
                </div>
            </div>
        );
    }

    renderNormalRow(){
        return (
            <div className="row flex-table-form-item row list--item" style={{marginLeft:0, marginRight:0}}>
                <div className="col-lg-2 packing-details-item">
                    <label>{this.item.kitsPacked.value}</label>
                </div>
                <div className="col-lg-2 packing-details-item">
                    <label>{this.item.packedBy.value}</label>
                </div>
                <div className="col-lg-3 packing-details-item">
                    <label>{moment(this.item.datePacked.value).format(dateFormat).replace('Invalid date', '-')}</label>
                </div>
                <div className="col-lg-2 packing-details-item">
                    <label>{this.item.QCBy.value}</label>
                </div>
                <div className="col-lg-3 packing-details-item"
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems: 'center',
                        paddingRight: 0
                    }}
                >
                    <label>{moment(this.item.QCDate.value).format(dateFormat).replace('Invalid date', '-')}</label>
                    <div>
                        <div className="item item__actions">
                            <text 
                                style={{
                                    marginRight: 10,
                                    color: '#753bbd',
                                    cursor: 'pointer'
                                }}
                                onClick={this.handleEdit}><u>Edit</u></text>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    render(){
        // console.log('render called')
        return this.props.item.edit ? this.renderEditbleRow() : this.renderNormalRow()
    }
}
export default KitPackingDetailsItem;
KitPackingDetailsItem.propTypes = {
    index: PropTypes.number.isRequired,
    item: PropTypes.any.isRequired,
    upsertPackingDetailItem: PropTypes.func.isRequired,
    enableEditModeOfKitPackingDetails: PropTypes.func.isRequired,
}
