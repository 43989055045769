import React, { Component } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';
import { Auth } from 'aws-amplify';
import { GET_PANELS_BY_EPISODEID_API } from './../../utils/constants';

import SVG from 'react-inlinesvg';

import expand from './../../assets/images/accordion-toggle-expand.svg';
import collapse from './../../assets/images/accordion-toggle-collapse.svg';

import { sortByObjectProperty } from './../../utils/utils';

import './../../scss/accordion.scss';

class AccordionPanels extends Component {

    state = {
        isLoading: true,
        isExpanded: false,
        isTouched: false,
        result: [],
    }

    handleToggleAccordion = () => {
        const { isTouched } = this.state;
        const { Day_period_id } = this.props.episode;
        if (!isTouched) {
            this.getPanelbyDayPeriodIdAPI(Day_period_id);
        } else {
            this.toggleAccordion();
        }
    }

    toggleAccordion = () => {
        const isExpanded = this.state.isExpanded;
        this.setState({
            isExpanded: !isExpanded,
        })
    }

    getPanelbyDayPeriodIdAPI = (dayPeriodId) => {
        // The Panel is now touched and is loading panels
        this.setState({
            isLoading: true,
            isTouched: true,
            isExpanded: true,
        });

        const url = GET_PANELS_BY_EPISODEID_API.GET;

        Auth.currentSession().then((response) => {
            const headers = { Authorization: response.idToken.jwtToken };

            axios.get(`${url}?dpId=${dayPeriodId}`, { headers })
                .then((response) => {
                    if (response.data) {
                        const result = response.data[0].result;
                        if (result && result.length) {
                            this.setState({
                                result,
                            });
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    this.setState({ isLoading: false });
                });

        }).catch((error) => {
            console.error(error);
            this.setState({ isLoading: false });
        });
    }

    renderPanels = (panels) => {
        if (!panels.length) {
            return 'No panels found for this episode';
        }

        const panelsList = panels.sort(sortByObjectProperty('Panel_code'))
            .map(panel => {
                return (
                    <div className="list--item row" key={panel.Panel_ID}>
                        <div className="item item__panel">{panel.Panel_code}</div>
                    </div>
                )
            });

        return (
            <div className="review__episode-panels flex-table container">
                <div className="list--heading row">
                    <div className="item item__panel">Panels</div>
                </div>
                { panelsList }
            </div>
        );
    }

    displayPanelCount = (panelCount) => {
        return (
            <span className={`panel-count ${panelCount === 0 ? 'review__error-highlight' : ''}`}>
                {panelCount} {panelCount === 1 ? 'panel' : 'panels'}
            </span>
        )
    }


    render() {

        const {
            isExpanded,
            result,
            isLoading,
        } = this.state;

        const { episode, displayEpisodePeriod } = this.props;

        return (
            <div className={`episode-group row ${isExpanded ? 'is-expanded' : ''}`}>
                <div className="list--item" onClick={this.handleToggleAccordion}>
                    <div className="item item__day">{episode.Day_value}</div>
                    <div className="item item__period">{displayEpisodePeriod(episode)}</div>
                    <div className="item item__panels">
                        { this.displayPanelCount(episode.Panels) }
                    </div>
                    <div className="item item__urgent">{(episode.Urgent_flag === 'Y') ? 'Urgent' : 'Not urgent'}</div>
                    <div className="item item__actions">
                        <span
                            className={`accordion-toggle ${isExpanded ? 'is-expanded' : ''}`}
                            onClick={this.handleToggleAccordion}
                        >
                            {
                                isExpanded ?
                                    <SVG className="icon icon--collapse" src={collapse} /> :
                                    <SVG className="icon icon--expand" src={expand} />
                            }
                        </span>
                    </div>
                </div>

                <div className={`accordion__content ${isExpanded ? '' : 'hide'}`}>
                    {
                        isLoading ? 'Loading panels...' : this.renderPanels(result)
                    }
                </div>
            </div>
        )
    }
}

export default AccordionPanels;

AccordionPanels.propTypes = {
    episode: PropTypes.object.isRequired,
    displayEpisodePeriod: PropTypes.func.isRequired,
};