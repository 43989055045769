import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

class MBSItem extends Component {

    handleInputChange = (e) => {
        const target = e.target;
        const value = target.value;
        const name = target.name;
        const index = this.props.index;

        this.props.updateMBS(index, name, value);
    }

    render() {
        const {
            values,
            errors,
            index,
            removeMBS,
        } = this.props;        

        return (
            <div className="row flex-table-form-item list--item" key={index}>

                    <div className="item item__labels">
                        <div className="form-label analyte__mbs-code">MBS Code</div>
                        <div className="form-label analyte__fee">Fee</div>
                    </div>
                    
                    <div className="item item__1analyte">
                        <div className="form-input analyte__mbs-code">
                            <input
                                type="text" 
                                name="MBS_code" 
                                id="MBS_code" 
                                className={`form-control ${errors.MBS_code ? 'has-error' : ''}`}  
                                placeholder="Add MBS code" 
                                value={values.MBS_code} 
                                maxLength="5" 
                                onChange={this.handleInputChange} 
                                disabled={values.isExisting} 
                            />
                        </div>
                        <div className="form-input analyte__fee">
                            <input
                                type="text" 
                                name="Fee_for_one" 
                                id="Fee_for_one" 
                                className={`form-control ${errors.Fee_for_one ? 'has-error' : ''}`}  
                                placeholder="Add fee" 
                                value={values.Fee_for_one} 
                                onChange={this.handleInputChange} 
                            />
                        </div>
                    </div>

                    <div className="item item__2analyte">
                        <div className="form-input analyte__mbs-code">
                            <input
                                type="text" 
                                name="Two_codes" 
                                id="Two_codes" 
                                className={`form-control ${errors.Two_codes ? 'has-error' : ''}`}  
                                placeholder="Add MBS code" 
                                value={values.Two_codes} 
                                maxLength="5" 
                                onChange={this.handleInputChange} 
                            />
                        </div>
                        <div className="form-input analyte__fee">
                            <input
                                type="text" 
                                name="Fee_for_two" 
                                id="Fee_for_two" 
                                className={`form-control ${errors.Fee_for_two ? 'has-error' : ''}`}  
                                placeholder="Add fee" 
                                value={values.Fee_for_two} 
                                onChange={this.handleInputChange} 
                            />
                        </div>
                    </div>

                    <div className="item item__3analyte">
                        <div className="form-input analyte__mbs-code">
                            <input
                                type="text" 
                                name="Three_codes" 
                                id="Three_codes" 
                                className={`form-control ${errors.Three_codes ? 'has-error' : ''}`}  
                                placeholder="Add MBS code" 
                                value={values.Three_codes} 
                                maxLength="5" 
                                onChange={this.handleInputChange} 
                            />
                        </div>
                        <div className="form-input analyte__fee">
                            <input
                                type="text" 
                                name="Fee_for_three" 
                                id="Fee_for_three" 
                                className={`form-control ${errors.Fee_for_three ? 'has-error' : ''}`}  
                                placeholder="Add fee" 
                                value={values.Fee_for_three} 
                                onChange={this.handleInputChange} 
                            />
                        </div>
                    </div>

                    <div className="item item__4analyte">
                        <div className="form-input analyte__mbs-code">
                            <input
                                type="text" 
                                name="Four_codes" 
                                id="Four_codes" 
                                className={`form-control ${errors.Four_codes ? 'has-error' : ''}`}  
                                placeholder="Add MBS code" 
                                value={values.Four_codes} 
                                maxLength="5" 
                                onChange={this.handleInputChange} 
                            />
                        </div>
                        <div className="form-input analyte__fee">
                            <input
                                type="text" 
                                name="Fee_for_four" 
                                id="Fee_for_four" 
                                className={`form-control ${errors.Fee_for_four ? 'has-error' : ''}`}  
                                placeholder="Add fee" 
                                value={values.Fee_for_four} 
                                onChange={this.handleInputChange} 
                            />
                        </div>
                    </div>

                    <div className="item item__5analyte">  
                        <div className="form-input analyte__mbs-code">
                            <input
                                type="text" 
                                name="Five_codes" 
                                id="Five_codes" 
                                className={`form-control ${errors.Five_codes ? 'has-error' : ''}`}  
                                placeholder="Add MBS code" 
                                value={values.Five_codes} 
                                maxLength="5" 
                                onChange={this.handleInputChange} 
                            />
                        </div>
                        <div className="form-input analyte__fee">
                            <input
                                type="text" 
                                name="Fee_for_five" 
                                id="Fee_for_five" 
                                className={`form-control ${errors.Fee_for_five ? 'has-error' : ''}`}  
                                placeholder="Add fee" 
                                value={values.Fee_for_five} 
                                onChange={this.handleInputChange} 
                            />
                        </div>
                    </div>

                    <div className="item item__actions">
                    {
                        // If it is a new line, then display a Remove link.
                        !values.isExisting &&
                        <a onClick={() => removeMBS(index)} className="link">Remove</a>
                    }
                    </div>
                    
                    
                
                
                
            </div>
        )
    }
}

export default MBSItem;

MBSItem.propTypes = {
    index: PropTypes.number.isRequired,
    updateMBS: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired, // should be shaped
    errors: PropTypes.object.isRequired, // should be shaped
    removeMBS: PropTypes.func.isRequired,
};
