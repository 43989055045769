import React, {  useState } from 'react';
import './../../scss/search.scss';
const SearchHeader = (props) => {
    const [searchQuery, setSearchQuery] = useState('')
    return (
        <div className="search-header-container">
            <div className="row" style={{alignItems: 'flex-end'}}>
                <div className="col-2"> 
                    <label htmlFor="search" className="filter-label">TUBE BARCODE</label>
                    <input name="search-participant" id="search-participant" autoComplete="on" type="text" className="form-control" placeholder="Enter lab number" onChange={(event)=>{setSearchQuery(event.target.value)}}/>
                </div>
                <div className="col-1">   
                    <button className="btn btn-primary btn--add-panel" onClick = {()=>{props.onSubmit(searchQuery)}}>Search</button>
                </div>
            </div>
        </div>
    )
    
}
export default SearchHeader;