import axios from 'axios';
import { Auth } from 'aws-amplify';
import { TUBE_API } from '../../utils/constants';
var masterTubeDetails = null;

export function getMasterTubeDetails( callback) {
    if(masterTubeDetails != null){
        callback(masterTubeDetails);
    } else {
        fetchTubesAPI(callback);
    }
}

function fetchTubesAPI(callback){
    //console.log('fetching list of tubes');
    // Fetching...

    const url = TUBE_API.GET;

    Auth.currentSession().then((response) => {
        const headers = { Authorization: response.idToken.jwtToken };

        // Fetch the Panels list
        axios.get(url, { headers })
            .then((response) => {
                if (response.data) {
                    // console.log(response.data);
                    const result = response.data.result;
                    if (result && result.length) {
                        // console.log(result);
                        masterTubeDetails = result;
                        callback(result);
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .then(() => {
            });

    }).catch((error) => {
        console.error(error);
    });
}