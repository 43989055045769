import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Accordion from './../../../common/Accordion';
import AccordionEpisodes from './../../../common/AccordionEpisodes';

import moment from 'moment';
import { dateDisplay, dateFormatAPI } from './../../../../utils/constants';

class ReviewCohorts extends Component {

    handleEdit = () => {
        //console.log('Handling edit for Cohorts');
        const { updateCurrentStep, updateIsEditFromReviewScreen } = this.props;
        updateCurrentStep(3);
        updateIsEditFromReviewScreen(true);
    }

    render() {
        const { cohortDetails } = this.props;
        return (
            <div className="review-panel review__cohorts">
                <Accordion
                    label="Cohorts"
                    headingButtonAction={this.handleEdit}
                >
                    {
                        cohortDetails ?
                        cohortDetails.map((cohort, index) => {
                            return (
                                <div className="cohort" key={index}>
                                    <div className="cohort__details">
                                        <h4 className="cohort__heading">
                                            Cohort {cohort.Cohort_No || ''}
                                        </h4>
                                        <div className="review-table review__content">
                                            <div className="review-item row">
                                                <div className="review-heading col-4">
                                                    Target no. of on-study participants
                                                </div>
                                                <div className="col-8">
                                                    {cohort.Study_participants || ''}
                                                </div>
                                            </div>
                                            <div className="review-item row">
                                                <div className="review-heading col-4">
                                                    Target no. of Day -1 participants
                                                </div>
                                                <div className="col-8">
                                                    {cohort.Day1_participants || ''}
                                                </div>
                                            </div>
                                            <div className="review-item row">
                                                <div className="review-heading col-4">
                                                    First dosing date
                                                </div>
                                                <div className="col-8">
                                                    <span className="date-display">
                                                        {cohort.First_dosing_date ? moment(cohort.First_dosing_date, dateFormatAPI).format(dateDisplay) : ''}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="review-item row">
                                                <div className="review-heading col-4">
                                                    Required
                                                </div>
                                                <div className="col-8">
                                                    {(cohort.Required == 1) ? 'Yes' : 'No'}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <AccordionEpisodes
                                        Screening_skipped={this.props.Screening_skipped}
                                        episodesCount={cohort.Episodes}
                                        isExpandedByDefault={false}
                                        updateEditEpisodeFromReviewScreen={this.props.updateEditEpisodeFromReviewScreen}
                                        handleEdit={this.handleEdit}
                                        cohort={cohort}
                                        index={index}
                                        Protocol_cohort_ID={cohort.Protocol_cohort_ID}
                                    />
                                </div>
                            )
                        }) : null
                    }
                </Accordion>
            </div>
        )
    }
}

export default ReviewCohorts;

ReviewCohorts.propTypes = {
    cohortDetails: PropTypes.array,
    updateCurrentStep: PropTypes.func.isRequired,
    updateIsEditFromReviewScreen: PropTypes.func.isRequired,
    updateEditEpisodeFromReviewScreen: PropTypes.func.isRequired,
    Screening_skipped: PropTypes.bool,
};