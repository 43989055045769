import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './../../../scss/kit-label.scss';

import { formatEpisodeForKit } from './../../../utils/episodes';

class KitLabel extends Component {

    displayVisitDetails = () => {
        const { kit } = this.props;
        if (kit.day_value == null && kit.period_value == null && kit.hour_value == null && kit.minute_value == null) {
            return 'Screening';
        } else {
            return formatEpisodeForKit(kit);
        }
    }

    render() {
        const {
            kit,
        } = this.props;

        return (
            <div className="print_label kit-label">
                {
                    kit &&
                    <React.Fragment>
                    <h3>{kit.kit_code || ''}</h3>
                    <div className="flex-table kit-label__details container">
                        <div className="row list--item">
                            <div className="item kit-label__title">
                                <strong>Protocol</strong>
                            </div>
                            <div className="item kit-label__value">
                                <span>{kit.protocol || ''}</span>
                            </div>
                        </div>
                        <div className="row list--item">
                            <div className="item kit-label__title">
                                <strong>Visit</strong>
                            </div>
                            <div className="item kit-label__value">
                                <span>{this.displayVisitDetails()}</span>
                            </div>
                        </div>
                        <div className="row list--item">
                            <div className="item kit-label__title">
                                <strong>Expiry Date</strong>
                            </div>
                            <div className="item kit-label__value">
                                <span>{kit.expiry_date || ''}</span>
                            </div>
                        </div>
                    </div>
                    </React.Fragment>
                }
            </div>
        )
    }
}

export default KitLabel;

KitLabel.propTypes = {
    formatDateForDisplay: PropTypes.func,
    kit: PropTypes.object.isRequired, // should be shaped
};