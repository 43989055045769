import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

import SVG from 'react-inlinesvg';
import tick from './../../../assets/images/tick.svg';
import './../../../scss/multistep-form-progress.scss';

const steps = [
    'Protocol overview',
    'Screening',
    'Cohorts',
    'Review and export',
];


class MultistepFormProgress extends Component {

    // Need to pass the tickIcon component in here otherwise it errors out
    renderStep = (index, tickIcon) => {
        const currentStep = this.props.currentStep; // Current step on the screen
        const thisStep = index + 1; // Step being iterated
        const done = this.props.done; // Steps that are done

        let additionalClass = null; // To change step colour
        let tick = false; // To add a tick if it's done

        // Additional checks for editing protocol
        const protocolId = this.props.protocolId;
        const screeningId = this.props.screeningId;
        const screeningDone = this.props.screeningDone;
        const cohortsId = this.props.cohortsId;
        const cohortsDone = this.props.cohortsDone;

        // Check if the step is already done or not
        if ( done[thisStep] || (thisStep === 1 && protocolId) || (thisStep === 2 && (screeningId || screeningDone)) || (thisStep === 3 && (cohortsId || cohortsDone)) ) {
            // additionalClass = 'done';
            tick = true;
        } else {
            additionalClass = 'todo';
        }

        // Check if the step is currently active
        if (thisStep === currentStep) {
            additionalClass = 'active';
        }

        // Check if the step is currently active
        if (thisStep < currentStep) {
            additionalClass = 'done';
        }

        return (
            <li className={`progress__item progress--${additionalClass}`} key={steps[index]}>
                <span className="inner">
                    <span className="progress__icon">
                        <span className={`icon ${tick ? 'icon--tick' : ''}`}>
                            {
                                tick ? 
                                tickIcon : 
                                thisStep
                            }
                        </span>
                    </span>
                    <span className="progress__label">{steps[index]}</span>
                </span>
            </li>
        )
    }

    render() {
        const tickIcon = <SVG className="icon--tick" src={tick} />;

        return (
            <div className="progress__container">
                <ul className="progress__bar">
                    {
                        steps.map((step, index) => {
                            return this.renderStep(index, tickIcon);
                        })
                    }
                </ul>
            </div>
        )
    }
}

export default MultistepFormProgress;

MultistepFormProgress.propTypes = {
    currentStep: PropTypes.number.isRequired,
    done: PropTypes.object.isRequired,
    protocolId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    screeningId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    screeningDone: PropTypes.bool.isRequired,
    cohortsId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    cohortsDone: PropTypes.bool.isRequired,
};