import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

import '../../../../src/scss/kit-information.scss';
import NumberIncrementerGeneric from '../NumberIncrementerGeneric';
class KitInformationItem extends Component {

    constructor(props){
        super(props);
        this.state={
            quantity: this.props.item.quantity,
        }
    }
    componentDidUpdate(){

    }
    updateNumber=(value)=>{
        if(value == 'DECREMENT'){
            this.props.updateQuantity(this.props.index, -1)
        } else {
            this.props.updateQuantity(this.props.index, 1)
        }
    }
    render() {
        const {removeItem, index, item} = this.props;
        return (
            <div className="ki-item-container row">
                <div className="col-lg-6 ki-item-col"><li>{item.quantity + ' x ' + item.tube.Tube}</li></div>
                <div className="col-lg-4 ki-item-col">
                    <NumberIncrementerGeneric
                        updateNumber={this.updateNumber}
                        value={item.quantity}
                        min={1}
                        max={99}
                    />
                </div>
                <div className="col-lg-2 ki-item-col"><a className="link" onClick={() => removeItem(index)}>Remove</a></div>
            </div>
        )
    }
}

export default KitInformationItem;
KitInformationItem.propTypes = {
    removeItem: PropTypes.func.isRequired,
    updateQuantity: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    item: PropTypes.any.isRequired,
};