
// Map the status ID to the status label
export const PROTOCOL_STATUS_MAPPING = [
    '',
    'Draft – work in progress', // 1
    'Draft – pending approval', // 2
    'Live – pending amendments', // 3
    'Live', // 4
    'Completed', // 5
    'Rejected', // 6
];

// Get available statuses that can be updated to
export function getAvailableStatuses(status) {
    /*
    Draft - work in progress -> Draft - pending approval, Rejected
    Draft - pending approval -> Draft - work in progress, Live, Rejected
    Rejected -> Draft - work in progress
    Live -> Completed
    Completed -> Live
    */
    switch (status) {
        case '1': // Draft - work in progress -> Draft - pending approval, Rejected
            return ['2', '6'];
        case '2': // Draft - pending approval -> Draft - work in progress, Live, Rejected
            return ['1', '4', '6'];
        case '3': // Live – pending amendments -> Live
            return ['4'];
        case '4': // Live -> Live – pending amendments, Completed
            return ['3', '5'];
        case '5': // Completed -> Live
            return ['4'];
        case '6': // Rejected -> Draft - work in progress
            return ['1'];
        default:
            return [];
    }
}