import React, { Fragment } from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import './../../../../scss/tag-view.scss';

const GenderTestTag = (props) => {
    return (
        <div
            key={props.fetchedPanels.Panel_code}
            className='location-tag'
            title='click to remove'
            onClick={()=>props.removeGenderTest(props.fetchedPanels)}
        >
           {props.fetchedPanels.Panel_code}
        </div>
    )
};

export default GenderTestTag