import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import SVG from 'react-inlinesvg';

import tooltip from './../../assets/images/tooltip-purple.svg';

import { generatePortalUrl } from './../../utils/constants';

class CantFindPanel extends Component {

    state = {
        toggled: false,
    }

    togglePopover = () => {
        const toggled = !this.state.toggled;
        this.setState({ toggled });

        if (toggled) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }
    }

    /**
     * Handle clicks outside the component.
     * Hide the popover when someone clicks outside of it.
     */
    handleOutsideClick = (e) => {
        // ignore clicks on the component itself
        if (!e.target.closest('.popover')) {
            this.togglePopover();
            document.removeEventListener('click', this.handleOutsideClick, false);
        }
    }

    render() {
        const toggled = this.state.toggled;

        return (
            <span className="popover__container">
                <div className={`popover popover--cant-find-panel bs-popover-left ${toggled ? 'show' : ''}`} role="tooltip" id="popover--cant-find-panel">
                    <div className="arrow"></div>
                    <h3 className="popover-header">Can’t find a panel?</h3>
                    <div className="popover-body">
                        <p>If you can’t find a panel on the list, it could mean:</p>

                        <ul>
                            <li>The panel can be run in the clinical trials lab but it has not been added to the portal</li>
                            <li>The panel cannot be run in this lab but can be sent to another Clinical Labs Laboratory. If so, please document with the lab how the specimen should be handled</li>
                            <li>The panel cannot be run at Clinical Labs at all and will need to be referred on another company. This will need documented handling process.</li>
                        </ul>
                        <p>Please confirm with lab managers how to handle this panel. Once confirmed, you will be able to <Link to={generatePortalUrl('analytes-and-panels')} target="_blank">add a panel</Link>.</p>
                    </div>
                </div>
                <a className="link" data-toggle="popover" aria-describedby="popover--cant-find-panel" onClick={this.togglePopover}>
                    <span>Can’t find a panel</span>
                    <SVG className="icon icon--tooltip" src={tooltip} />
                </a>
            </span>
        )
    }
}

export default CantFindPanel;