import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { LOGIN_URL } from './../../../utils/constants';

class ResetPasswordSuccess extends Component {
    render() {
        return (
            <div className="reset-password">
                <div className="unauthenticated-layout__subheading">
                    <h3>Your password has been reset</h3>
                </div>
                <p>Please sign in with your new password.</p>
                <Link to={LOGIN_URL} className="btn btn-primary btn-block">Sign in</Link>
            </div>
        )
    }
}

export default ResetPasswordSuccess;