import React, { Component, Fragment } from 'react';
import { PropTypes } from 'prop-types';

import Pagination from './../common/Pagination';
import Preloader from './../common/Preloader';
import ActionDropdown from './ActionDropdown';

import RemoveProtocolModal from './RemoveProtocolModal';

import { PROTOCOL_STATUS_MAPPING } from './../../utils/protocol-statuses';

import './../../scss/filter-table.scss';
import './../../scss/protocols.scss';


class ProtocolList extends Component {

  state = {
        modalIsOpen: false,
        indexToBeRemoved: null,
        Protocol_code: null,
    };

    openModal = (index,Protocol_code) => {
        this.setState({ 
            modalIsOpen: true,
            indexToBeRemoved: index,
            Protocol_code: Protocol_code,
        });
        //console.log(index);
    }

    closeModal = () => {
        this.setState({ 
            modalIsOpen: false,
            indexToBeRemoved: null,
        });
    }
   
  render() {

    const items = this.props.filteredProtocols;
    const isLoading = this.props.isLoading;

   
   
    return (
      <Fragment>
        <div className="protocol-list flex-table filter-table container">
          <div className="row list--heading">
            <div className="item item__no">Protocol No.</div>
            <div className="item item__no">Location</div>
            <div className="item item__name">Study Sponsor</div>
            <div className="item item__status">Status</div>
            <div className="item item__actions"></div>
          </div>
          {
            items && items.length ?
              this.props.pageOfItems
              .map((protocol, index) => {

                let locationVal = 'All'
                let _locations = []
                if(protocol.locations != null){
                  _locations = JSON.parse(protocol.locations)
                  if(_locations.length > 1){
                    locationVal = 'Multiple'
                  } else if(_locations.length == 1) {
                    locationVal = _locations[0]
                  }
                }
                return (
                  <div key={protocol.Protocol_code} className={`row list--item list--item-${index}`}>
                      <div className="item item__no">{protocol.Protocol_code}</div>
                      <div className="item item__no" title={_locations}>{locationVal}</div>
                      <div className="item item__name">{protocol.Study_sponser}</div>
                      <div className="item item__status">
                        <span className={`status-icon status--${protocol.Status_id}`}></span>
                        {PROTOCOL_STATUS_MAPPING[protocol.Status_id]}
                      </div>
                      <div className="item item__actions">
                        <ActionDropdown 
                            protocol={protocol} 
                            checkProtocolStatus={this.props.checkProtocolStatus}
                            deleteProtocol={this.props.deleteProtocol}

                            checkStatusExport={this.props.checkStatusExport}
                            exportProtocol={this.props.exportProtocol}
                            getQuote={this.props.getQuote}

                            openModal={this.openModal}
                            updateProtocolStatusAPI={this.props.updateProtocolStatusAPI}
                            
                        />
                      </div>
                     
                  </div>
                )
              }) :
              <div className="row list--item list--no-results">
                <div className="item">{isLoading ? 'Fetching protocols...' : 'No matching records found'}</div>
              </div>
          }
          {
            (isLoading) ? <Preloader /> : null
          }
        </div>

        <RemoveProtocolModal
                        removeProtocol={this.props.deleteProtocol}
                        closeModal={this.closeModal}
                        modalIsOpen={this.state.modalIsOpen}
                        indexToBeRemoved={this.state.indexToBeRemoved}
                        openModal={this.state.openModal}
                        Protocol_code={this.state.Protocol_code}
                    />

        <Pagination 
          items={this.props.filteredProtocols} 
          onChangePage={this.props.onChangePage}
          searchQuery={this.props.searchQuery}
          existingStatuses={this.props.existingStatuses}
        />
      </Fragment>
    )
  }
}

export default ProtocolList;

ProtocolList.propTypes = {
  filteredProtocols: PropTypes.array.isRequired,
  pageOfItems: PropTypes.array.isRequired,
  onChangePage: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  checkProtocolStatus: PropTypes.func.isRequired,
  deleteProtocol: PropTypes.func.isRequired,
  checkStatusExport: PropTypes.func.isRequired,
  exportProtocol: PropTypes.func.isRequired,
  getQuote: PropTypes.func.isRequired,
  updateProtocolStatusAPI: PropTypes.func.isRequired,
  searchQuery: PropTypes.string,
  existingStatuses: PropTypes.object,
};
