import React, { Component } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import './../../scss/pagination.scss';

const defaultProps = {
    initialPage: 1,
    pageSize: 20
}

class Pagination extends Component {

    state = {
        pager: {}
    };

    componentWillMount() {
        // set page if items array isn't empty
        if (this.props.items && this.props.items.length) {
            this.setPage(this.props.initialPage);
        }
    }

    componentDidUpdate(prevProps) {
        const initialPage = this.props.initialPage;
        
        // (For Protocol List and Analyte/Panel list)
        // If a search or status filter has updated, go back to Page 1.
        if (this.props.searchQuery && this.props.searchQuery !== prevProps.searchQuery) {
            // console.log('search changed');
            this.setPage(initialPage);
            return;
        }
        if (this.props.existingStatuses && !_.isEqual(this.props.existingStatuses, prevProps.existingStatuses)) {
            // console.log('filter changed');
            this.setPage(initialPage);
            return;
        }

        // For Kits List
        // If the statusCheckboxes or selectedDays has updated, go back to Page 1.
        if (this.props.statusCheckboxes && this.props.statusCheckboxes !== prevProps.statusCheckboxes) {
            // console.log('status checkboxes changed');
            this.setPage(initialPage);
            return;
        }
        if (this.props.selectedDayStart && !_.isEqual(this.props.selectedDayStart, prevProps.selectedDayStart)) {
            // console.log('selected days changed');
            this.setPage(initialPage);
            return;
        }
        if (this.props.selectedDayEnd && !_.isEqual(this.props.selectedDayEnd, prevProps.selectedDayEnd)) {
            // console.log('selected days changed');
            this.setPage(initialPage);
            return;
        }
        
        // reset page if items array has changed
        if (this.props.items !== prevProps.items) {
            // console.log(this.props.items);
            const pager = this.state.pager;

            // If the currentPage is not 1, keep it the same page.
            if (pager && pager.currentPage && pager.currentPage !== initialPage) {
                this.setPage(pager.currentPage);
                return;
            }
            // Else, reset to page 1 (or whatever the initial page is)
            this.setPage(initialPage);
        }
    }

    setPage = (page) => {
        var { items, pageSize } = this.props;
        var pager = this.state.pager;

        if (page < 1 || page > pager.totalPages) {
            return;
        }

        // get new pager object for specified page
        pager = this.getPager(items.length, page, pageSize);

        // get new page of items from items array
        var pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);

        // update state
        this.setState({ pager });

        // call change page function in parent component
        this.props.onChangePage(pageOfItems);
    }

    getPager = (totalItems, currentPage, pageSize) => {
        // default to first page
        currentPage = currentPage || 1;

        // default page size is 10
        pageSize = pageSize || 10;

        // calculate total pages
        var totalPages = Math.ceil(totalItems / pageSize);
        // Set totalPages to 1 even if there's no items. Otherwise the pagination breaks
        if (totalPages === 0) {
            totalPages = 1;
        }

        var startPage, endPage;
        if (totalPages <= 10) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = totalPages;
        } else {
            // more than 10 total pages so calculate start and end pages
            if (currentPage <= 6) {
                startPage = 1;
                endPage = 10;
            } else if (currentPage + 4 >= totalPages) {
                startPage = totalPages - 9;
                endPage = totalPages;
            } else {
                startPage = currentPage - 5;
                endPage = currentPage + 4;
            }
        }

        // calculate start and end item indexes
        var startIndex = (currentPage - 1) * pageSize;
        var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        var pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);

        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }

    render() {
        var pager = this.state.pager;

        if (!pager.pages || pager.pages.length <= 1) {
            // don't display pager if there is only 1 page
            return null;
        }

        return (
            <ul className="pagination">
                <li className={`page-item ${pager.currentPage === 1 ? 'disabled' : ''}`}>
                    <a className="page-link" onClick={() => this.setPage(1)}>First</a>
                </li>
                <li className={`page-item ${pager.currentPage === 1 ? 'disabled' : ''}`}>
                    <a className="page-link" onClick={() => this.setPage(pager.currentPage - 1)}>Previous</a>
                </li>
                {pager.pages.map((page, index) =>
                    <li key={index} className={`page-item ${pager.currentPage === page ? 'active' : ''}`}>
                        <a className="page-link" onClick={() => this.setPage(page)}>{page}</a>
                    </li>
                )}
                <li className={`page-item ${pager.currentPage === pager.totalPages ? 'disabled' : ''}`}>
                    <a className="page-link" onClick={() => this.setPage(pager.currentPage + 1)}>Next</a>
                </li>
                <li className={`page-item ${pager.currentPage === pager.totalPages ? 'disabled' : ''}`}>
                    <a className="page-link" onClick={() => this.setPage(pager.totalPages)}>Last</a>
                </li>
            </ul>
        );
    }
}

Pagination.propTypes = {
    items: PropTypes.array.isRequired,
    onChangePage: PropTypes.func.isRequired,
    initialPage: PropTypes.number,
    pageSize: PropTypes.number,
    searchQuery: PropTypes.any,
    existingStatuses: PropTypes.any,
    statusCheckboxes: PropTypes.any,
    selectedDayStart: PropTypes.any,
    selectedDayEnd: PropTypes.any,
};

Pagination.defaultProps = defaultProps;

export default Pagination;