import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import AnalytePanelListContainer from './AnalytePanelListContainer';
import AddEditPanelContainer from './AddEditPanel/AddEditPanelContainer';
import MBSCodesContainer from './MBSCodes/MBSCodesContainer';
import { resetFilters } from '../../utils/constants';

class AnalytePanelManagement extends Component {
    render() {
        resetFilters();
        return (
            <Switch>
                <Route exact path={this.props.match.path}
                    render={(props) => <AnalytePanelListContainer {...props} username={this.props.username} />}
                />
                <Route path={`${this.props.match.path}/panels/add`} 
                    render={(props) => <AddEditPanelContainer {...props} username={this.props.username} />}
                />
                <Route path={`${this.props.match.path}/panels/edit/:panelId`} 
                    render={(props) => <AddEditPanelContainer {...props} username={this.props.username} />}
                />
                <Route path={`${this.props.match.path}/mbs-codes`} 
                    render={(props) => <MBSCodesContainer {...props} username={this.props.username} />}
                />
            </Switch>
        )
    }
}

export default AnalytePanelManagement;

AnalytePanelManagement.propTypes = {
    username: PropTypes.string,
    match: PropTypes.shape({
        path: PropTypes.string,
    }),
};