import React, { Component } from 'react'; 
import { PropTypes } from 'prop-types';
import Modal from 'react-modal';

import SVG from 'react-inlinesvg';
import close from './../../../../assets/images/close.svg';

Modal.setAppElement('#root');
class RemoveCohortModal extends Component {

    handleRemoveCohort = (index) => {
        this.props.removeCohort(index);
        this.props.closeModal();
    }

    render() {
        const { modalIsOpen, closeModal, indexToBeRemoved } = this.props;
        return (
            <Modal
                className="modal__bootstrap modal--cancel-protocol-overview modal-dialog"
                overlayClassName="overlay"
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title">Are you sure you want to delete this cohort?</h3>
                        <button type="button" className="close" onClick={closeModal}>
                            <span aria-hidden="true">
                                <SVG className="icon icon--close" src={close} />
                            </span>
                            <span className="sr-only"></span>
                        </button>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={closeModal}>Go back</button>
                        <button type="button" className="btn btn-primary" onClick={() => this.handleRemoveCohort(indexToBeRemoved)}>Delete cohort</button>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default RemoveCohortModal;

RemoveCohortModal.propTypes = {
    removeCohort: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    modalIsOpen: PropTypes.bool.isRequired,
    indexToBeRemoved: PropTypes.number,
};