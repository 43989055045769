import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import Modal from 'react-modal';

import SVG from 'react-inlinesvg';
import close from './../../../src/assets/images/close.svg';

Modal.setAppElement('#root');
class ConfirmationDialog extends Component {

    customStyles = {
        overlay: {zIndex: 1000}
    };
      
    handleConfirm = () => {
        
        this.props.closeModal();
        this.props.yesCallBack(this.props._tube, this.props._quantity);
    }

    renderHeading = () => {
        const { headingText } = this.props;
        return (
            headingText
        )
    }

    renderBody = () => {
        const { confirmationMessage } = this.props;
       
        return (
            <React.Fragment>
                <p>{confirmationMessage}</p>
            </React.Fragment>
        )
    }

    render() {
        // console.log('render cslled')
        return (
            <Modal

                className="modal__bootstrap modal--update-protocol-status modal-dialog"
                style={this.customStyles}
                overlayClassName="overlay"
                isOpen={this.props.modalIsOpen}
                onRequestClose={this.props.closeModal}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title">{this.renderHeading()}</h3>
                        <button type="button" className="close" onClick={this.props.closeModal}>
                            <span aria-hidden="true">
                                <SVG className="icon icon--close" src={close} />
                            </span>
                            <span className="sr-only"></span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {this.renderBody()}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={this.props.closeModal}>{this.props.noButtonText}</button>
                        <button type="button" className="btn btn-primary" onClick={this.handleConfirm}>{this.props.yesButtonText}</button>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default ConfirmationDialog;

// ConfirmationDialog.propTypes = {
//     closeModal: PropTypes.func.isRequired,
//     modalIsOpen: PropTypes.bool.isRequired,
//     selectedStatus: PropTypes.string.isRequired,
//     updateProtocolStatusAPI: PropTypes.func.isRequired,
//     fromProtocolList: PropTypes.bool,
//     protocol: PropTypes.object.isRequired,
// };