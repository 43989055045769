import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import KitsListContainer from './KitsListContainer';
import KitDetails from './KitDetails/KitDetails';

class KitsManagement extends Component {
    render() {
        return (
            <Switch>
                <Route exact path={this.props.match.path}
                    render={(props) => <KitsListContainer {...props} username={this.props.username} />}
                />
                <Route exact path={`${this.props.match.path}/lowstock/`}
                    render={(props) => <KitsListContainer {...props} username={this.props.username} kittype="lowstock" />}
                />
                <Route exact path={`${this.props.match.path}/expiringkits/`}
                    render={(props) => <KitsListContainer {...props} username={this.props.username} kittype="expiringkits" />}
                />
                <Route exact path={`${this.props.match.path}/episode/`}
                    render={(props) => <KitsListContainer {...props} username={this.props.username} kittype="episode" />}
                />
                {/* Error might be here */}
                <Route path={`${this.props.match.path}/:kitType/:kitCode`}
                    render={(props) => <KitDetails {...props} username={this.props.username} />}
                />
            </Switch>
        )
    }
}

export default KitsManagement;

KitsManagement.propTypes = {
    username: PropTypes.string,
    match: PropTypes.shape({
        path: PropTypes.string,
    }),
};