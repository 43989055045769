import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AddEditPanelAnalyteItem extends Component {
    render() {

        const { 
            analyte, 
            index, 
            isLive,
            previousAnalytes,
            handleRemoveAnalyte,
        } = this.props;

        const isPrevious = previousAnalytes.find(existingAnalyte => existingAnalyte === analyte);
        // console.log(isPrevious);

        return (
            <div className="analyte-item" key={index}>
                <div className="row">
                    <div className="col analyte--name">{analyte}</div>
                    {
                        isLive && (isPrevious !== undefined) ?
                        null
                        :
                        <div className="col analyte--remove">
                            <a onClick={() => handleRemoveAnalyte(index)} className="link">Remove</a>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default AddEditPanelAnalyteItem;

AddEditPanelAnalyteItem.propTypes = {
    analyte: PropTypes.string,
    index: PropTypes.number.isRequired,
    isLive: PropTypes.bool.isRequired,
    previousAnalytes: PropTypes.array.isRequired,
    handleRemoveAnalyte: PropTypes.func.isRequired,
};