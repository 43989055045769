import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import moment from 'moment';

import '../../../../scss/lib/react-day-picker/style.css';
import '../../../../scss/react-day-picker.scss';

import { formatDate, parseDate } from 'react-day-picker/moment';
import { dateFormat } from '../../../../utils/constants';

class ScreeningComencementDate extends Component {

    screeningComencementDateInput = React.createRef();

    handleDayChange = (day) => {
        const name = this.props.name;
        const index = this.props.index;
        let value = '';

        if (day === undefined) {
            const input = this.screeningComencementDateInput;
            value = input.current.value;
        } else {
            value = moment(day).format(dateFormat);
        }

        this.props.updateCohort(index, name, value);
    }

    render() {
        const values = this.props.values;
        const errors = this.props.errors;
        const name = this.props.name;

        const inputProps = {
            className: `form-control form-control--datepicker ${errors.Screening_date ? 'has-error' : ''}`,
            name: name,
            ref: this.screeningComencementDateInput,
            readOnly: this.props.disabled,
            disabled: this.props.disabled
        }
        // console.log('commencement date:', values.Screening_commence_date)
        return (
            <DayPickerInput 
            
                value={values.Screening_commence_date == null ? '' : values.Screening_commence_date}
                onDayChange={this.handleDayChange}
                placeholder={dateFormat}
                inputProps={inputProps}
                format={dateFormat}
                formatDate={formatDate}
                parseDate={parseDate}
            />
        )
    }
}

export default ScreeningComencementDate;

ScreeningComencementDate.propTypes = {
    name: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    updateCohort: PropTypes.func.isRequired,
    values: PropTypes.shape({
        Screening_commence_date: PropTypes.any,
    }).isRequired,
    errors: PropTypes.shape({
        Screening_commence_date: PropTypes.bool,
    }).isRequired,
    disabled: PropTypes.bool
};

ScreeningComencementDate.defaultProps = {
    disabled: false

}