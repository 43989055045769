import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SVG from 'react-inlinesvg';
import increment from './../../assets/images/increment.svg';
import decrement from './../../assets/images/decrement.svg';

import './../../scss/number-incrementer.scss';

class NumberIncrementer extends Component {

    render() {
        const {
            updateNumber,
            value,
            min,
            max,
            onChange,
            error,
        } = this.props;
        
        return (
            <div
                className={`number-incrementer form-control ${error.packed ? 'has-error' : ''}`}
            >
                <button
                    onClick={() => updateNumber('DECREMENT')}
                    className="invisible-input number__decrement"
                    disabled={value <= min}
                >
                    <SVG className="icon icon--decrement" src={decrement} />
                </button>
                <input
                    type="number"
                    name="packed"
                    className="invisible-input"
                    min={min}
                    max={max}
                    value={value}
                    onChange={onChange}
                />
                <button
                    onClick={() => updateNumber('INCREMENT')}
                    className="invisible-input number__increment"
                    disabled={value >= max}
                >
                    <SVG className="icon icon--increment" src={increment} />
                </button>
            </div>
        )
    }
}

export default NumberIncrementer;

NumberIncrementer.propTypes = {
    updateNumber: PropTypes.func.isRequired,
    value: PropTypes.any,
    min: PropTypes.any,
    max: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.bool.isRequired,
};