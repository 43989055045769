import React, { Component } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import Header from './Header/Header';
import HeaderNav from './Header/HeaderNav';
import './../scss/App.scss';
import ReactGA from 'react-ga';
import { Auth } from 'aws-amplify';

import AuthenticatedRoute from './AuthenticatedRoute';
import UnauthenticatedRoute from './UnauthenticatedRoute';

import { PORTAL_URL, LOGIN_URL } from './../utils/constants';

class App extends Component {

  constructor(props) {
    super(props);
    
    ReactGA.initialize('UA-115155772-6');

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      username: '',
    };
  }

  async componentDidMount() {
    // Check if there's a current session / user is logged in
    try {
      if (await Auth.currentSession()) {
        // If there's a current session, set isAuthenticated to true
        this.setState({ isAuthenticated: true });

        // And set the username to the state.
        Auth.currentUserInfo().then(result => {
          const username = result.attributes.email;
          this.setState({ username });
        });
      }
    }
    catch(error) {
      console.log(error);
    }

    // Authentication check has finished after the await. Set isAuthenticating flag to false.
    this.setState({ 
      isAuthenticating: false, 
      username: '',
    });
  }
  
  /**
   * Update whether user is authenticated or not
   */
  updateAuthState = (authenticated) => {
    if (authenticated) {
      Auth.currentUserInfo().then(result => {
        const username = result.attributes.email;
        this.setState({ 
          isAuthenticated: authenticated, 
          username 
        });
      });
    } else {
      this.setState({ 
        isAuthenticated: authenticated, 
        username: '' 
      });
    }
  }
  
  render() {
    return (
      // Only render when authentication check is done.
      !this.state.isAuthenticating &&
      <div className="App">
        <Header isAuthenticated={this.state.isAuthenticated} updateAuthState={this.updateAuthState}
                username={this.state.username} 
         />

        { this.state.isAuthenticated ? <HeaderNav /> : null }

        <Switch>
          <UnauthenticatedRoute 
            path={LOGIN_URL}
            isAuthenticated={this.state.isAuthenticated} 
            updateAuthState={this.updateAuthState}
          />

          <AuthenticatedRoute 
            path={PORTAL_URL}
            isAuthenticated={this.state.isAuthenticated}
            username={this.state.username}
          />

          {/* 
            Redirect rules for pages that don't exist:
            If logged in: Protocols page
            If not logged in: Login page
          */}
          { this.state.isAuthenticated ? <Redirect to={PORTAL_URL} /> : <Redirect to={LOGIN_URL} /> }

        </Switch>
      </div>
    );
  }
}

export default App;
