import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

import MultistepFormProgress from './MultistepFormProgress';
import ProtocolOverviewContainer from './ProtocolOverview/ProtocolOverviewContainer';
import ScreeningContainer from './Screening/ScreeningContainer';
import CohortsContainer from './Cohorts/CohortsContainer';
import ReviewAndExport from './ReviewAndExport/ReviewAndExport';

class MultistepForm extends Component {

    state = {
        done: {
            1: false,
            2: false,
            3: false,
            4: false,
        },
        currentStep: 1,
    }

    updateStepDone = (stepNumber, newCurrentStep) => {
        const done = this.state.done;

        this.setState({
            done: {
                ...done,
                [stepNumber]: true,
            },
            currentStep: newCurrentStep,
        });
    }

    updateCurrentStep = (newCurrentStep) => {
        this.setState({
            currentStep: newCurrentStep,
        });
    }

    renderForm = () => {
        const currentStep = this.state.currentStep;

        switch (currentStep) {
            case 1: 
                return <ProtocolOverviewContainer
                            existingProtocolNos={this.props.existingProtocolNos}
                            updateStepDone={this.updateStepDone}
                            updateCurrentStep={this.updateCurrentStep}
                            updateProtocolSummary={this.props.updateProtocolSummary}
                            updateProtocolId={this.props.updateProtocolId}
                            protocolId={this.props.protocolId}
                            username={this.props.username}
                            updateIsPageLoading={this.props.updateIsPageLoading}
                            isEditFromReviewScreen={this.props.isEditFromReviewScreen}
                            updateIsEditFromReviewScreen={this.props.updateIsEditFromReviewScreen}
                            updateScreeningId={this.props.updateScreeningId}
                            screeningId = {this.props.screeningId}
                        />;
            case 2:
                return <ScreeningContainer
                            updateStepDone={this.updateStepDone}
                            updateCurrentStep={this.updateCurrentStep}
                            protocolSummary={this.props.protocolSummary}
                            username={this.props.username}
                            updateProtocolId={this.props.updateProtocolId}
                            protocolId={this.props.protocolId}
                            updateScreeningId={this.props.updateScreeningId}
                            screeningId = {this.props.screeningId}
                            isEditFromReviewScreen={this.props.isEditFromReviewScreen}
                            updateIsEditFromReviewScreen={this.props.updateIsEditFromReviewScreen}
                        />;
            case 3:
                return <CohortsContainer
                            updateStepDone={this.updateStepDone}
                            updateCurrentStep={this.updateCurrentStep}
                            protocolSummary={this.props.protocolSummary}
                            username={this.props.username}
                            updateProtocolId={this.props.updateProtocolId}
                            protocolId={this.props.protocolId}
                            updateScreeningId={this.props.updateScreeningId}
                            screeningId={this.props.screeningId}
                            episodeFromReviewScreen={this.props.episodeFromReviewScreen}
                            episodeFromReviewScreenIndex={this.props.episodeFromReviewScreenIndex}
                            updateEditEpisodeFromReviewScreen={this.props.updateEditEpisodeFromReviewScreen}
                            isEditFromReviewScreen={this.props.isEditFromReviewScreen}
                            updateIsEditFromReviewScreen={this.props.updateIsEditFromReviewScreen}
                        />;
            case 4:
                return <ReviewAndExport
                            username={this.props.username}
                            protocolId={this.props.protocolId}
                            updateIsPageLoading={this.updateIsPageLoading}
                            updateCurrentStep={this.updateCurrentStep}
                            updateEditEpisodeFromReviewScreen={this.props.updateEditEpisodeFromReviewScreen}
                            updateIsEditFromReviewScreen={this.props.updateIsEditFromReviewScreen}
                        />;
            default:
                return null;
        }
    }

    render() {
        return (
            <div>
                <MultistepFormProgress 
                    protocolId={this.props.protocolId}
                    screeningId={this.props.screeningId}
                    screeningDone={this.props.screeningDone}
                    cohortsDone={this.props.cohortsDone}
                    currentStep={this.state.currentStep} 
                    done={this.state.done}
                />
                { this.renderForm() }
            </div>
        )
    }
}

export default MultistepForm;

MultistepForm.propTypes = {
    existingProtocolNos: PropTypes.array,
    updateProtocolSummary: PropTypes.func.isRequired,
    updateProtocolId: PropTypes.func.isRequired,
    protocolId: PropTypes.number,
    username: PropTypes.string,
    protocolSummary: PropTypes.shape({
        protocolNo: PropTypes.string,
        targetNoOfOnStudyParticipants: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        targetNoOfParticipantsToBeScreened: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
    }),
    updateScreeningId: PropTypes.func.isRequired,
    screeningId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    screeningDone: PropTypes.bool.isRequired,
    cohortsDone: PropTypes.bool.isRequired,
    updateIsPageLoading: PropTypes.func.isRequired,
    isEditFromReviewScreen: PropTypes.bool.isRequired,
    updateIsEditFromReviewScreen: PropTypes.func.isRequired,
    episodeFromReviewScreen: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    episodeFromReviewScreenIndex: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    updateEditEpisodeFromReviewScreen: PropTypes.func.isRequired,
};