import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

import FirstDosingDateInput from './FirstDosingDateInput';
import ScreeningComencementDate from './ScreeningComencementDate';
import ActionDropdown from './ActionDropdown';
import Checkbox from '../../../common/Checkbox';

class CohortItem extends Component {

    constructor(props){
        super(props)
        this.state = {
            screeningRequired: false
        }
    }
    handleInputChange = (e) => {
        const target = e.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const index = this.props.index;

        this.props.updateCohort(index, name, value);
    }
    render() {
        const index = this.props.index;
        const values = this.props.values;
        const errors = this.props.errors;
        const { errorTargetNoOfOnStudyParticipantsSum } = this.props;
        const { errorTargetNoOfScreeningParticipantsSum } = this.props;
        const cohortsLength = this.props.cohortsLength;
        const handleOpenEpisodes = this.props.handleOpenEpisodes;


        this.screeningCount = this.props.Screening_skipped ? 0 : 1;
        return (
            <div className="cohorts-item flex-table-form-item row list--item" key={index}>
                <div className="item item__cohort-no">
                    {/* (Cohort name) */}
                    <input 
                        type="text" 
                        name="Cohort_No" 
                        maxLength="50"
                        className={`form-control cohort-no ${errors.Cohort_No ? 'has-error': ''}`}
                        value={values.Cohort_No}
                        onChange={this.handleInputChange}
                    />

                </div>
                <div className="item item__target-no-of-on-study-participants">
                    <input 
                        type="text" 
                        name="Study_participants" 
                        maxLength="3"
                        className={`form-control form-control--cohorts-small ${(errors.Study_participants || errorTargetNoOfOnStudyParticipantsSum) ? 'has-error' : ''}`}
                        value={values.Study_participants}
                        onChange={this.handleInputChange}
                    />
                </div>
                <div className="item item__target-no-of-day-minus-one-participants">
                    <input 
                        type="text" 
                        name="Day1_participants" 
                        maxLength="3"
                        className={`form-control form-control--cohorts-small ${errors.Day1_participants ? 'has-error' : ''}`}
                        value={values.Day1_participants}
                        onChange={this.handleInputChange}
                    />
                </div>
                <div className="item item__required">
                    <label htmlFor={`Screening_required${index}`} className="checkbox__container">
                        <input
                            type="checkbox"
                            id={`Screening_required${index}`}
                            name="Screening_required" 
                            className="form-check-input"
                            checked={values.Screening_required}
                            disabled = {this.props.Screening_skipped}
                            onChange={this.handleInputChange}
                        />
                        <span className="form-check-checkmark"></span>
                    </label>
                </div>


                <div className="item item__target-no-of-day-minus-one-participants">
                    <input 
                        type="text" 
                        name="Target_participants_to_screen" 
                        maxLength="3"
                        disabled = {this.props.Screening_skipped || !values.Screening_required}
                        className={`form-control form-control--cohorts-small ${errors.Screening_participants || (errorTargetNoOfScreeningParticipantsSum && values.Screening_required) ? 'has-error' : ''}`}
                        value={values.Target_participants_to_screen == null ? '' : values.Target_participants_to_screen}
                        onChange={this.handleInputChange}
                    />
                </div>
                <div className="item item__first-dosing-date">
                    <ScreeningComencementDate
                        errors={errors}
                        values={values}
                        index={index}
                        disabled = {this.props.Screening_skipped || !values.Screening_required}
                        updateCohort={this.props.updateCohort}
                        name="Screening_commence_date"
                    />
                </div>
                <div className="item item__first-dosing-date">
                    <FirstDosingDateInput
                        errors={errors}
                        values={values}
                        index={index}
                        updateCohort={this.props.updateCohort}
                        name="First_dosing_date"
                    />
                </div>
                <div className="item item__episodes">
                    {/* <div className="input-group">
                        <input
                            type="text"
                            name="episodes"
                            className="form-control"
                            value={values.episodes}
                            onChange={this.handleInputChange}
                        />
                        <div className="input-group-append">
                            <button className="btn btn-secondary" type="button">View details</button>
                        </div>
                    </div> */}

                    <button onClick={() => handleOpenEpisodes(values, index)} className="btn btn-secondary btn--with-sections btn--episodes">
                        {/* Count Screening as part of the Episode count (+1) */}
                        <span className="btn__section">{values.Episodes + this.screeningCount}</span>
                        <span className="btn__section">View details</span>
                    </button>
                </div>
                <div className="item item__required">
                    <label htmlFor={`Required${index}`} className="checkbox__container">
                        <input
                            type="checkbox"
                            id={`Required${index}`}
                            name="Required" 
                            className="form-check-input"
                            checked={values.Required}
                            onChange={this.handleInputChange}
                        />
                        <span className="form-check-checkmark"></span>
                    </label>
                </div>
                <div className="item item__actions">
                    <ActionDropdown
                        values={values}
                        cohortsLength={cohortsLength}
                        removeCohort={this.props.removeCohort}
                        handleDuplicateCohort={this.props.handleDuplicateCohort}
                        openModal={this.props.openModal}
                        openAlternativeCohortNameModal={this.props.openAlternativeCohortNameModal}
                        index={index}
                    />
                </div>
            </div>
        )
    }
}

export default CohortItem;

CohortItem.propTypes = {
    index: PropTypes.number.isRequired,
    values: PropTypes.shape({
        Cohort_No: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
        Study_participants: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
        Day1_participants: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
        First_dosing_date: PropTypes.string.isRequired,
        Episodes: PropTypes.number,
        Required: PropTypes.bool.isRequired,
    }).isRequired,
    errors: PropTypes.object.isRequired,
    cohortsLength: PropTypes.number.isRequired,
    updateCohort: PropTypes.func.isRequired,
    removeCohort: PropTypes.func.isRequired,
    handleDuplicateCohort: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    openAlternativeCohortNameModal: PropTypes.func.isRequired,
    errorTargetNoOfOnStudyParticipantsSum: PropTypes.bool,
    errorTargetNoOfScreeningParticipantsSum: PropTypes.bool,
    handleOpenEpisodes: PropTypes.func.isRequired,
    Screening_skipped: PropTypes.bool,
    Screening_required: PropTypes.bool,
};
CohortItem.defaultProps = {

    Screening_required: false,
}

