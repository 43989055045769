import React, { Component } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import AddEditPanelAnalyteItem from './AddEditPanelAnalyteItem';

class AddEditPanelAnalytes extends Component {
    render() {

        const { 
            analytes,
            previousAnalytes,
            values,
            errors,
            isLive,
            handleChangeForAnalyte,
            handleAddAnalyte,
            handleRemoveAnalyte,
        } = this.props;
        
        // Check if the input value is valid
        const validAnalyte = _.trim(values.analyte) === '' ? false : true;

        return (
            <form className="add-edit-panel__analytes" onSubmit={handleAddAnalyte}>
                <div className="small-heading">
                    Analytes
                </div>
                <div className="analytes-list">
                    {
                        analytes && analytes.length ?
                            analytes.map((analyte, index) => {
                                return (
                                    <AddEditPanelAnalyteItem
                                        analyte={analyte}
                                        previousAnalytes={previousAnalytes}
                                        index={index}
                                        isLive={isLive}
                                        key={index}
                                        handleRemoveAnalyte={handleRemoveAnalyte}
                                    />
                                )
                            })
                            : null
                    }
                </div>
                <div className="form-row analyte-input">
                    <div className={`form-group col-md-10 ${(errors.analytes || errors.form) ? 'has-error' : ''}`}>
                        <input type="text" name="analyte" id="analyte" className="form-control" placeholder="Enter an analyte name" value={values.analyte} maxLength="100" onChange={handleChangeForAnalyte} autoComplete="off" />
                        {errors.analytes && <small id="analyteHelp" className="form-text">{errors.analytes}</small>}
                    </div>

                    <div className={`form-group col-md-2 ${(errors.analytes || errors.form) ? 'has-error' : ''}`}>
                        <button
                            className="btn btn-block btn-primary btn--add-analyte"
                            disabled={!validAnalyte}
                            type="submit"
                        >
                            Add
                            </button>
                    </div>
                </div>
            </form>
        )
    }
}

export default AddEditPanelAnalytes;

AddEditPanelAnalytes.propTypes = {
    analytes: PropTypes.array.isRequired,
    previousAnalytes: PropTypes.array.isRequired,
    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    isLive: PropTypes.bool.isRequired,
    handleChangeForAnalyte: PropTypes.func.isRequired,
    handleAddAnalyte: PropTypes.func.isRequired,
    handleRemoveAnalyte: PropTypes.func.isRequired,
};