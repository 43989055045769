import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

import SVG from 'react-inlinesvg';

import dots from '../../../assets/images/dots.svg';

import '../../../scss/action-dropdown.scss';

class PackingDetailsActionDropdown extends Component {

    state = {
        toggled: false,
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleOutsideClick, false);
    }

    /**
     * Handle clicks outside the component.
     * Hide the dropdown when someone clicks outside of it.
     */
    handleOutsideClick = (e) => {
        // ignore clicks on the component itself
        if (!e.target.closest('.action-dropdown')) {
            this.hideDropdown();
            document.removeEventListener('click', this.handleOutsideClick, false);
        }
    }
    
    toggleDropdown = () => {
        const toggled = !this.state.toggled;
        this.setState({ toggled });
        //console.log('Toggle the dropdown!');
        
        if (toggled) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }
    }
    
    hideDropdown = () => {
        const toggled = false;
        this.setState({ toggled });
    }


    render() {
        const toggled = this.state.toggled;
        
        return (
            <div className="action-dropdown__container">
                <button className="btn-action" onClick={() => this.toggleDropdown()} style={{float:'right'}}>
                    <SVG className="icon icon--dots" src={dots} />
                </button>
                <div className={`action-dropdown dropdown-menu ${toggled ? 'show' : ''}`}>
                    <a className="dropdown-item" onClick={() => this.props.handleEdit()}>Edit</a>
                    <a className="dropdown-item" onClick={() => {
                        this.toggleDropdown()
                        this.props.handleDone()
                    }}>Save</a>
                    
                </div>
            </div>
        )
    }
}

export default PackingDetailsActionDropdown;

PackingDetailsActionDropdown.propTypes = {
    handleEdit: PropTypes.func.isRequired,
    handleDone: PropTypes.func.isRequired,
}