import React, { Component } from 'react';

import ResetPasswordContainer from './ResetPasswordContainer';
import ResetPasswordConfirmContainer from './ResetPasswordConfirmContainer';
import ResetPasswordSuccess from './ResetPasswordSuccess';

class ResetPasswordManager extends Component {

    state = {
        confirmationCodeGenerated: false,
        resetPasswordSuccess: false,
        email: '',
    }

    updateConfirmationCodeGenerated = (boolean) => {
        this.setState({ confirmationCodeGenerated: boolean });
    }

    updateResetPasswordSuccess = (boolean) => {
        this.setState({ resetPasswordSuccess: boolean });
    }

    updateEmail = (email) => {
        this.setState({ email });
    }

    render() {
        const { confirmationCodeGenerated, resetPasswordSuccess } = this.state;

        if (confirmationCodeGenerated && resetPasswordSuccess) {
            return (
                <div className="reset-password__manager">
                    <ResetPasswordSuccess />
                </div>
            )
        }

        if (confirmationCodeGenerated && !resetPasswordSuccess) {
            return (
                <div className="reset-password__manager">
                    <ResetPasswordConfirmContainer 
                        email={this.state.email} 
                        updateResetPasswordSuccess={this.updateResetPasswordSuccess}
                    />
                </div>
            )
        }

        return (
            <div className="reset-password__manager">
                <ResetPasswordContainer 
                    updateConfirmationCodeGenerated={this.updateConfirmationCodeGenerated} 
                    updateEmail={this.updateEmail} 
                />
            </div>
        )
    }
}

export default ResetPasswordManager;