import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CancelProtocol from './CancelProtocol';
import Preloader from './../../../common/Preloader';
import ProtocolLocation from './ProtocolLocation';
import GenderTestOverride from './GenderTestOverride';

class ProtocolOverviewForm extends Component {

    render() {
        const {
            values,
            errors,
            validateForm,
            handleSubmit,
            handleChange,
            handleBlurForProtocolNo,
            isEdit,
            isSubmitting,
            isEditFromReviewScreen,
            locations,
            selectedLocations,
            addLocation,
            removeLocation,
            // Task-2
            fetchedPanels,
            selectedGenderTest,
            addGenderTest,
            removeGenderTest,
        } = this.props;

        return (
            <form onSubmit={handleSubmit}>

                <div className={`form-group ${(errors.protocol_no || errors.form) ? 'has-error' : ''}`}>
                    <label htmlFor="protocol_no">Protocol number</label>
                    <input type="text" name="protocol_no" id="protocol_no" maxLength="25" className="form-control" value={values.protocol_no} onChange={handleChange} onBlur={handleBlurForProtocolNo} />
                    {errors.protocol_no && <small id="protocol_noHelp" className="form-text">{errors.protocol_no}</small>}
                </div>

                <div className={`form-group ${(errors.protocol_name || errors.form) ? 'has-error' : ''}`}>
                    <label htmlFor="protocol_name">Protocol name</label>
                    <textarea name="protocol_name" id="protocol_name" maxLength="250" className="form-control" value={values.protocol_name} onChange={handleChange}></textarea>
                    {errors.protocol_name && <small id="protocol_nameHelp" className="form-text">{errors.protocol_name}</small>}
                </div>

                <div className={`form-group ${(errors.protocol_name || errors.form) ? 'has-error' : ''}`}>
                    <label htmlFor="protocol_name">Protocol location</label>
                    <ProtocolLocation
                        locations={locations}
                        selectedLocations={selectedLocations}
                        addLocation={addLocation}
                        removeLocation={removeLocation}
                    />
                    {errors.protocol_name && <small id="protocol_nameHelp" className="form-text">{errors.protocol_name}</small>}
                </div>

                <div className="form-row">
                    <div className={`form-group col-md-6 ${(errors.study_sponsor || errors.form) ? 'has-error' : ''}`}>
                        <label htmlFor="study_sponsor">Study sponsor</label>
                        <input type="text" name="study_sponsor" id="study_sponsor" maxLength="50" className="form-control" value={values.study_sponsor} onChange={handleChange} />
                        {errors.study_sponsor && <small id="study_sponsorHelp" className="form-text">{errors.study_sponsor}</small>}
                    </div>

                    <div className={`form-group col-md-6 ${(errors.coordinator || errors.form) ? 'has-error' : ''}`}>
                        <label htmlFor="coordinator">Clinical Research Coordinator</label>
                        <input type="text" name="coordinator" id="coordinator" className="form-control" value={values.coordinator} onChange={handleChange} />
                        {errors.coordinator && <small id="coordinatorHelp" className="form-text">{errors.coordinator}</small>}
                    </div>
                </div>
                <div className="form-row">
                    <div className={`form-group col-md-6 ${(errors.copy_doctor || errors.form) ? 'has-error' : ''}`}>
                        <label htmlFor="copy_doctor">Copy Doctor</label>
                        <input placeholder="Enter copy doctor name" type="text" name="copy_doctor" id="copy_doctor" maxLength="50" className="form-control" value={values.copy_doctor} onChange={handleChange} />
                        {errors.copy_doctor && <small id="copy_doctorHelp" className="form-text">{errors.copy_doctor}</small>}
                    </div>
                    <div className={`form-group col-md-6 ${(errors.copy_doctor || errors.form) ? 'has-error' : ''}`}>
                        <label htmlFor="copy_doctor">Ultra Code</label>
                        <input placeholder="Enter ultra code" type="text" name="ultra_code" id="ultra_code" maxLength="50" className="form-control" value={values.ultra_code} onChange={handleChange} />
                        {errors.ultra_code && <small id="ultra_codeHelp" className="form-text">{errors.ultra_code}</small>}
                    </div>
                </div>

                <div className="form-row">
                    <div className={`form-group col-md-6 ${(errors.participants || errors.form) ? 'has-error' : ''}`}>
                        <label htmlFor="participants">Target number of on-study participants</label>
                        <input type="text" name="participants" id="participants" className="form-control" value={values.participants} onChange={handleChange} />
                        {errors.participants && <small id="participantsHelp" className="form-text">{errors.participants}</small>}
                    </div>

                    <div className={`form-group col-md-6 ${(errors.target_participants || errors.form) ? 'has-error' : ''}`}>
                        <label htmlFor="target_participants">Target number of participants to be screened</label>
                        <input type="text" name="target_participants" id="target_participants" className="form-control" value={values.target_participants} onChange={handleChange} />
                        {errors.target_participants && <small id="target_participantsHelp" className="form-text">{errors.target_participants}</small>}
                    </div>
                </div>
                {/* Task-2 */}
                <div className={`form-group ${(errors.protocol_name || errors.form) ? 'has-error' : ''}`}>
                    <label htmlFor="protocol_name">Gender test override</label>
                    <GenderTestOverride
                        fetchedPanels={fetchedPanels}
                        selectedGenderTest={selectedGenderTest}
                        addGenderTest={addGenderTest}
                        removeGenderTest={removeGenderTest}
                    />
                    {errors.protocol_name && <small id="protocol_nameHelp" className="form-text">{errors.protocol_name}</small>}
                </div>

                <div className="form-btn-container btn-container">
                    <div className="row">
                        <div className="col">
                            {
                                // Hide the Cancel button if user is coming from Review and export screen
                                isEditFromReviewScreen ? null :
                                    <CancelProtocol />
                            }
                        </div>
                        <div className="col text-right">
                            <button
                                className="btn btn-primary btn--next"
                                onClick={validateForm}
                                disabled={isSubmitting}
                            >

                                {isSubmitting ?
                                    <span className="spinner__wrapper"><span className="loading-spinner"></span></span> :
                                    isEditFromReviewScreen ? 'Confirm changes' : 'Next'
                                }
                            </button>
                        </div>
                    </div>
                </div>

                {
                    (isSubmitting) ?
                        <Preloader />
                        : null
                }

            </form>
        )
    }
}

export default ProtocolOverviewForm;

ProtocolOverviewForm.propTypes = {
    values: PropTypes.shape({
        protocol_no: PropTypes.string.isRequired,
        protocol_name: PropTypes.string.isRequired,
        study_sponsor: PropTypes.string.isRequired,
        coordinator: PropTypes.string.isRequired,
        participants: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
        target_participants: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
    }).isRequired,

    locations: PropTypes.array.isRequired,
    selectedLocations: PropTypes.array.isRequired,
    addLocation: PropTypes.func.isRequired,
    removeLocation: PropTypes.func.isRequired,

    errors: PropTypes.object.isRequired,
    validateForm: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlurForProtocolNo: PropTypes.func.isRequired,
    isEdit: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    isEditFromReviewScreen: PropTypes.bool.isRequired,
};