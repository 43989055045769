import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Accordion from './../../../common/Accordion';

class ReviewProtocolOverview extends Component {

    handleEdit = () => {
        //console.log('Handling edit for Protocol Overview');
        const { updateCurrentStep, updateIsEditFromReviewScreen } = this.props;
        updateCurrentStep(1);
        updateIsEditFromReviewScreen(true);
    }

    render() {
        const { protocolDetails } = this.props;
        return (
            <div className="review-panel review__protocol-overview">
                <Accordion
                    label="Protocol Overview"
                    headingButtonAction={this.handleEdit}
                >
                {
                    protocolDetails ? 
                    <div className="review-table review__content">
                        <div className="review-item row">
                            <div className="review-heading col-3">
                                Protocol number
                            </div>
                            <div className="col-9">
                                {protocolDetails.Protocol_code || ''}
                            </div>
                        </div>
                        <div className="review-item row">
                            <div className="review-heading col-3">
                                Protocol name
                            </div>
                            <div className="col-9">
                                {protocolDetails.Protocol_name || ''}
                            </div>
                        </div>
                        <div className="review-item row">
                            <div className="review-heading col-3">
                                Study sponsor
                            </div>
                            <div className="col-9">
                                {protocolDetails.Study_sponser || ''}
                            </div>
                        </div>
                        <div className="review-item row">
                            <div className="review-heading col-3">
                                Clinical Research Coordinator
                            </div>
                            <div className="col-9">
                                {protocolDetails.Nucleus_coordinator || ''}
                            </div>
                        </div>
                        <div className="review-item row">
                            <div className="review-heading col-3">
                                Total number of participants to be dosed
                            </div>
                            <div className="col-9">
                                {protocolDetails.Study_participants || ''}
                            </div>
                        </div>
                        <div className="review-item row">
                            <div className="review-heading col-3">
                                Total number of participants to be screened
                            </div>
                            <div className="col-9">
                                {protocolDetails.Screening_participants || 0}
                            </div>
                        </div>
                    </div> : null
                }
                </Accordion>
            </div>
        )
    }
}

export default ReviewProtocolOverview;

ReviewProtocolOverview.propTypes = {
    protocolDetails: PropTypes.shape({
        Protocol_code: PropTypes.string.isRequired,
        Protocol_name: PropTypes.string.isRequired,
        Study_sponser: PropTypes.string.isRequired,
        Nucleus_coordinator: PropTypes.string.isRequired,
        Study_participants: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
        Screening_participants: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
    }),
    updateCurrentStep: PropTypes.func.isRequired,
    updateIsEditFromReviewScreen: PropTypes.func.isRequired,
};