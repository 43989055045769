/* eslint-disable no-undef */
/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import React from 'react'
import styled from 'styled-components'
import { useTable } from 'react-table'
import { dateFormatAPI } from '../../../../utils/constants';
import moment from 'moment';
import { formatEpisodeForKit } from '../../../../utils/episodes';

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`

function calculateNumOfBarcodeSheets(packaging_list){
  const pot = '1*Pot';
  const bloodTubes = packaging_list.find(item => item !== pot) ? 1 : 0;
  const urineTubes = packaging_list.find(item => item === pot) ? 1 : 0;
  const numOfBarcodeSheets = bloodTubes + urineTubes;
  return numOfBarcodeSheets;
}
function displayPackingList(row){

      if (!row.original.packaging_list) { // error check
          return "";
      }

      const parsed = JSON.parse(row.original.packaging_list);

      if (!Array.isArray(parsed)) { // error check
          return "";
      }

      const listItems = parsed.map((item, index) => {
          const formattedItem = item.replace('*', ' x ');
          return (
              <li key={index}>{formattedItem}</li>
          )
      });
      
      const numOfBarcodeSheets = calculateNumOfBarcodeSheets(parsed);
      
      return (
          <ul style={{paddingLeft:0, listStyle: 'none', fontSize:10}}>
              {listItems}
              <li>{numOfBarcodeSheets} x Barcode sheet</li>
          </ul>
      )
}
function displayPackingDateList(row){

  if (!row.original.packing_details) { // error check
      return "";
  }

  // console.log(parsed)
  const parsed = row.original.packing_details;

  // console.log(parsed)
  if (!Array.isArray(parsed)) { // error check
      return "";
  }

  const listItems = parsed.map((item, index) => {
      return (
          <li key={index}>{moment(item.Date_packed).format(dateFormatAPI)}</li>
      )
  });
  
  return (
      <ul style={{paddingLeft:0, listStyle: 'none', fontSize:10}}>
          {listItems}
      </ul>
  )
}
function displayPackedByList(row){

  if (!row.original.packing_details) { // error check
      return "";
  }

  // console.log(parsed)
  const parsed = row.original.packing_details;

  // console.log(parsed)
  if (!Array.isArray(parsed)) { // error check
      return "";
  }

  const listItems = parsed.map((item, index) => {
      return (
          <li key={index}>{item.Packed_by}</li>
      )
  });
  
  return (
      <ul style={{paddingLeft:0, listStyle: 'none', fontSize:10}}>
          {listItems}
      </ul>
  )
}
function displayDateQCList(row){

  if (!row.original.packing_details) { // error check
      return "";
  }

  // console.log(parsed)
  const parsed = row.original.packing_details;

  // console.log(parsed)
  if (!Array.isArray(parsed)) { // error check
      return "";
  }

  const listItems = parsed.map((item, index) => {
      return (
          <li key={index}>{moment(item.QC_date).format(dateFormatAPI)}</li>
      )
  });
  
  return (
      <ul style={{paddingLeft:0, listStyle: 'none', fontSize:10}}>
          {listItems}
      </ul>
  )
}
function displayQCByList(row){

  if (!row.original.packing_details) { // error check
      return "";
  }

  // console.log(parsed)
  const parsed = row.original.packing_details;

  // console.log(parsed)
  if (!Array.isArray(parsed)) { // error check
      return "";
  }

  const listItems = parsed.map((item, index) => {
      return (
          <li key={index}>{item.QC_by}</li>
      )
  });
  
  return (
      <ul style={{paddingLeft:0, listStyle: 'none', fontSize:10}}>
          {listItems}
      </ul>
  )
}
function Table({ columns, data,hiddenColumns}) {
  // Use the state and functions returned from useTable to build your UI
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
    initialState:{
      hiddenColumns:hiddenColumns
    }
  })

  headerGroups.splice(0,1)
  console.log('data', data)
  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(
          (row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )}
        )}
      </tbody>
    </table>
  )
}
function KitsTablePrint(props) {

  console.log(props)
  let hiddenColumns = [];
  switch(props.kitsToDisplay){
    case 'episode':
        hiddenColumns.push('expiry_date');
        hiddenColumns.push('number_used');
      break;
    case 'lowstock':
        hiddenColumns.push('expiry_date');
      break;
    case 'expiringkits':
        hiddenColumns.push('number_used');
      break;
  }
  const columns = React.useMemo(
    () => [
      {
        Header: 'Kit Details',
        columns: [
          {
            Header: 'Protocol',
            accessor: 'protocol',
          },
          {
            Header: 'Kit Number',
            accessor: 'kit_code',
          },
          {
            Header: 'Cohort',
            accessor: 'cohort'
          },
          {
            Header: 'Episode',
            accessor: 'episode',
            Cell: ({ row }) => {
              return formatEpisodeForKit(row.original)
            },
          },
          {
            Header: 'Packed / Required',
            accessor: 'packed',

            Cell: ({ row }) => {
              return <span>{(row.original.packed == null ? 0 : row.original.packed) + '/' + row.original.required}</span>
            },
          },
          {
            Header: 'Used Count',
            accessor: 'number_used',
            Cell: ({ row }) => {
              return row.original.number_used
            },
          },
          {
            Header: 'Packing Required',
            accessor: 'packing_required_date',
            Cell: ({ row }) => {
              return moment(row.original.Packing_required).format(dateFormatAPI)
            },
          },
          {
            Header: 'Expiry Date',
            accessor: 'expiry_date',
            show: false,
            Cell: ({ row }) => {
              return row.original.expiry_date == null ? '-' : moment(row.original.expiry_date).format(dateFormatAPI)
            },
          },
          {
            Header: 'Kit Summary',
            accessor: 'packaging_list',
            Cell: ({ row }) => {
              return displayPackingList(row)
            },
          },
          {
            Header: 'Date Packed',
            accessor: 'date_packed',
            Cell: ({ row }) => {
              return displayPackingDateList(row)
            },
          },
          {
            Header: 'Packed By',
            accessor: 'packed_by',
            Cell: ({ row }) => {
              return displayPackedByList(row)
            },
          },
          {
            Header: 'Date QC',
            accessor: 'date_qc',
            Cell: ({ row }) => {
              return displayDateQCList(row)
            },
          },
          {
            Header: 'QC By',
            accessor: 'qc_by',
            Cell: ({ row }) => {
              return displayQCByList(row)
            },
          },
        ],
      },
    ],
    []
  )

  return (
    <Styles>
      <Table columns={columns} data={props.kitsToPrint} hiddenColumns={hiddenColumns} />
    </Styles>
  )
}

export default KitsTablePrint
