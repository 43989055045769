import React, { Component } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';
import { Auth } from 'aws-amplify';

import ReviewQuote from './ReviewQuote';
import RadioBlock from './../../../common/RadioBlock';
import UpdateProtocolStatusConfirmModal from './UpdateProtocolStatusConfirmModal';

import { PROTOCOL_STATUS_MAPPING, getAvailableStatuses } from './../../../../utils/protocol-statuses';
import { UPDATE_PROTOCOL_API } from './../../../../utils/constants';

class ReviewProtocolStatus extends Component {

    state = {
        selectedStatus: null,
        currentStatus: null, // Get the status via props in componentDidMount()
        modalIsOpen: false,
        isChangingStatus: false,
    }

    componentDidMount() {

        const { protocolDetails } = this.props; // Get the status via props (from ReviewAndExport)
        if (protocolDetails && protocolDetails.Status_id) {
            const currentStatus = protocolDetails.Status_id.toString();
            const availableStatuses = getAvailableStatuses(currentStatus);
            // Set current and available statuses
            this.setState({
                currentStatus,
                availableStatuses,
            });
        }
       
    }

    handleOptionChange = (e) => {
        this.setState({
            selectedStatus: e.target.value,
        });
    }

    handleSubmit = () => {
        const { selectedStatus } = this.state;
        // If the status is Live or Completed, show the confirm modal
        if (selectedStatus === '3' || selectedStatus === '4' || selectedStatus === '5') {
            this.openModal();
            return;
        }
        this.updateProtocolStatusAPI();
    }

    updateProtocolStatusAPI = () => {
        const { selectedStatus, modalIsOpen } = this.state;
        //console.log(`Submit the new status ${selectedStatus}`);

        this.setState({ isChangingStatus: true });

        if (modalIsOpen) {
            this.closeModal();
        }

        const updateurl = UPDATE_PROTOCOL_API.POST;

        const data = JSON.stringify({
            body: {
                protocolId: this.props.protocolDetails.Protocol_ID.toString(),
                number: this.props.protocolDetails.Protocol_code == null ? "" : this.props.protocolDetails.Protocol_code,
                name: this.props.protocolDetails.Protocol_name == null ? "" : this.props.protocolDetails.Protocol_name,
                copydoctor: this.props.protocolDetails.Copy_doctor == null ? "" : this.props.protocolDetails.Copy_doctor,
                ultracode: this.props.protocolDetails.Ultra_code == null ? "" : this.props.protocolDetails.Ultra_code,
                sponsor: this.props.protocolDetails.Study_sponser == null ? "" : this.props.protocolDetails.Study_sponser,
                coord: this.props.protocolDetails.Nucleus_coordinator == null ? "" : this.props.protocolDetails.Nucleus_coordinator,
                onstudyparti: this.props.protocolDetails.Study_participants.toString(),
                screenparti: this.props.protocolDetails.Screening_participants.toString(),
                statusid: selectedStatus,
                user: this.props.username,
            }
        });
        console.log("change status body = "+data)

        Auth.currentSession().then((response) => {
            const headers = { Authorization: response.idToken.jwtToken };

            
            //console.log(data);
            axios.post(updateurl, data, { headers })
                .then((response) => {
                    
                    //console.log(response);
                    // When successful:
                    this.updateCurrentStatus(selectedStatus);
                    this.resetSelectedStatus();
                })
                .catch(function (error) {
                    //console.log(error);
                })
                .then(() => {
                    this.setState({ isChangingStatus: false });
                });
        });


    }

    updateCurrentStatus = (newStatus) => {
        const newAvailableStatuses = getAvailableStatuses(newStatus);
        this.setState({
            currentStatus: newStatus,
            availableStatuses: newAvailableStatuses,
        });
    }

    resetSelectedStatus = () => {
        this.setState({
            selectedStatus: null,
        });
    }

    displayCurrentStatus = () => {
        const { currentStatus } = this.state;
        return (
            <div className="status__current-status">
                <span className={`status-icon status--${currentStatus}`}></span>
                <span>{ PROTOCOL_STATUS_MAPPING[currentStatus] }</span>
            </div>
        )
         
    }

    renderStatusDescription = (status) => {
        switch (status) {
            case '1': // Draft – work in progress
                return (
                    <ul>
                        <li>Protocol details are being edited and not ready to be reviewed by the clinical research company</li>
                    </ul>
                )
            case '2': // Draft – pending approval
                return (
                    <ul>
                        <li>You sent a Protocol Draft for Pathology and quote to the clinical research company. You are waiting for their approval before pushing the protocol live</li>
                    </ul>
                )
            case '3': // Live – pending amendments
                return (
                    <ul>
                        <li>The clinical research company has asked for changes to the remaining scheduled tests</li>
                        <li>The clinical research company and Clinical Labs have agreed on an outage period, in which Clinical Labs will be able to make all the changes needed</li>
                        <li>With this status, the protocol will still appear in the Pathology Collection app, but its cohorts and episodes won’t be viewable.</li>
                        <li>You should change the status back to ‘Live’ once you have completed your changes.</li>
                    </ul>
                )
            case '4': // Live
                return (
                    <ul>
                        <li>The clinical research company approved the Protocol Draft for Pathology and quote</li>
                        <li>When the protocol is live, it can be viewed on the Pathology Collection app</li>
                        <li>The specifications for kits will be fixed based on the analytes selected in each episode. You will be able to track the packing progress in the Kits section</li>
                        {/* <li>You won’t be able to make any changes to the protocol details</li> */}
                    </ul>
                )
            case '5': // Completed
                return (
                    <ul>
                        <li>All episodes in the protocol have been collected and no more follow-up tests are needed</li>
                        <li>Once the protocol is completed, it can no longer be viewed on the Pathology Collection app</li>
                    </ul>
                )
            case '6': // Rejected
                return (
                    <ul>
                        <li>The clinical research company decided this protocol is no longer needed</li>
                    </ul>
                )
            default:
                return null;
        }
    }

    renderRadioBlocks = () => {
        const { availableStatuses, selectedStatus } = this.state;

        if (!availableStatuses || !availableStatuses.length) {
            return;
        }

        return availableStatuses.map(status => {
            return (
                <RadioBlock
                    key={status}
                    name="status"
                    containerClass="status__option"
                    id={status}
                    value={status}
                    handleOptionChange={this.handleOptionChange}
                    selectedOption={selectedStatus}
                    label={PROTOCOL_STATUS_MAPPING[status]}
                >
                    { this.renderStatusDescription(status) }
                </RadioBlock>
            )
        })
    }

    openModal = () => {
        this.setState({
            modalIsOpen: true,
        });
    }

    closeModal = () => {
        this.setState({
            modalIsOpen: false,
        });
    }

    render() {

        const currentStatus = this.state.currentStatus;
        const selectedStatus = this.state.selectedStatus;
        const modalIsOpen = this.state.modalIsOpen;
        const isChangingStatus = this.state.isChangingStatus;

        return (
            <React.Fragment>
            <div className="review-section review__protocol-status">
                <h3>Keep the protocol status up-to-date</h3>
                <div className="current-protocol-status">
                    <p>The current protocol status is:</p>
                    { this.displayCurrentStatus() }
                    <div>
                        <p>You can change the protocol status to one of the following options below:</p>
                        <div className="status__options radio-block__container">
                            { this.renderRadioBlocks() }
                        </div>
                    </div>
                </div>
                    <div className="row">
                        <div className="col text-right">
                            <button
                                className="btn btn-primary btn--change-status"
                                disabled={selectedStatus == null || isChangingStatus}
                                onClick={this.handleSubmit}
                            >
                                {isChangingStatus ? <span className="spinner__wrapper"><span className="loading-spinner"></span></span> : 'Change status'}
                        </button>
                    </div>
                </div>
            </div>

            {
                // Only display the Quote section if the status is not Draft – work in progress OR Rejected
                (currentStatus && currentStatus !== '1' && currentStatus !== '6') ? 
                <ReviewQuote
                    username={this.props.username}
                    protocolId={this.props.protocolId}
                /> : null
            }
            
            {
                modalIsOpen ? 
                <UpdateProtocolStatusConfirmModal
                    updateProtocolStatusAPI={this.updateProtocolStatusAPI}
                    closeModal={this.closeModal}
                    modalIsOpen={this.state.modalIsOpen}
                    selectedStatus={this.state.selectedStatus}
                /> : null
            }
            </React.Fragment>
        )
    }
}

export default ReviewProtocolStatus;

ReviewProtocolStatus.propTypes = {
    protocolDetails: PropTypes.shape({
        Protocol_ID: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
        Protocol_code: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
        Protocol_name: PropTypes.string.isRequired,
        Study_sponser: PropTypes.string.isRequired,
        Nucleus_coordinator: PropTypes.string.isRequired,
        Study_participants: PropTypes.number.isRequired,
        Screening_participants: PropTypes.number.isRequired,
    }).isRequired,
    username: PropTypes.string.isRequired,
    protocolId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};