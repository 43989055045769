import React, { Component } from 'react';
import PropTypes from 'prop-types';

import MultistepForm from './AddEditProtocol/MultistepForm';
import Preloader from './../common/Preloader';

import axios from 'axios';
import { Auth } from 'aws-amplify';
import { PROTOCOL_API, GET_SCREENING_BY_PROTOCOLID_API, GET_COHORTLIST_BY_PROTOCOLID_API } from './../../utils/constants';

class AddEditProtocol extends Component {

    state = {
        isPageLoading: false,
        protocolId: null,
        screeningId: null,
        screeningDone: false,
        cohortsDone: false,
        protocolSummary: {
            protocolNo: null,
            targetNoOfOnStudyParticipants: null,
            targetNoOfParticipantsToBeScreened: null,
        },
        isEditFromReviewScreen: false,
        episodeFromReviewScreen: '',
        episodeFromReviewScreenIndex: '',
    }

    componentDidMount() {
        const protocolId = this.props.match.params.protocolId;

        if (protocolId && protocolId !== undefined) {
            this.loadProtocolDetails();
            this.loadScreeningStatus();
            this.loadCohortsStatus();
        }
    }

    /* Load protocol details if it's an edit */
    loadProtocolDetails = () => {
        let protocolId = this.props.match.params.protocolId;
        protocolId = parseInt(protocolId);

        // Set the protocol ID to the state
        this.updateProtocolId(protocolId);

        /* Now get the protocol details based on protocol ID */
        const url = PROTOCOL_API.GET;

        // Get the current session
        Auth.currentSession().then((response) => {
            const headers = { Authorization: response.idToken.jwtToken };
            //console.log(url);
            axios.get(url, { headers })
                .then((response) => {
                    if (response.data) {
                        const result = response.data.result;

                        // Find the protocol from its ID
                        const protocol = result.filter(item => item.Protocol_ID === protocolId)[0];
                        // console.log(protocol);

                        this.updateProtocolSummary(
                            protocol.Protocol_code, 
                            protocol.Study_participants, 
                            protocol.Screening_participants
                        );
                    }
                })
                .catch((error) => {
                    console.error(error);
                });

        }).catch((error) => {
            console.error(error);
        });
    }

    /* Load screening status if it's an edit */
    loadScreeningStatus = () => {
        let protocolId = this.props.match.params.protocolId;
        protocolId = parseInt(protocolId);

        /* Uncomment to use API */
        const url = GET_SCREENING_BY_PROTOCOLID_API.GET;
        Auth.currentSession().then((response) => {
            const headers = { Authorization: response.idToken.jwtToken };

            axios.get(`${url}?protocolId=${protocolId}`, { headers })
                .then((response) => {
                    // console.log(response)
                    if (response.data.result) {
                        const result = response.data.result[0].result;
                        if (result.length) {
                            this.setState({ screeningDone: true });
                            // this.updateScreeningId(...)
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                });

        }).catch((error) => {
            console.error(error);
        });
    }

    loadCohortsStatus = () => {
        let protocolId = this.props.match.params.protocolId;
        protocolId = parseInt(protocolId);

        const url = GET_COHORTLIST_BY_PROTOCOLID_API.GET;
        Auth.currentSession().then((response) => {
            const headers = { Authorization: response.idToken.jwtToken };

            axios.get(`${url}?protocolId=${protocolId}`, { headers })
                .then((response) => {
                    if (response.data) {
                        const result = response.data[0].result;

                        if (result.length) {
                            this.setState({ cohortsDone: true });
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                });

        }).catch((error) => {
            console.error(error);
        });
    }

    updateProtocolId = (protocolId) => {
        this.setState({
            protocolId: protocolId,
        })
    }
    updateScreeningId = (screeningId) => {
        this.setState({
            screeningId: screeningId,
        })
    }
    updateProtocolSummary = (protocolNo, targetNoOfOnStudyParticipants, targetNoOfParticipantsToBeScreened) => {
        this.setState({
            protocolSummary: {
                protocolNo,
                targetNoOfOnStudyParticipants,
                targetNoOfParticipantsToBeScreened,
            }
        })
    }

    updateIsPageLoading = (boolean) => {
        this.setState({
            isPageLoading: boolean,
        })
    }

    /* 
    Required to check if the user clicked on "Edit episodes" on an episode
    in the Review and Export screen. This will pass the episode to open in EpisodesContainer
    */
    updateEditEpisodeFromReviewScreen = (cohort, index) => {
        this.setState({
            episodeFromReviewScreen: cohort,
            episodeFromReviewScreenIndex: index,
        })
    }

    /* 
    Required to check if the user clicked on "Edit" in the Review and Export screen. 
    This will turn the "Next" button into "Confirm changes"
    */
    updateIsEditFromReviewScreen = (boolean) => {
        this.setState({
            isEditFromReviewScreen: boolean,
        })
    }

    render() {
        const isPageLoading = this.state.isPageLoading;

        return (
            <React.Fragment>
                <div className="page-heading">
                    <div className="container">
                        <h1>Add a new protocol</h1>
                    </div>
                </div>
                <section className="add-edit-protocol section container">
                    {/* <p>Use this component to control whether to add or edit a protocol</p>
                    <p>{protocolId ? `Passed ID: ${protocolId}` : 'Let\'s create a new Protocol!' }</p> */}
                    <MultistepForm
                        existingProtocolNos={this.state.existingProtocolNos}
                        protocolSummary={this.state.protocolSummary}
                        updateProtocolSummary={this.updateProtocolSummary}
                        username={this.props.username}
                        updateProtocolId={this.updateProtocolId}
                        protocolId={this.state.protocolId}
                        updateScreeningId={this.updateScreeningId}
                        screeningId={this.state.screeningId}
                        screeningDone={this.state.screeningDone}
                        cohortsDone={this.state.cohortsDone}
                        updateIsPageLoading={this.updateIsPageLoading}
                        episodeFromReviewScreen={this.state.episodeFromReviewScreen}
                        episodeFromReviewScreenIndex={this.state.episodeFromReviewScreenIndex}
                        updateEditEpisodeFromReviewScreen={this.updateEditEpisodeFromReviewScreen}
                        isEditFromReviewScreen={this.state.isEditFromReviewScreen}
                        updateIsEditFromReviewScreen={this.updateIsEditFromReviewScreen}
                    />
                </section>
                {
                    isPageLoading ?
                    <Preloader />
                    : null
                }
            </React.Fragment>
        )
    }
}

export default AddEditProtocol;

AddEditProtocol.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            protocolId: PropTypes.string
        }),
    }),
    username: PropTypes.string,
};
