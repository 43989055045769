import React, { Component } from 'react';
import PropTypes from 'prop-types';

import KitLabel from './KitLabel';

import SVG from 'react-inlinesvg';
import chevronLeft from './../../../assets/images/chevron-left.svg';

import './../../../scss/kit-print.scss';

class KitPrint extends Component {

    state = {
        number: 10,
    }
    
    componentDidMount() {
        // Display print dialog box

        // https://www.google.com.au/search?ei=uTl6W9rFN5fr-QbMjZfYDg&q=react+link+maintain+state+in+new+tab&oq=react+link+maintain+state+in+new+tab&gs_l=psy-ab.3...8962.11376.0.11503.11.10.0.0.0.0.276.1226.0j4j2.6.0....0...1c.1.64.psy-ab..7.2.344...35i39k1.0.Y4XqJcxW7A8
        // print();
    }

    renderLabels = () => {
        const {
            number,
        } = this.state;

        const {
            kit,
        } = this.props;

        const labels = [];

        for (let i = 0; i < number; i++) {
            labels.push(
                <KitLabel
                    key={i}
                    kit={kit}
                />
            )
        }
        return labels;
    }
    
    render() {
        const {
            hidePrintPage,
        } = this.props;

        return (
            <div className="-portrait kit-print container">
                <div className="kit-print__back row">
                    <div className="col">
                        <div className="page__back">
                            <span
                                className="kit-print__back__link page__back__link"
                                onClick={hidePrintPage}
                            >
                                <SVG className="icon icon--back" src={chevronLeft} />
                                <span className="link">Back</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="kit-labels__container row">
                    { this.renderLabels() }
                </div>
            </div>
        )
    }
}

export default KitPrint;

KitPrint.propTypes = {
    kit: PropTypes.object.isRequired, // should be shaped
    hidePrintPage: PropTypes.func.isRequired,
};
