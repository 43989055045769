import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Accordion from './../../../common/Accordion';

import { sortByObjectProperty } from './../../../../utils/utils';

class ReviewScreening extends Component {

    handleEdit = () => {
        //console.log('Handling edit for Screening');
        const { updateCurrentStep, updateIsEditFromReviewScreen } = this.props;
        updateCurrentStep(2);
        updateIsEditFromReviewScreen(true);
    }

    renderPanels = (panels) => {
        return panels.sort(sortByObjectProperty('Panel_code'))
            .map(panel => {
                return (
                    <div className="list--item row" key={panel.panel_id}>
                        <div className="item item__panel">{panel.Panel_code}</div>
                    </div>
                )
            });
    }

    render() {
        const { screeningDetails } = this.props;
        return (
            <div className="review-panel review__screening">
                <Accordion
                    label="Screening"
                    headingButtonAction={this.handleEdit}
                >
                {
                    screeningDetails ? 
                    <div className="flex-table container">
                        <div className="list--heading row">
                            <div className="item item__panel">Panels</div>
                        </div>
                        {this.renderPanels(screeningDetails)}
                    </div> : null
                }
                </Accordion>
            </div>
        )
    }
}

export default ReviewScreening;

ReviewScreening.propTypes = {
    screeningDetails: PropTypes.array,
    updateCurrentStep: PropTypes.func.isRequired,
    updateIsEditFromReviewScreen: PropTypes.func.isRequired,
};