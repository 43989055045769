import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ProgressBar from './../common/ProgressBar';
import SVG from 'react-inlinesvg';

import moment from 'moment';
import { formatEpisodeForKit } from './../../utils/episodes';

import chevronRight from './../../assets/images/chevron-right-24px.svg';
import { dateFormat } from '../../utils/constants';
class EpisodeKits extends Component {
    
    render() {
        const { 
            pageOfItems,
            kitsToDisplay,
            handleViewKitDetails
         } = this.props;

        return (
            pageOfItems.map((kit, index) => {
                // console.log("before conversion:", kit.day_value.toLowerCase() == 'screening' ? kit.commencement_date : kit.first_dosing_date )
                let dateValue = kit.day_value.toLowerCase() == 'screening' ?  moment(kit.commencement_date).subtract(7,'d').format(dateFormat) : moment(kit.first_dosing_date).subtract(7,'d').format(dateFormat);
                // console.log('after conversion:', dateValue)
                return (
                    <div 
                        key={index} 
                        className={`row list--item list--item-${index}`}
                        // Rafey: Error might be here
                        onClick={() => handleViewKitDetails(kit.kit_code, kitsToDisplay)}
                    >
                        <div className="item item__kit-code">
                            {kit.kit_code}
                        </div>
                        <div className="item item__protocol">
                            {kit.protocol}
                        </div>
                        <div className="item item__cohort">
                            {kit.cohort}
                        </div>
                        <div className="item item__episode">
                            { formatEpisodeForKit(kit) }
                        </div>
                        {
                            kitsToDisplay == 'lowstock' && <div className="item item__numberused">{kit.number_used}</div>
                        }
                        <div className="item item__packed-required">
                            <span className={`packed-value ${kit.packed === kit.required ? 'packing--complete' : 'packing--incomplete'}`}>
                                {kit.packed == null ? 0 : kit.packed} / {kit.required}
                            </span>
                            <span className="packed-progress-bar">
                                <ProgressBar percentage={kit.packed / kit.required * 100} />
                            </span>
                        </div>

                        {
                            (kitsToDisplay == 'episode' || kitsToDisplay == 'lowstock') && <div className="item item__packingrequired">
                                {dateValue.replace('Invalid date', '-')}
                            </div>
                        }
                        {
                            kitsToDisplay == 'expiringkits' && <div className="item item__packingrequired">
                                {moment(kit.expiry_date).format(dateFormat).replace('Invalid date', '-')}
                            </div>
                        }
                        
                        <div className="item item__actions">
                            <SVG className="icon--chevron-right-24" src={chevronRight} />
                        </div>
                    </div>
                )
            })
        )
    }
}

export default EpisodeKits;

EpisodeKits.propTypes = {
    pageOfItems: PropTypes.array,
    handleViewKitDetails: PropTypes.func.isRequired,
    kitsToDisplay: PropTypes.string.isRequired,
};