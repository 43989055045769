import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Checkbox extends Component {
    render() {

        const {id, name, value, checked, className, label, onChange, ...rest} = this.props;

        return (
            <label htmlFor={id} className="checkbox__container">
                <input
                    type="checkbox"
                    id={id}
                    name={name}
                    value={value}
                    checked={checked}
                    disabled={this.props.disabled}
                    onChange={onChange}
                    className={className ? `${className} form-check-input` : 'form-check-input'}
                />
                <span className="form-check-checkmark"></span>
                <span className="form-check-label" style={{display:'block'}}>{label}</span>
            </label>
        )
    }
}

export default Checkbox;

Checkbox.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    defaultValue: PropTypes.bool,
    checked: PropTypes.bool,
    className: PropTypes.string,
    label: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.object,
    ]),
    onChange: PropTypes.func,
    disabled: PropTypes.bool
};
Checkbox.defaultProps = {
    disabled: false

}