import React, { Component } from 'react';
// import { NavLink } from 'react-router-dom';

import NavItem from './../common/NavItem';

import { generatePortalUrl } from './../../utils/constants';

import './../../scss/header-nav.scss';

class HeaderNav extends Component {
    render() {
        return (
            <nav>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ul className="nav nav-links">
                                <NavItem to={generatePortalUrl('protocols')}>Protocols</NavItem>
                                <NavItem to={generatePortalUrl('analytes-and-panels')}>Analytes and panels</NavItem>
                                <NavItem to={generatePortalUrl('kits')}>Kits</NavItem>
                                <NavItem to={generatePortalUrl('search')}>Search</NavItem>
                                <NavItem to={generatePortalUrl('settings')}>Settings</NavItem>
                                {/* Hide Participants and Lab forecasts links */}
                                {/* <NavItem to={generatePortalUrl('participants')}>Participants</NavItem>
                                <NavItem to={generatePortalUrl('lab-forecasts')}>Lab forecasts</NavItem> */}
                                
                                {/* <li className="nav-item active">
                                    <NavLink className="nav-link" activeClassName="active" to="/protocols">Protocols</NavLink>
                                </li> */}

                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}

export default HeaderNav;