import React, { Component } from 'react'; 
import { PropTypes } from 'prop-types';
import Modal from 'react-modal';

import SVG from 'react-inlinesvg';
import close from './../../../../assets/images/close.svg';
import { duration } from 'moment';

Modal.setAppElement('#root');
class AddAlternativeCohortNameModal extends Component {


    handleRemoveCohortName = (index) => {
        this.props.removeCohortName(index);
        //this.props.closeModal();
    }
    handleAddCohortName = () => {

        if(this.newName == null){

            return;
        }
        if(this.newName.trim() == ''){
            return;
        }
        if(this.props.alternativeNames.includes(this.newName)){
            return;
        }
        this.props.addCohortName(this.newName);
    }
    onNewNameValue = (event) => {
        this.newName = event.target.value
    }

    nameItem = (name, index) => {
        
        if(name == ''){
            return <div></div>
        }
       
        return (<div key={index} style={{
                    border:'none',
                    marginBottom:20,
                    borderBottomWidth: 1,
                    borderBottomColor: '#753dbd',
                    borderBottomStyle: 'solid',
                    
                }}>
                <span>
                    <label style={{width:'95%'}}>{name}</label>
                    <button className="btn-primary btn--next" onClick={()=>{this.handleRemoveCohortName(index)}}>x</button>
                </span>
            </div>)

    }

    

    render() {
        const { modalIsOpen, closeModal} = this.props;
        let names = [];
        if(this.props.alternativeNames != ''){
            names = this.props.alternativeNames.split('$')
        }
        return (
            <Modal
                className="modal__bootstrap modal--cancel-protocol-overview modal-dialog"
                overlayClassName="overlay"
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title">Alternative names of {this.props.cohortName}</h3>
                        <button type="button" className="close" onClick={closeModal}>
                            <span aria-hidden="true">
                                <SVG className="icon icon--close" src={close} />
                            </span>
                            <span className="sr-only"></span>
                        </button>
                    </div>
                    {
                        names.map((name, index) => {
                            return (
                                this.nameItem(name, index)
                            )
                        })
                    }
                    <div><span><input style={{width:'70%'}} onChange={this.onNewNameValue}></input><button className="btn-primary btn--next" onClick={this.handleAddCohortName} style={{marginLeft: 10}}>Add Name</button></span></div>
                </div>
            </Modal>
        );
    }
}

export default AddAlternativeCohortNameModal;

AddAlternativeCohortNameModal.propTypes = {
    removeCohortName: PropTypes.func.isRequired,
    addCohortName: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    modalIsOpen: PropTypes.bool.isRequired,
    indexToBeRemoved: PropTypes.number,
    alternativeNames: PropTypes.string,
    cohortName: PropTypes.string,
};
