/**
 * Checks if the user is not logged in.
 * 
 * If they're not logged in, they can go to proceed to one of the pages
 * in the routing switch statement: Login, ResetPassword, SetPassword.
 * 
 * If they're already logged in, redirect them to the default page (currently Protocols).
 */

import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import LoginManager from './Login/Login/LoginManager';
import ResetPasswordManager from './Login/ResetPassword/ResetPasswordManager';

import { PORTAL_URL, LOGIN_URL, generateLoginUrl } from './../utils/constants';

import './../scss/login.scss';

class UnauthenticatedRoute extends Component {
    render() {
        if (this.props.isAuthenticated) {
            return (
                <Redirect to={PORTAL_URL} />
            )
        } else {
            return (
                <main className="unauthenticated-layout container not-logged-in justify-content-center">
                    <div className="unauthenticated-layout__inner">
                        <div className="unauthenticated-layout__heading">
                            <h2>Protocol Admin Portal</h2>
                        </div>

                        {/*
    
                        Imagine this could be a general layout for all unauthorized pages like
                        the login page, forgot password, email-verified, etc...
                        
                        For this example project, we'll just have a Login Page
                        
                        */}
                        <Switch>
                            <Route exact path={generateLoginUrl('reset-password')} component={ResetPasswordManager} />
                            {/* <Route exact path={generateLoginUrl('set-password')} component={SetPasswordContainer} /> */}
                            <Route
                                exact path={LOGIN_URL}
                                render={ () => <LoginManager isAuthenticated={this.props.isAuthenticated} updateAuthState={this.props.updateAuthState} /> }
                            />
                            <Redirect to={LOGIN_URL} />
                        </Switch>
                    </div>
                </main>
            )
        }
    }
}

export default UnauthenticatedRoute;

UnauthenticatedRoute.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    updateAuthState: PropTypes.func.isRequired,
};