import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { LOGIN_URL } from './../../../utils/constants';

/**
 * Basic form that will be wrapped with Formik
 */
class ResetPasswordForm extends Component {

    render() {
        const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
        } = this.props;

        return (
            <div className="reset-password">
                <div className="unauthenticated-layout__subheading">
                    <h3>Reset your password</h3>
                </div>

                {/* Display error message for the whole form if getting the confirmation code was invalid */}
                {errors.form &&
                    <p className="form-message__container has-error">
                        <small id="formHelp" className="form-text">{errors.form}</small>
                    </p>
                }

                <form onSubmit={handleSubmit}>
                    <p>Please enter your registered email. A confirmation code will be sent to you and you will be able to create a new password in the next step.</p>
                    <div className={`form-group ${(errors.email || errors.form) && touched.email ? 'has-error' : ''}`}>
                        <label htmlFor="email">Email address</label>
                        <input type="text" name="email" id="email" className="form-control" autoFocus value={values.email} onChange={handleChange} onBlur={handleBlur} />
                        {errors.email && touched.email && <small id="emailHelp" className="form-text">{errors.email}</small>}
                    </div>
                    <div className="form-group form-group--button">
                        <button type="submit" className="btn btn-primary btn-block">
                            {isSubmitting ? <span className="spinner__wrapper"><span className="loading-spinner"></span></span> : 'Get confirmation code'}
                        </button>
                    </div>
                    <div className="link--login text-center">
                        <Link to={LOGIN_URL}>Sign in</Link>
                    </div>
                </form>
            </div>
        );
    }
}

export default ResetPasswordForm;

ResetPasswordForm.propTypes = {
    values: PropTypes.object,
    touched: PropTypes.object,
    errors: PropTypes.object,
    isSubmitting: PropTypes.bool,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    handleSubmit: PropTypes.func,
};