import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import Modal from 'react-modal';

import SVG from 'react-inlinesvg';
import close from './../../../../assets/images/close.svg';

Modal.setAppElement('#root');

class DownloadNotReadyModal extends Component {
    render() {
        const { modalIsOpen, closeModal } = this.props;
        return (
            <Modal
                className="modal__bootstrap modal--download-not-ready modal-dialog"
                overlayClassName="overlay"
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title">Quote and protocol summary aren’t ready yet</h3>
                        <button type="button" className="close" onClick={closeModal}>
                            <span aria-hidden="true">
                                <SVG className="icon icon--close" src={close} />
                            </span>
                            <span className="sr-only"></span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p>Please try again in a few minutes. Or you can wait for the quote and protocol summary to be sent to you by email once they have been generated.</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={closeModal}>Ok</button>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default DownloadNotReadyModal;

DownloadNotReadyModal.propTypes = {
    modalIsOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
};