import React, { Component } from 'react';

import { PropTypes } from 'prop-types';

class RadioBlock extends Component {
    render() {
        const { 
            children,
            containerClass,
            name,
            id,
            value,
            handleOptionChange,
            selectedOption,
            label,
         } = this.props;
        return (
            <div className={`${containerClass} radio-block__item radio__container`}>
                <input
                    type="radio"
                    name={name}
                    className="form-radio large"
                    id={id}
                    value={value}
                    onChange={handleOptionChange}
                    checked={selectedOption === value}
                />
                <label htmlFor={value}>
                    <div className="row">
                        <div className="col-xl-3 col-lg-4">
                            <span className="form-radio-radiomark"></span>
                            <span className="form-radio-label">
                                <span className={`status-icon status--${value}`}></span>
                                <span>{label}</span>
                            </span>
                        </div>
                        <div className="col-xl-9 col-lg-8">
                            {children}
                        </div>
                    </div>
                </label>
            </div>
        )
    }
}

export default RadioBlock;

RadioBlock.propTypes = {
    children: PropTypes.node.isRequired,
    containerClass: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    handleOptionChange: PropTypes.func.isRequired,
    selectedOption: PropTypes.string,
    label: PropTypes.string.isRequired,
};
