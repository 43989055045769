import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ActionDropdown from './ActionDropdown';
import Pagination from './../common/Pagination';
import Preloader from './../common/Preloader';

class AnalytePanelList extends Component {

    state = {
        pageOfItems: [],
    }

    /**
     * Update state with new page of items
     */
    onChangePage = (pageOfItems) => {
        this.setState({ pageOfItems });
    }

    render() {
        const {
            filteredPanels,
            isLoading,
            deletePanel,
        } = this.props;

        const { 
            pageOfItems
        } = this.state;

        return (
            <React.Fragment>
                <div className="analyte-panel-list flex-table filter-table container">
                    <div className="row list--heading">
                        <div className="item item__panel">Panel</div>
                        <div className="item item__analytes">Analytes</div>
                        <div className="item item__mbs-code">MBS Code</div>
                        <div className="item item__status"></div>
                        <div className="item item__actions"></div>
                    </div>
                    {
                        filteredPanels && filteredPanels.length ?
                        pageOfItems.map((panel, index) => {
                            return (
                                <div key={panel.Panel_ID} className={`row list--item list--item-${index}`}>
                                    <div className="item item__panel">
                                        {panel.Panel_code}
                                    </div>
                                    <div className="item item__analytes">
                                        {panel.Analytes}
                                    </div>
                                    <div className="item item__mbs-code">
                                        {panel.MBS_code}
                                    </div>
                                    <div className="item item__status">
                                        {
                                            panel.Live === 1 ?
                                                <span className="panel--live small-heading">Live</span>
                                                : null
                                        }
                                    </div>
                                    <div className="item item__actions">
                                        <ActionDropdown
                                            panel={panel}
                                            index={index}
                                            deletePanel={deletePanel}
                                        />
                                    </div>
                                </div>
                            )
                        })
                        :
                        <div className="row list--item list--no-results">
                            <div className="item">{isLoading ? 'Fetching panels...' : 'No matching records found'}</div>
                        </div>
                    }
                    {
                    (isLoading) ? <Preloader /> : null
                }
                </div>
                <Pagination 
                    items={filteredPanels} 
                    onChangePage={this.onChangePage}
                    searchQuery={this.props.searchQuery}
                />
            </React.Fragment>
        )
    }
}

export default AnalytePanelList;

AnalytePanelList.propTypes = {
    filteredPanels: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    deletePanel: PropTypes.func.isRequired,
    searchQuery: PropTypes.string,
};
