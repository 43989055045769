import React, { Fragment, useEffect } from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import './../../../../scss/tag-view.scss';
import LocationTag from './LocationTag';
import GenderTestTag from './GenderTestTag';

const GenderTestOverride = (props) => {
    useEffect(() => {
        // Only fetched who's gender is male or female only
        // Added in ProtocolOverviewContainer
        // console.log("Fetched Panels: ", props.fetchedPanels.filter(e => e.Gender_specific != 'All' && e.Gender_specific!=null))
    }, [props.fetchedPanels]);
    return (
        <div
            className='location-container'
        >
            <div className='locations-display'>
                {
                    props.selectedGenderTest.map((element) => {
                        return <GenderTestTag
                            removeGenderTest={props.removeGenderTest}
                            fetchedPanels={element}
                        />
                    })
                }


            </div>
            <Dropdown
                className='location-dropdown'
                // options={props.fetchedPanels.map(e => e.Panel_ID)}
                options={props.fetchedPanels.map(e => e.Panel_code)}
                value='select'
                placeholder="Select an option"
                onChange={props.addGenderTest}
            />

        </div>
    )
};

export default GenderTestOverride