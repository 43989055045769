import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';

import { generatePortalUrl } from './../../../utils/constants';

import SVG from 'react-inlinesvg';
import close from './../../../assets/images/close.svg';

Modal.setAppElement('#root');
class CancelPanel extends Component {
    state = {
        modalIsOpen: false
    };

    openModal = () => {
        this.setState({ modalIsOpen: true });
    }

    closeModal = () => {
        this.setState({ modalIsOpen: false });
    }

    handleCancelClick = () => {
        this.props.history.push(generatePortalUrl('analytes-and-panels'));
    }

    renderTitle = () => {
        const { isEdit } = this.props;
        if (isEdit) {
            return 'Cancel editing panel';
        } else {
            return 'Cancel adding panel';
        }
    }

    render() {
        const { isSubmitting } = this.props;

        return (
            <div>
                <button
                    className="btn btn-secondary btn--cancel"
                    disabled={isSubmitting}
                    onClick={this.openModal}
                    type="button"
                >
                    Cancel
                </button>
                <Modal
                    className="modal__bootstrap modal--cancel-panel modal-dialog"
                    overlayClassName="overlay"
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">{this.renderTitle()}</h3>
                            <button type="button" className="close" onClick={this.closeModal}>
                                <span aria-hidden="true">
                                    <SVG className="icon icon--close" src={close} />
                                </span>
                                <span className="sr-only"></span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>This will delete all the changes you’ve made to the panel</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={this.closeModal}>Go back</button>
                            <button type="button" className="btn btn-primary" onClick={this.handleCancelClick}>Cancel without saving</button>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default withRouter(CancelPanel);

CancelPanel.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func,
    }),
};