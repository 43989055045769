import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import DeletePanelModal from './DeletePanelModal';

import { generatePortalUrl } from './../../utils/constants';

import SVG from 'react-inlinesvg';

import dots from './../../assets/images/dots.svg';

import './../../scss/action-dropdown.scss';

class ActionDropdown extends Component {

    state = {
        toggled: false,
        deleteModalIsOpen: false,
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleOutsideClick, false);
    }

    /**
     * Handle clicks outside the component.
     * Hide the dropdown when someone clicks outside of it.
     */
    handleOutsideClick = (e) => {
        // ignore clicks on the component itself
        if (!e.target.closest('.action-dropdown')) {
            this.hideDropdown();
            document.removeEventListener('click', this.handleOutsideClick, false);
        }
    }

    toggleDropdown = () => {
        const toggled = !this.state.toggled;
        this.setState({ toggled });
        //console.log('Toggle the dropdown!');


        setTimeout(() => {
            if (toggled) {
                document.addEventListener('click', this.handleOutsideClick, false);
            } else {
                document.removeEventListener('click', this.handleOutsideClick, false);
            }
        }, 100);

    }

    hideDropdown = () => {
        const toggled = false;
        const submenuToggled = false;
        this.setState({ toggled, submenuToggled });
    }

    openDeleteModal = () => {
        this.setState({
            deleteModalIsOpen: true,
        });
    }

    closeDeleteModal = () => {
        this.setState({
            deleteModalIsOpen: false,
        });
    }

    render() {
        const toggled = this.state.toggled;
        const {
            panel,
            deletePanel,
        } = this.props;

        return (
            <div className="action-dropdown__container">
                <button className="btn-action" onClick={() => this.toggleDropdown()}>
                    <SVG className="icon icon--dots" src={dots} />
                </button>
                {/* { toggled ? this.renderDropdown() : null } */}
                <div className={`action-dropdown dropdown-menu ${toggled ? 'show' : ''}`}>
                    <Link to={generatePortalUrl(`analytes-and-panels/panels/edit/${panel.Panel_ID}`)} className="dropdown-item">Edit</Link>
                    {
                        panel.Live === 1 ?
                            null :
                            <React.Fragment>
                                <a className="dropdown-item" onClick={() => this.openDeleteModal()}>Delete panel</a>
                                <DeletePanelModal
                                    panel={panel}
                                    deleteModalIsOpen={this.state.deleteModalIsOpen}
                                    closeDeleteModal={this.closeDeleteModal}
                                    deletePanel={deletePanel}
                                />
                            </React.Fragment>
                    }
                </div>
            </div>
        )
    }
}

export default ActionDropdown;

ActionDropdown.propTypes = {
    panel: PropTypes.object,
    deletePanel: PropTypes.func.isRequired,
}