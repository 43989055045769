import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { PropTypes } from 'prop-types';

import axios from 'axios';
import { Auth } from 'aws-amplify';

import ReviewProtocolOverview from './ReviewProtocolOverview';
import ReviewScreening from './ReviewScreening';
import ReviewCohorts from './ReviewCohorts';
import ReviewProtocolStatus from './ReviewProtocolStatus';
import Preloader from './../../../common/Preloader';

import { 
    PROTOCOL_API, 
    generatePortalUrl, 
    GET_SCREENING_BY_PROTOCOLID_API,
    GET_COHORTLIST_BY_PROTOCOLID_API,
} from './../../../../utils/constants';

import './../../../../scss/review.scss';

class ReviewAndExport extends Component {

    state = {
        isLoadingProtocols: true,
        isLoadingScreening: true,
        isLoadingCohorts: true,
        protocolDetails: null,
        screeningDetails: null,
        cohortDetails: null,
        Screening_skipped: false
    }

    componentDidMount() {
        const { protocolId } = this.props;

        // Fetch protocol details, screening details, and cohort details
        this.loadAllDetails(protocolId);
    }

    /* Load ALL details  */
    loadAllDetails = (protocolId) => {
        protocolId = parseInt(protocolId);

        Auth.currentSession().then((response) => {
            const headers = { Authorization: response.idToken.jwtToken };

            this.loadProtocolDetails(protocolId, headers);
            this.loadScreeningDetails(protocolId, headers);
            this.loadCohortDetails(protocolId, headers);

        }).catch((error) => {
            this.setState({ 
                isLoadingProtocols: false,
                isLoadingScreening: false,
                isLoadingCohorts: false,
            });
            console.error(error);
        });
    }

    /* Load protocol details */
    loadProtocolDetails = (protocolId, headers) => {

        const url = PROTOCOL_API.GET;

        axios.get(url, { headers })
            .then((response) => {
                if (response.data) {
                    const result = response.data.result;

                    // Find the protocol from its ID
                    const protocol = result.filter(item => item.Protocol_ID === protocolId)[0];
                    
                    this.setState({
                        protocolDetails: protocol,
                    });
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .then(() => {
                // always executed
                this.setState({ isLoadingProtocols: false });
            });
    }

    /* Load screening details */
    loadScreeningDetails = (protocolId, headers) => {

        const url = GET_SCREENING_BY_PROTOCOLID_API.GET;

        axios.get(`${url}?protocolId=${protocolId}`, { headers })
            .then((response) => {
                if (response.data) {
                    // console.log(response);
                    if(response.data.Screening_skipped == null){

                        this.setState({Screening_skipped: false})
                    } else {
                        this.setState({Screening_skipped: response.data.Screening_skipped})
                    }
                    if(response.data[0] != null){
                        // console.log(response.data[0].result)
                        const result = response.data[0].result;
                        
                        this.setState({
                            screeningDetails: result,
                        });
                    }

                }




                
            })
            .catch((error) => {
                console.error(error);
            })
            .then(() => {
                // always executed
                this.setState({ isLoadingScreening: false });
            });
    }

    /* Load cohort details */
    loadCohortDetails = (protocolId, headers) => {

        const url = GET_COHORTLIST_BY_PROTOCOLID_API.GET;

        axios.get(`${url}?protocolId=${protocolId}`, { headers })
            .then((response) => {
                if (response.data) {
                    const result = response.data[0].result;

                    this.setState({
                        cohortDetails: result,
                    });
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .then(() => {
                // always executed
                this.setState({ isLoadingCohorts: false });
            });
    }

    /**
     * Takes user back to the Protocols listing page
     */
    handleSaveAndExit = () => {
        this.props.history.push(generatePortalUrl('protocols'));
    }

    render() {
        const protocolDetails = this.state.protocolDetails;
        const isLoadingProtocols = this.state.isLoadingProtocols;
        const isLoadingScreening = this.state.isLoadingScreening;
        const isLoadingCohorts = this.state.isLoadingCohorts;

        return (
            <React.Fragment>
            <div className="review__container">
                <div className="review">
                    <h3>Review and export</h3>
                    <div className="review-section">
                        <ReviewProtocolOverview
                            protocolDetails={this.state.protocolDetails}
                            updateCurrentStep={this.props.updateCurrentStep}
                            updateIsEditFromReviewScreen={this.props.updateIsEditFromReviewScreen}
                        />
                        {
                            !this.state.Screening_skipped ? 
                            <ReviewScreening
                                screeningDetails={this.state.screeningDetails}
                                updateCurrentStep={this.props.updateCurrentStep}
                                updateIsEditFromReviewScreen={this.props.updateIsEditFromReviewScreen}
                            /> : null
                        }
                        
                        <ReviewCohorts
                            Screening_skipped={this.state.Screening_skipped}
                            cohortDetails={this.state.cohortDetails}
                            updateCurrentStep={this.props.updateCurrentStep}
                            updateEditEpisodeFromReviewScreen={this.props.updateEditEpisodeFromReviewScreen}
                            updateIsEditFromReviewScreen={this.props.updateIsEditFromReviewScreen}
                        />
                    </div>

                    {
                        protocolDetails ?
                        <ReviewProtocolStatus
                            username={this.props.username}
                            protocolDetails={this.state.protocolDetails}
                            protocolId={this.props.protocolId}
                        /> : null
                    }
                    
                    <div className="review-section form-btn-container btn-container">
                        <div className="row">
                            <div className="col text-right">
                                <button
                                    className="btn btn-primary btn--save-exit"
                                    onClick={this.handleSaveAndExit}
                                >
                                    Save and exit protocol
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
            (isLoadingProtocols || isLoadingScreening || isLoadingCohorts) ?
                <Preloader />
                : null
            }
            </React.Fragment>
        )
    }
}

export default withRouter(ReviewAndExport);

ReviewAndExport.propTypes = {
    protocolId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    username: PropTypes.string.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func,
    }),
    updateCurrentStep: PropTypes.func.isRequired,
    updateIsEditFromReviewScreen: PropTypes.func.isRequired,
    updateEditEpisodeFromReviewScreen: PropTypes.func.isRequired,
}