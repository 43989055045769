import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './../../scss/progress-bar.scss';

class ProgressBar extends Component {
    render() {
        const { percentage } = this.props;
        return (
            <span className={`progress ${percentage === 100 ? 'progress--done' : 'progress--in-progress'}`}>
                <span
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: percentage + '%' }}
                    aria-valuenow={percentage}
                    aria-valuemin="0"
                    aria-valuemax="100"
                ></span>
            </span>
        )
    }
}

export default ProgressBar;

ProgressBar.propTypes = {
    percentage: PropTypes.number.isRequired,
};