import React, { Component } from 'react';
import PropTypes from 'prop-types';


import SVG from 'react-inlinesvg';
import chevronLeft from '../../../../assets/images/chevron-left.svg';

import moment from 'moment';
import '../../../../scss/kit-print.scss';
import KitPrintingFilters from './KitsPrintingFilters';
import KitsTablePrint from './KitsTablePrint';
import { dateFormat } from '../../../../utils/constants';
class KitListPrint extends Component {

    state = {
        number: 16,
    }
    
    componentDidMount() {
        // Display print dialog box

        // https://www.google.com.au/search?ei=uTl6W9rFN5fr-QbMjZfYDg&q=react+link+maintain+state+in+new+tab&oq=react+link+maintain+state+in+new+tab&gs_l=psy-ab.3...8962.11376.0.11503.11.10.0.0.0.0.276.1226.0j4j2.6.0....0...1c.1.64.psy-ab..7.2.344...35i39k1.0.Y4XqJcxW7A8
        // print();
    }
    render() {
        const {
            hidePrintPage,
            kitsToPrint,
            kitsToDisplay,
            statusCheckboxes,
            selectedDayStart,
            selectedDayEnd,
            dateFilter
        } = this.props;

        let heading = 'Kit Summary';
        let packingFilter = 'All'
        if(statusCheckboxes.incomplete == statusCheckboxes.complete){
            packingFilter = 'All';
        } else if(statusCheckboxes.incomplete) {
            packingFilter = 'Incomplete'
        } else if(statusCheckboxes.complete){
            packingFilter = 'Complete'
        }
        switch(kitsToDisplay){
            case 'lowstock':
                heading = 'Low stock kits'
            break;
            case 'expiringkits':
                heading = 'Expiring kits'
            break;
        }
        return (
            <div className="a4-page-landscape kit-print container">
                <div className="kit-print__back row">
                    <div className="col">
                        <div className="page__back">
                            <span
                                className="kit-print__back__link page__back__link"
                                onClick={hidePrintPage}
                            >
                                <SVG className="icon icon--back" src={chevronLeft} />
                                <span className="link">Back</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="row" style={{padding: '1rem', display: 'flex'}}>
                    <div className="col-lg-4 col-md-4">
                        {
                            this.props.kitsToDisplay == 'episode' && <KitPrintingFilters
                            selectedFilters = {{
                                dateRange: !dateFilter ? 'All' :  moment(selectedDayStart).format(dateFormat) + ' - ' + moment(selectedDayEnd).format(dateFormat),
                                protocol: this.props.stringFilter == '' ? 'All' : this.props.stringFilter,
                                packingStatus: packingFilter
                            }}
                        />
                        }
                    </div>
                    <div className="col-lg-4 col-md-4 printing_title">
                        <h2>{heading}</h2>
                    </div>
                    <div className="col-lg-4 col-md-4 date-printed">
                        <label>Date Printed</label>
                        <span>{moment(new Date).format(dateFormat)}</span>
                    </div>
                </div>
                <KitsTablePrint
                    kitsToPrint={kitsToPrint}
                    kitsToDisplay={kitsToDisplay}
                />

            </div>
        )
    }
}

export default KitListPrint;

KitListPrint.propTypes = {
    hidePrintPage: PropTypes.func.isRequired,
    kitsToPrint: PropTypes.array.isRequired,
    kitsToDisplay: PropTypes.string.isRequired,
    stringFilter: PropTypes.string.isRequired,
    selectedDayStart: PropTypes.any.isRequired,
    selectedDayEnd: PropTypes.any.isRequired,
    statusCheckboxes: PropTypes.any.isRequired,
    dateFilter: PropTypes.bool.isRequired,
};
