import React, { Component } from 'react';
import PropTypes from 'prop-types';

import UpdateProtocolStatusConfirmModal from './AddEditProtocol/ReviewAndExport/UpdateProtocolStatusConfirmModal';

import { PROTOCOL_STATUS_MAPPING, getAvailableStatuses } from './../../utils/protocol-statuses';

class UpdateProtocolStatus extends Component {

    state = {
        modalIsOpen: false,
        selectedStatus: null,
    }

    renderStatuses = () => {
        const { protocol } = this.props;
        if (protocol && protocol.Status_id) {
            const currentStatus = protocol.Status_id.toString();
            const availableStatuses = getAvailableStatuses(currentStatus);

            return availableStatuses.map(status => {
                return (
                    <a className="dropdown-item" onClick={() => this.handleUpdateStatus(status)} key={status}>
                        <span className={`status-icon status--${status}`}></span>
                        {PROTOCOL_STATUS_MAPPING[status]}
                    </a>
                )
            })
        }
    }

    openModal = () => {
        this.setState({
            modalIsOpen: true,
        });
    }

    closeModal = () => {
        this.setState({
            modalIsOpen: false,
        });
    }

    updateSelectedStatus = (status) => {
        this.setState({
            selectedStatus: status,
        });
    }

    handleUpdateStatus = (newStatus) => {
        const { 
            protocol, 
            updateProtocolStatusAPI, 
            toggleDropdown,
            hideSubmenu,
        } = this.props;
        //console.log(newStatus);
        
        // close the dropdown
        toggleDropdown();
        hideSubmenu();

        // update the selected status
        this.updateSelectedStatus(newStatus);
        
        // If live or completed, display a modal
        if (newStatus === '3' || newStatus === '4' || newStatus === '5') {
            this.openModal();
            return;
        }

        // otherwise call API
        updateProtocolStatusAPI(protocol, newStatus);

        // success: update the status of the protocol in protocols and filteredProtocols state
        // (in ProtocolListContainer)

    }
    
    render() {

        const modalIsOpen = this.state.modalIsOpen;

        return (
            <React.Fragment>
                { this.renderStatuses() }

                {
                    modalIsOpen ?
                        <UpdateProtocolStatusConfirmModal
                            updateProtocolStatusAPI={this.props.updateProtocolStatusAPI}
                            closeModal={this.closeModal}
                            modalIsOpen={this.state.modalIsOpen}
                            selectedStatus={this.state.selectedStatus}
                            protocol={this.props.protocol}
                            fromProtocolList={true}
                        /> : null
                }
            </React.Fragment>
        )
    }
}

export default UpdateProtocolStatus;

UpdateProtocolStatus.propTypes = {
    protocol: PropTypes.object,
    updateProtocolStatusAPI: PropTypes.func.isRequired,
    toggleDropdown: PropTypes.func.isRequired,
    hideSubmenu: PropTypes.func.isRequired,
};
