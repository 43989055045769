import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SVG from 'react-inlinesvg';
import increment from './../../../src/assets/images/increment.svg';
import decrement from './../../../src/assets/images/decrement.svg';

import './../../scss/number-incrementer.scss';

class NumberIncrementerGeneric extends Component {

    render() {
        const {
            updateNumber,
            value,
            min,
            max,
        } = this.props;
        
        return (
            <div
                className="number-incrementer"
            >
                <button
                    onClick={() => updateNumber('DECREMENT')}
                    className="invisible-input"
                    disabled={value <= min}
                >
                    <SVG className="icon icon--decrement" src={decrement} />
                </button>
                <button
                    onClick={() => updateNumber('INCREMENT')}
                    className="invisible-input"
                    disabled={value >= max}
                >
                    <SVG className="icon icon--increment" src={increment} />
                </button>
            </div>
        )
    }
}

export default NumberIncrementerGeneric;

NumberIncrementerGeneric.propTypes = {
    updateNumber: PropTypes.func.isRequired,
    value: PropTypes.any,
    min: PropTypes.any,
    max: PropTypes.any,
};