import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Checkbox from '../../common/Checkbox';
import { generatePortalUrl } from './../../../utils/constants';

class AddEditPanelForm extends Component {
    
    render() {
        const { 
            values, 
            errors,
            handleChange,
            isLive,
        } = this.props;
        const  fetchedTubes = this.props.fetchedTubes;

        // console.log('tubes:', JSON.stringify(fetchedTubes));
        return (
            <div className="add-edit-panel__form">
                <div className="form-row panel-name__container">
                    <div className={`form-group col-md-4 ${(errors.panelName || errors.form) ? 'has-error' : ''}`}>
                        <label htmlFor="panelName">Panel name</label>
                        <input type="text" name="panelName" id="panelName" maxLength="3" className="form-control form-control-lg" value={values.panelName} onChange={handleChange} disabled={isLive} />
                        {errors.panelName && <small id="panelNameHelp" className="form-text">{errors.panelName}</small>}
                    </div>
                    <div className="col-md-4">
                    {
                        isLive ?
                        <div>
                            <span className="panel--live">Live</span>
                        </div>
                        : null
                    }
                    </div>
                    <div className={`form-group col-md-4 ${(errors.panelName || errors.form) ? 'has-error' : ''}`}>
                        <label htmlFor="panelName">Gender Specific</label>
                        <select type="text" name="genderSpecific" id="gender" className="form-control" value={values.genderSpecific} onChange={handleChange}>
                            <option value="All">All</option>
                            <option value="Male Only">Male Only</option>
                            <option value="Female Only">Female Only</option>
                            
                        </select>
                    </div>
                </div>

                <div className="form-row panel-name__container">
                    <div className={`form-group col-md-8 ${(errors.testName || errors.form) ? 'has-error' : ''}`}>
                        <label htmlFor="testName">Test name</label>
                        <input name="testName" id="testName" maxLength="25" className="form-control" value={values.testName} onChange={handleChange} />
                        {errors.testName && <small id="testNameHelp" className="form-text">{errors.testName}</small>}
                    </div>
                    <div className={'form-row form-group col-md-4'} 
                        style={{
                            display:'flex', 
                            flexDirection:'row', 
                            alignItems:'center', 
                            marginBottom: 0
                        }}>
                        <div className="col-md-12" style={{marginTop:8}}>
                            <Checkbox
                                id="weightRequired"
                                name="weightRequired"
                                checked = {values.weightRequired}
                                label="Required Participant Weight at Collection"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className={`form-group col-md-4 ${(errors.tube || errors.form) ? 'has-error' : ''}`}>
                        <label htmlFor="tube">Tube</label>
                        <select type="text" name="tube" id="tube" className="form-control" value={values.tube} onChange={handleChange} disabled={isLive}>
                            <option value="">Select a tube</option>
                            {/* List of tubes should be fetched from API */}
                            { 
                                fetchedTubes && fetchedTubes.map(fetchedTubes => {
                                    return (
                                        <option value={fetchedTubes.Tube} key={fetchedTubes.Tube}>{fetchedTubes.Tube}</option>
                                    )
                                })
                             }
                        </select>
                        {errors.tube && <small id="tubeHelp" className="form-text">{errors.tube}</small>}
                    </div>

                    <div className={`form-group col-md-4 ${(errors.qty || errors.form) ? 'has-error' : ''}`}>
                        <label htmlFor="qty">Qty</label>
                        <input type="text" name="qty" id="qty" className="form-control" value={values.qty} onChange={handleChange} disabled={isLive} />
                        {errors.qty && <small id="qtyHelp" className="form-text">{errors.qty}</small>}
                    </div>

                    <div className={`form-group col-md-4 ${(errors.mbsCode || errors.oneanalytesmbscode || errors.form) ? 'has-error' : ''}`}>
                        <label htmlFor="mbsCode">MBS code (Optional)</label>
                        <input type="text" name="mbsCode" id="mbsCode" className="form-control" value={values.mbsCode} onChange={handleChange} />
                        {errors.mbsCode && 
                        <small id="mbsCodeHelp" className="form-text">
                        {
                            // Display a message with a link if the error is 'MBSCodeDoesNotExist'
                            errors.mbsCode === 'MBSCodeDoesNotExist' ?
                            <span>
                                This MBS code does not exist. Please <Link to={generatePortalUrl('analytes-and-panels/mbs-codes')} target="_blank">add a new MBS code</Link> or use an existing one.
                            </span>
                            :
                            // Else just display the error as normal
                            errors.mbsCode
                        }
                        </small>
                        }
                        {errors.oneanalytesmbscode && 
                        <small id="mbsCodeHelp" className="form-text">
                        {
                            // Display a message with a link if the error is 'OneAnalyteMBSCodeDoesNotExist'
                            errors.oneanalytesmbscode === 'OneAnalyteMBSCodeDoesNotExist' ?
                            <span>
                                Please enter a valid MBS code from the {"'1 ANALYTE'"} column. For more information please refer to the <Link to={generatePortalUrl('analytes-and-panels/mbs-codes')} target="_blank">Manage MBS codes</Link> table.
                            </span>
                            :
                            // Else just display the error as normal
                            errors.oneanalytesmbscode
                        }
                        </small>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default AddEditPanelForm;

AddEditPanelForm.propTypes = {
    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    isLive: PropTypes.bool.isRequired,
    fetchedTubes: PropTypes.array.isRequired, // should be shaped
};