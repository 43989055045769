import React, { Component } from 'react';

import KitsList from './KitsList';
import KitsFilter from './KitsFilter';

import moment from 'moment';

// Sample JSON
// import { screeningKitsJson } from './../../assets/screening-kits.js';
// import { episodeKitsJson } from './../../assets/episode-kits.js';

// APIS
import { SCREENING_KITS_API, EPISODE_KITS_API } from './../../utils/constants';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import KitListPrint from './KitListing/KitsPrinting/KitListPrint';
import { dateFormatAPI } from './../../utils/constants';
import { getWeekDays, getWeekRange } from './../../utils/dates';
import './../../scss/kits.scss';

class KitsListContainer extends Component {

    selectedFilters = {};
    state = {
        kits: [],
        filteredKits: [],
        kitsToDisplay: 'episode',
        isLoading: false,
        serverError: '',
        statusCheckboxes: {
            incomplete: true,
            complete: false,
        },
        selectedDays: [],
        dateFilter: true,
        filterDateStart: null,
        filterDateEnd: null,
        stringFilter: '',
        displayPrint: false
    }

    componentDidMount() {

        if(this.props.kittype == null){
            this.setState({kitsToDisplay:localStorage.getItem('kittype')})
        } else{
            this.setState({kitsToDisplay:this.props.kittype})
        }
        if(localStorage.getItem('dateFilter') != null){
            console.log('componentDidMount called', localStorage.getItem('dateFilter'))
            this.setState({dateFilter: localStorage.getItem('dateFilter') == '1' ? true : false})
        }
        if(localStorage.getItem('stringFilter') != null){
            this.setState({stringFilter: localStorage.getItem('stringFilter')})
        }
        if(localStorage.getItem('incomplete') != null){

            this.setState({
                statusCheckboxes: {
                    ...this.state.statusCheckboxes,
                    incomplete: localStorage.getItem('incomplete') == '1' ? true : false
                }
            })
        }
        if(localStorage.getItem('complete') != null){

            this.setState({
                statusCheckboxes: {
                    ...this.state.statusCheckboxes,
                    complete: localStorage.getItem('complete') == '1' ? true : false
                }
            })
        }
        // console.log('component mounted')
        this.getThisWeek();
        setTimeout(() => {
            this.fetchKitsAPI();
        }, 200);
    }

    resetState = () => {

        this.setState({
            kits: [],
            filteredKits: []
        });
    }

    resetEpisodeKitsFilters = ()=>{

        this.setState({
            statusCheckboxes: {
                incomplete: true,
                complete: false,
            },
            dateFilter: true,
        });


        localStorage.setItem('dateFilter', '1');
        localStorage.setItem('incomplete', '1');
        localStorage.setItem('complete', '0');
        localStorage.setItem('stringFilter', '');
    }

    fetchKitsAPI = () => {
        const { kitsToDisplay } = this.state;

        this.resetState();

        // Call the appropriate APIs
        if (kitsToDisplay === 'screening') {
            this.fetchScreeningKitsAPI();
            return;
        } else if (kitsToDisplay === 'episode') {
            this.beforeFetchEpisodeKitsAPI();
            return;
        } else if (kitsToDisplay === 'lowstock') {
            this.resetEpisodeKitsFilters();
            this.fetchLowStockKitsAPI();
            return;
        } else if (kitsToDisplay === 'expiringkits') {
            this.resetEpisodeKitsFilters();
            this.fetchExpiringKitsAPI();
             return;
        } else{
            this.setState({kitsToDisplay:'episode'},()=>{
                this.beforeFetchEpisodeKitsAPI();
                return;
            })
            
        } 
    }

    beforeFetchEpisodeKitsAPI = () => {//sjk
  
        if (this.state.filterDateStart == null || this.state.filterDateEnd == null) {
            return;
        }

        // Reset the state
        this.resetState();

        // Format the dates into the format for API
        const startDate = moment(this.state.filterDateStart).format(dateFormatAPI);
        const endDate = moment(this.state.filterDateEnd).format(dateFormatAPI);
        // console.log('lol')
        // console.log( startDate, endDate );

        // Call the fetch Episode kits API
        this.fetchEpisodeKitsAPI(startDate, endDate);
    }

    fetchEpisodeKitsAPI = (startDate, endDate) => {
        this.setState({ isLoading: true });
        let url = EPISODE_KITS_API.GET;
        url = this.state.dateFilter ? `${url}?fromDate='${startDate}'&toDate='${endDate}'` : url;
        Auth.currentSession().then((response) => {
            const headers = { Authorization: response.idToken.jwtToken };
            axios.get(url, { headers })
                .then((response) => {
                    if (response.data.statusCode && response.data.statusCode === 400) {
                        this.setState({
                            serverError: 'There was an error loading the kits. Please try again.',
                        });
                        return;
                    }
                    const result = this.mapPackingDetails(response.data[0].result);
                    this.setState({
                        kits: result,
                        filteredKits: result,
                    }, () => {
                        this.filterKits();
                    });
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    this.setState({ isLoading: false });
                });

        }).catch((error) => {
            console.error(error);
            this.setState({ isLoading: false });
        });
    }

    fetchLowStockKitsAPI = () => {
        this.setState({ isLoading: true });

        let url = EPISODE_KITS_API.GET;
        url = `${url}` + '?low_stock=true';
        console.log('url',url)
        Auth.currentSession().then((response) => {
            const headers = { Authorization: response.idToken.jwtToken };
            axios.get(url, { headers })
                .then((response) => {
                    if (response.data.statusCode && response.data.statusCode === 400) {
                        this.setState({
                            serverError: 'There was an error loading the kits. Please try again.',
                        });
                        return;
                    }
                    const result = this.mapPackingDetails(response.data[0].result);
                    this.setState({
                        kits: result,
                        filteredKits: result,
                    }, () => {
                        this.filterKits();
                    });
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    this.setState({ isLoading: false });
                });

        }).catch((error) => {
            console.error(error);
            this.setState({ isLoading: false });
        });
    }

    fetchExpiringKitsAPI = () => {
        this.setState({ isLoading: true });

        let url = EPISODE_KITS_API.GET;
        url = `${url}` + '?expired=-1';
        Auth.currentSession().then((response) => {
            const headers = { Authorization: response.idToken.jwtToken };

            // Fetch the Panels list
            axios.get(url, { headers })
                .then((response) => {

                    // console.log('resss',response)
                    // Status code 400 is a server error.
                    if (response.data.statusCode && response.data.statusCode === 400) {
                        this.setState({
                            serverError: 'There was an error loading the kits. Please try again.',
                        });
                        return;
                    }

                    const result = this.mapPackingDetails(response.data[0].result);
                    
                    this.setState({
                        kits: result,
                        filteredKits: result,
                    // Then filter based on current filter settings
                    }, () => {
                        this.filterKits();
                    });
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    this.setState({ isLoading: false });
                });

        }).catch((error) => {
            console.error(error);
            this.setState({ isLoading: false });
        });
    }

    fetchScreeningKitsAPI = () => {
        /* Uncomment to use sample JSON */
        // this.setState({
        //     kits: screeningKitsJson,
        //     filteredKits: screeningKitsJson,
        // })

        // API
        this.setState({ isLoading: true });

        const url = SCREENING_KITS_API.GET;

        Auth.currentSession().then((response) => {
            const headers = { Authorization: response.idToken.jwtToken };

            // Fetch the Panels list
            axios.get(url, { headers })
                .then((response) => {
                    // console.log(response);
                    
                    // Status code 400 is a server error.
                    if (response.data.statusCode && response.data.statusCode === 400) {
                        this.setState({
                            serverError: 'There was an error loading the kits. Please try again.',
                        });
                        return;
                    }

                    const result = this.mapPackingDetails(response.data[0].result);
                    this.setState({
                        kits: result,
                        filteredKits: result,
                    });
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    this.setState({ isLoading: false });
                });

        }).catch((error) => {
            console.error(error);
            this.setState({ isLoading: false });
        });
    }

    getThisWeek = () => {
        const today = new Date();
        const thisWeek = getWeekDays(getWeekRange(today).from);

        this.setState({
            filterDateStart: thisWeek[0],
            filterDateEnd: thisWeek[6],
        });
    }

    mapPackingDetails = (data) =>{

        data.forEach(element => {
            element.packing_details = this.packingDetailsToObject(element.packing_details)
            // console.log(element)
        });
        return data;
    }
    packingDetailsToObject = (item)=>{
        if(item == null){
            return null;
        } else {
            let packingDetailList = [];
            let _packingDetailList = item.split('&&');
            _packingDetailList.forEach(obj =>{
                if(obj.toString().trim() != ''){
                    let _objs = obj.toString().trim().split(',');
                    packingDetailList.push({
                        Id: _objs[0].toString().trim(),
                        Kits_packed: _objs[1].toString().trim(),
                        Packed_by: _objs[2].toString().trim(),
                        Date_packed: _objs[3].toString().trim(),
                        QC_by: _objs[4].toString().trim(),
                        QC_date: _objs[5].toString().trim(),
                        Created_at: _objs[6].toString().trim(),
                        Kitcode: _objs[7].toString().trim(),
                    })
                }
                 
            })
            return packingDetailList;
        }
    }
    updateDateFilter = (value)=>{
        localStorage.setItem('dateFilter', value == true ? '1' : '0')
        this.setState({dateFilter: value},()=>{
            this.fetchKitsAPI();
        })
    }
    updateSelectedDayStart = (dayStart) => {

        
        // console.log(dayStart)
        this.setState({
            filterDateStart: dayStart,
        }, () => {
            this.updateSelectedFilters('filterDateStart', dayStart);
        });
    }

    updateSelectedDayEnd = (dayEnd) => {

        
        // console.log(dayEnd)
        this.setState({
            filterDateEnd: dayEnd,
        }, () => {
            this.updateSelectedFilters('filterDateEnd', dayEnd);
        });

    }

    updateSelectedFilters = (key, value)=>{
        this.selectedFilters[key] = value;

        //send query string to apply filters
        this.beforeFetchEpisodeKitsAPI();
    }

    updateKitsToDisplay = (kitType) => {
        localStorage.setItem('kittype', kitType)
        this.setState({
            kitsToDisplay: kitType,
        }, () => {
            this.fetchKitsAPI();
        })
    }
    handleFilterByString = (value) => {
        this.setState({stringFilter: value});
        localStorage.setItem('stringFilter', value)
        // console.log(value)
        const { kits } = this.state;
        // Don't even do anything when there's no kits
        if (!kits || !kits.length || value == '') {
            return;
        }
        var filteredKits = kits.filter(function(event){
            return event.protocol.toLowerCase().includes(value.toLowerCase());
        });
        this.setState({ filteredKits });
    }
    /**
     * Update whether a status is checked or unchecked in the filter
     */
    updateStatusFilter = (name, value) => {
        localStorage.setItem(name, value == true ? '1' : '0')
        this.setState({
            statusCheckboxes: {
                ...this.state.statusCheckboxes,
                [name]: value
            }
        }, () => {
            this.filterKits();
        });

    }

    filterKits = () => {
        const { kits } = this.state;

        // Don't even do anything when there's no kits
        if (!kits || !kits.length) {
            return;
        }

        const filteredKits = this.filterKitsByPackagingStatus();
        //console.log(filteredKits);

        this.setState({ filteredKits },()=>{
            this.handleFilterByString(this.state.stringFilter)
        });
    }

    /**
     * Filter the list of Protocols by the status checkboxes
     */
    filterKitsByPackagingStatus = () => {

        const { kits, statusCheckboxes } = this.state;
        // console.log(statusCheckboxes);

        // If both are checked...
        if (statusCheckboxes.incomplete && statusCheckboxes.complete) {
            return kits;
        }

        // If neither are checked...
        if (!statusCheckboxes.incomplete && !statusCheckboxes.complete) {
            return kits;
        }

        const result = [];
        if (statusCheckboxes.complete) {
            for (let kit of kits) {
                if (kit.packed === kit.required) {
                    result.push(kit);
                }
            }
        }
        if (statusCheckboxes.incomplete) {

            // console.log('filering incomplete')
            for (let kit of kits) {
                if (kit.packed < kit.required) {
                    result.push(kit);
                }
            }
        }
        return result;
    }

    handlePrint = () => {

        this.displayPrintPage();
    }

    displayPrintPage = () => {
        this.setState({
            displayPrint: true,
        })
    }

    hidePrintPage = () => {
        this.setState({
            displayPrint: false,
        })
    }

    render() {


        if (this.state.filterDateStart == null || this.state.filterDateEnd == null){
            return <div/>
        }
        const {
            serverError,
            kitsToDisplay,
            displayPrint
        } = this.state;

        console.log('kitsToDisplay', this.state.kitsToDisplay);
        return (
            <React.Fragment>
                <div className="page-heading">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1>Kits</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={`kits-management section container ${displayPrint ? 'hide' : ''}`}>

                    <nav className="kits-management__nav">
                        <div className="row">
                            <div className="col-12">
                                <ul className="nav nav-links">
                                    <li className={`nav-item ${kitsToDisplay === 'episode' ? 'is-active' : ''}`}>
                                        <a className="nav-link" onClick={() => this.updateKitsToDisplay('episode')}>Screening and on-study tests</a>
                                    </li>
                                    <li className={`nav-item ${kitsToDisplay === 'lowstock' ? 'is-active' : ''}`}>
                                        <a className="nav-link" onClick={() => this.updateKitsToDisplay('lowstock')}>Low stock kits</a>
                                    </li>
                                    <li className={`nav-item ${kitsToDisplay === 'expiringkits' ? 'is-active' : ''}`}>
                                        <a className="nav-link" onClick={() => this.updateKitsToDisplay('expiringkits')}>Expiring kits</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>

                    {
                        serverError ?
                            <div className="form-errors__container row">
                                <div className="col-12">
                                    <div className="alert alert-danger" role="alert">
                                        <small>
                                            {serverError}
                                        </small>
                                    </div>
                                </div>
                            </div> : null
                    }
                    <div className="row kits__title">
                        <div className="col">
                            {
                                (kitsToDisplay === 'screening') && 
                                <h3>Screening kits</h3>
                            }
                            {
                                (kitsToDisplay === 'episode') &&
                                <h3>Screening and on-study test</h3>
                            }
                            {
                                (kitsToDisplay === 'lowstock') &&
                                <h3>Low stock kits</h3>
                            }
                            {
                                (kitsToDisplay === 'expiringkits') &&
                                <h3>Expiring kits</h3>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-2">
                            <KitsFilter
                                dateFilter={this.state.dateFilter}
                                stringFilter={this.state.stringFilter}
                                kitsToDisplay={kitsToDisplay}
                                updateStatusFilter={this.updateStatusFilter}
                                statusCheckboxes={this.state.statusCheckboxes}
                                selectedDayStart={this.state.filterDateStart}
                                selectedDayEnd={this.state.filterDateEnd}
                                updateSelectedDayStart={this.updateSelectedDayStart}
                                updateSelectedDayEnd={this.updateSelectedDayEnd}
                                handleFilterByString = {this.handleFilterByString}
                                updateDateFilter = {this.updateDateFilter}
                                handlePrint = {this.handlePrint}

                            />
                        </div>
                        <div className="col-lg-10">
                            <KitsList
                                kitsToDisplay={kitsToDisplay}
                                filteredKits={this.state.filteredKits}
                                isLoading={this.state.isLoading}
                                statusCheckboxes={this.state.statusCheckboxes}
                                selectedDayStart={this.state.filterDateStart}
                                selectedDayEnd={this.state.filterDateEnd}
                            />
                        </div>
                    </div>
                    
                </section>
                {
                    displayPrint && <section className={`kits-management section container ${!displayPrint ? 'hide' : ''}`}>
                        <KitListPrint
                            dateFilter={this.state.dateFilter}
                            stringFilter={this.state.stringFilter}
                            selectedDayStart={this.state.filterDateStart}
                            selectedDayEnd={this.state.filterDateEnd}
                            statusCheckboxes={this.state.statusCheckboxes}
                            hidePrintPage={this.hidePrintPage}
                            kitsToPrint={this.state.filteredKits}
                            kitsToDisplay={this.state.kitsToDisplay}
                        />
                    </section>
                }
            </React.Fragment>
        )
    }
}

export default KitsListContainer;