import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Checkbox from './../common/Checkbox';
import CustomisedDayPicker from './../common/CustomisedDayPicker';
import { formatDate, parseDate } from 'react-day-picker/moment';

import './../../scss/filter.scss';

class KitsFilter extends Component {

    constructor(props){
        super(props)
    }
    updateDateFilter = (event)=>{

        this.props.updateDateFilter(event.target.checked)
    }
    handleCheck = (e) => {
        const target = e.target;
        const value = target.checked;
        const name = target.id;

        // Update the checked state of the status
        this.props.updateStatusFilter(name, value);
    }
    handleFilterByString = (event) => {
        this.props.handleFilterByString(event.target.value)
    }
    render() {

        const { statusCheckboxes, kitsToDisplay, handlePrint } = this.props;
        return (
            <div className="filter__container">
                {(kitsToDisplay === 'episode') && <div className="filters__heading">Filter</div>}

                <div className="filters">
                    {
                        (kitsToDisplay === 'episode') && <div className="filter checkbox-filter__container">

                        <div className="checkbox-filter">
                            <Checkbox
                                id="filterbydate"
                                name="status"
                                value="filterbydate"
                                label="Date Range"
                                onChange={this.updateDateFilter}
                                checked={this.props.dateFilter}
                            />
                        </div>
                    </div>
                    }
                    {
                        // Display the date picker only for Episode kits
                        (kitsToDisplay === 'episode') && this.props.dateFilter &&
                        <div className="filter search-filter__container">
                            <label htmlFor="search" className="filter-label">From</label>
                            
                            <CustomisedDayPicker
                                date={this.props.selectedDayStart}
                                updateSelectedDays={this.props.updateSelectedDayStart}
                                disabledDays={{after: this.props.selectedDayEnd}}
                            />
                            <label htmlFor="search" className="filter-label">To</label>
                            <CustomisedDayPicker
                                date={this.props.selectedDayEnd}
                                updateSelectedDays={this.props.updateSelectedDayEnd}
                                disabledDays={{before: this.props.selectedDayStart}}
                            />
                            
                        </div>
                    }
                    {
                        (kitsToDisplay === 'episode') && <div className="filter search-filter__container">
                            {/* React's onChange has the same behaviour as onInput */}
                            
                            <label htmlFor="search" className="filter-label">Protocol number</label>
                            <input name="search" type="text" className="form-control" placeholder="Search" defaultValue={this.props.stringFilter} onChange={this.handleFilterByString} />
                        </div>
                    }
                    {
                        (kitsToDisplay === 'episode') &&  <div className="filter checkbox-filter__container">
                            <label htmlFor="status" className="filter-label">Status</label>

                            <div className="checkbox-filter">
                                <Checkbox
                                    id="incomplete"
                                    name="status"
                                    value="incomplete"
                                    label="Incomplete packing"
                                    onChange={this.handleCheck}
                                    checked={statusCheckboxes['incomplete']}
                                />
                                <Checkbox
                                    id="complete"
                                    name="status"
                                    value="complete"
                                    label="Complete packing"
                                    onChange={this.handleCheck}
                                    checked={statusCheckboxes['complete']}
                                />

                                
                            </div>
                        </div>
                    }

                    <button className="btn btn-primary" onClick={handlePrint}>Print kit summary</button>
                </div>
            </div>
        )
    }
}

export default KitsFilter;

KitsFilter.propTypes = {
    updateStatusFilter: PropTypes.func.isRequired,
    statusCheckboxes: PropTypes.object.isRequired, // should be shaped
    kitsToDisplay: PropTypes.string.isRequired,
    selectedDayStart: PropTypes.any.isRequired,
    selectedDayEnd: PropTypes.any.isRequired,
    updateSelectedDayStart: PropTypes.func.isRequired,
    updateSelectedDayEnd: PropTypes.func.isRequired,
    handleFilterByString: PropTypes.func.isRequired,
    updateDateFilter: PropTypes.func.isRequired,
    handlePrint: PropTypes.func.isRequired,
    dateFilter: PropTypes.bool.isRequired,
    stringFilter: PropTypes.string.isRequired
};