import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';


import axios from 'axios';
import { Auth } from 'aws-amplify';

import AddEditPanelForm from './AddEditPanelForm';
import AddEditPanelAnalytes from './AddEditPanelAnalytes';
import CancelPanel from './CancelPanel';
import ConfirmEditPanelModal from './ConfirmEditPanelModal';
import Preloader from './../../common/Preloader';

import { ANALYTE_API, PANEL_API, TUBE_API, GET_MBS_CODES_API , UPSERT_PANEL_BY_ID_API , generatePortalUrl } from './../../../utils/constants';

import { isAlphanumeric, isInt } from '../../../utils/utils';

import _ from 'lodash';

// These are analytes already declared in all panels. To be fetched
let existingAnalytes = [];
let existingPanels = [];
let existingMBSCodes = [];
let existingOneAnalytesMBSCodes = [];
let currentPanelName = '';

class AddEditPanelContainer extends Component {

    state = {
        isFetchingPanels: false,
        isFetchingAnalytes: false,
        isFetchingTubes: false,
        isFetchingMBSCodes: false,
        isSubmitting: false,
        isLive: false,
        isEdit: false,
        editModalIsOpen: false,
        values: {
            Panel_ID: null,
            panelName: '',
            testName: '',
            genderSpecific: 'All',
            weightRequired: false,
            tube: '',
            qty: '',
            mbsCode: '',
            oneanalytesmbscode: '',
            analyte: '',
        },
        analytes: [],
        previousAnalytes: [],
        fetchedTubes: [],
        errors: {
            panelName: '',
            testName: '',
            tube: '',
            qty: '',
            mbsCode: '',
            oneanalytesmbscode: '',
            analytes: '',
        },
        serverError: '',
    }

    componentDidMount() {
        
        // Reset the existing arrays
        existingAnalytes = [];
        existingPanels = [];
        existingMBSCodes = [];
        existingOneAnalytesMBSCodes = [];
        currentPanelName = '';
        
        // Fetch APIs
        this.fetchTubesAPI();
        setTimeout(() => {
            this.fetchAnalytesAPI();
        }, 500);
        this.fetchPanelsAPI();
        this.fetchMBSCodeAPI();
    }

    /* Fetches the list of panels */
    fetchPanelsAPI = () => {
        // If a panel ID exists in the url, it is an edit
        const panelId = this.props.match.params.panelId;

        this.setState({ isFetchingPanels: true });

        const url = PANEL_API.GET;

        Auth.currentSession().then((response) => {
            const headers = { Authorization: response.idToken.jwtToken };

            // Fetch the Panels list
            axios.get(url, { headers })
                .then((response) => {
                    if (response.data) {
                        const result = response.data.result;
                        existingPanels.push(...result);
                        // console.log(existingPanels);

                        if (panelId && panelId !== undefined) {
                            // console.log("result",result)
                            this.populatePanel(panelId, result);
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    this.setState({ isFetchingPanels: false });
                });

        }).catch((error) => {
            console.error(error);
            this.setState({ isFetchingPanels: false });
        });
    }

    /* Populate panel results to the state, and set the isEdit flag to true */
    populatePanel = (panelId, result) => {
        const { values } = this.state;
        //console.log(`populate panel ${panelId} results to the state`);
        // console.log(result);

        const currentPanel = result.find(item => item.Panel_ID == panelId);
        //console.log(currentPanel);

        if (!currentPanel) {
            return;
        }

        // Set isLive flag
        const isLive = (currentPanel.Live && currentPanel.Live === 1) ? true : false;

        // Parse analytes string to an array to display
        const analytes = this.parseAnalytes(currentPanel);

        // Set existing panel name so we can later use for validation
        currentPanelName = currentPanel.Panel_code

        this.setState({
            values: {
                ...values,
                Panel_ID: currentPanel.Panel_ID,
                panelName: currentPanel.Panel_code,
                testName: currentPanel.Test_name,
                genderSpecific: currentPanel.Gender_specific,
                weightRequired: currentPanel.Weight_required,
                tube: currentPanel.tube,
                qty: currentPanel.quantity,
                mbsCode: currentPanel.MBS_code,
                Live: currentPanel.Live,
            },
            isEdit: true,
            isLive,
            analytes: analytes,
            previousAnalytes: analytes,
        })
    }

    /* Fetch the list of tubes */
    fetchTubesAPI = () => {
        //console.log('fetching list of tubes');
        // Fetching...
        this.setState({ isFetchingTubes: true, })

        const url = TUBE_API.GET;

        Auth.currentSession().then((response) => {
            const headers = { Authorization: response.idToken.jwtToken };

            // Fetch the Panels list
            axios.get(url, { headers })
                .then((response) => {
                    if (response.data) {
                        // console.log(response.data);
                        const result = response.data.result;
                        if (result && result.length) {
                            console.log('result111111111999999',result);
                            this.setState({
                                fetchedTubes: result,
                            });
                        }

                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    this.setState({ isFetchingTubes: false, })
                });

        }).catch((error) => {
            console.error(error);
            this.setState({ isFetchingTubes: false, })
        });
    }

    /* Fetch the list of MBS Codes */
    fetchMBSCodeAPI = () => {
        //console.log('fetching list of MBS Codes');
        // Fetching...
        this.setState({ isFetchingMBSCodes: true, })

        const url = GET_MBS_CODES_API.GET;

        Auth.currentSession().then((response) => {
            const headers = { Authorization: response.idToken.jwtToken };

            // Fetch the Panels list
            axios.get(url, { headers })
                .then((response) => {
                    if (response.data) {
                        const result = response.data.result;
                        if (result && result.length) {
                            // console.log(result);
                            this.populateMBSCodes(result);
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => { 
                    this.setState({ isFetchingMBSCodes: false, })
                });

        }).catch((error) => {
            console.error(error);
            this.setState({ isFetchingMBSCodes: false, })
        });
    }
    
    /* Extract the MBS codes from the API result and store it in an external array */
    populateMBSCodes = (result) => {
        const MBSCodes = [];
        const OneAnalytesMBSCodes = [];
        result.map(item => {
            if (item.MBS_code) {
                OneAnalytesMBSCodes.push(item.MBS_code);
            }
            if (item.Two_codes) {
                MBSCodes.push(item.Two_codes);
            }
            if (item.Three_codes) {
                MBSCodes.push(item.Three_codes);
            }
            if (item.Four_codes) {
                MBSCodes.push(item.Four_codes);
            }
            if (item.Five_codes) {
                MBSCodes.push(item.Five_codes);
            }
        });
        existingMBSCodes = MBSCodes;
        existingOneAnalytesMBSCodes = OneAnalytesMBSCodes;
       // console.log('existingOneAnalytesMBSCodes....'+existingOneAnalytesMBSCodes);
       // console.log('existingMBSCodes....'+existingMBSCodes);
    }

    /* Fetches the list of analytes */
    fetchAnalytesAPI = () => {

        this.setState({ isFetchingAnalytes: true });

        const url = ANALYTE_API.GET;

        Auth.currentSession().then((response) => {
            // console.log('Token:', response.idToken.jwtToken)
            const headers = { Authorization: response.idToken.jwtToken };

            // Fetch the Analytes list
            axios.get(url, { headers })
                .then((response) => {

                    // console.log('Response:', response)
                    if (response.data) {
                        const result = JSON.parse(response.data.result);
                        existingAnalytes.push(...result);
                        // console.log('existint',existingAnalytes);
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    this.setState({ isFetchingAnalytes: false });
                });

        }).catch((error) => {
            console.error(error);
            this.setState({ isFetchingAnalytes: false });
        });
    }

    /* Parse analytes string to an array for display */
    parseAnalytes = (panel) => {
        const analytes = panel.Analytes;
        if (!analytes) {
            return [];
        }
        const parsed = JSON.parse(analytes);
        return parsed;
    }

    handleChange = (e) => {
        const target = e.target;
        let value = target.value;
        const name = target.name;

        
        // Panel names are automatically entered as uppercase
        if (name === 'panelName') {
            value = value.toUpperCase();
        } else if (name === 'weightRequired') {
            value = target.checked;
        } 

        // console.log(value)
        // console.log(name)

        const values = this.state.values;
        this.setState({
            values: {
                ...values,
                [name]: value
            }
        });
    }

    handleChangeForAnalyte = (e) => {
        const target = e.target;
        const value = target.value.replace('"', ''); // Prevent the user from entering "
        const name = target.name;

        const values = this.state.values;
        this.setState({
            values: {
                ...values,
                [name]: value
            }
        });
    }

    handleAddAnalyte = (e) => {
        e.preventDefault();

        // Get the user's input
        const { values } = this.state;
        //console.log(`analyte to add: ${values.analyte}`);

        // Trim the input value
        this.setState({ 
            values: {
                ...values,
                analyte: _.trim(values.analyte),
            }
        }, () => {
            // ...then validate
            this.validateAnalyte();
        });
    }

    validateAnalyte = () => {
        const { values, errors } = this.state;

        // Check if there's a duplicate in the current panel already, and display an error if so
        const alreadyInCurrentPanel = this.checkAnalyteAgainstCurrentPanel(values.analyte);
        if (alreadyInCurrentPanel !== undefined) {
            this.setState({
                errors: {
                    ...errors,
                    analytes: 'This analyte already exists in the current panel'
                },
            });
            return;
        }

        // Check if there's a duplicate already in existingAnalytes, and display an error if so
        const duplicate = this.checkAnalyteAgainstExistingAnalytes(values.analyte);
        if (duplicate !== undefined) {
            const Panel_code = duplicate.Panel_code || '';
            this.setState({
                errors: {
                    ...errors,
                    analytes: `This analyte already exists in panel ${Panel_code}`
                },
            });
            return;
        }

        this.addAnalyte(values.analyte);
    }

    addAnalyte = (analyte) => {
        // Get the analytes array
        const analytes = [...this.state.analytes];
        const { values, errors } = this.state;

        // Add the analyte to the analytes array
        analytes.push(analyte);

        // Set the state
        this.setState({ 
            analytes,
            values: {
                ...values,
                analyte: '', // Reset the input field
            },
            errors: {
                ...errors,
                analytes: '',
            }
        });

        // Focus on the input again
        const element = document.querySelector('#analyte');
        element.focus();
    }

    /* Check if there's a duplicate already in existingAnalytes */
    checkAnalyteAgainstExistingAnalytes = (string) => {
        const analyte = string.toLowerCase();
        const duplicate = existingAnalytes.find(item => {
            return item.Analyte.toLowerCase() === analyte;
        });

        return duplicate;
    }

    /* Check if there's a duplicate in the current panel already */
    checkAnalyteAgainstCurrentPanel = (string) => {
        const { analytes } = this.state;
        const analyte = string.toLowerCase();
        const duplicate = analytes.find(item => {
            return item.toLowerCase() === analyte;
        })

        return duplicate;
    }

    /* Check for a duplicate panel name */
    checkDuplicatePanelName = (panelName) => {

        // Check if the panel name is the same as before. If so, that's fine.
        if (panelName === currentPanelName) {
            return true;
        }

        // If the panel name has changed, check if it's the same as another one.
        const duplicate = existingPanels.find(item => {
            return item.Panel_code.toLowerCase() === panelName.toLowerCase();
        });

        if (duplicate) {
            return false;
        }

        return true;
    }

    /* Check that the MBS code exists */
    checkMBSCodeExists = (mbsCode) => {
        const find = existingMBSCodes.find(item => item === mbsCode);
        if (!find) {
            return false;
            
        }
        return true;
    }

    /* Check that the 1 analytes MBS Code exists */
    checkOneAnalytesMBSCodeExists = (oneanalytesmbscode) => {
        const find = existingOneAnalytesMBSCodes.find(item => item === oneanalytesmbscode)
        if(!find){
            return false;
        }
        return true;
    }

    handleRemoveAnalyte = (index) => {
        //console.log(`Removing analyte ${index}`);

        // Get the analytes array
        const analytes = [...this.state.analytes];

        // Remove the analyte based on its index
        analytes.splice(index, 1);

        // Set the state
        this.setState({
            analytes,
        });
    }
    
    handleSubmit = (e) => {
        e.preventDefault();

        // Validations first
        const validation = this.validateForm();
        const { errors, hasErrors } = validation;

        // Set the state with the values returned from validateForm
        this.setState({
            errors,
            hasErrors,
        });

        // Don't submit the form if the form hasErrors
        if (hasErrors) {
            console.log('Form has errors!');
            return;
        }
        
        //console.log('Form is valid, handling submit...');

        const { isLive } = this.state;

        // If the panel is live, display a modal first.
        if (isLive) {
            this.openEditModal();
            return;
        }
        this.updatePanelAPI();
    }

    updatePanelAPI = () => {
        //console.log('Update panel API!');

        this.setState({ isSubmitting: true, })

        const { values } = this.state;
        const { Panel_ID, panelName, testName, tube, qty, mbsCode, genderSpecific, weightRequired } = values;
        const { deleted, added } = this.returnUpdatesToAnalytes();

        // console.log('Panel values:');
        // console.log({ Panel_ID, panelName, testName, tube, qty, mbsCode } );
         //console.log('Deleted analytes:');
        //console.log(deleted);
        //console.log('Added analytes:');
         //console.log(added);

        const analytes = [];

        for (let item of deleted) {
            analytes.push(
                {
                    Analyte: item,
                    Status: 'R',
                }
            )
        }

        for (let item of added) {
            analytes.push(
                {
                    Analyte: item,
                    Status: 'N',
                }
            )
        }
        //console.log('analytes');
        //console.log(analytes);

        const url = UPSERT_PANEL_BY_ID_API.POST;
        Auth.currentSession().then((response) => {
            const headers = { Authorization: response.idToken.jwtToken };

            let data = JSON.stringify({
                body: {
                    panel_id: Panel_ID,
                    panel_name: panelName,
                    test_name: testName,
                    tube_name: tube,
                    qty: qty,
                    mbs_code: mbsCode,
                    analytes: analytes,
                    genderSpecific: genderSpecific,
                    weightRequired: weightRequired
                }
            })
            //console.log('upsert Method console enabled for Time Out error testing !!');
            //console.log(data);

            axios.post(url, data, { headers })
                .then((response) => {

                    // No longer submitting
                    this.setState({
                        isSubmitting: false,
                       
                    });

                    // console.log(response);
                     // Status code 400 is a server error.
                     if (response.data.statusCode && response.data.statusCode === 400) {
                        console.error('Server error with status code 400 returned');
                        this.setState({
                            serverError: 'There was an error submitting the form. Please try again.',
                        });
                        return;
                    }
                    // Done submit, go back to Analytes and Panels page
                    this.props.history.push(generatePortalUrl('analytes-and-panels'));
                    
                })
                .catch((error) => {
                    console.error(error);
                    this.setState({
                        serverError: 'There was an error submitting the form. Please try again.',
                        isSubmitting: false,
                     
                    });
                });
        });

        
        
    }

    returnUpdatesToAnalytes = () => {
        const { analytes, previousAnalytes } = this.state;

        // const noChange = _.intersectionWith(previousAnalytes, analytes, _.isEqual);
        // console.log('No change (do not send this data):');
        // console.log(noChange);

        const deleted = _.differenceWith(previousAnalytes, analytes, _.isEqual);
        // console.log('Deleted:');
        // console.log(deleted);

        const added = _.differenceWith(analytes, previousAnalytes, _.isEqual);
        // console.log('Added:');
        // console.log(added);

        return { deleted, added };

    }

    validateForm = () => {
        const { values, analytes } = this.state;
        const errors = {};
        let hasErrors = false;

        console.log("in start errors = "+JSON.stringify(errors)+" hasErrors = "+hasErrors)

        // Panel name
        if (!values.panelName) {
            errors.panelName = 'Please enter a panel name';
            hasErrors = true;
        } else if (!isAlphanumeric(values.panelName)) {
            errors.panelName = 'Please enter a valid panel name';
            hasErrors = true;
        } else if (!this.checkDuplicatePanelName(values.panelName)) {
            errors.panelName = 'This panel name already exists';
            hasErrors = true;
        } else {
            errors.panelName = '';
        }

        // Test name
        // Remove the validation for Test Name
       /* if (!values.testName || _.trim(values.testName) === '' ) {
            errors.testName = 'Please enter a test name';
            hasErrors = true;
        } else {
            errors.testName = '';
        }*/

        // Tube
        if (!values.tube) {
            errors.tube = 'Please select a tube for this panel';
            hasErrors = true;
        } else {
            errors.tube = '';
        }

        // Qty
        console.log("validating quantity")
        console.log("(!values.qty || values.qty < 1 || values.qty > 9 || !isInt(values.qty)) = "+(!values.qty || values.qty < 1 || values.qty > 9 || !isInt(values.qty)))
        if (!values.qty || values.qty < 1 || values.qty > 9 || !isInt(values.qty)) {
            errors.qty = 'Please enter a number between 1 and 9';
            hasErrors = true;
        } else {
            errors.qty = '';
        }
        console.log("errors.qty = "+errors.qty);
        console.log("hasErrors = "+hasErrors);

        // MBS Code
        if (!values.mbsCode || _.trim(values.mbsCode) === '') {
            // errors.mbsCode = 'Please enter an MBS code';
            // hasErrors = true;
            errors.mbsCode = '';
            // hasErrors = false;
        } 
        else
        if (!this.checkMBSCodeExists(values.mbsCode) && !this.checkOneAnalytesMBSCodeExists(values.mbsCode)) {
            errors.mbsCode = 'MBSCodeDoesNotExist';
            hasErrors = true;
            errors.oneanalytesmbscode = true;
        } 
        else
        if(!this.checkOneAnalytesMBSCodeExists(values.mbsCode)){
            errors.oneanalytesmbscode = 'OneAnalyteMBSCodeDoesNotExist';
            hasErrors = true;
        }
        else {
            errors.mbsCode = '';
            errors.oneanalytesmbscode = '';
        }

        // Analytes count
        if (!analytes.length) {
            errors.analytes = 'Please enter at least one analyte';
            hasErrors = true;
        } else {
            errors.analytes = '';
        }

        console.log("returning errors = "+JSON.stringify(errors)+" hasErrors = "+hasErrors)
        return { errors, hasErrors };
    }

    openEditModal = () => {
        this.setState({
            editModalIsOpen: true,
        });
    }

    closeEditModal = () => {
        this.setState({
            editModalIsOpen: false,
        });
    }

    render() {

        const {
            isFetchingPanels,
            isFetchingAnalytes,
            isFetchingTubes,
            isFetchingMBSCodes,
            isSubmitting,
            serverError,
        } = this.state;

        return (
            <React.Fragment>
                <div className="page-heading">
                    <div className="container">
                        <h1>Analytes and panels</h1>
                    </div>
                </div>
                <section className="add-edit-panel section container">
                    <div className="row">
                        <div className="col-8">

                            {
                                serverError ?
                                    <div className="form-errors__container row">
                                        <div className="col-12">
                                            <div className="alert alert-danger" role="alert">
                                                <small>
                                                    {serverError}
                                                </small>
                                            </div>
                                        </div>
                                    </div> : null
                            }

                            <AddEditPanelForm
                                values={this.state.values}
                                errors={this.state.errors}
                                isLive={this.state.isLive}
                                handleChange={this.handleChange}
                                handleSubmit={this.handleSubmit}
                                fetchedTubes={this.state.fetchedTubes}
                            />

                            <AddEditPanelAnalytes
                                analytes={this.state.analytes}
                                previousAnalytes={this.state.previousAnalytes}
                                values={this.state.values}
                                errors={this.state.errors}
                                isLive={this.state.isLive}
                                handleChangeForAnalyte={this.handleChangeForAnalyte}
                                handleAddAnalyte={this.handleAddAnalyte}
                                handleRemoveAnalyte={this.handleRemoveAnalyte}
                            />
                            
                            <div className="form-btn-container btn-container">                            
                                <div className="row">
                                    <div className="col">
                                        <CancelPanel
                                            isSubmitting={isSubmitting}
                                            isEdit={this.state.isEdit}
                                        />
                                    </div>
                                    <div className="col text-right">
                                        <button
                                            className="btn btn-primary btn--done"
                                            onClick={(e) => this.handleSubmit(e)}
                                            disabled={isSubmitting}
                                        >
                                            {isSubmitting ? <span className="spinner__wrapper"><span className="loading-spinner"></span></span> : 'Done'}
                                        </button>
                                        <ConfirmEditPanelModal
                                            editModalIsOpen={this.state.editModalIsOpen}
                                            values={this.state.values}
                                            openEditModal={this.openEditModal}
                                            closeEditModal={this.closeEditModal}
                                            updatePanelAPI={this.updatePanelAPI}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    (isFetchingPanels || isFetchingAnalytes || isFetchingTubes || isFetchingMBSCodes || isSubmitting) ? <Preloader /> : null
                }
            </React.Fragment>
        )
    }
}

export default withRouter(AddEditPanelContainer);

AddEditPanelContainer.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
    }),
    match: PropTypes.shape({
        params: PropTypes.shape({
            panelId: PropTypes.string
        }),
    }),
};