import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

import LoginContainer from './LoginContainer';
import SetPasswordContainer from './../SetPassword/SetPasswordContainer';
import SetPasswordSuccess from './../SetPassword/SetPasswordSuccess';

class LoginManager extends Component {

    state = {
        firstTimeLoggedIn: false,
        setPasswordSuccess: false,
        user: {},
    }

    setFirstTimeLoggedInState = (boolean) => {
        this.setState({ firstTimeLoggedIn: boolean });
    }

    updateSetPasswordSuccess = (boolean) => {
        setTimeout(() => {
            this.setState({ setPasswordSuccess: boolean });
        }, 200);
    }

    setUser = (user) => {
        this.setState({ user });
    }

    render() {
        const { firstTimeLoggedIn, setPasswordSuccess } = this.state;

        if (firstTimeLoggedIn && setPasswordSuccess) {
            return (
                <SetPasswordSuccess
                    updateAuthState={this.props.updateAuthState}
                />
            )
        }

        if (firstTimeLoggedIn && !setPasswordSuccess) {
            return (
                <SetPasswordContainer
                    updateSetPasswordSuccess={this.updateSetPasswordSuccess}
                    user={this.state.user}
                />
            )
        }

        return (
            <LoginContainer
                isAuthenticated={this.props.isAuthenticated}
                updateAuthState={this.props.updateAuthState}
                setFirstTimeLoggedInState={this.setFirstTimeLoggedInState}
                setUser={this.setUser}
            />
        )
    }
}

export default LoginManager;

LoginManager.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    updateAuthState: PropTypes.func.isRequired,
};