import React, { Fragment } from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import './../../../../scss/tag-view.scss';
import LocationTag from './LocationTag';

const ProtocolLocation = (props) => {
    return (
        <div
            className='location-container'
        >
            <div className='locations-display'>
                {
                    props.selectedLocations.map((element) => {
                        return <LocationTag
                            removeLocation={props.removeLocation}
                            location={element}
                        />
                    })
                }


            </div>
            <Dropdown
                className='location-dropdown'
                options={props.locations.map(e => e.name)}
                value='select'
                placeholder="Select an option"
                onChange={props.addLocation}
            />

        </div>
    )
};

export default ProtocolLocation