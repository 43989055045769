import React, { Component } from 'react';

import Checkbox from './Checkbox';

class Styleguide extends Component {
    render() {
        return (
            <div className="container">
                <h1>Styleguide</h1>
                <h1>Heading 1</h1>
                <h2>Heading 2</h2>
                <h3>Heading 3</h3>
                <h4>Heading 4</h4>
                <p>Paragraph text</p>
                <p className="small">Small paragraph text</p>
                <a href="#">A text link</a>
                <div className="small-heading">Small heading</div>

                <h2>Inputs</h2>
                <input type="text" className="form-control" placeholder="Input" />
                <input type="text" className="form-control" placeholder="Input disabled" disabled />

                <div className="form-group has-error">
                    <label htmlFor="exampleInputEmail1">Label</label>
                    <input type="email" className="form-control" placeholder="Input text" />
                    <small id="emailHelp" className="form-text">Error message appears here!</small>
                </div>

                <Checkbox
                    id="checkboxId"
                    name="checkboxName"
                    className="form-check-blah"
                    label="Checkbox label"
                />

                <h2>Buttons</h2>
                <button className="btn btn-primary">Primary</button>
                <button className="btn btn-secondary">Secondary</button>
                <button className="btn btn-primary" disabled>Primary disabled</button>
                <button className="btn btn-secondary" disabled>Secondary disabled</button>
                <button className="btn btn-primary btn-block">Primary button block</button>
            </div>
        )
    }
}

export default Styleguide;