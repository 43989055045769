import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SVG from 'react-inlinesvg';

import expand from './../../assets/images/accordion-toggle-expand.svg';
import collapse from './../../assets/images/accordion-toggle-collapse.svg';

import './../../scss/accordion.scss';

class Accordion extends Component {

    state = {
        isExpanded: true,
    }

    componentDidMount = () => {
        this.setDefaultExpanded();
    }

    setDefaultExpanded = () => {
        const { isExpandedByDefault } = this.props;
        if (isExpandedByDefault == null) {
            return;
        }
        this.setState({
            isExpanded: isExpandedByDefault,
        })
    }

    toggleAccordion = () => {
        const isExpanded = this.state.isExpanded;
        this.setState({
            isExpanded: !isExpanded,
        })
    }

    render() {

        const isExpanded = this.state.isExpanded;

        const {
            children,
            label,
            headingButtonAction,
        } = this.props;

        return (
            <div className="accordion">
                <div className="accordion__heading">
                    <div className="row align-items-center">
                        <div className="col">
                            <div 
                                className="accordion-toggle__container"
                            >
                                <span
                                    className={`accordion-toggle ${isExpanded ? 'is-expanded' : ''}`}
                                    onClick={this.toggleAccordion}
                                >
                                    {
                                        isExpanded ?
                                            <SVG className="icon icon--collapse" src={collapse} /> :
                                            <SVG className="icon icon--expand" src={expand} />
                                    }
                                    <h3>{label}</h3>
                                </span>
                            </div>
                        </div>
                        <div className="col text-right">
                            <button
                                className="btn btn-secondary text-center"
                                onClick={headingButtonAction}
                            >
                                Edit
                            </button>
                        </div>
                    </div>

                </div>
                <div className={`accordion__content ${isExpanded ? '' : 'hide'}`}>
                    {children}
                </div>
            </div>
        )
    }
}

export default Accordion;

Accordion.propTypes = {
    children: PropTypes.node,
    isExpandedByDefault: PropTypes.bool,
    label: PropTypes.string.isRequired,
    headingButtonAction: PropTypes.func.isRequired,
};