import React, { Component } from 'react';
import LocationListContainer from './LocationListContainer';
import SVG from 'react-inlinesvg';
import axios from 'axios';
import { Auth } from 'aws-amplify';

import add from '../../assets/images/add.svg';
import { generatePortalUrl, LOCATIONS_LIST_API } from '../../utils/constants'
import Preloader from '../common/Preloader';

class SettingsContainer extends Component {

    originalLocations = []
    state = {
        locations: [],
        isLoading: false,
        error: null
    }

    addNewLocation = (e) => {
        e.preventDefault();
        let temp = [...this.state.locations]
        temp.push({
            edit: true,
            name: '',
            docCode: '',
            accCode: ''
        })
        this.setState({ locations: temp })
    }
    editLocation = (index) => {
        let tempArray = [...this.state.locations]
        let temp = { ...tempArray[index] }
        temp.edit = true
        tempArray.splice(index, 1, temp)
        this.setState({ locations: tempArray })
    }
    saveLocation = (index, item) => {
        this.setState({ error: null })
        let tempArray = [...this.state.locations]
        let temp = { ...item }
        temp.edit = false
        tempArray.splice(index, 1, temp)
        this.setState({ locations: tempArray })
    }
    onDelete = (index) => {
        let tempArray = [...this.state.locations]
        tempArray.splice(index, 1)
        this.setState({ locations: tempArray })
    }
    onCancel = (index) => {
        let tempArray = [...this.state.locations]
        let temp = { ...tempArray[index] }
        temp.edit = false
        tempArray.splice(index, 1, temp)
        this.setState({ locations: tempArray })
    }
    onCancelChanges = (index) => {
        
        this.props.history.goBack();
        // this.props.history.push(generatePortalUrl('protocols'));
    }

    submitLocations = () => {

        let finalLocations = [...this.state.locations]




        for (const iterator of finalLocations) {
            if (iterator.edit) {

                this.setState({ error: 'Please save or cancel un-saved changes' })
                return;
            }
        }



        let seen = new Set();
        var hasDuplicates = finalLocations.some(function (currentObject) {
            return seen.size === seen.add(currentObject.name).size;
        });

        if (hasDuplicates) {
            this.setState({ error: 'Location names can not be same' })
            return;
        }


        this.setState({ isLoading: true })
        finalLocations.forEach(location => {
            let index = this.originalLocations.findIndex(e => e.id == location.id)
            if (index >= 0) {
                location['status'] = 'u'
            } else {
                location['status'] = 'a'
            }
        })
        this.originalLocations.forEach(oLocation => {
            let index = finalLocations.findIndex(e => e.id == oLocation.id)
            if (index < 0) {
                let temp = { ...oLocation }
                temp['status'] = 'd'
                finalLocations.push(temp)
            }
        })
        // console.log('Final Locations', finalLocations)

        this.postLocations(finalLocations)

    }

    postLocations = (finalLocations) => {

        this.setState({ isLoading: true })
        let url = LOCATIONS_LIST_API;
        Auth.currentSession().then((response) => {
            const headers = { Authorization: response.idToken.jwtToken };
            axios.post(url, finalLocations, { headers })
                .then((response) => {
                    if (response.data.statusCode && response.data.statusCode === 400) {
                        return;
                    }
                    // console.log(response.data)
                    this.originalLocations = response.data;
                    this.setState({ locations: response.data })
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    this.setState({ isLoading: false });
                });

        }).catch((error) => {
            console.error(error);
            this.setState({ isLoading: false });
        });


    }
    componentDidMount() {
        this.fetchLocationsList()
    }


    fetchLocationsList = () => {
        this.setState({ isLoading: true })
        let url = LOCATIONS_LIST_API;
        Auth.currentSession().then((response) => {
            const headers = { Authorization: response.idToken.jwtToken };
            axios.get(url, { headers })
                .then((response) => {
                    if (response.data.statusCode && response.data.statusCode === 400) {
                        return;
                    }
                    // console.log(response)
                    this.originalLocations = response.data;
                    this.setState({ locations: response.data })
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    this.setState({ isLoading: false });
                });

        }).catch((error) => {
            console.error(error);
            this.setState({ isLoading: false });
        });
    }

    render() {
        return (
            <div>
                <div className="page-heading">
                    <div className="page-heading">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h1>Settings</h1>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-8 location-settings-container'>
                            <LocationListContainer
                                locations={this.state.locations}
                                addNewLocation={this.addNewLocation}
                                editLocation={this.editLocation}
                                saveLocation={this.saveLocation}
                                onDelete={this.onDelete}
                                onCancel={this.onCancel}
                            />

                            <a onClick={(e) => { this.addNewLocation(e) }} className="form-add link" style={{ marginLeft: '25%' }}>
                                <SVG className="icon icon--add" src={add} />
                                <span>ADD A NEW LOCATION</span>
                            </a>

                            {this.state.error && <div style={{ marginLeft: '10%', color: 'red' }}> {this.state.error}</div>}

                            {
                                this.originalLocations.length > 0 && <div className='settings-footer-buttons-container' style={{ marginLeft: '25%' }}>
                                    <button onClick={this.onCancelChanges} className="btn btn-secondary btn--cancel settings-footer-buttons-container-button">Cancel</button>
                                    <button onClick={this.submitLocations} className="btn btn-primary btn--next settings-footer-buttons-container-button">Save</button>
                                </div>
                            }
                        </div>

                    </div>


                    {this.state.isLoading && <Preloader />}
                </div>

            </div>
        )
    }
}
export default SettingsContainer;