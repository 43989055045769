import React, { Component } from 'react';
import PropTypes from 'prop-types';

import EpisodesItem from './EpisodesItem';
import RemoveEpisodeModal from './RemoveEpisodeModal';

import SVG from 'react-inlinesvg';

import add from './../../../../assets/images/add.svg';

class EpisodesForm extends Component {

    state = {
        modalIsOpen: false,
        indexToBeRemoved: null,
    };

    openModal = (index) => {
        this.setState({
            modalIsOpen: true,
            indexToBeRemoved: index,
        });
        //console.log(index);
    }

    closeModal = () => {
        this.setState({
            modalIsOpen: false,
            indexToBeRemoved: null,
        });
    }

    render() {
        const { 
            episodes,
            hasErrors,
            errorDayValue,
            errorPeriodValue,
            errorHourValue,
            errorHourValueDosePlus,
            errorMinuteValue,
            serverError,
        } = this.props;
        const episodesLength = episodes.length;
        return (
            <div className="episodes-form__container">

                <div className="episodes-form">

                    {
                        serverError ?
                            <div className="form-errors__container row">
                                <div className="col-12">
                                    <div className="alert alert-danger" role="alert">
                                        <small>
                                            {serverError}
                                        </small>
                                    </div>
                                </div>
                            </div> : null
                    }

                    {
                        hasErrors ?
                            <div className="form-errors__container row">
                                <div className="col-12">
                                    <div className="alert alert-danger" role="alert">
                                        <p><strong>Please review the error(s) highlighted in red below</strong></p>
                                        <small>
                                            <ul>
                                                {
                                                    errorDayValue ? <li><strong>Day – </strong>Please enter a number between -999 and 999</li> : null
                                                }
                                                {
                                                    errorPeriodValue ? <li><strong>Period – </strong>Please select a period</li> : null
                                                }
                                                {
                                                    errorHourValue ? <li><strong>Period Hr – </strong>Please enter a number between 0 and 23</li> : null
                                                }
                                                {
                                                    errorHourValueDosePlus ? <li><strong>Period Hr (Dose +) – </strong>Please enter a number between 0 and 999</li> : null
                                                }
                                                {
                                                    errorMinuteValue ? <li><strong>Period Min – </strong>Please enter a number between 0 and 59</li> : null
                                                }
                                            </ul>
                                        </small>
                                    </div>
                                </div>
                            </div> : null
                    }

                    <div className="episodes-form__table flex-table-form flex-table container">
                        <div className="row list--heading">
                            <div className="item item__reorder"></div>
                            <div className="item item__day">Day</div>
                            <div className="item item__period">Period</div>
                            <div className="item item__period-details"></div>
                            <div className="item item__panels">Panels</div>
                            <div className="item item__urgent">Urgent?</div>
                            <div className="item item__actions"></div>
                        </div>
                        
                        {
                            !this.props.Screening_skipped ? 
                            <div className="episodes-item episodes-item--screening flex-table-form-item row list--item">
                                <div className="item item__reorder"></div>
                                <div className="item item__day">
                                    Screen
                                </div>
                                <div className="item item__period">
                                    Single
                                </div>
                                <div className="item item__period-details">
                                </div>
                                <div className="item item__panels">
                                    <span className="edit-panels-in-screening muted">Edit panels in Screening</span>
                                </div>
                                <div className="item item__urgent">
                                </div>
                                <div className="item item__actions">
                                </div>
                            </div>:null
                        }

                        {
                            episodes
                                .sort(((a, b) => a['values']['Sequence'] - b['values']['Sequence'])) // Sort by Sequence
                                .map((episode, index) => {
                                return (
                                    <EpisodesItem
                                        Screening_skipped = {this.props.Screening_skipped}
                                        key={index}
                                        index={index}
                                        values={episode.values}
                                        errors={episode.errors}
                                        episodesLength={episodesLength}
                                        togglePeriodDetails={this.props.togglePeriodDetails}
                                        updateEpisode={this.props.updateEpisode}
                                        removeEpisode={this.props.removeEpisode}
                                        reorderEpisode={this.props.reorderEpisode}
                                        openModal={this.openModal}
                                        handleOpenPanelsInEpisode={this.props.handleOpenPanelsInEpisode}
                                    />
                                )
                            })
                        }


                        <div className="form-add-container">
                            <a onClick={() => this.props.addEpisode()} className="form-add link">
                                <SVG className="icon icon--add" src={add} />
                                <span>Add a new episode</span>
                            </a>
                        </div>
                    </div>

                    <RemoveEpisodeModal
                        removeEpisode={this.props.removeEpisode}
                        closeModal={this.closeModal}
                        modalIsOpen={this.state.modalIsOpen}
                        indexToBeRemoved={this.state.indexToBeRemoved}
                    />

                </div>

            </div>
        )
    }
}

export default EpisodesForm;

EpisodesForm.propTypes = {
    episodes: PropTypes.array.isRequired,
    hasErrors: PropTypes.bool,
    errorDayValue: PropTypes.bool,
    errorPeriodValue: PropTypes.bool,
    errorHourValue: PropTypes.bool,
    errorHourValueDosePlus: PropTypes.bool,
    errorMinuteValue: PropTypes.bool,
    serverError: PropTypes.string,
    togglePeriodDetails: PropTypes.func.isRequired,
    updateEpisode: PropTypes.func.isRequired,
    removeEpisode: PropTypes.func.isRequired,
    handleOpenPanelsInEpisode: PropTypes.func.isRequired,
    addEpisode: PropTypes.func.isRequired,
    reorderEpisode: PropTypes.func.isRequired,
    Screening_skipped: PropTypes.bool,
};