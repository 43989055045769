import React, { Component } from 'react';

import { PropTypes } from 'prop-types';

import axios from 'axios';
import { Auth } from 'aws-amplify';
import _ from 'lodash';

import PanelsForm from './PanelsForm';
import CantFindPanel from './../../../../common/CantFindPanel';
import Preloader from './../../../../common/Preloader';

import { PANEL_API } from './../../../../../utils/constants';
import { GET_PANELS_BY_EPISODEID_API } from './../../../../../utils/constants';
import { INSERT_KIT_AND_TUBE_DETAILS,GET_TUBE_DETAILS_BY_KITCODE,UPSERT_PANELS_BY_EPISODEID_API } from './../../../../../utils/constants';

import SVG from 'react-inlinesvg';
import close from './../../../../../assets/images/close-large.svg';

import './../../../../../scss/episodes-panels.scss';
import { getMasterTubeDetails } from '../../../../common/MasterTubeDetails';
import ConfirmationDialog from '../../../../common/ConfirmationDialog';

let previousState = [];

class PanelsContainer extends Component {

    state = {
        isFetchingPanels: false,
        isLoadingEpisodeDetails: false,
        isSubmitting: false,
        hasErrors: false,
        errors: {
            emptyPanel: false,
            duplicatePanels: false,
        },
        serverError: '',
        fetchedPanels: [],
        Kitcode: 'N/A',
        panels: [
            {
                Period_panel_id: '',
                Panel_code: '',
                Panel_ID: '',
            }
        ],
        kitTubesDetail: [],
        fetchedTubes: [],
        showConfrimationDialog: false,
        CheckWOCBP: false,

    }

    componentDidMount() {
        getMasterTubeDetails((result)=>{
            this.setState({fetchedTubes: result});
         })
        previousState = [];
        const { Day_period_id, Panels } = this.props.activeEpisode;
        //console.log('day period id');
        //console.log(Day_period_id);
        this.fetchPanels();
        if (Panels) {
            // console.log('Panels found in this Episode, fetching panels...');
            this.fetchPanelsByEpisodeId(Day_period_id);
        } else {
            // console.log('No panels found in /this Episode, just display the initial state.');
        }
    }

    handleClosePanelsInEpisode = () => {
        this.props.closePanelsInEpisode();
    }

    /**
     * COPIED Loads previous details to the screen
     */
    loadPanelsDetails = () => {
        if (previousState.length) {
            const panels = [];
            for (let item of previousState) {
                panels.push(
                    {
                        Period_panel_id: item.Period_panel_id,
                        Panel_code: item.Panel_code,
                        Panel_ID: item.Panel_ID,
                    }
                )
            }
            // Just override the state
            this.setState({ panels });
        }

    }

    /**
     * COPIED Fetches the list of panels
     */
    fetchPanels = () => {

        this.setState({ isFetchingPanels: true });

        /* Uncomment to use API */
        const url = PANEL_API.GET;

        // Get the current session
        Auth.currentSession().then((response) => {
            // Grab the jwtToken to use as Authorization header
            const headers = { Authorization: response.idToken.jwtToken };

            // Fetch the Panels list
            axios.get(url, { headers })
                .then((response) => {
                    if (response.data) {
                        const result = response.data.result;
                        this.setState({
                            fetchedPanels: result || [],
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    this.setState({ isFetchingPanels: false });
                });

        }).catch((error) => {
            console.error(error);
            this.setState({ isFetchingPanels: false });
        });
    }

    /**
    * Fetches the list of panels from Episode ID
    */
    fetchPanelsByEpisodeId = (dayPeriodId) => {

        this.setState({ isLoadingEpisodeDetails: true });

        const url = GET_PANELS_BY_EPISODEID_API.GET;

        Auth.currentSession().then((response) => {
            const headers = { Authorization: response.idToken.jwtToken };

            // Fetch the Panels list
            axios.get(`${url}?dpId=${dayPeriodId}`, { headers })
                .then((response) => {
                    //console.log('Panel Container !!');
                    // console.log('dpd',response);
                    if (response.data) {
                        const result = response.data[0].result;
                        
                        if (result.length) {
                            console.log('WOCBP11', result)

                            this.setState({ CheckWOCBP: result[0].checkWocbp })
                            this.mapResultToPreviousState(result);
                        } else {
                            //console.log(`No episode panels found for Day Period ID ${dayPeriodId}`);
                        }
                        if(response.data[0] != null){
                            const result = response.data[0].result;
                            // console.log('panels contsiner',result)
                            if(result.length > 0){
                                this.setState({Kitcode: result[0].Kitcode})
                                this.getTubeDetailsByKitcode_API(result[0].Kitcode);
                            }
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    this.setState({ isLoadingEpisodeDetails: false });
                });

        }).catch((error) => {
            console.error(error);
            this.setState({ isLoadingEpisodeDetails: false });
        });
    }

    getTubeDetailsByKitcode_API = (kitcode) => {
        
        // console.log('fetching tube details')
        // resetting the previous state
        // resetting the previous state
       
        this.setState({ isLoadingScreeningDetails: true });


        const url = GET_TUBE_DETAILS_BY_KITCODE.GET;
        // Get the current session
        Auth.currentSession().then((response) => {
            // Grab the jwtToken to use as Authorization header
            const headers = { Authorization: response.idToken.jwtToken };

            // Fetch the Screening Panels list
            axios.get(`${url}?kitcode=${kitcode}`, { headers })
                .then((response) => {
                    
                    // console.log('dsadas',response)
                  

                    let tubes = response.data[0].result;
                    // console.log('dsadas',tubes)
                    for (let index = 0; index < tubes.length; index++) {
                        this.addItems(tubes[index].Tube, tubes[index].Quantity)
                        
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    this.setState({ isLoadingEpisodeDetails: false });
                });

        }).catch((error) => {
            console.error(error);
            this.setState({ isLoadingEpisodeDetails: false });
        });
    }
    upsertPanelDetailsForEpisodeAPI = () => {
        this.setState({
            isSubmitting: true,
        });

        const dayPeriodId = this.props.activeEpisode.Day_period_id;
        //console.log(`Day period ID: ${dayPeriodId}`);

        let { deleted, added } = this.returnUpdates();

        // console.log('ddd',deleted);
        // console.log('addd',added);

        const panels = [];

        for (let item of deleted) {
            panels.push(
                {
                    Period_panel_Id: item.Period_panel_id,
                    Panel_Id: item.Panel_ID,
                    Panel_Code: item.Panel_code,
                    Status: 'R',
                }
            )
        }

        for (let item of added) {
            panels.push(
                {
                    //Period_panel_Id: item.Period_panel_id,
                    //updated the Period_panel_Id to null for status N
                    Period_panel_Id: null,
                    Panel_Id: item.Panel_ID,
                    Panel_Code: item.Panel_code,
                    Status: 'N',
                }
            )
        }
        //console.log('panels');
        //console.log(panels);
        const url = UPSERT_PANELS_BY_EPISODEID_API.POST;
        Auth.currentSession().then((response) => {
             const headers = { Authorization: response.idToken.jwtToken };


            let data = JSON.stringify({
                 body: {
                     Protocol_Id: this.props.protocolId,
                     Protocol_cohort_id: this.props.activeProtocolCohort.Protocol_cohort_ID,
                     Day_period_id:this.props.activeEpisode.Day_period_id,
                     user: this.props.username,
                     panels:panels,
                     CheckWOCBP: this.state.CheckWOCBP == false ? 0 : 1


                 }
             })
             //console.log('Upsert Data!!!');
             //console.log(data);
            axios.post(url, data, { headers })
                .then((response) => {

                    //console.log(response);

                    // No longer submitting
                    this.setState({
                        serverError: '',
                        isSubmitting: false,
                    });

                    // Status code 400 is a server error.
                    if (response.data.statusCode && response.data.statusCode === 400) {
                        this.setState({
                            serverError: 'There was an error submitting the form. Please try again.',
                        });
                        return;
                    }

                    // When success:
                    const result = response.data.body[0].result[0];

                    // Update the Episode's ID and new Panels count
                    if (result.day_period_id) {
                        this.handleUpdateEpisodeId(result.day_period_id);

                        setTimeout(() => {
                            this.upserttubedetails_API()
                        }, 100);
                    }   
                    this.handleUpdateEpisodePanels();
                    this.handleClosePanelsInEpisode();
                    

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        serverError: 'There was an error submitting the form. Please try again.',
                        isSubmitting: false,
                    });
                });

        });
    }

    upserttubedetails_API = () => {
        const isEditFromReviewScreen = this.props.isEditFromReviewScreen;
       
        // Set isSubmitting to true
        this.setState({
            isSubmitting: true
        });
        const url = INSERT_KIT_AND_TUBE_DETAILS.POST;
        //console.log('panels');
        //console.log(panels);
        Auth.currentSession().then((response) => {
            const headers = { Authorization: response.idToken.jwtToken };

            let addedTubes = this.state.kitTubesDetail;
            let tubeDetails = [];
            for (let index = 0; index < addedTubes.length; index++) {
                tubeDetails.push([addedTubes[index].tube.tube_id, addedTubes[index].quantity]) 
            }
            let data = JSON.stringify({
                body: {
                    kit_code: this.state.Kitcode == null ? 'N/A' : this.state.Kitcode,
                    Day_period_id: this.props.activeEpisode.Day_period_id,
                    Screening_id: null,
                    kit_date: new Date().toISOString(),
                    required: this.props.activeProtocolCohort.Study_participants,//get from props: TBD
                    tubedetails:tubeDetails

                }
            })

            
		
            //console.log('upsert Method console enabled for Time Out error testing !!');
            // console.log(data);

            axios.post(url, data, { headers })
                .then((response) => {

                    // console.log('response:', response)
                    // No longer submitting
                    this.setState({
                        isSubmitting: false,
                    });

                    //console.log(response);

                    // Status code 400 is a server error.
                    if (response.data.statusCode && response.data.statusCode === 400) {
                        console.error('Server error with status code 400 returned');
                        this.setState({
                            serverError: 'There was an error submitting the form. Please try again.',
                        });
                        return;
                    }

                })
                .catch((error) => {
                    console.error(error);
                    this.setState({
                        serverError: 'There was an error submitting the form. Please try again.',
                        isSubmitting: false,
                    });
                });
        });
    }
    
    genrateNewKitcode_API = () => {
        
        // console.log('ScreeningID: ', this.props.screeningId)
        // Set isSubmitting to true
        this.setState({
            isSubmitting: true,
        });
        const url = INSERT_KIT_AND_TUBE_DETAILS.POST;
        Auth.currentSession().then((response) => {
            const headers = { Authorization: response.idToken.jwtToken };
            let addedTubes = this.state.kitTubesDetail;
            let tubeDetails = [];
            for (let index = 0; index < addedTubes.length; index++) {
                tubeDetails.push([addedTubes[index].tube.tube_id, addedTubes[index].quantity]) 
            }
            let data = JSON.stringify({
                body: {



                    kit_code: this.state.Kitcode == null ? 'N/A' : this.state.Kitcode,
                    Day_period_id: this.props.activeEpisode.Day_period_id,
                    Screening_id: null,
                    kit_date: new Date().toISOString(),
                    required: this.props.activeProtocolCohort.Study_participants,//get from props: TBD
                    regenrate: 1,
                    tubedetails:tubeDetails
                }
            })
            axios.post(url, data, { headers })
                .then((response) => {
                    // console.log(response)
                    this.setState({
                        isSubmitting: false
                    });
                    if (response.data.statusCode && response.data.statusCode === 400) {
                        console.error('Server error with status code 400 returned');
                        this.setState({
                            serverError: 'There was an error submitting the form. Please try again.',
                        });
                        return;
                    }
                    this.setState({
                        Kitcode: response.data.body.kitCode
                    });

                })
                .catch((error) => {
                    console.error(error);
                    this.setState({
                        serverError: 'There was an error submitting the form. Please try again.',
                        isSubmitting: false,
                    });
                });
        });
    }
    updateRegenrationChoice = ()=>{
        this.genrateNewKitcode_API()
    }
    /* COPIED */
    mapResultToPreviousState = (result) => {
        const newResult = result.map(item => {
            return {
                Period_panel_id: item.Period_panel_id,
                Panel_code: item.Panel_code,
                Panel_ID: item.Panel_ID,

            }
        });
        previousState = newResult;

        //console.log(previousState);



        this.loadPanelsDetails();
    }


       //////////////////////////////////////////////////////// Kit Tube Information functions START//////////////////
    addItems = (tube, quantity)=>{

        quantity = Number(quantity) 
        this.addOrIncrementTubes(tube, quantity)
    }


    removeItem = (index)=>{
        this.removingTubeIndex = index;
        this.showAlertToRemoveTube(this.state.kitTubesDetail[index].tube.Tube)
        
    }
    updateQuantity = (index, value)=>{
        if(value < 0){
            this.removeOrdecrementTubes(this.state.kitTubesDetail[index].tube.Tube, value)
        } else{
            this.addOrIncrementTubes(this.state.kitTubesDetail[index].tube.Tube, value, true)
        }
    }
    yesCallBackReduceQuantity = (_tube,_quantity)=>{

        // console.log('decrementing',_quantity);
        let temp = [...this.state.kitTubesDetail];
        if(temp.length > 0){
            let index = temp.findIndex(item=> item.tube.Tube == _tube);
            if(index >= 0){
                let t = {...temp[index]};
                t.quantity += _quantity; 
                temp[index] = t; 
                if(temp[index].quantity <= 0){
                    temp.splice(index,1);
                }
            }
            this.setState({kitTubesDetail: temp},()=>{

            // console.log('after decrementing',this.state.kitTubesDetail);
            });
        }
    }
    yesCallBackRemoveTube = ()=>
    {
        let index = this.removingTubeIndex;
        let temp = [...this.state.kitTubesDetail];
        temp.splice(index,1)
        this.setState({kitTubesDetail:temp})
    }
    noCallBack = ()=>{
        
    }
    showAlertToRemoveTube = (_tube)=>{
        this.confirmationMessage = 'Do you want to remove ' + _tube + ' tube';
        this.yesCallBack = this.yesCallBackRemoveTube
        this.headingText = 'Are you sure?'
        this.noButtonText = 'No';
        this.yesButtonText = 'Yes';
        this.setState({showConfrimationDialog:true})
        
    }
    showAlertToDecrementTube = (_tube)=>{
        let quantity = 0;
        let temp = [...this.state.kitTubesDetail];
        if(temp.length > 0){
            let index = temp.findIndex(item=> item.tube.Tube == _tube);
            if(index >= 0){
                let t = {...temp[index]};
                quantity = t.quantity;
                
            }
        }
        if(quantity > 1){
            this.confirmationMessage = 'Do you want to reduce the quantity of ' + _tube + ' tubes?';
        } else {
            this.confirmationMessage = 'Do you want to remove ' + _tube + ' tube';
        }
        this.yesCallBack = this.yesCallBackReduceQuantity
        this.headingText = 'Are you sure?'
        this.noButtonText = 'No';
        this.yesButtonText = 'Yes';
        this.setState({showConfrimationDialog:true})
        
    }







    updateQuantity = (index, value)=>{
        if(value < 0){
            this.removeOrdecrementTubes(this.state.kitTubesDetail[index].tube.Tube, value)
        } else{
            this.addOrIncrementTubes(this.state.kitTubesDetail[index].tube.Tube, value, true)
        }
    }
    removeOrdecrementTubes = (_tube,_quantity)=>{
        let temp = [...this.state.kitTubesDetail];
        if(temp.length > 0){
            let index = temp.findIndex(item=> item.tube.Tube == _tube);
            if(index >= 0){
                let t = {...temp[index]};
                t.quantity += _quantity; 
                temp[index] = t; 
                if(temp[index].quantity <= 0){
                    temp.splice(index,1);
                }
            }
            this.setState({kitTubesDetail: temp},()=>{
            });
        }
        
    }
    addOrIncrementTubes = (_tube,_quantity, shouldIncrement = false)=>{
        setTimeout(() => {
            let temp = [...this.state.kitTubesDetail];
            let index = temp.findIndex(item=> item.tube.Tube == _tube);
            if(index >= 0){
                if(shouldIncrement){
                    let t = {...temp[index]};
                    t.quantity += _quantity; 
                    temp.splice(index,1,t);    
                }       
            } else {
                temp.push({tube: this.state.fetchedTubes.find(item=> item.Tube == _tube), quantity: _quantity})
            }
            this.setState({kitTubesDetail: temp},()=>{
            });
        }, 100);
        
    }


        //////////////////////////////////////////////////////// Kit Tube Information functions END//////////////////

    /* COPIED */
    addPanel = () => {
        const panels = this.state.panels;
        const item = {
            Period_panel_id: '',
            Panel_code: '',
            Panel_ID: '',
        }
        this.setState({
            panels: [...panels, item]
        })
    }

    /* COPIED */
    removePanel = (index) => {
        const panels = this.state.panels;

        // Don't let them delete if there's only one left
        if (panels.length <= 1) {
            return;
        }

        const fetchedPanels = this.state.fetchedPanels;
        let _tempPanel = fetchedPanels.find(fetchedPanel => panels[index].Panel_ID == fetchedPanel.Panel_ID)
        if(_tempPanel != null){
            this.removeOrdecrementTubes(_tempPanel.tube, (-1*_tempPanel.quantity))
        }

        panels.splice(index, 1);
        this.setState({ panels });
    }

    /* COPIED */
    updatePanel = (e, index) => {
        const panels = this.state.panels;
        const fetchedPanels = this.state.fetchedPanels;

        const value = e.target.value ? parseInt(e.target.value) : '';
        let _tempPanel = fetchedPanels.find(fetchedPanel => panels[index].Panel_ID == fetchedPanel.Panel_ID)
        if(_tempPanel != null){
            this.removeOrdecrementTubes(_tempPanel.tube, (-1*_tempPanel.quantity))
        }
        panels[index].Panel_ID = value;

        // Add further details to the panel
        const panelItem = fetchedPanels.find(fetchedPanel => value == fetchedPanel.Panel_ID);
        if (panelItem) {
            panels[index].Panel_code = panelItem.Panel_code || '';
            this.addOrIncrementTubes(panelItem.tube, panelItem.quantity);//add new tubes in tube detail for kit generation

            // If they did not select an panel, make everything else blank
        } else {
            panels[index].Panel_code = '';
        }

        // If the changed value is something, remove error message if any
        if (value && value !== '') {
            delete panels[index].error;
        }

        this.setState({ panels });
    }

    /* COPIED */
    validateForm = () => {
        const panels = this.state.panels;
        let hasErrors = false;

        let emptyPanel = false;
        let duplicatePanels = false;

        let uniqueArray = [];

        // Loop through and check if any of the list items are blank
        panels.map(panel => {
            if (!panel.Panel_ID || panel.Panel_ID === '') {
                panel.error = true;
                hasErrors = true;
                emptyPanel = true;
            } else {
                // panel.error = false;

                // Check if any of the list items already exist
                const findOne = uniqueArray.find(unique => unique == panel.Panel_ID);
                if (findOne === undefined) {
                    uniqueArray.push(panel.Panel_ID);
                    delete panel.error;
                } else {
                    panel.error = true;
                    hasErrors = true;
                    duplicatePanels = true;
                }
            }
        });

        return {
            panels,
            hasErrors,
            emptyPanel,
            duplicatePanels,
        };
    }

    /* COPIED */
    handleSubmit = (e) => {
        // Prevent form from submitting
        e.preventDefault();

        // Validate the form
        const validation = this.validateForm();
        const { panels, hasErrors, emptyPanel, duplicatePanels } = validation;

        this.setState({
            serverError: '', // reset server error
            panels,
            hasErrors,
            errors: {
                emptyPanel,
                duplicatePanels,
            },
        });

        if (hasErrors) {
            //console.log('Dont submit, has errors');
            // If there's no errors, submit the form
        } else {
            //console.log('Form is valid, submitting...');
            // API CALL
            this.upsertPanelDetailsForEpisodeAPI();
        }
    }

    /* COPIED */
    returnUpdates = () => {
        const panels = this.state.panels;

        // const noChange = _.intersectionWith(previousState, panels, _.isEqual);
        // console.log('No change (do not send this data):');
        // console.log(noChange);

        // console.log('prevPanels', previousState.panels)
        // console.log('ThisPanels', this.state.panels)
        const deleted = _.differenceWith(previousState, panels, _.isEqual);
        // console.log('Deleted:');
        // console.log(deleted);

        const added = _.differenceWith(panels, previousState, _.isEqual);
        // console.log('Added:');
        // console.log(added);

        return { deleted, added };

    }

    /**
     * Update the Episode's ID for the open episode.
     * Open episode's index is in activeEpisodeIndex.
     */
    handleUpdateEpisodeId = (id) => {
        const {
            activeEpisodeIndex,
        } = this.props;

        id = parseInt(id);

        this.props.updateEpisode(activeEpisodeIndex, 'Day_period_id', id);

        
    }

    /**
     * Update the Protocol Cohort's new Panels Length for the open episode.
     * Open episode's index is in activeEpisodeIndex.
     */
    handleUpdateEpisodePanels = () => {
        const {
            activeEpisodeIndex,
        } = this.props;

        const panelsLength = this.state.panels.length;

        this.props.updateEpisode(activeEpisodeIndex, 'Panels', panelsLength);
    }

    displayEpisodeName = () => {
        const { activeEpisode } = this.props;
        if (!activeEpisode) {
            return;
        }

        let string = '';
        const { Day_value } = activeEpisode;

        if (Day_value) {
            string += `Day ${Day_value}`;

            const { Period_value } = activeEpisode;

            if (Period_value) {
                string += ` (${Period_value}`;

                const { Hour_value, Minute_value, hasPeriodDetails } = activeEpisode;
                
                if (Period_value === 'Dose +') {
                    string += (Hour_value !== null && Hour_value !== '') ? ` ${Hour_value}hr` : '';
                    string += (Minute_value !== null && Minute_value !== '') ? ` ${Minute_value}min` : '';
                } else {
                    if (hasPeriodDetails) {
                        string += (Hour_value !== null && Hour_value !== '') ? ` ${Hour_value}:` : ' XX:';
                        string += (Minute_value !== null && Minute_value !== '') ? `${Minute_value}` : 'XX';
                    }
                }

                string += ')';
            }
        } else {
            string += 'New episode';
        }

        return string;
    }

    enableWOCBP = () => {
        this.setState({ CheckWOCBP: !this.state.CheckWOCBP })
    }

    render() {
        const { isSubmitting, isFetchingPanels, isLoadingEpisodeDetails } = this.state;
        return (
            <React.Fragment>
            <div className="episodes__panels">
                <div className="episodes__panels__header">
                    <div className="container">
                        <h2>Choose panels</h2>
                    </div>
                    <button 
                        type="button" 
                        className="close" 
                        onClick={this.handleClosePanelsInEpisode}
                        disabled={isSubmitting}
                    >
                        <span aria-hidden="true">
                            <SVG className="icon icon--close" src={close} />
                        </span>
                    </button>
                </div>
                <div className="episodes__panels__summary">
                    <div className="container">
                        <div className="inner">
                            <div className="row">
                                <div className="col">
                                    {/* <h4>Day 1 (Dose + 12hr)</h4> */}
                                    <h4>{this.displayEpisodeName()}</h4>
                                </div>
                                <div className="col text-right">
                                    <CantFindPanel />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PanelsForm

                    updateRegenrationChoice = {this.updateRegenrationChoice}
                    Kitcode = {this.state.Kitcode}
                    kitTubesDetail={this.state.kitTubesDetail}
                    addItems={this.addItems}
                    removeItem={this.removeItem}
                    updateQuantity={this.updateQuantity}
                    panels={this.state.panels}
                    addPanel={this.addPanel}
                    removePanel={this.removePanel}
                    updatePanel={this.updatePanel}
                    handleSubmit={this.handleSubmit}
                    fetchedPanels={this.state.fetchedPanels}
                    hasErrors={this.state.hasErrors}
                    errors={this.state.errors}
                    serverError={this.state.serverError}
                    CheckWOCBP={this.state.CheckWOCBP}
                    enableWOCBP={this.enableWOCBP}

                />
                <div className="episodes__panels__footer">
                    <div className="form-btn-container btn-container">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <button
                                        className="btn btn-secondary btn--cancel"
                                        onClick={this.handleClosePanelsInEpisode}
                                        disabled={isSubmitting}
                                    >
                                        Cancel
                                    </button>
                                </div>
                                <div className="col text-right">
                                    <button
                                        className="btn btn-primary btn--done"
                                        onClick={(e) => this.handleSubmit(e)}
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? <span className="spinner__wrapper"><span className="loading-spinner"></span></span> : 'Done'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                (isFetchingPanels || isLoadingEpisodeDetails || isSubmitting) ?
                    <Preloader />
                    : null
            }
            <ConfirmationDialog
                    _tube = {this._tube}
                    _quantity = {this._quantity}
                    noButtonText = {this.noButtonText}
                    yesButtonText = {this.yesButtonText}
                    confirmationMessage = {this.confirmationMessage}
                    modalIsOpen={this.state.showConfrimationDialog}
                    headingText = {this.headingText}
                    yesCallBack = {this.yesCallBack}
                    noCallBack = {this.yesCallBack}
                    closeModal={()=>{this.setState({showConfrimationDialog: false})}}
                />
            </React.Fragment>
        )
    }
}

export default PanelsContainer;

PanelsContainer.propTypes = {
    activeEpisode: PropTypes.shape({
        Day_period_id: PropTypes.number.isRequired,
        Panels: PropTypes.number.isRequired,
    }).isRequired,
    closePanelsInEpisode: PropTypes.func.isRequired,
    activeProtocolCohort: PropTypes.shape({
        Protocol_cohort_ID: PropTypes.number.isRequired,
        Study_participants: PropTypes.any.isRequired,
    }).isRequired,
    username: PropTypes.string.isRequired,
    activeEpisodeIndex: PropTypes.number.isRequired,
    updateEpisode: PropTypes.func.isRequired,
    protocolId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    participants: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};
