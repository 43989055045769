import React, { Component } from 'react'; 
import { PropTypes } from 'prop-types';
import Modal from 'react-modal';

import SVG from 'react-inlinesvg';
import close from './../../assets/images/close.svg';

Modal.setAppElement('#root');
class RemoveProtocolModal extends Component {

    handleRemoveProtocol = (index) => {
        this.props.removeProtocol(index);
        this.props.closeModal();
    }

    render() {
        const { modalIsOpen, closeModal, indexToBeRemoved } = this.props;
        return (
            <Modal
                className="modal__bootstrap modal--cancel-protocol-overview modal-dialog"
                overlayClassName="overlay"
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title">Delete protocol?</h3>
                        <button type="button" className="close" onClick={closeModal}>
                            <span aria-hidden="true">
                                <SVG className="icon icon--close" src={close} />
                            </span>
                            <span className="sr-only"></span>
                        </button>
                        </div>
                    <div className="modal-body">
                        <p>Please confirm that you want to delete protocol <b>{this.props.Protocol_code}</b>. This action cannot be undone and deleting the protocol will remove all the details in it.</p>
                    </div>
                    
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={closeModal}>Go back</button>
                        <button type="button" className="btn btn-primary" onClick={() => this.handleRemoveProtocol(indexToBeRemoved)}>Delete Protocol</button>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default RemoveProtocolModal;

RemoveProtocolModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    modalIsOpen: PropTypes.bool.isRequired,
    indexToBeRemoved: PropTypes.number,
    removeProtocol: PropTypes.func.isRequired,
    Protocol_code: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};