import React, { Component } from 'react'; 
import { PropTypes } from 'prop-types';
import Modal from 'react-modal';

import SVG from 'react-inlinesvg';
import close from '../../../../src/assets/images/close.svg';

Modal.setAppElement('#root');
class NewTubeModal extends Component {
    quantity = 1;
    handleConfirm = () => {
        console.log(this.tube, this.quantity)
        if(this.tube != null && this.quantity != null){
            this.props.closeModal();
            this.props.addItems(this.tube, this.quantity)
        }
    }
    handleQuantityChange = (e) => {

        this.quantity = e.target.value;
        console.log(this.quantity)
    }
    handleTubeChange = (e) => {
        this.tube = e.target.value;
        console.log(this.tube)
    }

    render() {
        // console.log('rendering modal')
        const { modalIsOpen, closeModal, fetchedTubes} = this.props;
        return (
            <Modal
                className="modal__bootstrap modal--cancel-protocol-overview modal-dialog"
                overlayClassName="overlay ki-overlay"
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
            >
                <div className="modal-content ki-modal-content">
                    <div className="ki-modal-header">
                        <span className="ki-modal-title">Add Kit Items</span>
                    </div>
                    <div className="ki-modal-body">
                        
                        <div className="row ki-modal-row">
                            <div className="col-md-4">
                                <label htmlFor="tube">Tube</label>
                                <select type="text" name="tube" id="tube" className="form-control" onChange={this.handleTubeChange}>
                                    <option value="">Select a tube</option>
                                    {/* List of tubes should be fetched from API */}
                                    { 
                                        fetchedTubes && fetchedTubes.map(fetchedTubes => {
                                            return (
                                                <option value={fetchedTubes.Tube} key={fetchedTubes.Tube}>{fetchedTubes.Tube}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>

                            <div className="col-md-4">
                                <label htmlFor="qty">Qty</label>
                                <input 
                                    type="number" 
                                    className="form-control"
                                    min={1}
                                    max={99}
                                    onChange={this.handleQuantityChange}
                                />
                            </div>

                        </div>
           
                    </div>
                    <div className="ki-modal-footer">
                        <button type="button" className="btn btn-primary" onClick={this.handleConfirm}>Confirm</button>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default NewTubeModal;

NewTubeModal.propTypes = {
    addTube: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    addItems: PropTypes.func.isRequired,
    modalIsOpen: PropTypes.bool.isRequired,
    fetchedTubes: PropTypes.array.isRequired,
};